// 일반계약 상세
import React, { useEffect, useState } from "react";
import "../../variables/RelationList";
import {
  Card,
  CardHeader,
  CardBody,
  Form,
  Row,
  Col,
  Spinner,
  Label,
  FormGroup,
  Input,
} from "reactstrap";
import { useParams } from "react-router-dom";
import { API } from "../../ApiConfig";
import instanceAxios from "components/reissue/InstanceAxios";
import ContractDetailButton from "variables/ContractDetailButton";
import FileDownView from "views/Support/FileDownView";
import ErrAlert from "variables/ErrAlert";

const titleName = "일반계약";

/**
 *
 * @returns 일반계약 리스트에서 클릭한 id값에 맞는 단일 게시물 상세보기 페이지를 리턴
 */
function ContractDetail1() {
  const [contract, setContract] = useState([]);
  const [loading, setLoading] = useState(true); // 로딩 상태 추가

  // 수정, 정보보기에 사용되는 클릭한 라이선스 id값
  const { id } = useParams();

  /**
   * 일반계약 데이터를 불러와 사용하기 편하도록 변환하는 함수
   */
  async function fetchData() {
    try {
      const response = await instanceAxios.get(`${API.CONTRACTLIST}/${id}`);
      const data = response.data?.data || [];
      setContract(data);
    } catch (error) {
      // 에러 처리
      ErrAlert({ err: "데이터 불러오기 실패" });
    } finally {
      // 로딩 상태 업데이트
      setLoading(false);
    }
  }

  useEffect(() => {
    // 페이지 로딩 시 라이선스 정보 가져오기
    fetchData();
  }, [id]); // ID가 변경될 때마다 useEffect 실행

  // 작업상태 값 변경 ex) WORKING -> 작업 진행중
  const [conStates, setConStates] = useState([]);
  // 계약 구분 값 변경
  const [divStates, setDivStates] = useState([]);
  // 남은 일자 계산
  const [remainingDays, setRemainingDays] = useState("-");

  useEffect(() => {
    // maintainData가 존재하고, maintainData가 배열일 경우에만 작업 상태 값을 설정
    const updatedConStates = () => {
      if (contract.state === "CONTRACT_COMPLETE") {
        return "계약 완료";
      } else if (contract.state === "CONTRACTING") {
        return "계약중";
      } else if (contract.state === "ETC") {
        return "기타";
      } else if (contract.state === "CONTRACT_EXPIRED") {
        return "계약 만료";
      } else {
        return "-";
      }
    };
    setConStates(updatedConStates);

    const updatedDivStates = () => {
      if (contract.division === "PERMANENT") {
        return "영구형";
      } else if (contract.division === "SUBSCRIBE") {
        return "구독형";
      } else if (contract.division === "OTHER") {
        return "기타";
      } else {
        return "-";
      }
    };
    setDivStates(updatedDivStates);

    // 남은 일자 계산
    if (contract.end) {
      const today = new Date();
      const endDate = new Date(contract.end);
      const diffTime = endDate.getTime() - today.getTime();
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      setRemainingDays(diffDays >= 0 ? diffDays : "-");
    } else {
      setRemainingDays("-");
    }

  }, [contract]);

  return (
    <>
      <div className="content">
        <Form>
          <Row>
            <Col md="12">
              <div className="detail-wrap">
                <Card className="card-user">
                  <CardHeader>
                    <h5 className="title">{titleName} 상세보기</h5>
                    <p className="category" style={{ fontSize: "17px" }}></p>
                  </CardHeader>
                  <CardBody>
                    {loading ? (
                      <Spinner color="primary" />
                    ) : (
                      <>
                        <Row>
                          <Col md="7">
                            <FormGroup>
                              <Label>계약명</Label>
                              <Input
                                className="detail-input"
                                value={contract.contract || "-"}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>구분</Label>
                              <Input
                                className="detail-input"
                                value={divStates || "-"}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>계약상태</Label>
                              <Input
                                className="detail-input"
                                value={conStates || "-"}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <FormGroup>
                          <Label>고객사</Label>
                          <Input
                            className="detail-input"
                            value={contract.customer || "-"}
                            disabled
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>프로젝트</Label>
                          <Input
                            className="detail-input"
                            value={contract.project || "-"}
                            disabled
                          />
                        </FormGroup>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label>계약 시작</Label>
                              <Input
                                className="detail-input"
                                value={contract.start || "-"}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>계약 종료</Label>
                              <Input
                                className="detail-input"
                                value={contract.end || "-"}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>남은일자</Label>
                              <Input
                                className="detail-input"
                                value={remainingDays || "-"}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label>고객사 담당자</Label>
                              <Input
                                className="detail-input"
                                value={contract.supervisorCus || "-"}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>영업 담당자(나인원)</Label>
                              <Input
                                className="detail-input"
                                value={contract.supervisorNo || "-"}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>영업 담당자(와탭)</Label>
                              <Input
                                className="detail-input"
                                value={contract.supervisorWt || "-"}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <FormGroup>
                          <Label>항목</Label>
                          {contract.arrange ? (
                            contract.arrange.map((item, index) => (
                              <div key={index}>
                                <Row>
                                  <Col md="2">
                                    <FormGroup>
                                      <Input
                                        className="detail-input"
                                        value={`${item.majorCategory} `}
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Input
                                        className="detail-input"
                                        value={`${item.middleCategory} `}
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Input
                                        className="detail-input"
                                        value={`${item.smallCategory} `}
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Input
                                        className="detail-input"
                                        value={`${item.itemDetail} `}
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </div>
                            ))
                          ) : (
                            <div>
                              <FormGroup>
                                <Input
                                  className="detail-input"
                                  value="-"
                                  disabled
                                />
                              </FormGroup>
                            </div>
                          )}
                        </FormGroup>
                        <FormGroup>
                          <Label>첨부파일</Label>
                          <div>
                            <FileDownView domain="CONTRACT" domainId={id} />
                          </div>
                        </FormGroup>
                        <FormGroup>
                          <Label>비고</Label>
                          <Input
                            className="detail-input"
                            value={contract.content || ""}
                            disabled
                            type="textarea"
                          />
                        </FormGroup>
                        <ContractDetailButton
                          domainId={id}
                          domain="CONTRACT"
                          suffix="1"
                          deleteTf={contract.deleteTAndF}
                        />
                      </>
                    )}
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}

export default ContractDetail1;
