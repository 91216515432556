import { API } from "ApiConfig";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

/**
 * 라우트 하단에 걸어주는 토큰 확인 컴포넌트
 * @returns 토큰이 없을 시 alert창과 함께 로그인 페이지로 돌려보냄
 */
const TokenAlert = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const storedToken = localStorage.getItem("accessToken");            // 로컬 스토리지에서 토큰 가져오기
    if (!storedToken && window.location.pathname !== `/${API.LOGIN}`) { // 토큰이 없고 현재 경로가 로그인 페이지가 아닌 경우
      Swal.fire({
        icon: "error",
        title: "로그인이 필요합니다.",
        text: `로그인 후 이용해주세요`,
      });
      navigate(`${API.LOGIN}`); // 로그인 페이지로 이동
    }
  }, [navigate]);
  return null;
};

export default TokenAlert;
