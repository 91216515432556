import React, { useEffect, useState } from "react";
import "../../variables/RelationList";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Form,
  Row,
  Col,
  Spinner,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import { API } from "../../ApiConfig";
import instanceAxios from "components/reissue/InstanceAxios";
import NoticeDetailButton from "variables/NoticeDetailButton";
import FileDownView from "views/Support/FileDownView";
import ErrAlert from "variables/ErrAlert";
import CkEditor from "variables/CkEditor";

const titleName = "공지사항";

/**
 *
 * @returns 프로젝트 리스트에서 클릭한 id값에 맞는 단일 게시물 상세보기 페이지를 리턴
 */
function NoticeDetail() {
  const navigate = useNavigate();
  const memRole = localStorage.getItem("memRole");
  // 수정, 정보보기에 사용되는 클릭한 프로젝트의 id값
  const { id } = useParams();

  /**
   * 프로젝트 데이터를 불러와 사용하기 편하도록 변환하는 함수
   */
  async function fetchData() {
    try {
      const response = await instanceAxios.get(`${API.NOTICELIST}/${id}`);
      const data = response.data?.data || [];
      setNotice(data);
    } catch (error) {
      // 에러 처리
      ErrAlert({ err: "데이터 불러오기 실패" });
    } finally {
      // 로딩 상태 업데이트
      setLoading(false);
    }
  }

  const [notice, setNotice] = useState([]);
  const [loading, setLoading] = useState(true); // 로딩 상태 추가

  useEffect(() => {
    // 페이지 로딩 시 프로젝트 정보 가져오기
    fetchData();
  }, [id]); // ID가 변경될 때마다 useEffect 실행

  // 공지사항이 숨김처리 되어있고, 권한이 사용자면 에러 띄움
  if (notice.hide && memRole === "USER") {
    ErrAlert({ err: "접근할 수 없는 권한입니다." });
    navigate(`/${API.NOTICE}`);
  }

  return (
    <>
      <div className="content">
        <Form>
          <Row>
            <Col md="12">
              <div className="detail-wrap">
                <Card className="card-user">
                  <CardHeader>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <h5 className="title">{titleName} 상세보기</h5>
                      {memRole === "ADMIN" ? (
                        <FormGroup>
                          <Input type="checkbox" checked={notice.hide} />
                          <Label>숨김 여부</Label>
                        </FormGroup>
                      ) : null}
                    </div>
                  </CardHeader>
                  <CardBody>
                    {loading ? (
                      <Spinner color="primary" />
                    ) : (
                      <>
                        <FormGroup>
                          <Label>제목</Label>
                          <Input
                            className="detail-input"
                            value={notice.title || "-"}
                            disabled={notice.hide}
                          />
                        </FormGroup>
                        <FormGroup>
                          <div>
                            <Label>첨부파일</Label>
                            <FileDownView domain="NOTICE" domainId={id} />
                          </div>
                        </FormGroup>
                        <FormGroup>
                          <Label>본문</Label>
                          {/* <Input
                            disabled
                            className="detail-input textArea"
                            defaultValue={notice.content || ""}
                            type="textarea"
                          /> */}
                          <CkEditor data={notice.content || ""} disabled={true} />
                        </FormGroup>
                        <NoticeDetailButton
                          domainId={id}
                          domain="NOTICE"
                          memRole={memRole}
                          deleteTf={notice.deleteTf}
                        />
                      </>
                    )}
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}

export default NoticeDetail;
