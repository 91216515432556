import Swal from "sweetalert2";

// 에러 알림창
const ErrAlert = ({ err }) => {
  const errAlert = () => {
    Swal.fire({
      icon: "error",
      title: "ERROR",
      text: err,
    });
  };

  // 컴포넌트 렌더링
  return errAlert();
};

export default ErrAlert;
