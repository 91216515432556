// table list 페이지
// 길이가 길어지면 아래로 내려가는게 나은지 스크롤이 있는게 나은지

import React, { useEffect } from "react";
import { useState } from "react";
import "../../assets/css/Notice.css";
// reactstrap components
import { CardTitle, Table, Spinner } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { API } from "../../ApiConfig";
import instanceAxios from "components/reissue/InstanceAxios";
import ErrAlert from "variables/ErrAlert";

/**
 * 어떤 리스트인지 나타내는 제목입니다.
 */
const titleName = "공지사항";

/**
 * 메인화면에 나오는 공지사항 리스트
 * @returns table형식의 list page를 리턴합니다.
 */
function NoticeListMain() {
  const [noticeListData, setNoticeListData] = useState([]);
  const [loading, setLoading] = useState(true); // 로딩 상태 추가

  async function fetchData(page = 1) {
    try {
      const response = await instanceAxios.get(
        `${API.NOTICELIST}?page=${page}`
      );
      const data = response?.data?.data.data || [];
      const lastestData = data
        .filter((item) => item.hide === false)
        .slice(0, 3);                 // 3개만 가져오기
      setNoticeListData(lastestData);
    } catch (error) {
      // 에러 처리
      ErrAlert({ err: "데이터 불러오기 실패" });
    } finally {
      // 로딩 상태 업데이트
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const navigate = useNavigate();

  /**
   * 배열을 페이지 번호에 맞춰 반복문을 돌려 담아주는 함수입니다.
   * @returns 게시글을 배열에 담아 리턴합니다.
   */
  function arrayList() {
    var array = [];

    // 초기 리스트에 데이터가 없을 때
    if (!noticeListData || noticeListData.length === 0) {
      return (
        <tr key="no-data" style={{ textAlign: "center" }}>
          <td colSpan="3">등록된 글이 없습니다.</td>
        </tr>
      );
    } else {
      // 초기 리스트에 데이터가 있을 때
      noticeListData.map((item, index) => {
        if (item.hide === false) {
          array.push(
            <tr
              key={item.id}
              id="move"
              onClick={() => navigate(`${API.NOTICEDETAIL}/${item.id}`)}
              style={{ textAlign: "center" }}
            >
              <div className={"notice-list-wrap"}>
                <div className={"notice-list"}>
                  <td>{noticeListData.length - index}</td>
                  <td>{item.title || "-"}</td>
                  <td>{item.createdDate}</td>
                </div>
              </div>
            </tr>
          );
        }
      });
    }

    return array;
  }

  return (
    <>
      <div className="content">
        <CardTitle className="cardTitle" tag="h5">
          {titleName}
        </CardTitle>
        {loading ? (
          <Spinner color="primary" />
        ) : (
          <>
            <Table>
              <thead className={"text-primary"}>
                <tr className={"notice-title-wrap"}>
                  <div className={"notice-title"}>
                    <th>번호</th>
                    <th>제목</th>
                    <th>작성일</th>
                  </div>
                </tr>
              </thead>
              <tbody>{arrayList()}</tbody>
            </Table>
          </>
        )}
      </div>
    </>
  );
}

export default NoticeListMain;
