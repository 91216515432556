/*!

=========================================================
* Paper Dashboard React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import Calendar from "./Calendar";
import React from "react";
// reactstrap components
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import NoticeListMain from "views/Notice/NoticeListMain";
// core components

function Dashboard() {
  return (
    <>
      <div className="content">
        {/* <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h5">공지사항</CardTitle>
              </CardHeader>
              <CardBody>여기에 리스트</CardBody>
            </Card>
          </Col>
        </Row> */}
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <NoticeListMain />
                <div style={{ marginBottom: "40px" }} />
                <CardTitle className="cardTitle" tag="h5">
                  업무 캘린더
                </CardTitle>
                {/* <p className="card-category">공지사항</p> */}
                <div
                  style={{
                    textAlign: "right",
                    fontSize: "1rem",
                  }}
                >
                  <p>
                    <span style={{ color: "#9487FF" }}>▣ 유지보수</span>
                    <span style={{ color: "#D551F6" }}>▣ 지원일정</span>
                    <span style={{ color: "gray" }}>▣ 라이선스</span>
                  </p>
                </div>
              </CardHeader>
              <CardBody>
                {/* 캘린더 */}
                <Calendar />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Dashboard;
