import { API } from "../../ApiConfig";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const LoadingPage = () => {
  const navigate = useNavigate();
  const url = new URL(window.location.href);
  const accessToken = url.searchParams.get("access_token");
  const refreshToken = url.searchParams.get("refresh_token");
  const memState = url.searchParams.get("member_state");
  const memRole = url.searchParams.get("member_role");

  useEffect(() => {
    if (memState === "APPROVE") {
      // 회원 상태가 'APPROVE'인 경우 실행
      // 로컬 스토리지에 토큰과 회원 역할 저장
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("memRole", memRole);

      //  addTokenToHeader(); // 헤더에 토큰 추가
      navigate(`${API.MAIN}`);

      // 강제로 새로고침
      window.location.reload();
    } else {
      // 회원 상태가 'APPROVE'가 아닌 경우
      // 경고 창 표시 후 로그인 페이지로 이동
      Swal.fire({
        icon: "warning",
        title: "관리자 승인이 필요합니다.",
      });
      navigate(`/${API.LOGIN}`);
    }
  }, [accessToken, navigate]);

  return null;
};

export default LoadingPage;
