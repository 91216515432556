import { useNavigate } from "react-router-dom";
import { Row, Button } from "reactstrap";
import { API } from "ApiConfig";
/**
 * 관리자 상세 페이지에서 사용되는 버튼 컴포넌트
 * @param {object} props - 부모 컴포넌트로부터 전달되는 속성
 * @param {string} props.domain - 도메인 이름
 * @param {string} props.domainId - 도메인 식별자
 * @returns MemberDetailButton 컴포넌트
 */
function MemberDetailButton(props) {
    const navigate = useNavigate();

    return (
        <Row>
            <div className="update mr-auto ml-auto">
                <Button
                    outline
                    color="success"
                    type="button"
                    onClick={() => navigate(`/${API[props.domain]}`)}
                >
                    목록
                </Button>
                <Button
                    outline
                    color="primary"
                    type="button"
                    onClick={() =>
                        navigate(`${API[props.domain + "MODIFY"]}` + `/${props.domainId}`)
                    }
                >
                    수정
                </Button>
            </div>
        </Row>
    )
}

export default MemberDetailButton;