/*!

=========================================================
* Paper Dashboard React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import { useState, useEffect } from "react";
import Select from "react-select";
import instanceAxios from "components/reissue/InstanceAxios";
import { useNavigate } from "react-router-dom";
import { API } from "ApiConfig";
import { fetchCustomers, fetchCustomerAddressesById } from "ApiUtils";
import FileUpload from "components/RegistForm/FileUpload";
import Swal from "sweetalert2";
import DeleteButton from "variables/DeleteButton";
import ToastAlert from "variables/ToastAlert";
import ErrAlert from "variables/ErrAlert";

/**
 * 담당자 등록 페이지
 * @author 김지연
 * @returns 담당자 등록 폼 리턴
 */

function SupervisorRegist() {
  // ======form submit 형식 =====================
  // const [values, setValues] = useState({
  //   supervisorClass: "", // 담당자 분류
  //   name: "", // 이름
  //   phone: "", //연락처
  //   email: "", // 이메일
  //   content: "", // 비고
  // });

  const [supervisorClass, setSupervisorClass] = useState("CUSTOMER"); // 담당자 분류
  const [name, setName] = useState(""); // 이름
  const [phone, setPhone] = useState(""); // 전화번호
  const [email, setEmail] = useState(""); // 이메일
  const [content, setContent] = useState(""); // 비고
  const [customer, setCustomer] = useState(""); // 고객사 ID
  const [cLocation, setCLocation] = useState(""); // 지점명
  const [file, setFile] = useState([]); // 첨부파일
  const [isCusInitial, setIsCusInitial] = useState(true); // 초기 상태를 추적합니다.
  const [phoneTouched, setPhoneTouched] = useState(false); // 전화번호 입력 여부를 추적합니다.

  //==========담당자 분류 리스트=============
  const supervisorDiv = [
    { value: "CUSTOMER", label: "고객사" },
    { value: "NINEONE", label: "나인원" },
    { value: "WATAB", label: "와탭" },
  ];
  //==============고객사, 지점명 정보 가져오기===============
  const [customerName, setCustomerName] = useState([]); // 고객사 명 리스트
  const [customerAddresses, setCustomerAddresses] = useState([]); // 지점명 리스트

  // 고객사 주소 불러오기
  useEffect(() => {
    const fetchData = async () => {
      try {
        const customers = await fetchCustomers();
        setCustomerName(customers);
      } catch (error) {
        ErrAlert({ err: "데이터 불러오기 실패" });
      }
    };

    fetchData();
  }, []);

  // 고객사 변경시 지점명 불러오기
  const handleCIdChange = async (selectedOption) => {
    const newCustomerId = selectedOption.value;
    const newCustomer = selectedOption.label;

    if(newCustomerId <= 3 && newCustomer === "나인원소프트") {
      setSupervisorClass("NINEONE");
    }
    if(newCustomerId <= 3 && newCustomer === "와탭") {
      setSupervisorClass("WATAB");
    }
    setCustomer(newCustomerId);
    setCustomerAddresses([]);

    try {
      if (newCustomerId) {
        const addresses = await fetchCustomerAddressesById(newCustomerId);
        setCustomerAddresses(addresses);
      }
    } catch (error) {
      ErrAlert({ err: "데이터 불러오기 실패" });
    }
    setIsCusInitial(false);
    setCLocation("");
  };

  // == 유효성 검사
  // 전화번호 입력 시 처리 함수
  const handlePhoneChange = (event) => {
    const inputPhone = event.target.value;

    // 입력된 값에서 숫자만 추출하여 하이픈을 추가하여 새로운 전화번호 형식으로 변환
    const formattedPhone = inputPhone
      .replace(/\D/g, "") // 숫자 이외의 문자 모두 제거
      .replace(/(\d{3})(\d{1,4})(\d{1,4})/, "$1-$2-$3"); // 전화번호 형식에 맞게 하이픈 추가

    setPhone(formattedPhone); // 상태값 업데이트
    setPhoneTouched(true);
  };

  // 전화번호 유효성 검사 함수
  const isValidPhone = () => {
    // 전화번호 입력이 있을 때만 유효성을 검사합니다.
    if (phoneTouched && phone.length > 0) {
      // 전화번호 형식에 맞는지 정규표현식을 통해 검사
      const phoneRegex = /^\d{3}-\d{4}-\d{4}$/;
      return phoneRegex.test(phone);
    }
    return true;
  };

  // form submit시 form 내부 내용 formData화 하여 내보내기
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isValidPhone()) {
      Swal.fire({
        icon: "error",
        title: "유효하지 않은 전화번호",
        text: "유효한 전화번호 형식이 아닙니다.",
        showCancelButton: false,
        confirmButtonText: "확인",
      });
    } else {
      Swal.fire({
        icon: "question",
        title: "등록",
        text: `등록 하시겠습니까?`,
        showCancelButton: true,
        confirmButtonText: "등록",
        cancelButtonText: "취소",
      }).then(async (res) => {
        if (res.isConfirmed) {
          const formData = new FormData();

          //텍스트 입력 필드 데이터 추가
          // customer id가 1이면 나인원, 2면 와탭, 그 외에는 고객사 분류로 등록
          formData.append("supervisorClass", supervisorClass);
          formData.append("customer", customer);
          formData.append("location", cLocation.value);
          formData.append("name", name);
          formData.append("phone", phone);
          formData.append("email", email);
          formData.append("content", content);

          /**
           * TODO 백(SupportServiceImpl에 파일 추가될시 파일 업로드 되는 거 확인)
           */
          for (var i = 0; i < file.length; i++) {
            formData.append("fileList", file[i]);
          }

          try {
            const response = await instanceAxios.post(
              `${API.SUPERVISORLIST}`,
              formData,
              {
                headers: {
                  "Content-Type": `multipart/form-data;charset=UTF-8`,
                },
              }
            );
            ToastAlert({ icon: "success", title: "등록이 완료되었습니다." });
            navigate(`/${API.SUPERVISOR}`);
          } catch (error) {
            ErrAlert({ err: "필수 항목을 확인해주세요" });
          }
        } else {
          //취소
        }
      });
    }
  };

  // 등록 폼
  const navigate = useNavigate();
  return (
    <div className="content">
      <div className="regist-wrap">
        <Card className="card-user">
          <CardHeader>
            <CardTitle tag="h5">담당자 등록</CardTitle>
          </CardHeader>
          <CardBody>
            <Form encType="multipart/form-data" onSubmit={handleSubmit}>
              {/* <Row>
                <Col md="4">
                  <FormGroup>
                    <label>담당자 분류</label>
                    <Select
                      name="supervisorClass"
                      options={supervisorDiv}
                      // onChange={(selectedOption) =>
                      //   handleChange({
                      //     target: {
                      //       name: "supervisorClass",
                      //       value: selectedOption.value,
                      //     },
                      //   })
                      // }
                      onChange={setSupervisorClass}
                    />
                  </FormGroup>
                </Col>
              </Row> */}
              <Row>
                <Col className="pr-1" md="7">
                  <FormGroup>
                    <label>고객사</label>
                    <Select
                      required
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          borderColor: isCusInitial
                            ? "#ff0000"
                            : provided.borderColor,
                          // 선택되었을 때와 선택되지 않았을 때의 테두리색을 지정할 수 있습니다.
                        }),
                      }}
                      placeholder="필수값입니다"
                      name="customer"
                      options={customerName}
                      onChange={handleCIdChange}
                    />
                  </FormGroup>
                </Col>
                <Col className="pl-1" md="5">
                  <FormGroup>
                    <label>지점명</label>
                    <Select
                      name="cLocation"
                      options={customerAddresses}
                      placeholder="지점명을 선택하세요"
                      onChange={(selectedOption) => {
                        setCLocation(selectedOption);
                      }}
                      value={cLocation}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="pr-1" md="3">
                  <FormGroup>
                    <label>이름</label>
                    <Input
                      required
                      className={name ? "is-valid" : "is-invalid"}
                      type="text"
                      name="name"
                      onChange={(event) => {
                        setName(event.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col className="px-1" md="4">
                  <FormGroup>
                    <label>연락처</label>
                    <Input
                      type="tel"
                      name="phone"
                      value={phone}
                      onChange={handlePhoneChange}
                      onBlur={isValidPhone}
                      className={isValidPhone() ? "is-valid" : "is-invalid"}
                    />
                    {/* 유효성 검사 실패 시 메시지 표시 */}
                    {!isValidPhone() && (
                      <div className="invalid-feedback">
                        유효한 전화번호 형식이 아닙니다.
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col className="pl-1" md="5">
                  <FormGroup>
                    <label>이메일</label>
                    <Input
                      type="email"
                      name="email"
                      onChange={(event) => {
                        setEmail(event.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <FileUpload file={file} setFile={setFile} />
              <Row>
                <Col md="12">
                  <FormGroup>
                    <label>비고</label>
                    <Input
                      type="textarea"
                      name="content"
                      onChange={(event) => {
                        setContent(event.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <DeleteButton
                  to={`/${API.LICSUPERVISORENSE}`}
                  text1="작성을 취소 하시겠습니까?"
                />
              </Row>
            </Form>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default SupervisorRegist;
