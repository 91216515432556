import React, { useEffect, useState } from "react";
import "../../variables/RelationList";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Form,
  Row,
  Col,
  Spinner,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { useParams } from "react-router-dom";
import { API } from "../../ApiConfig";
import instanceAxios from "components/reissue/InstanceAxios";
import DetailButton from "variables/DetailButton";
import FileDownView from "views/Support/FileDownView";
import ErrAlert from "variables/ErrAlert";
import CkEditor from "variables/CkEditor";

const titleName = "프로젝트";

/**
 *
 * @returns 프로젝트 리스트에서 클릭한 id값에 맞는 단일 게시물 상세보기 페이지를 리턴
 */
function ProjectDetail() {
  // 수정, 정보보기에 사용되는 클릭한 프로젝트의 id값
  const { id } = useParams();

  /**
   * 프로젝트 데이터를 불러와 사용하기 편하도록 변환하는 함수
   */
  async function fetchData() {
    try {
      const response = await instanceAxios.get(`${API.PROJECTLIST}/${id}`);
      const data = response.data?.data || [];
      setProject(data);
    } catch (error) {
      // 에러 처리
      ErrAlert({ err: "데이터 불러오기 실패" });
    } finally {
      // 로딩 상태 업데이트
      setLoading(false);
    }
  }
  const [project, setProject] = useState([]);
  const [loading, setLoading] = useState(true); // 로딩 상태 추가
  useEffect(() => {
    // 페이지 로딩 시 프로젝트 정보 가져오기
    fetchData();
  }, [id]); // ID가 변경될 때마다 useEffect 실행

  return (
    <>
      <div className="content">
        <Form>
          <Row>
            <Col md="12">
              <div className="detail-wrap">
                <Card className="card-user">
                  <CardHeader>
                    <h5 className="title">{titleName} 상세보기</h5>
                    <p className="category" style={{ fontSize: "17px" }}></p>
                  </CardHeader>
                  <CardBody>
                    {loading ? (
                      <Spinner color="primary" />
                    ) : (
                      <>
                        <FormGroup>
                          <Label>프로젝트</Label>
                          <Input
                            className="detail-input"
                            value={project.project || "-"}
                            disabled
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>고객사</Label>
                          <Input
                            className="detail-input"
                            value={project.customer || "-"}
                            disabled
                          />
                        </FormGroup>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label>영업 담당자(나인원)</Label>
                              <Input
                                className="detail-input"
                                value={project.supervisorNo || "-"}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>영업 담당자(와탭)</Label>
                              <Input
                                className="detail-input"
                                value={project.supervisorWt || "-"}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label>프로젝트 시작일</Label>
                              <Input
                                className="detail-input"
                                value={project.start || "-"}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>프로젝트 종료일</Label>
                              <Input
                                className="detail-input"
                                value={project.end || "-"}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <FormGroup>
                          <Label>작업자</Label>
                          <Input
                            className="detail-input"
                            value={
                              project.workers && project.workers.length > 0
                                ? project.workers.join(", ")
                                : "-"
                            }
                            disabled
                          />
                        </FormGroup>
                        <FormGroup>
                          <div>
                            <Label>첨부파일</Label>
                            <FileDownView domain="PROJECT" domainId={id} />
                          </div>
                        </FormGroup>
                        <FormGroup>
                          <Label>설명</Label>
                          {/* <Input
                            disabled
                            className="detail-input textArea"
                            defaultValue={project.content || ""}
                            type="textarea"
                          /> */}
                          <CkEditor data={project.content || ""} disabled={true} />
                        </FormGroup>
                        <DetailButton domainId={id} domain="PROJECT" deleteTf={project.deleteTAndF} />
                      </>
                    )}
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}

export default ProjectDetail;
