// 일반계약 상세
import React, { useEffect, useState } from "react";
import "../../variables/RelationList";
import {
  Card,
  CardTitle,
  CardHeader,
  CardBody,
  Form,
  Row,
  Col,
  Spinner,
  Label,
  FormGroup,
  Input,
} from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import { API } from "../../ApiConfig";
import instanceAxios from "components/reissue/InstanceAxios";
import { fetchCustomers, fetchProjectsByCustomerName } from "ApiUtils";
import Select from "react-select";
import FormJson from "components/RegistForm/FormJson";
import { fetchSupervisorByCustomerName } from "ApiUtils";
import { fetchSupervisors } from "ApiUtils";
import DeleteButton from "variables/DeleteButton";
import FileUpload from "components/RegistForm/FileUpload";
import OldFileUpload from "components/RegistForm/OldFileUpload";
import Swal from "sweetalert2";
import ToastAlert from "variables/ToastAlert";
import ErrAlert from "variables/ErrAlert";

/**
 *
 * @returns 일반계약 리스트에서 클릭한 id값에 맞는 단일 게시물 상세보기 페이지를 리턴
 */
function ContractCommonModify() {
  const { id } = useParams(); // id 파라미터
  const [loading, setLoading] = useState(true); // 로딩 상태 추가

  // 계약 상태 옵션
  const stateOptions = [
    { value: "CONTRACTING", label: "계약중" },
    { value: "CONTRACT_COMPLETE", label: "계약 완료" },
    { value: "CONTRACT_EXPIRED", label: "계약 만료" },
    { value: "ETC", label: "기타" },
  ];

  //계약 구분 옵션
  const divOptions = [
    { value: "SUBSCRIBE", label: "구독형" },
    { value: "PERMANENT", label: "영구형" },
    { value: "OTHER", label: "기타" },
  ];
  const [contractClass, setContractClass] = useState("");
  const [contractName, setContractName] = useState(""); // 계약명
  const [customer, setCustomer] = useState(""); // 고객사(고객사 id저장)
  const [start, setStart] = useState(""); // 계약 시작일
  const [end, setEnd] = useState(""); // 계약 종료일
  const [state, setState] = useState(""); // 계약 상태
  const [project, setProject] = useState(""); // 선택한 프로젝트
  const [supervisorCus, setSupervisorCus] = useState(""); // 선택한 고객사 담당자
  const [supervisorNo, setSupervisorNo] = useState(""); //나인원 담당자
  const [supervisorWt, setSupervisorWt] = useState(""); //와탭 담당자
  const [division, setDivision] = useState(""); //구분(구독형, 영구형..)
  const [content, setContent] = useState(""); // 비고

  const [file, setFile] = useState([]); // 첨부파일

  // FormJson 컴포넌트에서 입력한 내용을 상태로 관리
  const [dynamicFieldsData, setDynamicFieldsData] = useState([]); //계약 범위

  const [isWtInitial, setIsWtInitial] = useState(false); // 초기 상태를 추적합니다.
  const [isNoInitial, setIsNoInitial] = useState(false); // 초기 상태를 추적합니다.
  const [isSupInitial, setIsSupInitial] = useState(false); // 초기 상태를 추적합니다.
  const [isCusInitial, setIsCusInitial] = useState(false); // 초기 상태를 추적합니다.
  const [isPrjInitial, setIsPrjInitial] = useState(false); // 초기 상태를 추적합니다.

  const [startDateError, setStartDateError] = useState(false);

  //======= api load
  const [customerName, setCustomerName] = useState([]); // 고객사명 리스트
  const [projectName, setProjectName] = useState([]); // 프로젝트명 리스트
  const [CusList, setCusList] = useState([]); // 고객사 담당자 리스트
  const [NoList, setNoList] = useState([]); //나인원 담당자 저장소
  const [WtList, setWtList] = useState([]); //와탭 담당자 저장소
  const [Data, setData] = useState([]);

  // == 파일 업로드 수정 ==
  const [oldFileList, setOldFileList] = useState([]);
  const [domainFileList, setDomainFileList] = useState([]);

  //일반 계약 정보 조회 및 초기화
  useEffect(() => {
    /**
     * id값에 맞는 정보 조회
     */
    const mainData = async () => {
      try {
        const response = await instanceAxios.get(`${API.CONTRACTLIST}/${id}`);
        const data = response.data?.data || [];
        setData(data);

        // 구분 초기값
        const initialDiv = divOptions.find(
          (option) => option.value === data.division
        );
        if (initialDiv) {
          setDivision(initialDiv);
        }

        // 상태 초기값
        const initialState = stateOptions.find(
          (option) => option.value === data.state
        );
        if (initialState) {
          setState(initialState);
        }

        //아무것도 수정 안할 시 데이터 등록
        setContractClass(data.contractClass);
        setContractName(data.contract);
        setCustomer(data.customer);
        setStart(data.start);
        setEnd(data.end);
        setProject(data.project);
        setSupervisorCus(data.supervisorCus);
        setSupervisorNo(data.supervisorNo);
        setSupervisorWt(data.supervisorWt);
        setContent(data.content);

        setDomainFileList(data.fileResponseDtoList);

        setDynamicFieldsData(data.arrange);
        console.log(dynamicFieldsData);
        return data;
      } catch (error) {
        ErrAlert({ err: "데이터 불러오기 실패" });
      }
    };

    //고객사 정보 및 필요 정보 불러오기
    const fetchData = async () => {
      const data = await mainData();
      try {
        const customers = await fetchCustomers();
        customers.splice(0, 2);
        setCustomerName(customers);

        // 고객사 초기값
        const initialCustomer = customers.find(
          (option) => option.label === data?.customer
        );
        setCustomer(initialCustomer);

        // 고객사 초기값 설정 후 프로젝트 초기설정
        if (initialCustomer) {
          const [projects, supervisors, cusSupervisor] = await Promise.all([
            fetchProjectsByCustomerName(initialCustomer.value),
            fetchSupervisors(),
            fetchSupervisorByCustomerName(initialCustomer.label),
          ]);

          setProjectName(
            projects.map((project) => ({
              value: project.id,
              label: project.project,
            }))
          );

          // 프로젝트 초기값
          const initialProject = projects.find(
            (option) => option.label === data?.projects
          );

          setProject({
            value: initialProject.id,
            label: initialProject.project,
          });

          // 고객사 담당자 초기값 세팅
          const initialSupervisorCus = cusSupervisor.find(
            (option) => option.supervisor === data?.supervisorCus
          );
          setSupervisorCus({
            value: initialSupervisorCus.id,
            label: initialSupervisorCus.supervisor,
          });

          setCusList(
            cusSupervisor.map((supervisor) => ({
              value: supervisor.id,
              label: supervisor.supervisor,
            }))
          );

          // 담당자 초기값 세팅
          const initialSupervisorNo = supervisors.supervisorsNo.find(
            (option) => option.label === data?.supervisorNo
          );
          const initialSupervisorWt = supervisors.supervisorsWt.find(
            (option) => option.label === data?.supervisorWt
          );
          setSupervisorNo(initialSupervisorNo);
          setSupervisorWt(initialSupervisorWt);
          setNoList(supervisors.supervisorsNo);
          setWtList(supervisors.supervisorsWt);
        }
      } catch (error) {
        ErrAlert({ err: "데이터 불러오기 실패" });
      } finally {
        // 로딩 상태 업데이트
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  //고객사 선택시 맞는 프로젝트 불러오기
  const handleCustomerChange = async (selectedOption) => {
    const newCustomerName = selectedOption.label;
    const newCustomerId = selectedOption.value;
    setCustomer(selectedOption);
    setProjectName([]);
    setCusList([]);
    setIsSupInitial(true);
    setIsPrjInitial(true);

    try {
      if (newCustomerName) {
        const fetchData = async () => {
          const [supervisors] = await Promise.all([
            fetchSupervisorByCustomerName(newCustomerName),
          ]);

          setCusList(
            supervisors.map((supervisor) => ({
              value: supervisor.id,
              label: supervisor.supervisor,
            }))
          );
        };

        fetchData();
      }
      if (newCustomerId) {
        const fetchProject = async () => {
          const projects = await fetchProjectsByCustomerName(newCustomerId);

          setProjectName(
            projects.map((project) => ({
              value: project.id,
              label: project.project,
            }))
          );
        };

        fetchProject();
      }

    } catch (error) {
      ErrAlert({ err: "데이터 불러오기 실패" });
    }

    setProject("");
    setSupervisorCus("");
  };

  // 동적 필드 데이터 변경 시 상태 업데이트
  const handleDynamicFieldsChange = (data) => {
    setDynamicFieldsData(data);
  };

  // 삭제된 필드의 인덱스를 받아와 해당 필드를 제거하는 함수
  const handleRemoveField = (indexToRemove) => {
    setDynamicFieldsData((prevData) => {
      // 인덱스를 기준으로 필드를 제거한 새로운 배열 생성 .. 요소 제외 인덱스만 사용
      const newData = prevData.filter((_, index) => index !== indexToRemove);
      return newData;
    });
  };

  // 계약 시작일 변경 시 실행되는 함수
  const handleStartDateChange = (event) => {
    const selectedStartDate = event.target.value;
    setStart(selectedStartDate);
    // 종료일이 설정되어 있고 시작일이 종료일보다 이후인 경우 오류 설정
    if (end && selectedStartDate > end) {
      setStartDateError(true);
    } else {
      setStartDateError(false);
    }
  };

  // 계약 종료일 변경 시 실행되는 함수
  const handleEndDateChange = (event) => {
    const selectedEndDate = event.target.value;
    setEnd(selectedEndDate);

    // 시작일이 설정되어 있고 종료일이 시작일보다 이전인 경우 오류 설정
    if (selectedEndDate < start) {
      setStartDateError(true);
    } else {
      setStartDateError(false);
    }
  };

  // ======== form submit ========
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (startDateError) {
      Swal.fire({
        icon: "warning",
        title: "주의",
        text: `시작일/종료일을 확인해주세요.`,
        showCancelButton: false,
        confirmButtonText: "확인",
      })
    } else {
      Swal.fire({
        icon: "question",
        title: "수정",
        text: `수정 하시겠습니까?`,
        showCancelButton: true,
        confirmButtonText: "저장",
        cancelButtonText: "취소",
      }).then(async (res) => {
        if (res.isConfirmed) {
          const formData = new FormData();

          formData.append("contractClass", contractClass);
          formData.append("contractName", contractName);
          formData.append("customer", customer.value);
          formData.append("start", start || "");
          formData.append("end", end || "");
          formData.append("state", state.value || "ETC");
          formData.append("project", project.value);
          formData.append("division", division.value);
          formData.append("supervisorCus", supervisorCus.value);
          formData.append("supervisorNo", supervisorNo.value || 1);
          formData.append("supervisorWt", supervisorWt.value || 2);
          formData.append("content", content);
          formData.append("oldFileList", oldFileList);

          // 파일 등록
          for (var i = 0; i < file.length; i++) {
            formData.append("newFileList", file[i]);
          }

          if (dynamicFieldsData) {
            dynamicFieldsData.forEach((info, index) => {
              formData.append(
                `arrange[${index}].majorCategory`,
                info.majorCategory
              );
              formData.append(
                `arrange[${index}].middleCategory`,
                info.middleCategory
              );
              formData.append(
                `arrange[${index}].smallCategory`,
                info.smallCategory
              );
              formData.append(`arrange[${index}].itemDetail`, info.itemDetail);
            });
          } else {
          }

          try {
            const response = await instanceAxios.put(
              `${API.CONTRACTLIST}/${id}`,
              formData,
              {
                headers: {
                  "Content-Type": "application/form-data;charset=UTF-8",
                },
              }
            );

            ToastAlert({ icon: "success", title: "수정이 완료되었습니다." });
            navigate(`${API.CONTRACTDETAIL1}/${id}`);
          } catch (error) {
            ErrAlert({ err: "필수 항목을 확인해주세요" });
          }
        } else {
          //취소
        }
      });
    }

  };

  // ========== form =============== //
  const navigate = useNavigate();
  return (
    <>
      <div className="content">
        <Form encType="multipart/form-data" onSubmit={handleSubmit}>
          <Row>
            <Col md="12">
              <div className="detail-wrap">
                <Card className="card-user">
                  <CardHeader>
                    <CardTitle tag="h5">일반 계약 수정</CardTitle>
                  </CardHeader>
                  <CardBody>
                    {loading ? (
                      <Spinner color="primary" />
                    ) : (
                      <>
                        <Row>
                          <Col md="7">
                            <FormGroup>
                              <Label>계약명</Label>
                              <Input
                                required
                                className={
                                  contractName ? "is-valid" : "is-invalid"
                                }
                                placeholder="필수로 입력해주세요."
                                onChange={(event) => {
                                  setContractName(event.target.value);
                                }}
                                defaultValue={Data.contract}
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>구분</Label>
                              <Select
                                name="divison"
                                options={divOptions}
                                onChange={setDivision}
                                value={division}
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>계약상태</Label>
                              <Select
                                name="state"
                                options={stateOptions}
                                onChange={setState}
                                value={state}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <FormGroup>
                          <Label>고객사</Label>
                          <Select
                            required
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                borderColor: isCusInitial
                                  ? "#ff0000"
                                  : provided.borderColor,
                                // 선택되었을 때와 선택되지 않았을 때의 테두리색을 지정할 수 있습니다.
                              }),
                            }}
                            placeholder="필수값입니다"
                            options={customerName}
                            onChange={handleCustomerChange}
                            value={customer}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>프로젝트</Label>
                          <Select
                            name="cProject"
                            required
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                borderColor: isPrjInitial
                                  ? "#ff0000"
                                  : provided.borderColor,
                                // 선택되었을 때와 선택되지 않았을 때의 테두리색을 지정할 수 있습니다.
                              }),
                            }}
                            placeholder="필수값입니다"
                            options={projectName}
                            onChange={(selectedOption) => {
                              setProject(selectedOption);
                              setIsPrjInitial(false);
                            }}
                            value={project}
                          />
                        </FormGroup>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label>계약 시작</Label>
                              <Input
                                type="date"
                                name="start"
                                onChange={handleStartDateChange}
                                defaultValue={start}
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>계약 종료</Label>
                              <Input
                                type="date"
                                name="end"
                                onChange={handleEndDateChange}
                                defaultValue={end}
                              />
                              {startDateError && (
                                <span style={{ color: "red" }}>
                                  계약 종료일은 계약 시작일 이후여야 합니다.
                                </span>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label>고객사 담당자</Label>
                              <Select
                                name="supervisorCus"
                                options={CusList}
                                onChange={(selectedOption) => {
                                  setSupervisorCus(selectedOption);
                                  setIsSupInitial(false);
                                }}
                                value={supervisorCus}
                                styles={{
                                  control: (provided, state) => ({
                                    ...provided,
                                    borderColor: isSupInitial
                                      ? "#ff0000"
                                      : provided.borderColor,
                                    // 선택되었을 때와 선택되지 않았을 때의 테두리색을 지정할 수 있습니다.
                                  }),
                                }}
                                placeholder="필수값입니다"
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>영업 담당자(나인원)</Label>
                              <Select
                                name="supervisorNo"
                                options={NoList}
                                onChange={(event) => {
                                  setSupervisorNo(event);
                                  setIsNoInitial(false);
                                }}
                                value={supervisorNo}
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>영업 담당자(와탭)</Label>
                              <Select
                                name="supervisorWt"
                                options={WtList}
                                onChange={(event) => {
                                  setSupervisorWt(event);
                                  setIsWtInitial(false);
                                }}
                                value={supervisorWt}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <FormGroup>
                          <Label>항목</Label>
                          <FormJson
                            onChange={handleDynamicFieldsChange}
                            data={dynamicFieldsData}
                            onRemoveField={handleRemoveField}
                            isEditMode={true}
                          />
                        </FormGroup>
                        <Row style={{ paddingLeft: "0.2em" }}>
                          <Col md="6">
                            <FormGroup>
                              <label>기존 첨부 파일</label>
                              <OldFileUpload
                                domainFileList={domainFileList}
                                setDomainFileList={setDomainFileList}
                                setOldFileList={setOldFileList}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label>신규 첨부 파일</label>
                              <FileUpload file={file} setFile={setFile} />
                            </FormGroup>
                          </Col>
                        </Row>
                        <FormGroup>
                          <Label>비고</Label>
                          <Input
                            type="textarea"
                            name="content"
                            onChange={(selectedOption) => {
                              setContent(selectedOption.target.value);
                            }}
                            defaultValue={Data.content}
                          />
                        </FormGroup>
                        <Row>
                          <DeleteButton
                            to={`${API.CONTRACTDETAIL1}/${id}`}
                            text1="수정을 취소하시겠습니까?"
                          />
                        </Row>
                      </>
                    )}
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}

export default ContractCommonModify;
