// 담당자 상세페이지

import { API } from "../../ApiConfig";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import instanceAxios from "components/reissue/InstanceAxios";
import ErrAlert from "variables/ErrAlert";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import MemberDetailButton from "variables/MemberDetailButton";

const titleName = "멤버";

/**
 * 멤버 데이터를 불러와 사용하기 편하도록 변환하는 함수
 */

function MemberDetail() {
  const [member, setMember] = useState([]);         // 멤버 상태 및 설정 함수
  const [detailName, setDetailName] = useState(""); // 세부 이름 상태 및 설정 함수
  const [loading, setLoading] = useState(true);     // 로딩 상태 추가

  const { id } = useParams();                       // URL에서 ID 가져오기

  async function fetchData() {
    try {
      // 멤버 데이터 가져오기
      const response = await instanceAxios.get(`${API.MEMBERLIST}/${id}`);
      const data = response.data?.data || [];

      setMember(data); // 멤버 상태 업데이트
      setDetailName(detailName); // 세부 이름 상태 업데이트
    } catch (error) {
      ErrAlert({ err: "데이터 불러오기 실패" });
      // 에러 처리
    } finally {
      // 로딩 상태 업데이트
      setLoading(false);
    }
  }

  useEffect(() => {
    // 페이지 로딩 시 프로젝트 정보 가져오기
    fetchData();
  }, [id]); // ID가 변경될 때마다 useEffect 실행

  return (
    <>
      <div className="content">
        <Form>
          <Row>
            <Col md="12">
              <div className="detail-wrap">
                <Card className="card-user">
                  <CardHeader>
                    <h5 className="title">{titleName} 상세보기</h5>
                    <p className="category" style={{ fontSize: "17px" }}>
                      {detailName}
                    </p>
                  </CardHeader>
                  <CardBody>
                    {loading ? (
                      <Spinner color="primary" />
                    ) : (
                      <>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label>이름</Label>
                              <Input
                                className="detail-input"
                                value={member.name || "-"}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>이메일</Label>
                              <Input
                                className="detail-input"
                                value={member.email || "-"}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label>권한</Label>
                              <Input
                                className="detail-input"
                                value={
                                  member.role === "ADMIN"
                                    ? "관리자"
                                    : member.role === "USER"
                                      ? "사용자"
                                      : member.state || "-"
                                }
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>상태</Label>
                              <Input
                                className="detail-input"
                                value={
                                  member.state === "STANDBY"
                                    ? "대기"
                                    : member.state === "APPROVE"
                                      ? "승인"
                                      : member.state === "LEAVE"
                                        ? "탈퇴"
                                        : member.state || "-"
                                }
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <MemberDetailButton domainId={id} domain="MEMBER" />
                      </>
                    )}
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}

export default MemberDetail;
