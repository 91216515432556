import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  Spinner,
} from "reactstrap";
import { useState, useEffect } from "react";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import { API } from "ApiConfig";
import instanceAxios from "components/reissue/InstanceAxios";
import { fetchSupervisors } from "ApiUtils";
import FileUpload from "components/RegistForm/FileUpload";
import OldFileUpload from "components/RegistForm/OldFileUpload";
import DeleteButton from "variables/DeleteButton";
import Swal from "sweetalert2";
import ToastAlert from "variables/ToastAlert";
import ErrAlert from "variables/ErrAlert";

/**
 * 고객사 수정 페이지
 * @author 김지연, 김예함(기능, css)
 * @returns 고객사 수정 폼 리턴
 * @todo 연락처 유효성 검사 필요
 */
function CustomerModify() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true); // 로딩 상태 추가

  useEffect(() => {
    /**
     * id값에 맞는 고객사정보 조회
     */
    const fetchData = async () => {
      try {
        const response = await instanceAxios.get(`${API.CUSTOMERLIST}/${id}`);
        const data = response.data?.data || [];
        setData(data);
        setName(data.customer);
        setNote(data.note);
        setDomainFileList(data.fileResponseDtoList);
        return data; // 데이터 반환
      } catch (error) {
        ErrAlert({ err: "데이터 불러오기 실패" });
      }
    };

    /**데이터를 불러온 후 초기 데이터 세팅 */
    const fetchSupervisorData = async () => {
      const data = await fetchData();
      try {
        const { supervisorsNo, supervisorsWt } = await fetchSupervisors();
        setNoList(supervisorsNo);
        setWtList(supervisorsWt);

        const initialSupervisorNo = supervisorsNo.find(
          (option) => option.label === data?.supervisorNo
        );

        const initialSupervisorWt = supervisorsWt.find(
          (option) => option.label === data?.supervisorWt
        );
        setSupervisorNo(initialSupervisorNo);
        setSupervisorWt(initialSupervisorWt);
      } catch (error) {
        ErrAlert({ err: "데이터 불러오기 실패" });
      } finally {
        // 로딩 상태 업데이트
        setLoading(false);
      }
    };

    fetchSupervisorData();

    // 데이터를 가져올 때까지 기다림
    const fetchDataAndInitialize = async () => {
      const data = await fetchData();

      // customerAddress 초기화
      const initialCustomerAddress = data.customerAddress
        ? data.customerAddress.map((addressItem) => ({
          location: addressItem.location || "",
          address: addressItem.address || "",
        }))
        : [{ location: "", address: "" }];
      setCustomerAddressInfoList(initialCustomerAddress);
    };

    fetchDataAndInitialize();
  }, [id]);

  const [Data, setData] = useState([]);
  const [name, setName] = useState([]);
  const [note, setNote] = useState([]); //비고
  const [NoList, setNoList] = useState([]); //나인원 담당자 저장소
  const [WtList, setWtList] = useState([]); //와탭 담당자 저장소
  const [customerAddressInfoList, setCustomerAddressInfoList] = useState([
    { location: "", address: "" },
  ]);
  const [supervisorNo, setSupervisorNo] = useState("");
  const [supervisorWt, setSupervisorWt] = useState("");
  const [file, setFile] = useState([]);


  // == 파일 업로드 수정 ==
  const [domainFileList, setDomainFileList] = useState([]);
  const [oldFileList, setOldFileList] = useState([]);

  /**
   * 주소 필드 추가
   */
  const addAddress = () => {
    const updatedAddressList = [
      ...customerAddressInfoList,
      { location: "", address: "" },
    ];
    setCustomerAddressInfoList(updatedAddressList);
  };
  /**
   * 주소 필드 제거
   * @param {number} i - 주소 필드 배열의 인덱스
   * @requires useState(react)
   * @returns 주소 필드 배열의 i번째 항목을 1개 제거 후 리턴
   */
  const removeAddress = (i) => {
    const updatedAddressList = [...customerAddressInfoList];
    updatedAddressList.splice(i, 1);
    setCustomerAddressInfoList(updatedAddressList);
  };
  const handleInputChange = (index, event) => {
    const updatedAddressList = [...customerAddressInfoList];
    updatedAddressList[index][event.target.name] = event.target.value;
    setCustomerAddressInfoList(updatedAddressList);
  };

  // form submit시 form 내부 내용 JSON화 하여 내보내기
  const handleSubmit = async (e) => {
    e.preventDefault();
    Swal.fire({
      icon: "question",
      title: "수정",
      text: `수정 하시겠습니까?`,
      showCancelButton: true,
      confirmButtonText: "저장",
      cancelButtonText: "취소",
    }).then(async (res) => {
      if (res.isConfirmed) {
        const formData = new FormData();

        formData.append("name", name);
        formData.append("supervisorNo", supervisorNo.value || 1);
        formData.append("supervisorWt", supervisorWt.value || 2);
        formData.append("note", note);
        formData.append("oldFileList", oldFileList);

        customerAddressInfoList.forEach((info, index) => {
          formData.append(
            `customerAddressInfoList[${index}].location`,
            info.location
          );
          formData.append(
            `customerAddressInfoList[${index}].address`,
            info.address
          );
        });

        /**
         * TODO 백(SupportServiceImpl에 파일 추가될시 파일 업로드 되는 거 확인)
         */
        for (var i = 0; i < file.length; i++) {
          formData.append("newFileList", file[i]);
        }

        try {
          const response = await instanceAxios.put(
            `${API.CUSTOMERLIST}/${id}`, //
            formData,
            {
              headers: {
                "Content-Type": "application/form-data;charset=UTF-8",
              },
            }
          );
          ToastAlert({ icon: "success", title: "수정이 완료되었습니다." });
          navigate(`${API.CUSTOMERDETAIL}/${id}`);
        } catch (error) {
          ErrAlert({ err: "필수 항목을 확인해주세요" });
        }
      } else {
        //취소
      }
    });
  };

  const navigate = useNavigate();
  return (
    <div className="content">
      <div className="detail-wrap">
        <Card className="card-user">
          <CardHeader>
            <CardTitle tag="h5">고객사 수정</CardTitle>
          </CardHeader>
          <CardBody>
            {loading ? (
              <Spinner color="primary" />
            ) : (
              <>
                <Form encType="multipart/form-data" onSubmit={handleSubmit}>
                  <FormGroup>
                    <Label>고객사명</Label>
                    <Input
                      required
                      className={name ? "is-valid" : "is-invalid"}
                      placeholder="필수로 입력해주세요."
                      type="text"
                      name="name"
                      onChange={(event) => {
                        setName(event.target.value);
                      }}
                      defaultValue={Data.customer}
                    />
                  </FormGroup>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <div>
                          {customerAddressInfoList.map((address, index) => {
                            return (
                              <div key={index}>
                                <Row>
                                  <Col className="pr-1" md="2">
                                    <Label>지점명</Label>
                                    <Input
                                      type="text"
                                      name="location"
                                      value={address.location || ""}
                                      onChange={(e) =>
                                        handleInputChange(index, e)
                                      }
                                    />
                                  </Col>
                                  <Col className="px-1" md="9">
                                    <Label>주소</Label>
                                    <Input
                                      type="text"
                                      name="address"
                                      value={address.address || ""}
                                      onChange={(e) =>
                                        handleInputChange(index, e)
                                      }
                                    />
                                  </Col>
                                  <Col className="pl-1" md="1">
                                    <Button
                                      type="button"
                                      onClick={
                                        index === 0
                                          ? addAddress
                                          : () => removeAddress(index)
                                      }
                                      style={{ marginTop: "25px" }}
                                    >
                                      {index === 0 ? "+" : "-"}
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            );
                          })}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>영업 담당자(나인원)</Label>
                        <Select
                          placeholder="필수값입니다"
                          name="supervisorNo"
                          options={NoList}
                          onChange={(event) => {
                            setSupervisorNo(event);
                          }}
                          value={supervisorNo}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for="examplePassword">영업 담당자(와탭)</Label>
                        <Select
                          placeholder="필수값입니다"
                          name="supervisorWt"
                          options={WtList}
                          onChange={(event) => {
                            setSupervisorWt(event);
                          }}
                          value={supervisorWt}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{ paddingLeft: "0.2em" }}>
                    <Col md="6">
                      <FormGroup>
                        <label>기존 첨부 파일</label>
                        <OldFileUpload
                          domainFileList={domainFileList}
                          setDomainFileList={setDomainFileList}
                          setOldFileList={setOldFileList}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label>신규 첨부 파일</label>
                        <FileUpload file={file} setFile={setFile} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>비고</label>
                        <Input
                          type="textarea"
                          name="note"
                          onChange={(event) => {
                            setNote(event.target.value);
                          }}
                          defaultValue={Data.note}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <DeleteButton
                      to={`${API.CUSTOMERDETAIL}/${id}`}
                      text1="수정을 취소하시겠습니까?"
                    />
                  </Row>
                </Form>
              </>
            )}
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
export default CustomerModify;
