// notification

/*!

=========================================================
* Paper Dashboard React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { API } from "../../ApiConfig";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Container,
} from "reactstrap";

import Logout from "views/Login/Logout";
import Notification from "views/Main/Notification";
import MaterialIcon from "react-google-material-icons";

function DemoNavbar(props) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [color, setColor] = React.useState("transparent");
  const sidebarToggle = React.useRef();
  const location = useLocation();

  const toggle = () => {
    if (isOpen) {
      setColor("transparent");
    } else {
      setColor("dark");
    }
    setIsOpen(!isOpen);
  };

  const openSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    sidebarToggle.current.classList.toggle("toggled");
  };
  // function that adds color dark/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && isOpen) {
      setColor("dark");
    } else {
      setColor("transparent");
    }
  };
  React.useEffect(() => {
    window.addEventListener("resize", updateColor.bind(this));
  });
  React.useEffect(() => {
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      sidebarToggle.current.classList.toggle("toggled");
    }
  }, [location]);

  return (
    // add or remove classes depending if we are on full-screen-maps page or not
    <Navbar
      color={
        location.pathname.indexOf("full-screen-maps") !== -1 ? "dark" : color
      }
      expand="lg"
      className={
        location.pathname.indexOf("full-screen-maps") !== -1
          ? "navbar-absolute fixed-top"
          : "navbar-absolute fixed-top " +
            (color === "transparent" ? "navbar-transparent " : "")
      }
    >
      <Container fluid>
        <div className="navbar-wrapper">
          <div className="navbar-toggle">
            <button
              type="button"
              ref={sidebarToggle}
              className="navbar-toggler"
              onClick={() => openSidebar()}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <NavbarBrand href={API.MAIN} className="text-primary">
            <MaterialIcon icon="home" size={30} />
          </NavbarBrand>
        </div>

        <NavbarToggler onClick={toggle}>
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
        </NavbarToggler>

        <Collapse isOpen={isOpen} navbar className="justify-content-end">
          {/* 검색창 컴포넌트 */}
          {/* <form>
            <InputGroup className="no-border">
              <Input placeholder="Search..." />
              <InputGroupAddon addonType="append">
                <InputGroupText>
                  <i className="nc-icon nc-zoom-split" />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </form> */}

          <Notification />

          <Nav navbar>
            {/* 기존 무슨 아이콘인지 모르겠음 */}
            {/* <NavItem>
              <Link to="#pablo" className="nav-link btn-magnify">
                <i className="nc-icon nc-layout-11" />
                <p>
                  <span className="d-lg-none d-md-block">Stats</span>
                </p>
              </Link>
            </NavItem> */}

            {/*
          
            <Dropdown
              nav
              isOpen={dropdownOpen}
              toggle={(e) => dropdownToggle(e)}
            >
              <DropdownToggle caret nav>
                <i className="nc-icon nc-bell-55" />
                <p>
                  <span className="d-lg-none d-md-block">Some Actions</span>
                </p>
              </DropdownToggle>
      
              <DropdownMenu right>
                {" "}
          
                <Table style={{textAlign:"center", fontSize:"0.7em", width:"23em"}}>
                  <tr style={{fontSize:"2.0em", fontWeight:"bold", backgroundColor:"whitesmoke", width:"28.5em"}}>
                    <td colSpan={5}>
                    알림📬
                    </td>
                  </tr>
                  <tr
                  style={{border:"1px solid white"}} 
                   onChange={(event)=>{
                    setPeriod(event.target.value);
                  }}>
                    <td style={{color:"darkblue"}}>만료 기간</td>
                    <td><label>7일 <input type="radio" name="period" value="seven" defaultChecked></input></label></td>
                    <td><label>30일 <input type="radio" name="period" value="month"></input></label></td>
                  </tr>
                  <tr
                  style={{border:"1px solid white"}} 
                    onChange={(event)=>{
                      setNoticeClass(event.target.value);
                    }}
                  >
                    <td style={{color:"darkblue"}}>분류</td>
                    <td><label>전체 <input type="radio" name="noticeClass" value="ALL" defaultChecked></input></label></td>
                    <td><label>라이선스 <input type="radio" name="noticeClass" value="LICENSE"></input></label></td>
                  </tr>
                  <tr
                    style={{border:"1px solid white"}} 
                    onChange={(event)=>{
                      setNoticeClass(event.target.value);
                    }}
                  >
                    <td><label>계약(전체) <input type="radio" name="noticeClass" value="CONTRACTALL"></input></label></td>
                    <td><label>계약(설치) <input type="radio" name="noticeClass" value="CONTRACT1"></input></label></td>
                    <td><label>계약(유지보수) <input type="radio" name="noticeClass" value="CONTRACT2"></input></label></td>
                  </tr>
                </Table>                          
                  {showTable()}
              </DropdownMenu>
            </Dropdown>
          */}


            {/* <NavItem>
              <Link
                to={`${API.MYPAGE}`}
                className="nav-link btn-rotate text-primary"
                style={{ marginRight: "-5px" }}
              >
                <MaterialIcon icon="person" size={30} />
              </Link>
            </NavItem> */}
            
            {/* <NavItem>
              <Link
                onClick={tokenRemove}
                to="/admin/LoginPage"
                className="nav-link btn-rotate"
                style={{ marginRight: "-5px" }}
              >
                <i className="nc-icon nc-user-run" />
              </Link>
            </NavItem> */}
            <Logout />
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default DemoNavbar;
