import { API } from "ApiConfig";
import instanceAxios from "components/reissue/InstanceAxios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Row } from "reactstrap";
import Swal from "sweetalert2";
import ErrAlert from "./ErrAlert";

/**
 * 공지사항 상세 페이지에서 사용되는 버튼 컴포넌트
 * @param {object} props - 부모 컴포넌트로부터 전달되는 속성
 * @param {string} props.domain - 도메인 이름
 * @param {string} props.domainId - 도메인 식별자
 * @param {string} props.memRole - 회원 권한
 * @param {boolean} props.deleteTf - 삭제 여부
 * @returns NoticeDetailButton 컴포넌트
 */
function NoticeDetailButton(props) {
  const navigate = useNavigate();

  /**
   * 게시물 삭제 처리
   */
  const handleDelete = async () => {
    Swal.fire({
      icon: "warning",
      title: "삭제",
      text: `게시물을 삭제 하시겠습니까?`,
      showCancelButton: true,
      confirmButtonText: "삭제",
      cancelButtonText: "취소",
    }).then(async (res) => {
      if (res.isConfirmed) {
        try {
          // 여기서 서버에 DELETE 요청
          await instanceAxios.patch(
            `${API[props.domain + "LIST"]}` + `/${props.domainId}` + "/delete"
          );
          // 서버 응답에 따른 동작 수행
          Swal.fire({
            icon: "success",
            title: "삭제",
            text: `삭제 되었습니다!`,
          });
          navigate(`/${API[props.domain]}`);
        } catch (error) {
          let errorMessage = "삭제 요청을 처리하는 도중 오류가 발생했습니다.";
          if (error.response) {
            const statusCode = error.response.status;
            switch (statusCode) {
              case 401:
                errorMessage = "삭제 권한이 없습니다.";
                break;
              case 404:
                errorMessage = "삭제할 게시물을 찾을 수 없습니다.";
                break;
              default:
                errorMessage = "삭제 요청을 처리하는 도중 오류가 발생했습니다.";
                break;
            }
          } else {
            errorMessage = "서버 응답을 받지 못했습니다. 잠시 후 다시 시도해주세요.";
          }
          // 삭제 실패 시 오류 메시지를 표시
          ErrAlert({ err: errorMessage });
        }
      } else {
        //취소
      }
    });
  };

  /**
     * 게시물 복원 처리
     */
  const handleRestore = async () => {
    Swal.fire({
      icon: "warning",
      title: "복원",
      text: `게시물을 복원 하시겠습니까?`,
      showCancelButton: true,
      confirmButtonText: "복원",
      cancelButtonText: "취소",
    }).then(async (res) => {
      if (res.isConfirmed) {
        try {
          //여기서 서버에 복원 요청
          await instanceAxios.patch(
            `${API[props.domain + "LIST"]}` + `/${props.domainId}` + "/restore"
          );
          Swal.fire({
            icon: "success",
            title: "복원",
            text: `복원 되었습니다!`,
          });
          navigate(`/${API[props.domain]}`);
        } catch (error) {
          let errorMessage = "요청을 처리하는 도중 오류가 발생했습니다.";
          if (error.response) {
            const statusCode = error.response.status;
            switch (statusCode) {
              case 401:
                errorMessage = "권한이 없습니다. 복원을 수행할 수 없습니다.";
                break;
              case 404:
                errorMessage = "요청한 항목을 찾을 수 없습니다.";
                break;
              default:
                errorMessage = "복원 요청을 처리하는 도중 오류가 발생했습니다.";
                break;
            }
          } else {
            errorMessage = "서버 응답을 받지 못했습니다. 잠시 후 다시 시도해주세요.";
          }
          ErrAlert({ err: errorMessage });
        }
      } else {

      }
    });
  };


  /**
   * 게시물 완전 삭제 핸들러
   */
  const handleAdminDelete = async () => {
    Swal.fire({
      icon: "warning",
      title: "완전 삭제",
      html: `게시물을 완전히 삭제하시겠습니까?<br>이후에는 해당 글을 복원할 수 없습니다.`,
      showCancelButton: true,
      confirmButtonText: "삭제",
      cancelButtonText: "취소",
    }).then(async (res) => {
      if (res.isConfirmed) {
        try {
          //여기서 서버에 완전 삭제 요청
          await instanceAxios.delete(
            `${API[props.domain + "LIST"]}` + `/${props.domainId}` + "/admindelete"
          );

          Swal.fire({
            icon: "success",
            title: "삭제",
            text: `완전 삭제 되었습니다!`,
          });
          navigate(`/${API[props.domain]}`);
        } catch (error) {
          let errorMessage = "삭제 요청을 처리하는 도중 오류가 발생했습니다.";
          if (error.response) {
            const statusCode = error.response.status;
            switch (statusCode) {
              case 401:
                errorMessage = "삭제 권한이 없습니다.";
                break;
              case 404:
                errorMessage = "삭제할 게시물을 찾을 수 없습니다.";
                break;
              default:
                errorMessage = "완전 삭제 요청을 처리하는 도중 오류가 발생했습니다.";
                break;
            }
          } else {
            errorMessage = "서버 응답을 받지 못했습니다. 잠시 후 다시 시도해주세요.";
          }
          // 삭제 실패 시 오류 메시지를 표시
          ErrAlert({ err: errorMessage });
        }
      } else {
      }
    });
  };


  return (
    <Row>
      <div className="update mr-auto ml-auto">
        <Button
          outline
          color="success"
          type="button"
          onClick={() => navigate(`/${API[props.domain]}`)}
        >
          목록
        </Button>
        {props.memRole === "ADMIN" ?
          <span>
            {props.deleteTf === false ?
              <Button
                outline
                color="primary"
                type="button"
                onClick={() =>
                  navigate(`${API[props.domain + "MODIFY"]}` + `/${props.domainId}`)
                }
              >
                수정
              </Button> : null}
            {props.deleteTf === false ?
              <Button outline color="danger" type="button" onClick={handleDelete}>
                삭제
              </Button> : null}
            {props.deleteTf === true ?
              <Button
                outline
                color="warning"
                type="button"
                onClick={handleRestore}
              >
                복원
              </Button> : null}
            {props.deleteTf === true ?
              <Button
                outline
                color="danger"
                type="button"
                onClick={handleAdminDelete}
              >
                완전 삭제
              </Button> : null}

          </span>
          : null
        }
      </div>
    </Row>
  );
}

export default NoticeDetailButton;
