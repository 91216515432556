// table list 페이지
// 길이가 길어지면 아래로 내려가는게 나은지 스크롤이 있는게 나은지

import React, { useEffect } from "react";
import { useState } from "react";
import Paging from "../../variables/Paging";
import "../../assets/css/Notice.css";
import MaterialIcon from "react-google-material-icons";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Col,
  Button,
  Spinner,
} from "reactstrap";

import { useNavigate } from "react-router-dom";
import { API } from "../../ApiConfig";
import instanceAxios from "components/reissue/InstanceAxios";
import ErrAlert from "variables/ErrAlert";

/**
 * 어떤 리스트인지 나타내는 제목입니다.
 */
const titleName = "공지사항";

/**
 * 공지사항 리스트 페이지
 * @returns table형식의 list page를 리턴합니다.
 */
function NoticeList() {
  const [noticeListData, setNoticeListData] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0); // 전체 요소 갯수
  const [loading, setLoading] = useState(true); // 로딩 상태 추가
  const PER_PAGE = 10; // 한 페이지 출력 갯수
  
  const memRole = localStorage.getItem("memRole");

  async function fetchData(page = 1) {
    try {
      const response = await instanceAxios.get(
        `${API.NOTICELIST}?page=${page}&pageSize=10`
      );
      const data = response?.data?.data.data || [];

      setNoticeListData(data);
      setCount(response.data.data.totalElements);
    } catch (error) {
      // 에러 처리
      ErrAlert({ err: "데이터 불러오기 실패" });
    } finally {
      // 로딩 상태 업데이트
      setLoading(false);
    }
  }

  const [reloadData, setReloadData] = useState(false);

  useEffect(() => {
    fetchData();
  }, [reloadData]);

  /**
   * 전체{}건 을 클릭시 필터가 적용되지 않은 첫 페이지로 돌아갑니다
   */
  const handleTotalCountClick = () => {
    window.location.reload();
  };

  /**
   * 페이지가 변경될 때마다 페이지 번호를 가져와 useState의 setPage를 이용하여 업데이트 해주는 함수입니다.
   * @param {number} page
   */
  const handlePageChange = async (newPage) => {
    setLoading(true); // 로딩 상태를 true로 설정하여 데이터를 가져오는 중임을 나타냄
    setPage(newPage);
    await fetchData(newPage);
  };

  const navigate = useNavigate();

  /**
   * 배열을 페이지 번호에 맞춰 반복문을 돌려 담아주는 함수입니다.
   * @returns 게시글을 배열에 담아 리턴합니다.
   */
  function arrayList() {
    var array = [];

    // 초기 리스트에 데이터가 없을 때
    if (!noticeListData || noticeListData.length === 0) {
      return (
        <tr key="no-data" style={{ textAlign: "center" }}>
          <td colSpan="3">등록된 글이 없습니다.</td>
        </tr>
      );
    } else {
      // 초기 리스트에 데이터가 있을 때
      noticeListData.map((item, index) => {
        const isDeleted = item.deleteTandF;
        // const isAscending = sortState === "true";
        // const displayIndex = isAscending ? (page - 1) * PER_PAGE + index + 1 : count - ((page - 1) * PER_PAGE + index);
        const startingIndex = count - (page - 1) * PER_PAGE - index;
        array.push(
          <tr
            key={item.id}
            id="move"
            onClick={() => navigate(`${API.NOTICEDETAIL}/${item.id}`)}
            style={{ textAlign: "center" }}
          >
            {memRole === "ADMIN" ? (
              <div
                className={`notice-list-wrap ${isDeleted ? "deleted-text" : ""
                  }`}
              >
                <div className={"notice-admin-list"}>
                  <td>{startingIndex || "-"}</td>
                  <td>{item.title || "-"}</td>
                  <td>
                    {item.hide ? (
                      <MaterialIcon icon="radio_button_unchecked" />
                    ) : (
                      <MaterialIcon icon="close" />
                    )}
                  </td>
                  <td>{item.createdDate}</td>
                </div>
              </div>
            ) : !item.hide ? (
              <div className={"notice-list-wrap"}>
                <div className={"notice-list"}>
                  <td>{startingIndex + index || "-"}</td>
                  <td>{item.title || "-"}</td>
                  <td>{item.createdDate}</td>
                </div>
              </div>
            ) : null}
          </tr>
        );
      });
    }

    return array;
  }

  return (
    <>
      <div className="content">
        <Card>
          <CardHeader>
            <CardTitle className="cardTitle" tag="h4">
              {titleName}
            </CardTitle>
          </CardHeader>
          <CardBody>
            {/* <Table responsive /> */}
            <div style={{ display: "flex" }}>
              <Col md="auto" className="d-flex align-items-center">
                <p
                  className="category"
                  style={{ fontSize: "17px", display: "flex" }}
                >
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={handleTotalCountClick}
                    onMouseEnter={(e) => (e.target.style.color = "#240170")}
                    onMouseLeave={(e) => (e.target.style.color = "#9A9A9A")}
                  >
                    전체 {count}건
                  </span>
                </p>
              </Col>
            </div>
            {loading ? (
              <Spinner color="primary" />
            ) : (
              <>
                <Table>
                  <thead className={"text-primary"}>
                    <tr className={"notice-title-wrap"}>
                      {memRole === "ADMIN" ? (
                        <div className={"notice-admin-title"}>
                          <th>번호</th>
                          <th>제목</th>
                          <th>숨김 여부</th>
                          <th>작성일</th>
                        </div>
                      ) : (
                        <div className={"notice-title"}>
                          <th>번호</th>
                          <th>제목</th>
                          <th>작성일</th>
                        </div>
                      )}
                    </tr>
                  </thead>
                  <tbody>{arrayList()}</tbody>
                </Table>
                <div style={{ textAlign: "right", padding: "0px 15px" }}>
                  {memRole === "ADMIN" ? (
                    <Button
                      outline
                      color="primary"
                      type="submit"
                      onClick={() => navigate(`${API.NOTICEREGIST}`)}
                    >
                      등록
                    </Button>
                  ) : null}
                </div>
              </>
            )}
          </CardBody>
          {!loading && (
            <Paging
              count={count}
              page={page}
              perPage={PER_PAGE}
              handlePageChange={handlePageChange}
            />
          )}
        </Card>
      </div>
    </>
  );
}

export default NoticeList;
