/*!

=========================================================
* Paper Dashboard React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { API } from "ApiConfig";
import {
  fetchCustomers,
  fetchProjectsByCustomerName,
  fetchSupervisors,
  fetchSupervisorByCustomerName,
} from "ApiUtils";
import instanceAxios from "components/reissue/InstanceAxios";
import Select from "react-select";
import FileUpload from "components/RegistForm/FileUpload";
import Swal from "sweetalert2";
import DeleteButton from "variables/DeleteButton";
import ToastAlert from "variables/ToastAlert";
import ErrAlert from "variables/ErrAlert";
import { Tooltip } from "@mui/material";

/**
 * 유지보수 계약 등록 폼
 * @author 김지연
 * @returns 유지보수 계약 등록 폼 리턴
 */

function ContractMaintain() {
  //유지보수 계약 명시용 변수
  const contractClass = "REPAIR";

  // 계약 상태 옵션
  const stateOptions = [
    { value: "CONTRACTING", label: "계약중" },
    { value: "CONTRACT_COMPLETE", label: "계약 완료" },
    { value: "CONTRACT_EXPIRED", label: "계약 만료" },
    { value: "ETC", label: "기타" },
  ];

  const [contractName, setContractName] = useState(""); // 계약명
  const [state, setState] = useState(""); // 계약 상태
  const [customer, setCustomer] = useState(""); // 고객사(고객사 id저장)
  const [project, setProject] = useState(""); // 선택한 프로젝트
  const [start, setStart] = useState(""); // 계약 시작일
  const [end, setEnd] = useState(""); // 계약 종료일
  const [division, setDivision] = useState(""); //지원 형태
  const [firstDate, setFirstDate] = useState(""); // 최초 유지보수 예정일
  const [supervisorCus, setSupervisorCus] = useState(""); // 고객사 담당자
  const [supervisorNo, setSupervisorNo] = useState(""); //나인원 담당자
  const [supervisorWt, setSupervisorWt] = useState(""); //와탭 담당자
  const [content, setContent] = useState(""); // 비고
  const [file, setFile] = useState([]); // 첨부파일

  const [startDateError, setStartDateError] = useState(false); // 계약 시작일 오류 여부 상태
  const [divisionError, setDivisionError] = useState(false); // 지원 형태 여부 상태

  const [isWtInitial, setIsWtInitial] = useState(true); // 초기 상태를 추적합니다.
  const [isNoInitial, setIsNoInitial] = useState(true); // 초기 상태를 추적합니다.
  const [isSupInitial, setIsSupInitial] = useState(true); // 초기 상태를 추적합니다.
  const [isCusInitial, setIsCusInitial] = useState(true); // 초기 상태를 추적합니다.
  const [isPrjInitial, setIsPrjInitial] = useState(true); // 초기 상태를 추적합니다.

  // ======= api load
  const [customerName, setCustomerName] = useState([]); // 고객사명 리스트
  const [projectName, setProjectName] = useState([]); // 프로젝트명 리스트
  const [CusList, setCusList] = useState([]); // 고객사 담당자 리스트
  const [NoList, setNoList] = useState([]); //나인원 담당자 저장소
  const [WtList, setWtList] = useState([]); //와탭 담당자 저장소

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  // 고객사, 나인원.와탭 담당자 가져오기
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [customers, supervisors] = await Promise.all([
          fetchCustomers(),
          fetchSupervisors(),
        ]);
        customers.splice(0, 2);

        setCustomerName(customers);
        setNoList(supervisors.supervisorsNo);
        setWtList(supervisors.supervisorsWt);
      } catch (error) {
        ErrAlert({ err: "데이터 불러오기 실패" });
      }
    };

    fetchData();
  }, []);

  // 계약 시작일 또는 지원 형태가 변경될 때 최초 유지보수 예정일을 업데이트하는 함수
  useEffect(() => {
    if (start && division) {
      let startDate = new Date(start);
      let newDate;

      if (division === "반기") {
        newDate = new Date(startDate.setMonth(startDate.getMonth() + 6));
      } else if (division === "분기") {
        newDate = new Date(startDate.setMonth(startDate.getMonth() + 3));
      } else if (!isNaN(parseInt(division))) { // division이 숫자인 경우
        newDate = new Date(startDate.setMonth(startDate.getMonth() + parseInt(division)));
      }

      setFirstDate(newDate ? newDate.toISOString().slice(0, 10) : "");
    } else {
      setFirstDate(""); // 지원 형태가 입력되지 않은 경우 최초 유지보수 예정일 초기화
    }
  }, [start, division]);

  // 지원 형태 입력 시 state 업데이트
  const handleDivisionChange = (event) => {
    setDivision(event.target.value);
  };

  // 최초 유지보수 예정일 입력 시 state 업데이트
  const handleFirstDateChange = (event) => {
    setFirstDate(event.target.value);
  };

  // 고객사가 변경될시 프로젝트와 고객사 담당자가 변경되는 함수
  const handleCustomerChange = async (selectedOption) => {
    const newCustomerName = selectedOption.label;
    const newCustomerId = selectedOption.value;
    setCustomer(selectedOption.value);
    setProjectName([]);
    setCusList([]);
    setIsSupInitial(true);
    setIsPrjInitial(true);

    try {
      if (newCustomerName) {
        const fetchData = async () => {
          const [supervisors] = await Promise.all([
            fetchSupervisorByCustomerName(newCustomerName),
          ]);

          setCusList(
            supervisors.map((supervisor) => ({
              value: supervisor.id,
              label: supervisor.supervisor,
            }))
          );
        };

        fetchData();
      }
      if (newCustomerId) {
        const fetchProject = async () => {
          const projects = await fetchProjectsByCustomerName(newCustomerId);

          setProjectName(
            projects.map((project) => ({
              value: project.id,
              label: project.project,
            }))
          );
        };

        fetchProject();
      }
    } catch (error) {
      ErrAlert({ err: "데이터 불러오기 실패" });
    }
    setIsCusInitial(false);
    setProject("");
    setSupervisorCus("");
  };

  // 계약 시작일 변경 시 실행되는 함수
  const handleStartDateChange = (event) => {
    const selectedStartDate = event.target.value;
    setStart(selectedStartDate);

    // 종료일이 설정되어 있고 시작일이 종료일보다 이후인 경우 오류 설정
    if (end && selectedStartDate > end) {
      setStartDateError(true);
    } else {
      setStartDateError(false);
    }
  };

  // 계약 종료일 변경 시 실행되는 함수
  const handleEndDateChange = (event) => {
    const selectedEndDate = event.target.value;
    setEnd(selectedEndDate);

    // 시작일이 설정되어 있고 종료일이 시작일보다 이전인 경우 오류 설정
    if (selectedEndDate < start) {
      setStartDateError(true);
    } else {
      setStartDateError(false);
    }
  };

  //=============== form submit ==================== //

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (startDateError) {
      Swal.fire({
        icon: "warning",
        title: "주의",
        text: `시작일/종료일을 확인해주세요.`,
        showCancelButton: false,
        confirmButtonText: "확인",
      })
    } else {
      Swal.fire({
        icon: "question",
        title: "등록",
        text: `등록 하시겠습니까?`,
        showCancelButton: true,
        confirmButtonText: "등록",
        cancelButtonText: "취소",
      }).then(async (res) => {
        if (res.isConfirmed) {
          const formData = new FormData();

          formData.append("contractClass", contractClass);
          formData.append("contractName", contractName);
          formData.append("state", state || "ETC");
          formData.append("customer", customer);
          formData.append("project", project.value);
          formData.append("start", start);
          formData.append("end", end || "");
          formData.append("division", division);
          formData.append("firstDate", firstDate);
          formData.append("supervisorCus", supervisorCus.value);
          formData.append("supervisorNo", supervisorNo.value || 1);
          formData.append("supervisorWt", supervisorWt.value || 2);
          formData.append("content", content);

          for (var i = 0; i < file.length; i++) {
            formData.append("fileList", file[i]);
          }

          try {
            const response = await instanceAxios.post(
              API.CONTRACTLIST,
              formData,
              {
                headers: {
                  "Content-Type": "application/form-data;charset=UTF-8",
                },
              }
            );
            ToastAlert({ icon: "success", title: "등록이 완료되었습니다." });
            navigate(`/${API.CONTRACT}`);
          } catch (error) {
            ErrAlert({ err: "필수 항목을 확인해주세요" });
          }
        } else {
          //취소
        }
      });
    };
  }


  // =============== 등록 폼

  const navigate = useNavigate();
  return (
    <Card className="card-user">
      <CardHeader>
        <CardTitle tag="h5">유지보수 계약 등록</CardTitle>
      </CardHeader>
      <CardBody>
        <Form encType="multipart/form-data" onSubmit={handleSubmit}>
          <Row>
            <Col className="pr-1" md="8">
              <FormGroup>
                <label>계약명</label>
                <Input
                  required
                  className={contractName ? "is-valid" : "is-invalid"}
                  placeholder="필수로 입력해주세요."
                  type="text"
                  name="contractName"
                  onChange={(event) => {
                    setContractName(event.target.value);
                  }}
                />
              </FormGroup>
            </Col>
            <Col className="pl-1" md="4">
              <FormGroup>
                <label>계약 상태</label>
                <Select
                  name="state"
                  options={stateOptions}
                  onChange={(event) => {
                    setState(event.value);
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="pr-1" md="5">
              <FormGroup>
                <label>고객사</label>
                <Select
                  required
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      borderColor: isCusInitial
                        ? "#ff0000"
                        : provided.borderColor,
                      // 선택되었을 때와 선택되지 않았을 때의 테두리색을 지정할 수 있습니다.
                    }),
                  }}
                  placeholder="필수값입니다"
                  options={customerName}
                  onChange={handleCustomerChange}
                />
              </FormGroup>
            </Col>
            <Col className="pl-1" md="7">
              <FormGroup>
                <label>프로젝트</label>
                <Select
                  name="cProject"
                  required
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      borderColor: isPrjInitial
                        ? "#ff0000"
                        : provided.borderColor,
                      // 선택되었을 때와 선택되지 않았을 때의 테두리색을 지정할 수 있습니다.
                    }),
                  }}
                  placeholder="필수값입니다"
                  options={projectName}
                  onChange={(selectedOption) => {
                    setProject(selectedOption);
                    setIsPrjInitial(false);
                  }}
                  value={project}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="pr-1" md="4">
              <FormGroup>
                <label>계약 시작</label>
                <Input
                  type="date"
                  name="start"
                  onChange={handleStartDateChange}
                />
              </FormGroup>
            </Col>
            <Col className="pl-1" md="4">
              <FormGroup>
                <label>계약 종료</label>
                <Input
                  type="date"
                  name="end"
                  onChange={handleEndDateChange}
                />
                {startDateError && (
                  <span style={{ color: "red" }}>
                    계약 종료일은 계약 시작일 이후여야 합니다.
                  </span>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="pr-1" md="4">
              <FormGroup>
                <label>지원 형태</label>
                <Input
                  list="formlist"
                  name="divison"
                  id="divisionInput"
                  // onChange={(selectedOption) => {
                  //   setDivision(selectedOption.target.value);
                  // }}
                  onChange={handleDivisionChange}
                />
                <datalist id="formlist">
                  <option>반기</option>
                  <option>분기</option>
                  <option>숫자를 입력하세요.</option>
                </datalist>

                <Tooltip
                  placement="right"
                  isOpen={tooltipOpen}
                  target="divisionInput"
                  toggle={toggleTooltip}>
                  지원 형태는 '반기','분기', 혹은 '숫자'만 입력해주세요. ex) 4, 7..
                </Tooltip>

              </FormGroup>
            </Col>
            <Col className="pl-1" md="4">
              <FormGroup>
                <label>최초 유지보수 예정일</label>
                <Input
                  type="date"
                  name="firstDate"
                  // onChange={(selectedOption) => {
                  //   setFirstDate(selectedOption.target.value);
                  // }}
                  value={firstDate}
                  onChange={handleFirstDateChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="pr-1" md="4">
              <FormGroup>
                <label>고객사 담당자</label>
                <Select
                  name="cSupervisor"
                  options={CusList}
                  onChange={(selectedOption) => {
                    setSupervisorCus(selectedOption);
                    setIsSupInitial(false);
                  }}
                  value={supervisorCus}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      borderColor: isSupInitial
                        ? "#ff0000"
                        : provided.borderColor,
                      // 선택되었을 때와 선택되지 않았을 때의 테두리색을 지정할 수 있습니다.
                    }),
                  }}
                  placeholder="필수값입니다"
                />
              </FormGroup>
            </Col>
            <Col className="px-1" md="4">
              <FormGroup>
                <label>영업 담당자(나인원)</label>
                <Select
                  name="supervisorNo"
                  options={NoList}
                  onChange={(selectedOption) => {
                    setSupervisorNo(selectedOption);
                    setIsNoInitial(false);
                  }}
                />
              </FormGroup>
            </Col>
            <Col className="pl-1" md="4">
              <FormGroup>
                <label>영업 담당자(와 탭)</label>
                <Select
                  name="supervisorWt"
                  options={WtList}
                  onChange={(selectedOption) => {
                    setSupervisorWt(selectedOption);
                    setIsWtInitial(false);
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <FileUpload file={file} setFile={setFile} />
          <Row>
            <Col md="12">
              <FormGroup>
                <label>비고</label>
                <Input
                  type="textarea"
                  name="content"
                  onChange={(event) => {
                    setContent(event.target.value);
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <DeleteButton
              to={`/${API.CONTRACT}`}
              text1="작성중인 게시물을 삭제 하시겠습니까?"
            />
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
}

export default ContractMaintain;
