import React, { useEffect } from "react";
import { Button, Input, Row, Col } from "reactstrap";

const { useState } = require("react");

function FormJson({ onChange, data, onRemoveField, isEditMode }) {
  // 기본 데이터
  const defaultFields = isEditMode
    ? [
      {
        majorCategory: "",
        middleCategory: "",
        smallCategory: "",
        itemDetail: "",
      },
    ]
    : [
      {
        majorCategory: "APM",
        middleCategory: "core",
        smallCategory: "agent",
        itemDetail: "",
      },
      {
        majorCategory: "CPM",
        middleCategory: "core",
        smallCategory: "agent",
        itemDetail: "",
      },
      {
        majorCategory: "DPM",
        middleCategory: "core",
        smallCategory: "agent",
        itemDetail: "",
      },
      {
        majorCategory: "SMS",
        middleCategory: "core",
        smallCategory: "agent",
        itemDetail: "",
      },
    ];

  const [dynamicFields, setDynamicFields] = useState(defaultFields);

  // 데이터가 업데이트될 때마다 동적 필드 업데이트
  useEffect(() => {
    if (data && data.length > 0) {
      setDynamicFields(data);
    }
  }, [data]);

  // 필드 한 줄 추가
  const addField = () => {
    setDynamicFields((prevData) => {
      return [
        ...prevData,
        {
          majorCategory: "",
          middleCategory: "",
          smallCategory: "",
          itemDetail: "",
        },
      ];
    });
  };

  // 선택한 필드 제거(몇번째 줄 index)
  const removeField = (i) => {
    setDynamicFields((prevData) => {
      const newData = prevData.filter((_, index) => index !== i); // 해당 인덱스를 제외한 데이터로 필터링
      onChange([...newData]); // 변경된 데이터 상위 컴포넌트로 전달
      return newData;
    });
  };

  // 값 입력시 받아오는 함수(몇번째 줄, 해당 필드 네임, 받아오는 값)
  const handleChange = (index, fieldName, value) => {
    setDynamicFields((prevData) => {
      const newData = [...prevData];
      newData[index][fieldName] = value;
      return newData;
    });
    onChange([...dynamicFields]);
  };

  return (
    <div>
      {dynamicFields.map((field, i) => {
        return (
          <div key={i}>
            <Row style={{ alignItems: "center" }}>
              <Col className="pr-1" md="2">
                <Input
                  type="text"
                  value={field.majorCategory}
                  onChange={(e) =>
                    handleChange(i, "majorCategory", e.target.value)
                  }
                />
              </Col>
              <Col className="px-1" md="2">
                <Input
                  type="text"
                  // placeholder="Core"
                  value={field.middleCategory}
                  onChange={(e) =>
                    handleChange(i, "middleCategory", e.target.value)
                  }
                />
              </Col>
              <Col className="px-1" md="2">
                <Input
                  type="text"
                  // placeholder="Agent"
                  value={field.smallCategory}
                  onChange={(e) =>
                    handleChange(i, "smallCategory", e.target.value)
                  }
                />
              </Col>
              <Col className="px-1" md="5">
                <Input
                  type="text"
                  // placeholder="비고"
                  value={field.itemDetail}
                  onChange={(e) =>
                    handleChange(i, "itemDetail", e.target.value)
                  }
                />
              </Col>
              <Col className="pl-1" md="1">
                {onRemoveField && (
                  <Button
                    type="button"
                    onClick={i === 0 ? addField : () => onRemoveField(i)}
                  >
                    {i === 0 ? "+" : "-"}
                  </Button>
                )}
                {!onRemoveField && (
                  <Button
                    type="button"
                    onClick={i === 0 ? addField : () => removeField(i)}
                  >
                    {i === 0 ? "+" : "-"}
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        );
      })}
    </div>
  );
}

export default FormJson;
