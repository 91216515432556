// table list 페이지
// 길이가 길어지면 아래로 내려가는게 나은지 스크롤이 있는게 나은지

import React, { useEffect } from "react";
import { useState } from "react";
import Paging from "../../variables/Paging";
import "../../assets/css/List.css";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Col,
  Button,
  Spinner,
} from "reactstrap";

import { useNavigate } from "react-router-dom";
import { API } from "../../ApiConfig";
import instanceAxios from "components/reissue/InstanceAxios";
import ErrAlert from "variables/ErrAlert";
import StateFileter from "variables/Fillter/StateFilter";
import Swal from "sweetalert2";
import SelectBox from "variables/Fillter/SelectBox";
import SortFilter from "variables/Fillter/SortFilter";

/**
 * 어떤 리스트인지 나타내는 제목입니다.
 */
const titleName = "지원";

/**
 * 지원 리스트 페이지
 * @returns table형식의 list page를 리턴합니다.
 */
function SupportList() {
  const [supportData, setListData] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0); // 전체 요소 갯수
  const [loading, setLoading] = useState(true); // 로딩 상태 추가
  const PER_PAGE = 10; // 한 페이지 출력 갯수
  const startingIndex = (page - 1) * PER_PAGE + 1; // 현재 페이지 첫번째 아이템 번호

  // 정렬 상태
  const [sortCol, setSortCol] = useState(""); // 정렬되는 열
  const [sortState, setSortState] = useState("false"); //정렬 방향
  const [supportState, setSupportState] = useState([]); // 지원 상태 필터 상태

  const [expandedIds, setExpandedIds] = useState([]); // 펼쳐 보기 게시글 ID 배열
  const [expandAll, setExpandAll] = useState(false); // 모든 아이템 확장 여부
  const [expandableIds, setExpandableIds] = useState([]); // 펼쳐보기가 가능한 게시글 id 배열

  // 두 번째 셀렉트로 필터링된 데이터를 저장할 상태
  const [firstSelectValue, setFirstSelectValue] = useState("");
  const [secondSelectValue, setSecondSelectValue] = useState("");
  const [searchValues, setSearchValues] = useState({
    firstSelectValue: "",
    secondSelectValue: "",
  });
  const [isSearch, setIsSearch] = useState(false); // 검색 상태 추가

  // 게시글 펼쳐보기 닫기 함수
  const toggleExpand = (id) => {
    let newExpandedIds;
    if (expandedIds.includes(id)) {
      // 이미 펼쳐진 게시글의 경우 해당 ID를 expandedIds에서 제거
      newExpandedIds = expandedIds.filter((item) => item !== id);
    } else {
      // 펼쳐지지 않은 게시글의 경우 해당 ID를 expandedIds에 추가
      newExpandedIds = [...expandedIds, id];
    }
    // 업데이트된 expandedIds 상태를 설정
    setExpandedIds(newExpandedIds);

    if (newExpandedIds.length === expandableIds.length) {
      // 모든 게시글이 펼쳐진 경우 expandAll 상태를 true로 설정
      setExpandAll(true);
    } else {
      // 일부 게시글만 펼쳐진 경우 expandAll 상태를 false로 설정
      setExpandAll(false);
    }
  };

  // 전체 펼치기/닫기
  const toggleExpandAll = () => {
    if (expandAll) {  // expandAll 상태가 true인 경우 (모든 게시글이 펼쳐진 상태)
      setExpandedIds([]); // 전체 닫기
    } else { // expandAll 상태가 false인 경우 (모든 게시글이 닫힌 상태)
      setExpandedIds(expandableIds); // 전부 열기
    }
    // expandAll 상태를 토글 (true <-> false)
    setExpandAll(!expandAll);
  };

  // 아이템이 확장되어 있는지 여부를 확인하는 함수
  const isExpanded = (id) => expandedIds.includes(id);

  async function fetchData(page = 1, col = "", sortState = "true", state = "") {
    try {
      const { firstSelectValue, secondSelectValue } = searchValues;
      const response = await instanceAxios.get(
        `${API.SUPPORTLIST}?page=${page}&${firstSelectValue}=${secondSelectValue}&order=${col}&asc=${sortState}&state=${state}`
      );
      const data = response.data?.data?.data || [];

      setListData(data);
      setCount(response.data.data.totalElements);
      // Update expandableIds
      const newExpandableIds = data
        .filter((item) => item.followingListResponseDtoList.length > 0)
        .map((item) => item.id);
      setExpandableIds(newExpandableIds);
    } catch (error) {
      ErrAlert({ err: "데이터 불러오기 실패" });
    } finally {
      // 로딩 상태 업데이트
      setLoading(false); // 로딩 처리도 다시 해야할듯?
    }
  }

  //최초 로딩시 데이터 가져오기
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData(page, sortCol, sortState, supportState);
  }, [searchValues, page, sortCol, sortState, supportState]);

  /**
   * 전체{}건 을 클릭시 필터가 적용되지 않은 첫 페이지로 돌아갑니다
   */
  const handleTotalCountClick = () => {
    window.location.reload();
  };

  /**
   * 페이지가 변경될 때마다 페이지 번호를 가져와 useState의 setPage를 이용하여 업데이트 해주는 함수입니다.
   * @param {number} page
   */
  const handlePageChange = async (newPage) => {
    setLoading(true); // 로딩 상태를 true로 설정하여 데이터를 가져오는 중임을 나타냄
    setPage(newPage);
    await fetchData(newPage, sortCol, sortState, supportState);
  };

  const navigate = useNavigate();

  /**
   * 첫 번째 셀렉트 변경 시 호출되는 함수
   * @param {첫 번째 셀렉트 값} value
   */
  const handleFirstSelectChange = (value) => {
    setFirstSelectValue(value);
  };

  /**
   * 두 번째 셀렉트 변경 시 호출되는 함수
   * @param {두 번째 셀렉트 값} value
   */
  const handleSecondSelectChange = (value) => {
    setSecondSelectValue(value);
  };

  /**
   * 검색버튼을 클릭하면 실행되는 함수
   * 셀렉트 된 값에 맞춰 필터링하여 저장
   */
  const handleSearchButtonClick = () => {
    if (firstSelectValue && secondSelectValue) {
      setPage(1); // 검색 후 첫 페이지로 이동
      setLoading(true); // 로딩 상태를 true로 설정하여 데이터를 가져오는 중임을 나타냄
      setIsSearch(true); // 검색 상태를 true로 설정
      setSearchValues({ firstSelectValue, secondSelectValue });
      // fetchData();
    } else {
      Swal.fire({
        icon: "warning",
        title: "오류",
        text: `검색 카테고리를 선택해주세요.`,
      });
    }
  };

  //===========================================================================
  // 계약 상태 옵션
  const stateOptions = [
    { value: "WORKING", label: "작업중" },
    { value: "WORK_EXPECTED", label: "작업 예정" },
    { value: "WORK_COMPLETE", label: "작업 완료" },
  ];

  // 계약 상태 필터를 위한 state
  const [filterOpen, setFilterOpen] = useState(false);
  //===========================================================================

  /**
   * 정렬 버튼 클릭 시 작동되는 함수
   */
  const handleSort = async (col) => {
    const newSortState = sortState === "true" ? "false" : "true";
    setLoading(true);
    setSortCol(col);
    setSortState(newSortState);
    await fetchData(page, col, newSortState, supportState);
  };

  // state ENUM 명을 한글로 변경해서 return
  function supState(state) {
    // var state;
    stateOptions.map((option) => {
      if (option.value === state) {
        state = option.label;
      }
    });
    return state;
  }

  // 필터링 처리 함수
  const handleTempFilter = async (tempFilter) => {
    // 부모 컴포넌트에서 tempFilter 값을 사용하는 작업을 수행합니다.
    // temFilter를 보내야함 setContractState
    setLoading(true);
    setSupportState(tempFilter);
    await fetchData(page, sortCol, sortState, tempFilter);
  };

  /**
   * 배열을 페이지 번호에 맞춰 반복문을 돌려 담아주는 함수입니다.
   * 필터가 적용되면 조건에 부합하게 다시 리스트를 뿌려줍니다.
   * @returns 게시글을 배열에 담아 리턴합니다.
   */
  function arrayList() {
    var array = [];
    const isAscending = sortState === "true";

    // 초기 리스트에 데이터가 없을 때
    if (!supportData || supportData.length === 0) {
      if (isSearch) {
        return (
          <tr key="no-search-data" style={{ textAlign: "center" }}>
            <td colSpan="7">검색된 결과가 없습니다.</td>
          </tr>
        );
      } else {
        return (
          <tr key="no-data" style={{ textAlign: "center" }}>
            <td colSpan="7">등록된 글이 없습니다.</td>
          </tr>
        );
      }
    }
    //초기 리스트에 데이터가 있을 때
    supportData.map((item, index) => {
      const isDeleted = item.deleteTAndF;
      const displayIndex = isAscending ? (page - 1) * PER_PAGE + index + 1 : count - ((page - 1) * PER_PAGE + index);
      const hasFollowingList = item.followingListResponseDtoList.length > 0;
      array.push(
        <tr
          key={item.id}
          id="move"
          onClick={() => navigate(`${API.SUPPORTDETAIL}/${item.id}`)}
          style={{ textAlign: "center" }}
        >
          <div
            className={`maintain-list-wrap ${isDeleted ? "deleted-text" : ""
              }`}>
            <div className={"maintain-list-left"}>
              <td>{displayIndex || "-"}</td>
              <td>{item.customer || "-"}</td>
              <td>
                {item.name || "-"}
                <span style={{ color: "blue", fontWeight: "bolder" }}>
                  {item.followingListResponseDtoList.length > 0 ? `(${item?.followingListResponseDtoList.length})` : null}
                </span>
                {/* 펼쳐보기/접기 버튼 */}
                {hasFollowingList && (
                  <Button
                    className="expand-button"
                    onClick={(e) => {
                      e.stopPropagation(); // 이벤트 전파 중지
                      toggleExpand(item.id);
                    }}
                    size="sm"
                    style={{ marginLeft: "1em" }}
                  >
                    {isExpanded(item.id) ? "접기" : "펼치기"}
                  </Button>
                )}
              </td>
            </div>
            <div className={"maintain-list-right"}>
              <div className={"maintain-date-wrap"}>
                <td>{item.start || "-"}</td>
                <div className={"date-period-mark"}>~</div>
                <td>{item.end || "-"}</td>
              </div>
              <div className="maintain-progress-wrap">
                <td>{supState(item.supportState) || "-"}</td>
              </div>
            </div>
          </div>
        </tr>
      );
      // 펼쳐진 상태일 때 아래에 하위 리스트 표시
      if (isExpanded(item.id)) {
        array.push(
          item.followingListResponseDtoList.map((subItem) => (
            // 하위 리스트 내용 렌더링
            <tr key={subItem.id} style={{ textAlign: "center" }}>
              <div className={`maintain-list-wrap expand-following ${subItem.deleteTAndF ? "deleted-text" : ""}`}>
                <div className={"maintain-list-left"}>
                  <td></td>
                  <td></td>
                  <td>{subItem.name || "-"}</td>
                </div>
                <div className={"maintain-list-right"}>
                  <td>{subItem.start || "-"}</td>
                  <td>{subItem.end || "-"}</td>
                  <div className="maintain-progress-wrap">
                    <td>{supState(subItem.supportState) || "-"}</td>
                  </div>
                </div>
              </div>
            </tr>
          ))
        );
      }
    });
    return array;
  }

  return (
    <>
      <div className="content">
        <Card>
          <CardHeader>
            <CardTitle tag="h4" className="cardTitle">
              {titleName}
            </CardTitle>
          </CardHeader>
          <CardBody>
            <div style={{ display: "flex" }}>
              <Col md="auto" className="d-flex align-items-center">
                <p
                  className="category"
                  style={{ fontSize: "17px", display: "flex" }}
                >
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={handleTotalCountClick}
                    onMouseEnter={(e) => (e.target.style.color = "#240170")}
                    onMouseLeave={(e) => (e.target.style.color = "#9A9A9A")}
                  >
                    전체 {count}건
                  </span>
                </p>
                <Button onClick={toggleExpandAll} size="sm" style={{ marginTop: "-5px", marginLeft: "1em", paddingTop: "0.5em", paddingBottom: "0.5em" }} color="primary">
                  {expandAll ? "전체 닫기" : "전체 펼쳐보기"}
                </Button>
              </Col>
              <SelectBox
                onFirstSelectChange={handleFirstSelectChange}
                onSecondSelectChange={handleSecondSelectChange}
                options="support"
                handleSearchButtonClick={handleSearchButtonClick}
              />
              <Col md="auto" className="d-flex align-items-center">
                <i
                  id="iconBtn"
                  className="nc-icon nc-zoom-split"
                  onClick={handleSearchButtonClick}
                  color="primary"
                />
              </Col>
            </div>

            <Table>
              <thead className="text-primary">
                <tr className={"maintain-title-wrap"}>
                  <div className={"maintain-title-left"}>
                    <SortFilter
                      text="번호"
                      value="number"
                      handleSort={handleSort}
                      isActive={sortCol === "number"}
                      activeCol={sortCol}
                      sortState={sortState}
                    />
                    <th>고객사</th>
                    <th>지원명</th>
                  </div>
                  <div className={"maintain-title-right"}>
                    <SortFilter
                      text="시작일"
                      value="start"
                      handleSort={handleSort}
                      isActive={sortCol === "start"}
                      activeCol={sortCol}
                      sortState={sortState}
                    />
                    <SortFilter
                      text="종료일"
                      value="end"
                      handleSort={handleSort}
                      isActive={sortCol === "end"}
                      activeCol={sortCol}
                      sortState={sortState}
                    />
                    <th>작업상태
                      <StateFileter
                        domain="SUPPORT"
                        stateOptions={stateOptions}
                        filterOpen={filterOpen}
                        setFilterOpen={setFilterOpen}
                        setCount={setCount}
                        handleTempFilter={handleTempFilter}
                      />
                    </th>
                  </div>
                </tr>
              </thead>
              {loading ? (
                <Spinner color="primary" style={{ marginTop: "15px" }} />
              ) : (
                <>
                  <tbody>{arrayList()}</tbody>
                </>
              )}
            </Table>
            {!loading && (
              <div style={{ textAlign: "right", padding: "0px 15px" }}>
                <Button
                  outline
                  color="primary"
                  type="submit"
                  onClick={() => navigate(`${API.SUPPORTREGIST}`)}
                >
                  등록
                </Button>
              </div>
            )}
          </CardBody>
          {!loading && (
            <Paging
              count={count}
              page={page}
              perPage={PER_PAGE}
              handlePageChange={handlePageChange}
            />
          )}
        </Card>
      </div>
    </>
  );
}

export default SupportList;
