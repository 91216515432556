import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import { API } from "ApiConfig";
import Select, { components } from "react-select";
import {
  fetchCustomers,
  fetchMembers,
  fetchSupervisorByCustomerName,
  fetchContractsByCustomerName,
  fetchContractById,
} from "ApiUtils";
import instanceAxios from "components/reissue/InstanceAxios";
import FileUpload from "components/RegistForm/FileUpload";
import OldFileUpload from "components/RegistForm/OldFileUpload";
import DeleteButton from "variables/DeleteButton";
import Swal from "sweetalert2";
import ToastAlert from "variables/ToastAlert";
import ErrAlert from "variables/ErrAlert";
import CkEditor from "variables/CkEditor";
/**
 * 유지보수 수정 페이지
 * @author 김지연, 김예함
 * @returns 유지보수 수정 폼 리턴
 */
function MaintainModify() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true); // 로딩 상태 추가

  //=====================Member dropdown select======================
  const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
  }) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    //스타일 설정
    let bg = "transparent";
    if (isFocused) bg = "#eee";
    if (isActive) bg = "#B2D4FF";

    const style = {
      alignItems: "center",
      backgroundColor: bg,
      color: "inherit",
      display: "flex",
    };

    //prop
    const props = {
      ...innerProps,
      onMouseDown,
      onMouseUp,
      onMouseLeave,
      style,
    };

    return (
      <components.Option
        {...rest}
        isDisabled={isDisabled}
        isFocused={isFocused}
        isSelected={isSelected}
        getStyles={getStyles}
        innerProps={props}
      >
        <input type="checkbox" checked={isSelected} />
        {children}
      </components.Option>
    );
  };

  // ======= 작업 상태 옵션
  const stateOptions = [
    { value: "WORK_EXPECTED", label: "작업 예정" },
    { value: "WORKING", label: "작업 진행중" },
    { value: "WORK_COMPLETE", label: "작업 완료" },
  ];

  const [customer, setCustomer] = useState(""); // 고객사
  const [repairContract, setRepairContract] = useState(""); // 계약-유지보수
  const [state, setState] = useState(""); // 작업 상태
  const [division, setDivision] = useState(""); // 지원 형태
  const [supervisor, setSupervisor] = useState(""); // 고객사 담당자
  const [workerList, setWorkerList] = useState(""); // 작업 담당자
  const [start, setStart] = useState(""); // 지원 예정일
  const [end, setEnd] = useState(""); // 지원 종료일
  const [content, setContent] = useState(""); // 유지보수 항목
  const [ref, setRef] = useState(""); // 참조사항
  const [file, setFile] = useState([]); // 첨부 파일

  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  const [isCusInitial, setIsCusInitial] = useState(false); // 초기 상태를 추적합니다.
  const [isConInitial, setIsConInitial] = useState(false); // 초기 상태를 추적합니다.
  const [isSupInitial, setIsSupInitial] = useState(false);

  const [contractTerm, setContractTerm] = useState(""); // 계약기간 .. 등록x
  const [Data, setData] = useState([]);

  const [startDateError, setStartDateError] = useState(false); // 계약 시작일 오류 여부 상태

  //========= api load
  const [customerName, setCustomerName] = useState([]); // 고객사명 리스트
  const [repairContracts, setRepairContracts] = useState([]); // 계약-유지보수 리스트
  const [supervisorList, setSupervisorList] = useState([]); // 고객사 담당자 리스트
  const [memberOptions, setMemberOptions] = useState([]); // 멤버(작업 담당자) 리스트

  //== 파일 수정 ==
  const [oldFileList, setOldFileList] = useState([]);
  const [domainFileList, setDomainFileList] = useState([]);

  useEffect(() => {
    /**
     * id값에 맞는 유지보수정보 조회, 고객사, 멤버리스트
     */
    const fetchData = async () => {
      // const data = await fetchMaintainData();
      try {
        const [customers, members, mainData] = await Promise.all([
          fetchCustomers(),
          fetchMembers(),
          instanceAxios.get(`${API.MAINTAINLIST}/${id}`),
        ]);

        const data = mainData.data?.data || [];
        setData(data);

        // 작업상태 초기값 세팅
        const initialState = stateOptions.find(
          (option) => option.value === data?.state
        );
        setState(initialState);

        // 고객사 리스트에서 처음 두개는 제외
        customers.splice(0, 2);
        setCustomerName(customers);
        setMemberOptions(members);

        const sDateTimeString = data.start;
        const [sDate, sTime] = sDateTimeString.split(" "); // 문자열을 공백을 기준으로 나누어서 배열로 만듭니다.
        setStart(sDate);
        setStartTime(sTime);

        const eDateTimeString = data.end; // 예시로 받아온 데이터에서 "start" 키에 해당하는 값을 가져옵니다.
        const [eDate, eTime] = eDateTimeString.split(" "); // 문자열을 공백을 기준으로 나누어서 배열로 만듭니다.
        setEnd(eDate);
        setEndTime(eTime);

        setContent(data.content);
        setRef(data.ref);

        setDomainFileList(mainData.data.data.fileResponseDtoList);

        // 고객사 초기값 세팅
        const initialCustomer = customers.find(
          (option) => option.label === data?.customer
        );
        setCustomer(initialCustomer);

        // 멤버 초기값 세팅
        if (members.length > 0) {
          const initialMembers = [];
          data.workers.forEach((memberLabel) => {
            const initialMember = members.find(
              (member) => member.label === memberLabel
            );
            if (initialMember) {
              initialMembers.push(initialMember);
            }
          });
          setWorkerList(initialMembers);
        }

        // 고객사 초기값 설정 후 고객사담당자, 계약유지보수 초기설정
        if (initialCustomer) {
          const [contracts, supervisors] = await Promise.all([
            fetchContractsByCustomerName(initialCustomer.value),
            fetchSupervisorByCustomerName(initialCustomer.label),
          ]);

          setRepairContracts(
            contracts.map((contract) => ({
              value: contract.id,
              label: contract.contract,
            }))
          );

          // 계약 초기값 세팅
          // const initialRepair = contracts.find(
          //   (option) => option.name === data?.contract
          // );
          const initialRepair = contracts.find(
            (contract) => contract.contract === data?.contract
          );

          setRepairContract({
            value: initialRepair.id,
            label: initialRepair.contract,
          });

          setSupervisorList(
            supervisors.map((supervisor) => ({
              value: supervisor.id,
              label: supervisor.supervisor,
            }))
          );
          // 고객사 담당자 초기값 세팅
          const initialSupervisor = supervisors.find(
            (option) => option.supervisor === data?.supervisor
          );

          setSupervisor({
            value: initialSupervisor.id,
            label: initialSupervisor.supervisor,
          });
        }
      } catch (error) {
        ErrAlert({ err: "데이터 불러오기 실패" });
      } finally {
        // 로딩 상태 업데이트
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  //고객사 선택시 리스트 변경 - 계약유지보수, 고객사 담당자
  const handleCustomerChange = async (selectedOption) => {
    const newCustomerName = selectedOption.label;
    const newCustomerId = selectedOption.value;
    setCustomer(selectedOption);
    setRepairContracts([]);
    setSupervisorList([]);
    setIsConInitial(true);
    setIsSupInitial(true);

    try {
      if (newCustomerName) {
        const fetchData = async () => {
          const [supervisors] = await Promise.all([
            fetchSupervisorByCustomerName(newCustomerName),
          ]);

          setSupervisorList(
            supervisors.map((supervisor) => ({
              value: supervisor.id,
              label: supervisor.supervisor,
            }))
          );
        };

        setIsCusInitial(false);
        fetchData();
      }
      if (newCustomerId) {
        const fetchContract = async () => {
          const contracts = await fetchContractsByCustomerName(newCustomerId);

          setRepairContracts(
            contracts.map((contract) => ({
              value: contract.id,
              label: contract.contract,
            }))
          );
        };

        fetchContract();
      }
    } catch (error) {
      ErrAlert({ err: "데이터 불러오기 실패" });
    }

    setRepairContract("");
    setSupervisor("");
    setDivision("");
    setContractTerm("");
  };

  // 계약 유지보수 선택시 리스트 변경 - 지원 형태, 계약 기간
  const handleContractChange = async (selectedOption) => {
    const newContractId = selectedOption.value;

    try {
      if (newContractId) {
        const contractData = await fetchContractById(newContractId);
        setDivision(contractData.data.division); // division 값을 설정합니다.

        const startDate = contractData.data.start;
        const endDate = contractData.data.end;

        const contractTerm = (startDate && endDate) ? `${startDate} ~ ${endDate}` : '-';
        setContractTerm(contractTerm);
      }
    } catch (error) {
      ErrAlert({ err: "데이터 불러오기 실패" });
    }
  };

  // 작업상태 값 변경 ex) WORKING -> 작업 진행중
  // const [workStates, setWorkStates] = useState([]);
  useEffect(() => {
    // maintainData가 존재하고, maintainData가 배열일 경우에만 작업 상태 값을 초기 설정
    const updatedWorkStates = () => {
      if (Data.state === "WORKING") {
        return { label: "작업 진행중", value: "WORKING" };
      } else if (Data.state === "WORK_EXPECTED") {
        return { label: "작업 예정", value: "WORK_EXPECTED" };
      } else if (Data.state === "WORK_COMPLETE") {
        return { label: "작업 완료", value: "WORK_COMPLETE" };
      } else {
        return "-";
      }
    };
    setState(updatedWorkStates);
    setDivision(Data.division);
    setContractTerm(Data.contractPeriod);
  }, [Data]);

  // 종료일이 변경될 때 실행되는 함수
  const handleEndDateChange = (event) => {
    const selectedEndDate = event.target.value;
    setEnd(selectedEndDate);

    // 만약 선택된 종료일이 시작일보다 이전이면 시작일 오류 상태를 설정합니다.
    if (selectedEndDate < start) {
      setStartDateError(true);
    } else {
      setStartDateError(false);
    }
  };

  // 시작일이 변경될 때 실행되는 함수
  const handleStartDateChange = (event) => {
    const selectedStartDate = event.target.value;
    setStart(selectedStartDate);

    // 종료일이 존재하고 선택된 시작일이 종료일보다 이후이면 시작일 오류 상태를 설정합니다.
    if (end && selectedStartDate > end) {
      setStartDateError(true);
    } else {
      setStartDateError(false);
    }
  };

  const handleStartTimeChange = (event) => {
    const selectedStartTime = event.target.value;

    setStartDateError(false);

    if (selectedStartTime > endTime && start === end) {
      setStartDateError(true);
    }

    setStartTime(selectedStartTime);
  }

  const handleEndTimechange = (event) => {
    const selectEndTime = event.target.value;

    setStartDateError(false);

    if (startTime > selectEndTime && start === end) {
      setStartDateError(true);
    }

    setEndTime(selectEndTime);
  }

  // 시작일에 일수를 더하는 함수
  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result.toISOString().slice(0, 10);
  };

  // ========== form 등록 ==============
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (startDateError) {
      Swal.fire({
        icon: "warning",
        title: "주의",
        text: `시작일/종료일을 확인해주세요.`,
        showCancelButton: false,
        confirmButtonText: "확인",
      })
    } else {
      Swal.fire({
        icon: "question",
        title: "수정",
        text: `수정 하시겠습니까?`,
        showCancelButton: true,
        confirmButtonText: "저장",
        cancelButtonText: "취소",
      }).then(async (res) => {
        if (res.isConfirmed) {
          const formData = new FormData();

          let selectedStartTime = startTime || "00:00";
          let selectedEndTime = endTime || "00:00";

          formData.append("customer", customer.value);
          formData.append("repairContract", repairContract.value);
          formData.append("state", state.value);
          formData.append("division", division);
          formData.append("supervisor", supervisor.value);
          formData.append("start", start + "T" + selectedStartTime);
          formData.append(
            "end",
            end ? end + "T" + selectedEndTime : start + "T" + selectedEndTime
          );
          formData.append("content", content);
          formData.append("ref", ref);
          formData.append("oldFileList", oldFileList);
          for (var i = 0; i < file.length; i++) {
            formData.append("newFileList", file[i]);
          }

          // 멤버 데이터 처리
          const workerListValues = workerList.map((member) => member.value);
          formData.append("workers", workerListValues);

          try {
            const response = await instanceAxios.put(
              `${API.MAINTAINLIST}/${id}`,
              formData,
              {
                headers: {
                  "Content-Type": "application/form-data;charset=UTF-8",
                },
              }
            );
            ToastAlert({ icon: "success", title: "수정이 완료되었습니다." });
            navigate(`${API.MAINTAINDETAIL}/${id}`);
          } catch (error) {
            ErrAlert({ err: "필수 항목을 확인해주세요" });
          }
        } else {
          //취소
        }
      });
    }
  };

  //======== 수정 폼 ========
  const navigate = useNavigate();
  return (
    <div className="content">
      <div className="detail-wrap">
        <Card className="card-user">
          <CardHeader>
            <CardTitle tag="h5">유지보수 수정 </CardTitle>
          </CardHeader>
          <CardBody>
            {loading ? (
              <Spinner color="primary" />
            ) : (
              <>
                <Form encType="multipart/form-data" onSubmit={handleSubmit}>
                  <Row>
                    <Col>
                      <FormGroup>
                        <label>고객사</label>
                        <Select
                          required
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              borderColor: isCusInitial
                                ? "#ff0000"
                                : provided.borderColor,
                              // 선택되었을 때와 선택되지 않았을 때의 테두리색을 지정할 수 있습니다.
                            }),
                          }}
                          placeholder="필수값입니다"
                          options={customerName}
                          onChange={handleCustomerChange}
                          value={customer}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label>계약-유지보수</label>
                        <Select
                          required
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              borderColor: isConInitial
                                ? "#ff0000"
                                : provided.borderColor,
                              // 선택되었을 때와 선택되지 않았을 때의 테두리색을 지정할 수 있습니다.
                            }),
                          }}
                          placeholder="필수값입니다"
                          options={repairContracts}
                          onChange={(selectedOption) => {
                            handleContractChange(selectedOption);
                            setRepairContract(selectedOption);
                            setIsConInitial(false);
                          }}
                          value={repairContract}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <label>작업 상태</label>
                        <Select
                          required
                          name="state"
                          options={stateOptions}
                          onChange={(event) => {
                            setState(event);
                          }}
                          value={state}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label>작업자</label>
                        <div className="MDrop">
                          <Select
                            name="members"
                            isMulti
                            closeMenuOnSelect={false}
                            onChange={(options) => {
                              if (Array.isArray(options)) {
                                setWorkerList(options.map((opt) => opt));
                              }
                            }}
                            options={memberOptions}
                            components={{
                              Option: InputOption,
                            }}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                            value={workerList}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <label>고객사 담당자</label>
                        <Select
                          required
                          menuPortalTarget={document.body}
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              borderColor: isSupInitial
                                ? "#ff0000"
                                : provided.borderColor,
                              // 선택되었을 때와 선택되지 않았을 때의 테두리색을 지정할 수 있습니다.
                            }),
                            menuPortal: (base) => ({ ...base, zIndex: 9998 }), // zIndex를 설정하여 옵션 리스트가 다른 요소 위에 표시되도록 합니다.
                          }}
                          placeholder="필수값입니다"
                          name="cSupervisor"
                          options={supervisorList}
                          onChange={(selectedOption) => {
                            setSupervisor(selectedOption);
                            setIsSupInitial(false);
                          }}
                          value={supervisor}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <FormGroup>
                        <label>지원 형태</label>
                        <Input
                          name="select"
                          type="text"
                          value={division}
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label>계약 기간</label>
                        <Input type="text" value={contractTerm} readOnly />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <label>지원(예정)일</label>
                        <Input
                          required
                          className={start ? "is-valid" : "is-invalid"}
                          type="date"
                          name="start"
                          value={start}
                          onChange={handleStartDateChange}
                        />
                        {!start && (
                          <span style={{ color: "red" }}>필수로 입력해주세요.</span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <label>지원(예정)시간</label>
                        <Input
                          type="time"
                          name="startTime"
                          value={startTime}
                          onChange={handleStartTimeChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label>지원 종료일</label>
                        <Input
                          type="date"
                          name="end"
                          value={end}
                          onChange={handleEndDateChange}
                        />
                        {startDateError && (
                          <span style={{ color: "red" }}>
                            지원 종료일은 지원 예정일 이후여야 합니다.
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <label>지원(종료)시간</label>
                        <Input
                          type="time"
                          name="endTime"
                          value={endTime}
                          onChange={handleEndTimechange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>유지보수 항목</label>
                        {/* <Input
                          className="textArea"
                          type="textarea"
                          name="content"
                          onChange={(event) => {
                            setContent(event.target.value);
                          }}
                          defaultValue={Data.content}
                        /> */}
                        <CkEditor onChange={setContent} data={Data.content} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>참조 사항</label>
                        <Input
                          type="textarea"
                          name="ref"
                          onChange={(event) => {
                            setRef(event.target.value);
                          }}
                          defaultValue={Data.ref}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{ paddingLeft: "0.2em" }}>
                    <Col md="6">
                      <FormGroup>
                        <label>기존 첨부 파일</label>
                        <OldFileUpload
                          domainFileList={domainFileList}
                          setDomainFileList={setDomainFileList}
                          setOldFileList={setOldFileList}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label>신규 첨부 파일</label>
                        <FileUpload file={file} setFile={setFile} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <DeleteButton
                      to={`${API.MAINTAINDETAIL}/${id}`}
                      text1="수정을 취소하시겠습니까?"
                    />
                  </Row>
                </Form>
              </>
            )}
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
export default MaintainModify;
