import React from "react";
import MaterialIcon from "react-google-material-icons";

// 번호를 정렬하는 셀을 나타내는 컴포넌트
function SortFilter({ text, value, handleSort, isActive, activeCol, sortState }) {
  // 클릭된 정렬 상태에 따라 아이콘을 변경하는 로직
  let icon = isActive && activeCol === value ? "arrow_upward" : "arrow_downward";
  if (isActive && activeCol === value && sortState === "true") {
    icon = "arrow_upward"; // 오름차순 정렬 상태인 경우 아이콘을 화살표 위쪽으로 설정
  } else if (isActive && activeCol === value && sortState === "false") {
    icon = "arrow_downward"; // 내림차순 정렬 상태인 경우 아이콘을 화살표 아래쪽으로 설정
  }

  return (
    <th className="sort-wrap">
      {text}
      {/* 정렬 아이콘 클릭 시 handleSort 함수 호출하여 정렬 기준 변경 */}
      <div
        className={isActive && activeCol === value ? "sort-style text-danger" : "sort-style"}
        onClick={() => handleSort(value)}
        role="button"
      >
        {/* 아이콘 표시 */}
        <MaterialIcon icon={icon} size={24}/>
      </div>
    </th>
  );
}

export default SortFilter;
