import Swal from "sweetalert2";

/**
 * 토스트 알림을 띄우는 컴포넌트
 * @param {string} icon - 알림 아이콘 타입 (success, error, warning 등)
 * @param {string} title - 알림 제목
 * @returns 토스트 알림
 */
const ToastAlert = ({ icon, title }) => {
  /**
   * 토스트 알림을 띄우는 함수
   */
  const showToast = () => {
    const toast = Swal.mixin({
      toast: true,              // 토스트 모드 설정
      position: "top",          // 알림 위치
      showConfirmButton: false, // 확인 버튼 숨김
      timer: 1500,              // 알림이 보여지는 시간 (ms)
      timerProgressBar: true,   // 타이머 프로그레스 바 표시
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    // 토스트 알림 띄우기
    toast.fire({
      icon: icon,
      title: title,
    });
  };

  // 컴포넌트 렌더링
  return showToast();
};

export default ToastAlert;
