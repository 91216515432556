/*!
=========================================================
* Paper Dashboard React - v1.3.2
=========================================================
* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
} from "reactstrap";
import { useState, useEffect } from "react";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { API } from "ApiConfig";
import instanceAxios from "components/reissue/InstanceAxios";
import { fetchSupervisors } from "ApiUtils";
import FileUpload from "components/RegistForm/FileUpload";
import Swal from "sweetalert2";
import DeleteButton from "variables/DeleteButton";
import ToastAlert from "variables/ToastAlert";
import ErrAlert from "variables/ErrAlert";

/**
 * 고객사 등록 페이지
 * @author 김지연
 * @returns 고객사 등록 폼 리턴
 * @todo 연락처 유효성 검사 필요
 */
function CustomerRegist() {
  //================ form submit시 내보낼 형식 */
  // const [values, setValues] = useState({
  //   customerAddressInfoList: [],
  // });
  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setValues((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // };

  const [name, setName] = useState("");
  const [supervisorNo, setSupervisorNo] = useState("");
  const [supervisorWt, setSupervisorWt] = useState("");
  const [note, setNote] = useState("");

  const [file, setFile] = useState([]);

  const [isWtInitial, setIsWtInitial] = useState(true); // 초기 상태를 추적합니다.
  const [isNoInitial, setIsNoInitial] = useState(true); // 초기 상태를 추적합니다.

  //===============담당자 리스트 가져오기=========================================
  const [NoList, setNoList] = useState([]); //나인원 담당자 저장소
  const [WtList, setWtList] = useState([]); //와탭 담당자 저장소

  useEffect(() => {
    const fetchSupervisorData = async () => {
      try {
        const { supervisorsNo, supervisorsWt } = await fetchSupervisors();
        setNoList(supervisorsNo);
        setWtList(supervisorsWt);
      } catch (error) {
        ErrAlert({ err: "담당자 불러오기 실패" });
      }
    };

    fetchSupervisorData();
  }, []);

  // ====================== 주소 다중 추가 ===================//
  // location : 지점명, address : 주소
  const [customerAddressInfoList, setCustomerAddressInfoList] = useState([
    { location: "", address: "" },
  ]);
  /**
   * 주소 필드 추가
   */
  const addAddress = () => {
    const updatedAddressList = [
      ...customerAddressInfoList,
      { location: "", address: "" },
    ];
    setCustomerAddressInfoList(updatedAddressList);
  };
  /**
   * 주소 필드 제거
   * @param {number} i - 주소 필드 배열의 인덱스
   * @requires useState(react)
   * @returns 주소 필드 배열의 i번째 항목을 1개 제거 후 리턴
   */
  const removeAddress = (i) => {
    const updatedAddressList = [...customerAddressInfoList];
    updatedAddressList.splice(i, 1);
    setCustomerAddressInfoList(updatedAddressList);
  };
  const handleInputChange = (index, event) => {
    const updatedAddressList = [...customerAddressInfoList];
    updatedAddressList[index][event.target.name] = event.target.value;
    setCustomerAddressInfoList(updatedAddressList);
  };

  // form submit시 form 내부 내용 JSON화 하여 내보내기
  const handleSubmit = async (e) => {
    e.preventDefault();

    Swal.fire({
      icon: "question",
      title: "등록",
      text: `등록 하시겠습니까?`,
      showCancelButton: true,
      confirmButtonText: "등록",
      cancelButtonText: "취소",
    }).then(async (res) => {
      if (res.isConfirmed) {
        const formData = new FormData();

        formData.append("name", name);
        formData.append("supervisorNo", supervisorNo || 1);
        formData.append("supervisorWt", supervisorWt || 2);
        formData.append("note", note);

        customerAddressInfoList.forEach((info, index) => {
          formData.append(
            `customerAddressInfoList[${index}].location`,
            info.location
          );
          formData.append(
            `customerAddressInfoList[${index}].address`,
            info.address
          );
        });

        /**
         * TODO 백(SupportServiceImpl에 파일 추가될시 파일 업로드 되는 거 확인)
         */
        for (var i = 0; i < file.length; i++) {
          formData.append("fileList", file[i]);
        }

        try {
          const response = await instanceAxios.post(
            API.CUSTOMERLIST,
            formData,
            {
              headers: {
                "Content-Type": "application/form-data;charset=UTF-8",
              },
            }
          );
          // 서버 응답에 따른 동작 수행
          ToastAlert({ icon: "success", title: "등록이 완료되었습니다." });
          navigate(`/${API.CUSTOMER}`);
        } catch (error) {
          ErrAlert({ err: "필수 항목을 확인해주세요" });
        }
      } else {
        //취소
      }
    });
  };

  const navigate = useNavigate();
  return (
    <div className="content">
      <div className="regist-wrap">
        <Card className="card-user">
          <CardHeader>
            <CardTitle tag="h5">고객사 등록</CardTitle>
          </CardHeader>
          <CardBody>
            <Form encType="multipart/form-data" onSubmit={handleSubmit}>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <label>고객사</label>
                    <Input
                      required
                      className={name ? "is-valid" : "is-invalid"}
                      placeholder="필수로 입력해주세요."
                      type="text"
                      name="name"
                      onChange={(event) => {
                        setName(event.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <div>
                      {customerAddressInfoList.map((field, index) => {
                        return (
                          <div key={index}>
                            <Row>
                              <Col className="pr-1" md="2">
                                <Label>지점명</Label>
                                <Input
                                  type="text"
                                  name="location"
                                  value={field.location}
                                  onChange={(e) => handleInputChange(index, e)}
                                />
                              </Col>
                              <Col className="px-1" md="9">
                                <Label>주소</Label>
                                <Input
                                  type="text"
                                  name="address"
                                  value={field.address}
                                  onChange={(e) => handleInputChange(index, e)}
                                />
                              </Col>
                              <Col className="pl-1" md="1">
                                <Button
                                  type="button"
                                  onClick={
                                    index === 0
                                      ? addAddress
                                      : () => removeAddress(index)
                                  }
                                  style={{ marginTop: "25px" }}
                                >
                                  {index === 0 ? "+" : "-"}
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        );
                      })}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="pr-1" md="4">
                  <FormGroup>
                    <label>영업 담당자(나인원)</label>
                    <Select
                      name="supervisorNo"
                      options={NoList}
                      onChange={(event) => {
                        setSupervisorNo(event.value);
                        setIsNoInitial(false);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col className="pl-1" md="4">
                  <FormGroup>
                    <label>영업 담당자(와 탭)</label>
                    <Select
                      name="supervisorWt"
                      options={WtList}
                      onChange={(event) => {
                        setSupervisorWt(event.value);
                        setIsWtInitial(false);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <FileUpload file={file} setFile={setFile} />
              <Row>
                <Col md="12">
                  <FormGroup>
                    <label>비고</label>
                    <Input
                      type="textarea"
                      name="note"
                      onChange={(event) => {
                        setNote(event.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <DeleteButton
                  to={`/${API.CUSTOMER}`}
                  text1="작성을 취소 하시겠습니까?"
                />
              </Row>
            </Form>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
export default CustomerRegist;
