/*!

=========================================================
* Paper Dashboard React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import instanceAxios from "components/reissue/InstanceAxios";
import { useNavigate } from "react-router-dom";
import { API } from "ApiConfig";
import { fetchCustomers, fetchSupervisors, fetchMembers } from "ApiUtils";
import FileUpload from "components/RegistForm/FileUpload";
import Swal from "sweetalert2";
import DeleteButton from "variables/DeleteButton";
import ToastAlert from "variables/ToastAlert";
import ErrAlert from "variables/ErrAlert";
import CkEditor from "variables/CkEditor";

/**
 * 프로젝트 등록 페이지
 * @author 김지연
 * @returns 프로젝트 등록 폼 리턴
 */

function ProjectRegist() {
  // =============== form submit시 내보낼 형식 ==============
  const [name, setName] = useState(""); // 프로젝트 명
  const [customer, setCustomer] = useState(""); // 고객사 -
  const [supervisorNo, setSupervisorNo] = useState(""); // 나인원 담당자
  const [supervisorWt, setSupervisorWt] = useState(""); // 와탭 담당자
  const [start, setStart] = useState(""); // 시작일
  const [end, setEnd] = useState(""); // 종료일
  const [selectedMembers, setSelectedMembers] = useState([]); // 멤버
  const [content, setContent] = useState(""); // 비고
  const [file, setFile] = useState([]);
  const [startDateError, setStartDateError] = useState(false); // 계약 시작일 오류 여부 상태

  const [isCusInitial, setIsCusInitial] = useState(true); // 초기 상태를 추적합니다.
  const [isWtInitial, setIsWtInitial] = useState(true); // 초기 상태를 추적합니다.
  const [isNoInitial, setIsNoInitial] = useState(true); // 초기 상태를 추적합니다.

  //=============== 리스트 가져오기=========================================
  const [customerName, setCustomerName] = useState([]); // 고객사 저장소
  const [NoList, setNoList] = useState([]); //나인원 담당자 저장소
  const [WtList, setWtList] = useState([]); //와탭 담당자 저장소
  const [memberOptions, setMemberOptions] = useState([]); // 멤버 저장소

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [customers, supervisors, members] = await Promise.all([
          fetchCustomers(),
          fetchSupervisors(),
          fetchMembers(),
        ]);

        // 고객사 리스트 중 처음 두 개를 제외하고 반환
        customers.splice(0, 2);

        setCustomerName(customers);
        setNoList(supervisors.supervisorsNo);
        setWtList(supervisors.supervisorsWt);
        setMemberOptions(members);
      } catch (error) {
        ErrAlert({ err: "데이터 불러오기 실패" });
      }
    };

    fetchData();
  }, []);

  //=====================Member dropdown select======================

  const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
  }) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    //스타일 설정
    let bg = "transparent";
    if (isFocused) bg = "#eee";
    if (isActive) bg = "#B2D4FF";

    const style = {
      alignItems: "center",
      backgroundColor: bg,
      color: "inherit",
      display: "flex",
    };

    //prop
    const props = {
      ...innerProps,
      onMouseDown,
      onMouseUp,
      onMouseLeave,
      style,
    };

    return (
      <components.Option
        {...rest}
        isDisabled={isDisabled}
        isFocused={isFocused}
        isSelected={isSelected}
        getStyles={getStyles}
        innerProps={props}
      >
        <input type="checkbox" checked={isSelected} />
        {children}
      </components.Option>
    );
  };

  // 종료일이 변경될 때 실행되는 함수
  const handleEndDateChange = (event) => {
    const selectedEndDate = event.target.value;
    setEnd(selectedEndDate);

    // 만약 선택된 종료일이 시작일보다 이전이면 시작일 오류 상태를 설정합니다.
    if (selectedEndDate < start) {
      setStartDateError(true);
    } else {
      setStartDateError(false);
    }
  };

  // 시작일이 변경될 때 실행되는 함수
  const handleStartDateChange = (event) => {
    const selectedStartDate = event.target.value;
    setStart(selectedStartDate);

    // 종료일이 존재하고 선택된 시작일이 종료일보다 이후이면 시작일 오류 상태를 설정합니다.
    if (end && selectedStartDate > end) {
      setStartDateError(true);
    } else {
      setStartDateError(false);
    }
  };

  // form submit시 FormData 내용
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (startDateError) {
      Swal.fire({
        icon: "warning",
        title: "주의",
        text: `시작일/종료일을 확인해주세요.`,
        showCancelButton: false,
        confirmButtonText: "확인",
      })
    } else {
      Swal.fire({
        icon: "question",
        title: "등록",
        text: `등록 하시겠습니까?`,
        showCancelButton: true,
        confirmButtonText: "등록",
        cancelButtonText: "취소",
      }).then(async (res) => {
        if (res.isConfirmed) {
          const formData = new FormData();
          formData.append("name", name);
          formData.append("customer", customer);
          formData.append("supervisorNo", supervisorNo || 1);
          formData.append("supervisorWt", supervisorWt || 2);
          formData.append("start", start);
          formData.append("end", end);
          formData.append("content", content);

          //파일 및 멤버 처리
          for (var i = 0; i < file.length; i++) {
            formData.append("fileList", file[i]);
          }

          // 멤버 데이터 처리
          formData.append("workers", selectedMembers);

          try {
            const response = await instanceAxios.post(
              `${API.PROJECTLIST}`,
              formData,
              {
                headers: {
                  "Content-Type": `multipart/form-data;charset=UTF-8`,
                },
              }
            );
            ToastAlert({ icon: "success", title: "등록이 완료되었습니다." });
            navigate(`/${API.PROJECT}`);
          } catch (error) {
            let errorMessage = "오류가 발생했습니다.";
            if (error.response && error.response.status === 400) {
              // 서버에서 400 에러를 반환하면 잘못된 요청이므로 사용자에게 안내합니다.
              errorMessage = "잘못된 요청입니다. 입력 값을 다시 확인해주세요.";
            } else if (error.response && error.response.status === 500) {
              // 서버에서 500 에러를 반환하면 서버 오류이므로 사용자에게 안내합니다.
              errorMessage = "서버 오류가 발생했습니다. 잠시 후 다시 시도해주세요.";
            }
            ErrAlert({ err: errorMessage });
          }
        } else {
          //취소
        }
      });
    }
  };

  const navigate = useNavigate();
  //============= 등록 폼
  return (
    <div className="content">
      <div className="regist-wrap">
        <Card className="card-user">
          <CardHeader>
            <CardTitle tag="h5">프로젝트 등록</CardTitle>
          </CardHeader>
          <CardBody>
            <Form encType="multipart/form-data" onSubmit={handleSubmit}>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <label>프로젝트 명</label>
                    <Input
                      type="text"
                      name="name"
                      onChange={(event) => {
                        setName(event.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="pr-1" md="4">
                  <FormGroup>
                    <label>고객사</label>
                    <Select
                      required
                      placeholder="필수값입니다"
                      name="customer"
                      options={customerName}
                      onChange={(event) => {
                        setCustomer(event.value);
                        setIsCusInitial(false);
                      }}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        control: (provided, state) => ({
                          ...provided,
                          borderColor: isCusInitial
                            ? "#ff0000"
                            : provided.borderColor,
                          // 선택되었을 때와 선택되지 않았을 때의 테두리색을 지정할 수 있습니다.
                        }),
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col className="px-1" md="4">
                  <FormGroup>
                    <label>영업 담당자(나인원)</label>
                    <Select
                      name="supervisorNo"
                      options={NoList}
                      onChange={(event) => {
                        setSupervisorNo(event.value);
                        setIsNoInitial(false);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col className="pl-1" md="4">
                  <FormGroup>
                    <label>영업 담당자(와 탭)</label>
                    <Select
                      name="supervisorWt"
                      options={WtList}
                      onChange={(event) => {
                        setSupervisorWt(event.value);
                        setIsWtInitial(false);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="pr-1" md="4">
                  <FormGroup>
                    <label>프로젝트 시작일</label>
                    <Input
                      type="date"
                      name="start"
                      onChange={handleStartDateChange}
                    />
                  </FormGroup>
                </Col>
                <Col className="px-1" md="4">
                  <FormGroup>
                    <label>프로젝트 종료일</label>
                    <Input
                      type="date"
                      name="end"
                      onChange={handleEndDateChange}
                    />
                    {startDateError && (
                      <span style={{ color: "red" }}>
                        프로젝트 종료일은 프로젝트 시작일 이후여야 합니다.
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col className="pl-1" md="4">
                  <FormGroup>
                    <label>작업자</label>
                    <div className="MDrop">
                      <Select
                        name="workers"
                        isMulti
                        closeMenuOnSelect={false}
                        onChange={(options) => {
                          if (Array.isArray(options)) {
                            setSelectedMembers(options.map((opt) => opt.value));
                          }
                        }}
                        options={memberOptions}
                        components={{
                          Option: InputOption,
                        }}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <FileUpload file={file} setFile={setFile} />
              <Row>
                <Col md="12">
                  <FormGroup>
                    <label>설명</label>
                    {/* <Input
                      type="textarea"
                      className="textArea"
                      name="content"
                      onChange={(event) => {
                        setContent(event.target.value);
                      }}
                    /> */}
                    <CkEditor onChange={setContent} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <DeleteButton
                  to={`/${API.PROJECT}`}
                  text1="작성중인 게시물을 삭제 하시겠습니까?"
                />
              </Row>
            </Form>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
export default ProjectRegist;
