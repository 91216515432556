// table list 페이지
// 길이가 길어지면 아래로 내려가는게 나은지 스크롤이 있는게 나은지

import React, { useEffect } from "react";
import { useState } from "react";
import "../../assets/css/List.css";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Col,
  Spinner,
} from "reactstrap";

import { useNavigate } from "react-router-dom";
import { API } from "../../ApiConfig";
import instanceAxios from "components/reissue/InstanceAxios";
import Swal from "sweetalert2";
import ErrAlert from "variables/ErrAlert";

/**
 * 어떤 리스트인지 나타내는 제목입니다.
 */
const titleName = "멤버";

/**
 *
 * @returns table형식의 list page를 리턴합니다.
 */
function MemberList() {
  //const [originalData, setOriginalData] = useState([]);
  const [memberData, setMemberData] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0); // 전체 요소 갯수
  const [loading, setLoading] = useState(true); // 로딩 상태 추가
  const PER_PAGE = 5; // 한 페이지 출력 갯수
  const [perPage, setPerPage] = useState(5); // 한 페이지 출력 갯수를 데이터 개수로 설정

  const memRole = localStorage.getItem("memRole");

  useEffect(() => {
    if (memRole === "USER") {
      ErrAlert({ err: "접근할 수 없는 권한입니다." });
      navigate(`/${API.MAIN}`);
    }
  }, []);

  async function fetchData() {
    try {
      const response = await instanceAxios.get(`${API.MEMBERLIST}`);

      const data = response.data?.data || [];
      // perpage를 데이터의 개수로 설정
      const dataLength = data.length;
      setPerPage(dataLength);

      setMemberData(data);
      setCount(response.data.data.length);
    } catch (error) {
      // 에러 처리
      ErrAlert({ err: "데이터 불러오기 실패" });
    } finally {
      // 로딩 상태 업데이트
      setLoading(false);
    }
  }

  const [reloadData, setReloadData] = useState(false);

  useEffect(() => {
    fetchData();
  }, [reloadData]);

  /**
   * 전체{}건 을 클릭시 필터가 적용되지 않은 첫 페이지로 돌아갑니다
   */
  const handleTotalCountClick = () => {
    // 필터 상태 초기화
    setSearchClicked(false);
    setFilteredMaintains([]);

    // 페이지를 1로 설정하여 초기 리스트로 이동
    setPage(1);

    // 페이지 리로드
    setReloadData(!reloadData);
  };

  const navigate = useNavigate();

  // 두 번째 셀렉트로 필터링된 데이터를 저장할 상태
  const [filteredMaintains, setFilteredMaintains] = useState([]);
  const [searchClicked, setSearchClicked] = useState(false); // 검색 버튼 클릭 여부를 저장
  const startingIndex = (page - 1) * perPage + 1; // 현재 페이지의 첫 번째 아이템 번호

  // createdDate 값을 변환하여 반환하는 함수
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("ko-KR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };

  /**
   * 배열을 페이지 번호에 맞춰 반복문을 돌려 담아주는 함수입니다.
   * @returns 게시글을 배열에 담아 리턴합니다.
   */
  function arrayList() {
    var array = [];
    const pageRender = (page - 1) * perPage;

    // 검색 버튼을 클릭한 경우에만 필터된 데이터를 출력
    if (searchClicked) {
      // 두 번째 셀렉트로 필터링된 데이터를 사용하도록 수정
      const membersToDisplay =
        filteredMaintains.length > 0 ? filteredMaintains : null;
      // 필터된 데이터가 없다면 아무것도 출력하지 않음
      if (!membersToDisplay || membersToDisplay.length === 0) {
        return (
          <tr key="no-data" style={{ textAlign: "center" }}>
            <td colSpan="5">선택한 값에 맞는 리스트가 없습니다.</td>
          </tr>
        );
      }
      //      for (var i = pageRender; i < pageRender + PER_PAGE; i++)
      for (
        var i = pageRender;
        i < Math.min(pageRender + perPage, membersToDisplay.length);
        i++
      ) {
        // 첫 번째 셀렉트 값이 'customer'이고, 두 번째 셀렉트 값이 해당 프로젝트의 'customer'와 일치하면 렌더링
        if (i < membersToDisplay.length) {
          const currentId = membersToDisplay[i].id; // 현재 프로젝트 객체를 저장
          array.push(
            <tr
              key={membersToDisplay[i].id}
              id="move"
              onClick={() => {
                navigate(`${API.MEMBERDETAIL}/${currentId}`);
              }}
            >
              <div className="maintain-list-wrap">
                <div className="maintain-list-left">
                  <td>{startingIndex + i || "-"}</td>
                  <td>{membersToDisplay[i].name || "-"}</td>
                  <td>{membersToDisplay[i].email || "-"}</td>
                </div>
                <div className={"maintain-list-right"}>
                  <td>
                    {membersToDisplay[i].role === "ADMIN"
                      ? "관리자"
                      : "사용자" || "-"}
                  </td>
                  <td>
                    {membersToDisplay[i].state === "APPROVE"
                      ? "승인"
                      : membersToDisplay[i].state === "STANDBY"
                        ? "대기"
                        : membersToDisplay[i].state === "LEAVE"
                          ? "탈퇴"
                          : membersToDisplay[i].state || "-"}
                  </td>
                  <div className="maintain-progress-wrap">
                    <td>
                      {formatDate(membersToDisplay[i].createdDate) || "-"}
                    </td>
                  </div>
                </div>
              </div>
            </tr>
          );
        }
      }
    } else {
      // 초기 리스트에 데이터가 없을 때
      if (!memberData || memberData.length === 0) {
        return (
          <tr key="no-data" style={{ textAlign: "center" }}>
            <td colSpan="6">등록된 글이 없습니다.</td>
          </tr>
        );
      }

      // 초기 리스트에 데이터가 있을 때
      memberData.slice(pageRender, pageRender + perPage).map((item, index) =>
        array.push(
          <tr
            key={item.id}
            id="move"
            onClick={() => navigate(`${API.MEMBERDETAIL}/${item.id}`)}
            style={{ textAlign: "center" }}
          >
            <div className={"maintain-list-wrap"}>
              <div className={"maintain-list-left"}>
                <td>{startingIndex + index || "-"}</td>
                <td>{item.name || "-"}</td>
                <td>{item.email || "-"}</td>
              </div>
              <div className={"maintain-list-right"}>
                <td>
                  {item.role === "ADMIN" ? "관리자" : "일반 사용자" || "-"}
                </td>
                <td>
                  {item.state === "APPROVE"
                    ? "승인"
                    : item.state === "STANDBY"
                      ? "대기"
                      : item.state === "LEAVE"
                        ? "탈퇴"
                        : item.state || "-"}
                </td>
                <div className="maintain-progress-wrap">
                  <td>{formatDate(item.createdDate) || "-"}</td>
                </div>
              </div>
            </div>
          </tr>
        )
      );
    }

    return array;
  }
  return (
    <>
      <div className="content">
        <Card>
          <CardHeader>
            <CardTitle className="cardTitle" tag="h4">
              {titleName}
            </CardTitle>
          </CardHeader>
          <CardBody>
            {loading ? (
              <Spinner color="primary" />
            ) : (
              <>
                {/* <Table responsive /> */}
                <div style={{ display: "flex" }}>
                  <Col md="auto" className="d-flex align-items-center">
                    <p
                      className="category"
                      style={{ fontSize: "17px", display: "flex" }}
                    >
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={handleTotalCountClick}
                        onMouseEnter={(e) => (e.target.style.color = "#240170")}
                        onMouseLeave={(e) => (e.target.style.color = "#9A9A9A")}
                      >
                        전체 {count}건
                      </span>
                    </p>
                  </Col>
                </div>
                <Table>
                  <thead className={"text-primary"}>
                    <tr className={"maintain-title-wrap"}>
                      <div className={"maintain-title-left"}>
                        <th>번호</th>
                        <th>이름</th>
                        <th>이메일</th>
                      </div>
                      <div className={"maintain-title-right"}>
                        <th>권한</th>
                        <th>상태</th>
                        <th>가입일</th>
                      </div>
                    </tr>
                  </thead>
                  <tbody>{arrayList()}</tbody>
                </Table>
              </>
            )}
          </CardBody>
        </Card>
      </div>
    </>
  );
}

export default MemberList;
