function StateFilter(props) {
  /**
     * 상태 필터 컴포넌트
     * 작성자: 이준복
     * props로 넘겨받아야 하는 것:
     * 1. stateOptions - 상태 옵션 데이터 배열
     * 2. filterOpen - 필터 영역이 열려있는지 여부를 나타내는 상태
     * 3. setFilterOpen - 필터 영역의 열림 여부를 설정하는 함수
     * 4. handleTempFilter - 임시 필터링 처리를 위한 콜백 함수
     * @returns JSX 요소를 반환
     */

  // const domain = props.domain;

  const stateOptions = props.stateOptions;          // 상태 옵션 데이터 배열

  // const originalData = props.originalData;
  // const setOriginalData = props.setOriginalData;

  const filterOpen = props.filterOpen;              // 필터 영역의 열림 여부 상태
  const setFilterOpen = props.setFilterOpen;        // 필터 영역의 열림 여부를 설정하는 함수

  // const setListData = props.setListData;
  /**
   * 상태 체크박스 체크 여부 배열
   */
  function calculateChk() {
    // 체크박스 true, false 배열
    var tempCheck = [];
    for (var i = 0; i < stateOptions.length; i++) {
      tempCheck.push(
        document.getElementById("filterCondition").childNodes[i].childNodes[0]
          .checked
      );
    }

    var tempFilter = [];
    tempCheck.map((check, index) => {
      if (check === true) {
        tempFilter.push(stateOptions[index].value);
      }
    });
    props.handleTempFilter(tempFilter);
    // var tempListData = [];

    // // 체크박스 true(체크) 된 것과 동일한 상태의 값만 배열에 담는다.
    // // includes 사용하면 properties 에러 발생해서 === 으로 처리
    // // # 각각 responseDto에서  작업상태를 의미하는 변수명이 달라 if로 분기 처리
    // if (domain === "SUPPORT") {
    //   originalData?.map((data) => {
    //     tempFilter?.map((filterElement) => {
    //       if (data.supportState === filterElement) {
    //         tempListData.push(data);
    //       }
    //     });
    //   });
    // } else {
    //   originalData?.map((data) => {
    //     tempFilter?.map((filterElement) => {
    //       if (data.state === filterElement) {
    //         tempListData.push(data);
    //       }
    //     });
    //   });
    // }

    // setListData(tempListData);
  }

  // 상태 필터 옵션 렌더링
  const filterView = () => {
    return stateOptions.map((option) => {
      return (
        <li key={option.value}>
          <input
            type="checkbox"
            name="chk"
            id={option.value}
            value={option.value}
            onChange={(event) => {
              calculateChk();
            }}
            defaultChecked
          />{" "}
          <label
            style={{
              fontSize: "1em",
              color: "black",
              width: "4em",
              margin: "auto",
            }}
          >
            {option.label}
          </label>
        </li>
      );
    });
  };

  /**
   * 필터 영역을 열고 닫는 함수
   * @param {string} state - 열림 여부를 설정하는 값 ('visible' 또는 'hidden')
   */
  const openCloseSelect = (state) => {
    document.getElementById("Div").style.visibility = `${state}`;
    //setOpenClose("")
  };
  //##  ▼ 버튼 클릭 시 계약상태 체크박스 영역 열고 닫기
  function openCloseBtn() {
    if (filterOpen === false) {
      openCloseSelect("visible");
      setFilterOpen(true);
    } else {
      openCloseSelect("hidden");
      setFilterOpen(false);
    }
  }

  return (
    <>
      <span
        style={{ position: "absolute", width: "auto" }}
        type="button"
        onClick={() => {
          openCloseBtn();
        }}
      >
        ⚙️
      </span>
      <div
        id="Div"
        className="filter-div"
        style={{
          position: "absolute",
          // right:"0",
          width: "auto",
          borderRadius: "0.5em",
          border: "1px solid lightgray",
          visibility: "hidden",
          fontSize: "12px",
          backgroundColor: "whitesmoke",
          zIndex: "1",
        }}
      >
        <ul id="filterCondition" className="filter-wrap">
          {filterView()}
        </ul>
      </div>
    </>
  );
}
export default StateFilter;
