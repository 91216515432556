// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[type="checkbox"] {
  vertical-align: middle;
  -webkit-appearance: none;
          appearance: none;
  border: max(2px, 0.1em) solid gray;
  border-radius: 15%;
  width: 1.15em;
  height: 1.15em;
  transition: border 0.1s ease-in-out;
  margin-bottom: 1.5px;
}

[type="checkbox"]:checked {
  border: 0.4em solid rgb(89, 80, 211);
}

[type="checkbox"]:hover {
  box-shadow: 0 0 0 max(1px, 0.2em) lightgray;
  cursor: pointer;
}

[type="checkbox"]:hover + span {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/CheckButton.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,wBAAgB;UAAhB,gBAAgB;EAChB,kCAAkC;EAClC,kBAAkB;EAClB,aAAa;EACb,cAAc;EACd,mCAAmC;EACnC,oBAAoB;AACtB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,2CAA2C;EAC3C,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB","sourcesContent":["[type=\"checkbox\"] {\n  vertical-align: middle;\n  appearance: none;\n  border: max(2px, 0.1em) solid gray;\n  border-radius: 15%;\n  width: 1.15em;\n  height: 1.15em;\n  transition: border 0.1s ease-in-out;\n  margin-bottom: 1.5px;\n}\n\n[type=\"checkbox\"]:checked {\n  border: 0.4em solid rgb(89, 80, 211);\n}\n\n[type=\"checkbox\"]:hover {\n  box-shadow: 0 0 0 max(1px, 0.2em) lightgray;\n  cursor: pointer;\n}\n\n[type=\"checkbox\"]:hover + span {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
