// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#move:hover {
  /* box-shadow: 0 0 0 max(1px, 0.2em) lightgray; */
  background-color: #f3f6ff;
  cursor: pointer;
}

/* 추후 고객사 카드 색상 */
/* background: rgb(88, 37, 200);
  background: linear-gradient(
    90deg,
    rgba(88, 37, 200, 1) 2%,
    rgba(101, 76, 203, 1) 37%,
    rgba(119, 109, 221, 1) 100%
  );
  color: whitesmoke; */

  .deleted {
    border: 1px solid red;
  }
  
  .deleted-text {
    color: red !important;
  }

  .expand-button{
    margin-left: 2em;
  }

  .expand-following {
    background-color: #fcfbf5;
  }
  
@media screen and (max-width: 768px) {
  .filter-div{
    right: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/assets/css/List.css"],"names":[],"mappings":"AAAA;EACE,iDAAiD;EACjD,yBAAyB;EACzB,eAAe;AACjB;;AAEA,iBAAiB;AACjB;;;;;;;sBAOsB;;EAEpB;IACE,qBAAqB;EACvB;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;EAC3B;;AAEF;EACE;IACE,QAAQ;EACV;AACF","sourcesContent":["#move:hover {\n  /* box-shadow: 0 0 0 max(1px, 0.2em) lightgray; */\n  background-color: #f3f6ff;\n  cursor: pointer;\n}\n\n/* 추후 고객사 카드 색상 */\n/* background: rgb(88, 37, 200);\n  background: linear-gradient(\n    90deg,\n    rgba(88, 37, 200, 1) 2%,\n    rgba(101, 76, 203, 1) 37%,\n    rgba(119, 109, 221, 1) 100%\n  );\n  color: whitesmoke; */\n\n  .deleted {\n    border: 1px solid red;\n  }\n  \n  .deleted-text {\n    color: red !important;\n  }\n\n  .expand-button{\n    margin-left: 2em;\n  }\n\n  .expand-following {\n    background-color: #fcfbf5;\n  }\n  \n@media screen and (max-width: 768px) {\n  .filter-div{\n    right: 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
