import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import Swal from "sweetalert2";

/**
 * 삭제 버튼 컴포넌트
 * @param {string} to - 삭제 후 이동할 경로
 * @param {string} text1 - 경고창에 표시할 메시지
 */
function DeleteButton({ to, text1 }) {
  const navigate = useNavigate();
  /**
   * 삭제 이벤트 처리 함수
   */
  const deleteEvent = () => {
    Swal.fire({
      icon: "warning",
      title: "잠깐!",
      text: text1,
      showCancelButton: true,
      confirmButtonText: "예",
      cancelButtonText: "아니요",
    }).then((res) => {
      if (res.isConfirmed) {
        navigate(`${to}`);
      } else {
        // 취소
      }
    });
  };

  return (
    <div className="update ml-auto mr-auto">
      <Button outline color="danger" onClick={deleteEvent}>
        취소
      </Button>
      <Button outline color="primary" type="submit">
        저장
      </Button>
    </div>
  );
}

export default DeleteButton;
