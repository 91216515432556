import instanceAxios from "components/reissue/InstanceAxios";
import { useState } from "react";
import Select from "react-select";
import { Col, Row } from "reactstrap";
import { getSelectOptions } from "./SelectOption";
import {Input} from "reactstrap";

/**
 * 한 개의 셀렉트 박스와 텍스트 박스를 렌더링하고 각각 선택된 값을 추적하는 컴포넌트
 * @param {function} onFirstSelectChange - 첫 번째 셀렉트 박스에서 선택된 값이 변경될 때 호출되는 콜백 함수
 * @param {function} onSecondSelectChange - 두 번째 텍스트 박스에서 선택된 값이 변경될 때 호출되는 콜백 함수
 * @param {array} options - 첫 번째 셀렉트 박스의 옵션 데이터
 * @returns 두 개의 셀렉트 박스를 렌더링
 */
const SelectBox = ({ onFirstSelectChange, onSecondSelectChange, options, handleSearchButtonClick }) => {
  // 첫 번째 셀렉트 박스의 옵션 데이터 생성
  const selectOptions1 = getSelectOptions(options);
  // 두 번째 셀렉트 박스의 상태 관리를 위한 useState 훅 사용
  const [selectOptions2, setSelectOptions2] = useState([]);
  const [selectData2, setSelectData2] = useState("");

  // 첫 번째 셀렉트의 선택된 값을 추적하기 위한 상태
  async function fetchData(selectedOptionValue) {
    try {
      const request = await instanceAxios.get(`/api/${selectedOptionValue}`);

      const data = request.data?.data?.data || [];
      // value와 label 프로퍼티를 가진 객체로 변환하여 새로운 배열 생성
      const options = data.map((res) => ({
        value: res.id,
        label: res[selectedOptionValue],
      }));

      // 다음에 두 번째 셀렉트의 options를 업데이트
      setSelectOptions2(options);
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * 첫번째 셀렉트 박스에서 값이 선택되면 그에 맞는 데이터를 불러오고, 다른 값을 선택 시 두번째 박스 초기화
   * @param {첫 번째 셀렉트에서 선택 된 값} selectedOption
   */
  const handleFirstSelectChange = (selectedOption) => {
    // 부모 컴포넌트로 첫 번째 선택된 값 전달
    onFirstSelectChange(selectedOption.value);
    // 첫 번째 셀렉트된 값을 데이터를 불러오는 함수로 전달
    // fetchData(selectedOption.value);
  };

  return (
    <Row className="justify-content-end" style={{ width: "100%" }}>
      <Col md="auto" className="d-flex align-items-center">
        <Select
          className="selectBox-custom"
          options={selectOptions1}
          onChange={handleFirstSelectChange}
          defaultValue="select"
          isSearchable={false} // 검색 기능 비활성화
        />
      </Col>
      <Col md="auto" className="d-flex align-items-center">
        {/* <Select
          className="selectBox-custom"
          options={selectOptions2}
          onChange={(selectedOption) => {
            // 부모 컴포넌트로 두 번째 선택된 값 전달
            onSecondSelectChange(selectedOption.label);
            setSelectData2(selectedOption);
          }}
          value={selectData2} // 선택된 값을 명시적으로 지정
          defaultValue="select"
        /> */}
        <Input
          style={{height: "2.8em"}}
          className="selectBox-custom"
          onChange={(event) => {
            // 부모 컴포넌트로 두 번째 선택된 값 전달
            onSecondSelectChange(event.target.value);
            setSelectData2(event.target.value);
          }}
          onKeyPress={(event) => {
            // 키가 "Enter"인지 확인
            if (event.key === 'Enter') {
              // "Enter" 키를 누르면 handleSearchButtonClick 함수 호출
              handleSearchButtonClick();
            }
          }}
          value={selectData2} // 선택된 값을 명시적으로 지정
        />
      </Col>
    </Row>
  );
};

export default SelectBox;
