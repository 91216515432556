// 고객사 연관정보 리스트 - 계약
import React from "react";
import { useState, useEffect } from "react";
import Paging from "../../../variables/Paging";
import "../../../assets/css/List.css";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Col,
  Spinner,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { API } from "../../../ApiConfig";
import Swal from "sweetalert2";

/**
 * 어떤 리스트인지 나타내는 제목입니다.
 */
const titleName = "계약";

/**
 *
 * @returns table형식의 list page를 리턴합니다.
 */
function ContractRD(prop) {
  const [contractData, setContractData] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0); // 전체 요소 갯수
  const [loading, setLoading] = useState(true); // 로딩 상태 추가
  const DEFAULT_PER_PAGE = 5; // 한 페이지 출력 갯수
  // ## 필터 적용 전 원본 리스트 데이터

  async function fetchData() {
    try {
      const data = prop.data.customer.contractListResponseDtoList;
      setContractData(data);
      setCount(data.length);
    } catch (error) {
      console.log(error);
      // 에러 처리
    } finally {
      // 로딩 상태 업데이트
      setLoading(false);
    }
  }

  const [reloadData, setReloadData] = useState(false);

  useEffect(() => {
    fetchData();
  }, [reloadData]);

  // const [List, setList] = useState(firstList);
  // let listSize = List.length;

  /**
   * 전체{}건 을 클릭시 필터가 적용되지 않은 첫 페이지로 돌아갑니다
   */
  const handleTotalCountClick = () => {
    // 필터 상태 초기화
    setSearchClicked(false);
    setFilteredContracts([]);

    // 페이지를 1로 설정하여 초기 리스트로 이동
    setPage(1);

    // 페이지 리로드
    setReloadData(!reloadData);
  };

  const [PER_PAGE, setPerPage] = useState(DEFAULT_PER_PAGE);

  /**
   * 페이지가 변경될 때마다 페이지 번호를 가져와 useState의 setPage를 이용하여 업데이트 해주는 함수입니다.
   * @param {number} page
   */
  const handlePageChange = (page) => {
    setPage(page);
  };

  const navigate = useNavigate();

  // 두 번째 셀렉트로 필터링된 데이터를 저장할 상태
  const [filteredContracts, setFilteredContracts] = useState([]);
  const [searchClicked, setSearchClicked] = useState(false); // 검색 버튼 클릭 여부를 저장

  // 계약구분 값 변경 ex) WORKING -> 작업 진행중
  const [conDiv, setConDiv] = useState([]);

  useEffect(() => {
    // contractData 존재하고, contractData 배열일 경우에만 작업 상태 값을 설정
    if (Array.isArray(contractData) && contractData.length > 0) {
      const updatedConDiv = contractData
        .slice((page - 1) * PER_PAGE, page * PER_PAGE)
        .map((item) => {
          if (item.contractClass === "REPAIR") {
            return "유지보수";
          } else if (item.contractClass === "SET") {
            return "일반";
          } else {
            return "-";
          }
        });
      setConDiv(updatedConDiv);
    }
  }, [contractData, page]);

  // ## 계약 상태 옵션
  const stateOptions = [
    { value: "CONTRACTING", label: "계약중" },
    { value: "CONTRACT_COMPLETE", label: "계약 완료" },
    { value: "CONTRACT_EXPIRED", label: "계약 만료" },
    { value: "ETC", label: "기타" },
  ];
  // ## state ENUM 명을 한글로 변경해서 return
  function conState(state) {
    var state;
    stateOptions.map((option) => {
      if (option.value === state) {
        state = option.label;
      }
    });
    return state;
  }

  /**
   * 배열을 페이지 번호에 맞춰 반복문을 돌려 담아주는 함수입니다.
   * 필터가 적용되면 조건에 부합하게 다시 리스트를 뿌려줍니다.
   * @returns 게시글을 배열에 담아 리턴합니다.
   */
  function arrayList() {
    var array = [];
    const pageRender = (page - 1) * PER_PAGE;
    // 현재 페이지의 첫 번째 아이템 번호
    const startingIndex = (page - 1) * PER_PAGE + 1;

    // 검색 버튼을 클릭한 경우에만 필터된 데이터를 출력
    if (searchClicked) {
      // 두 번째 셀렉트로 필터링된 데이터를 사용하도록 수정
      const contractsToDisplay =
        filteredContracts.length > 0 ? filteredContracts : null;
      // 필터된 데이터가 없다면 아무것도 출력하지 않음
      if (!contractsToDisplay || contractsToDisplay.length === 0) {
        return (
          <tr key="no-data" style={{ textAlign: "center" }}>
            <td colSpan="5">선택한 값에 맞는 리스트가 없습니다.</td>
          </tr>
        );
      }

      for (var i = pageRender; i < pageRender + PER_PAGE; i++) {
        // 첫 번째 셀렉트 값이 'customer'이고, 두 번째 셀렉트 값이 해당 프로젝트의 'customer'와 일치하면 렌더링
        if (i < contractsToDisplay.length) {
          const currentId = contractsToDisplay[i].id; // 현재 프로젝트 객체를 저장
          const currentClass = contractsToDisplay[i].contractClass;
          array.push(
            <tr
              key={contractsToDisplay[i].id}
              id="move"
              onClick={() => {
                if (currentClass === "REPAIR") {
                  navigate(`${API.CONTRACTDETAIL1}/${currentId}`);
                } else if (currentClass.contractClass === "SET") {
                  navigate(`${API.CONTRACTDETAIL2}/${currentId}`);
                }
              }}
              style={{ textAlign: "center" }}
            >
              <td>{startingIndex + i || "-"}</td>
              {/* <td>{contractsToDisplay[i].customer || "-"}</td> */}
              <td>{contractsToDisplay[i].contract || "-"}</td>
              <td>{conDiv[i].contractClass || "-"}</td>
              {/* <td>{contractsToDisplay[i].start || "-"}</td> */}
              <td>{contractsToDisplay[i].end || "-"}</td>
              <td>{contractsToDisplay[i].state || "-"}</td>
            </tr>
          );
        }
      }
    } else {
      // 초기 리스트에 데이터가 없을 때
      if (!contractData || contractData.length === 0) {
        return (
          <tr key="no-data" style={{ textAlign: "center" }}>
            <td colSpan="7">등록된 글이 없습니다.</td>
          </tr>
        );
      }

      // 초기 리스트에 데이터가 있을 때
      contractData.slice(pageRender, pageRender + PER_PAGE).map((item, index) =>
        array.push(
          <tr
            key={item.id}
            id="move"
            onClick={() => {
              if (item.contractClass === "SET") {
                navigate(`${API.CONTRACTDETAIL1}/${item.id}`);
              } else if (item.contractClass === "REPAIR") {
                navigate(`${API.CONTRACTDETAIL2}/${item.id}`);
              }
              // navigate(`${API.CONTRACTDETAIL1}/${item.id}`);
            }}
            style={{ textAlign: "center" }}
            className={item.deleteTandF ? "deleted-text" : ""}
          >
            <td>{startingIndex + index || "-"}</td>
            {/* <td>{item.customer || "-"}</td> */}
            <td>{item.contract || "-"}</td>
            <td>{conDiv[index] || "-"}</td>
            {/* <td>{item.start || "-"}</td> */}
            <td>{item.end || "-"}</td>
            <td>{conState(item.state) || "-"}</td>
          </tr>
        )
      );
    }

    return array;
  }

  return (
    <>
      <div className="content">
        <Card>
          <CardHeader>
            <CardTitle tag="h4" className="cardTitle">
              {titleName}
            </CardTitle>
          </CardHeader>
          <CardBody>
            {loading ? (
              <Spinner color="primary" />
            ) : (
              <>
                {/* <Table responsive /> */}
                <div style={{ display: "flex" }}>
                  <Col md="auto" className="d-flex align-items-center">
                    <p
                      className="category"
                      style={{ fontSize: "17px", display: "flex" }}
                    >
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={handleTotalCountClick}
                        onMouseEnter={(e) => (e.target.style.color = "#240170")}
                        onMouseLeave={(e) => (e.target.style.color = "#9A9A9A")}
                      >
                        전체 {count}건
                      </span>
                    </p>
                  </Col>
                </div>
                <Table>
                  <thead className="text-primary">
                    <tr style={{ textAlign: "center" }}>
                      <th>번호</th>
                      {/* <th>고객사</th> */}
                      <th>계약명</th>
                      <th>계약구분</th>
                      {/* <th>계약시작일</th> */}
                      <th>계약종료일</th>
                      <th style={{ verticalAlign: "center" }}>계약상태</th>
                      {/* <th>{showStateCheck}</th> */}
                    </tr>
                  </thead>
                  <tbody>{arrayList()}</tbody>
                </Table>
              </>
            )}
          </CardBody>
          {!loading && (
            <Paging
              count={count}
              page={page}
              perPage={PER_PAGE}
              handlePageChange={handlePageChange}
            />
          )}
        </Card>
      </div>
    </>
  );
}

export default ContractRD;
