// 담당자 상세페이지

import { API } from "../../ApiConfig";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import instanceAxios from "components/reissue/InstanceAxios";
import ErrAlert from "variables/ErrAlert";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import Select from "react-select";
import DeleteButton from "variables/DeleteButton";
import Swal from "sweetalert2";
import ToastAlert from "variables/ToastAlert";

const titleName = "멤버";

/**
 * 멤버 데이터를 불러와 사용하기 편하도록 변환하는 함수
 */

function MemberModify() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true); // 로딩 상태 추가

  const [Data, setData] = useState([]);

  const [roles, setRoles] = useState("");
  const [state, setState] = useState("");

  // ===== 상태 분류 =====
  const stateDiv = [
    { value: "STANDBY", label: "대기" },
    { value: "APPROVE", label: "승인" },
    { value: "LEAVE", label: "탈퇴" },
  ];

  // ==== 권한 분류 =====
  const rolesDiv = [
    { value: "USER", label: "일반 사용자" },
    { value: "ADMIN", label: "관리자" },
  ];

  const fetchData = async () => {
    try {
      const response = await instanceAxios.get(`${API.MEMBERLIST}/${id}`);
      const data = response.data?.data || [];

      setData(data);

      //초기 상태 설정
      const initialState = stateDiv.find(
        (option) => option.value === data.state
      );
      if (initialState) {
        setState(initialState);
      }

      //초기 권한 설정
      const initialRole = rolesDiv.find((option) => option.value === data.role);
      if (initialRole) {
        setRoles(initialRole);
      }
      return data;
    } catch (error) {
      ErrAlert({ err: "데이터 불러오기 실패" });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    // 페이지 로딩 시 프로젝트 정보 가져오기
    fetchData();
  }, [id]); // ID가 변경될 때마다 useEffect 실행

  const handleSubmit = async (e) => {
    e.preventDefault();
    Swal.fire({
      icon: "question",
      title: "수정",
      text: `수정 하시겠습니까?`,
      showCancelButton: true,
      confirmButtonText: "저장",
      cancelButtonText: "취소",
    }).then(async (res) => {
      if (res.isConfirmed) {
        const formData = new FormData();

        formData.append("state", state.value);
        formData.append("roles", roles.value);

        try {
          const response = await instanceAxios.patch(
            `${API.MEMBERLIST}/${id}`,
            formData,
            {
              headers: {
                "Content-Type": "application/form-data;charset=UTF-8",
              },
            }
          );
          ToastAlert({ icon: "success", title: "수정이 완료되었습니다." });
          navigate(`${API.MEMBERDETAIL}/${id}`);
        } catch (error) {
          ErrAlert({ err: "필수 항목을 확인해주세요" });
        }
      }
    });
  };
  const navigate = useNavigate();
  return (
    <>
      <div className="content">
        <Form encType="multipart/form-data" onSubmit={handleSubmit}>
          <Row>
            <Col md="12">
              <div className="detail-wrap">
                <Card className="card-user">
                  <CardHeader>
                    <h5 className="title">{titleName} 권한/상태 수정</h5>
                  </CardHeader>
                  <CardBody>
                    {loading ? (
                      <Spinner color="primary" />
                    ) : (
                      <>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label>이름</Label>
                              <Input
                                name="name"
                                value={Data.name || "-"}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>이메일</Label>
                              <Input value={Data.email || "-"} disabled />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label>권한</Label>
                              <Select
                                name="roles"
                                options={rolesDiv}
                                value={roles}
                                onChange={setRoles}
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>상태</Label>
                              <Select
                                name="state"
                                options={stateDiv}
                                value={state}
                                onChange={setState}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <DeleteButton
                          to={`${API.MEMBERDETAIL}/${id}`}
                          text1="수정을 취소하시겠습니까?"
                        />
                      </>
                    )}
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}

export default MemberModify;
