/*

=========================================================
* Paper Dashboard React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/**
 * 지원 상세보기 페이지
 * @author 이준복
 * @returns 지원, 이어쓰기 상세보기 페이지 리턴
 */
import instanceAxios from "components/reissue/InstanceAxios";
import { API } from "../../ApiConfig";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Row,
  Col,
  Spinner,
  Input,
} from "reactstrap";
import DetailButton from "variables/DetailButton";
import FileDownView from "./FileDownView";
import FollwingFileDownView from "./FollowingFileDownView";
import FolliwngDetailButton from "variables/FollowingDetailButton";
import ErrAlert from "variables/ErrAlert";
import CkEditor from "variables/CkEditor";

// names랑 contents 변경하세요
const List = [
  { id: 1, names: "지원명" },
  { id: 2, names: "고객사명" },
  { id: 3, names: "작업 상태" },
  { id: 4, names: "프로젝트" },
  { id: 5, names: "지원 항목" }, // 지원 내용 요약
  { id: 6, names: "지원(예정)일" },
  { id: 7, names: "요청자" },
  { id: 8, names: "작업요청일" },
  { id: 9, names: "작업자" },
  { id: 10, names: "업무 구분" },
  { id: 11, names: "종료일" },
  { id: 12, names: "첨부파일" },
];

/**
 * 지원 상세보기 페이지
 * @author 이준복
 * @returns 지원, 이어쓰기 상세보기 페이지 리턴
 */
function SupportDetail() {
  const [totalSummary, setTotalSummary] = useState();       // 전체 요약
  const [supportDetail, setSupportDetail] = useState([]);   // 지원 상세보기
  const [listView, setListView] = useState([]);             // 이어쓰기 리스트 뷰
  const [followings, setFollowings] = useState([]);         // 이어쓰기
  const [idState, setIdState] = useState();

  const [loading, setLoading] = useState(true);

  const [open, setOpen] = useState(false);    // 닫기/펼치기 여부
  const [openHeader, setOpenHeader] = useState("펼치기📖");

  const nullCheck = (data) => {
    return data != null ? data : "미등록";
  };

  const nullCheckDetail = (data, division) => {
    return data != null ? data : `${division} 미등록`;
  };

  const { id } = useParams();
  //useEffect 에서 상세정보 가져올 메소드
  const getSupportDetail = useCallback(async () => {
    try {
      await instanceAxios.get(`${API.SUPPORTLIST}/${id}`).then((response) => {
        setSupportDetail(response.data.data);
        setFollowings(response.data.data.followingResponseDtoList);
        setIdState(id);
      });
    } catch (e) {
      ErrAlert({ err: "데이터 불러오기 실패" });
    } finally {
      setLoading(false);
    }
  });

  // 지원 업무 구분
  const repairOption = [
    { key: 1, value: "INQUERY_WATAB", text: "와탭 문의" },
    { key: 2, value: "INQUERY_CABLE", text: "유선" },
    { key: 3, value: "VISIT", text: "방문" },
    { key: 4, value: "MAIL", text: "메일" },
    { key: 5, value: "VPN", text: "VPN" },
    { key: 6, value: "RESIDE", text: "상주" },
    { key: 7, value: "INSTALL", text: "설치"},
    { key: 8, value: "INSPECTION", text: "검수"},
  ];

  // 작업 상태
  const stateOption = [
    { key: 1, value: "WORK_EXPECTED", text: "작업 예정" },
    { key: 2, value: "WORKING", text: "작업 중" },
    { key: 3, value: "WORK_COMPLETE", text: "작업 완료" },
  ];

  // 지원 업무 구분 옵션 출력
  const printRepairOption = (target) => {
    var tempOptionView = "미등록";
    // 옵션 비교하여 일치하는 텍스트 찾기
    repairOption.map((option) => {
      if (target == option.value) {
        tempOptionView = option.text;
      }
    });
    return tempOptionView;
  };

  // 작업 상태 옵션 출력
  const printStateOptionView = (target) => {
    var tempOptionView = "미등록";
    // 옵션 비교하여 일치하는 텍스트 찾기
    stateOption.map((option) => {
      if (target === option.value) {
        tempOptionView = option.text;
      }
    });
    return tempOptionView;
  };

  // 작업자들을 리스트로 변환하는 함수
  const workersToList = (supportDetail) => {
    if (!supportDetail.workers || supportDetail.workers.length === 0) {
      return content("미등록");     // 아무것도 없을시 미등록 반환
    }

    var tempWorkerNameList = [];
    // supportDetail.workers 배열이 존재하고 비어 있지 않은 경우에만 처리
    if (supportDetail.workers && supportDetail.workers.length > 0) {
      // 각 작업자 이름에 대해 반복
      supportDetail.workers.forEach((worker, index) => {
        // 현재 작업자 이름을 배열에 추가
        tempWorkerNameList.push(worker);

        // 만약 현재 작업자가 마지막이 아니면 쉼표와 공백을 추가
        if (index < supportDetail.workers.length - 1) {
          tempWorkerNameList.push(", ");
        }
      });
    }

    // 배열에 있는 작업자 이름들을 하나의 문자열로 합침
    return content(tempWorkerNameList.join(""));
  };

  // 이어쓰기 작업자 리스트 변환 함수
  const FollowingWorkersToList = (following) => {
    if (!following.workers || following.workers.length === 0) {
      return content("미등록");     // 아무것도 없을시 미등록 반환
    }
    var tempWorkerNameList = [];

    // 배열이 존재하고 비어 있지 않은 경우에만 처리
    if (following.workers && following.workers.length > 0) {
      following.workers.forEach((worker, index) => {
        tempWorkerNameList.push(worker);

        // 만약 현재 작업자가 마지막이 아니면 쉼표와 공백을 추가
        if (index < following.workers.length - 1) {
          tempWorkerNameList.push(", ");
        }
      });
    }
    return content(tempWorkerNameList.join(""));
  };

  // 리스트 뷰를 만드는 함수
  const makeListView = useCallback(() => {
    var tempListViwe = [];
    tempListViwe.push(supportDetail);
    setListView(tempListViwe);
  });

  // useEffect id로 지원 상세보기 데이터 가져올 영역
  useEffect(() => {
    getSupportDetail();
  }, [idState]);

  // useEffect를 사용하여 리스트 뷰를 만들고 갱신하는 부분
  useEffect(() => {
    makeListView();
    // alert({open});
  }, [supportDetail, open]);

  // 게시물 요약
  // 지원 상세보기 페이지 랜더링될 때 전체 지원 요약 정리해서 detail에 담는다.
  // useEffect(() => {
  //   var tempSummary = "";
  //   // 메인 지원 정보
  //   listView.forEach((element, index) => {
  //     if (!element.deleteTAndF) {
  //       tempSummary +=
  //         `⚫  ${nullCheckDetail(element.name, "지원명")} ( ${nullCheckDetail(
  //           printStateOptionView(element.supportState),
  //           "작업상태"
  //         )} ) | ${nullCheckDetail(element.start, "시작일")} ~ ${nullCheckDetail(
  //           element.end,
  //           "종료일"
  //         )}\n${element.content == null || element.content.trim() == ""
  //           ? "내용 없음"
  //           : element.content
  //         }${index !== listView.length - 1 ? '\n\n' : ''}`;
  //     }
  //   });

  //   // 이어쓰기 정보
  //   followings.forEach((element, index) => {
  //     if (!element.deleteTAndF) {
  //       tempSummary +=
  //         `\n\n🔵 ${nullCheckDetail(element.name, "지원명")} ( ${nullCheckDetail(
  //           printStateOptionView(element.state),
  //           "작업상태"
  //         )} ) | ${nullCheckDetail(element.start, "시작일")} ~ ${nullCheckDetail(
  //           element.end,
  //           "종료일"
  //         )}\n${element.content == null || element.content.trim() == ""
  //           ? "내용 없음"
  //           : element.content
  //         }${index !== followings.length - 1 ? '' : ''}`;
  //     }
  //   });
  //   setTotalSummary(tempSummary);
  // }, [listView]);

  useEffect(() => {
    var tempSummary = "";
    listView.forEach((element) => {
      tempSummary +=
        `⚫  ${nullCheckDetail(element.name, "지원명")} ( ${nullCheckDetail(
          printStateOptionView(element.supportState),
          "작업상태"
        )} ) | ${nullCheckDetail(element.start, "시작일")} ~ ${nullCheckDetail(
          element.end,
          "종료일"
        )}` +
        `\n${element.content == null || element.content.trim() == ""
          ? ""
          : element.content
        }`;
    });

    followings.forEach((element) => {
      tempSummary +=
        `\n🔵 ${nullCheckDetail(element.name, "지원명")} ( ${nullCheckDetail(
          printStateOptionView(element.state),
          "작업상태"
        )} ) | ${nullCheckDetail(element.start, "시작일")} ~ ${nullCheckDetail(
          element.end,
          "종료일"
        )}` +
        `\n${element.content == null || element.content.trim() == ""
          ? ""
          : element.content
        }`;
    });
    setTotalSummary(tempSummary); 
  }, [listView]);

  // 상단에 게시글 요약 보여주는 부분
  const summary =
    (
      <Form>
        <Row>
          <Col md="12">
            <Card className="card-user">
              <CardHeader>
                <button
                  style={{
                    color: "steelblue",
                    backgroundColor: "white",
                    border: "none",
                    marginTop: "0.5em",
                    marginLeft: "0.2em",
                  }}
                  onClick={() => navigate(`/${API.SUPPORT}`)}
                >
                  ◀ | 목록으로
                </button>
                <br></br>
                <br></br>
                <h5 className="title" style={{ marginLeft: "2em" }}>
                  {loading
                    ? `불러오는 중`
                    : `${nullCheckDetail(supportDetail.name, "지원명")}`}
                </h5>
                <hr />
              </CardHeader>
              <CardBody>
                <Row>
                  <Col className="px-1" md="1"></Col>
                  <Col
                    className="px-1"
                    md="3"
                    style={{
                      border: "none",
                      textAlign: "center",
                      paddingTop: "7px",
                    }}
                  >
                    <h6>
                      {loading
                        ? `불러오는 중`
                        : `${nullCheckDetail(
                          supportDetail.customer,
                          "고객사"
                        )}`}
                    </h6>
                  </Col>
                  <Col
                    className="px-1"
                    md="2"
                    style={{
                      border: "none",
                      textAlign: "center",
                      paddingTop: "7px",
                    }}
                  >
                    <h6>
                      {loading
                        ? `불러오는 중`
                        : `${nullCheckDetail(
                          printStateOptionView(supportDetail.supportState),
                          "작업상태"
                        )}`}
                    </h6>
                  </Col>
                  <Col
                    className="px-1"
                    md="1"
                    style={{ paddingTop: "7px" }}
                  ></Col>
                  <Col className="pr-1" md="2" style={{ paddingTop: "7px" }}>
                    <h6>프로젝트 |</h6>
                  </Col>
                  <Col className="pl-1" md="3" style={{ paddingTop: "7px" }}>
                    {loading
                      ? `불러오는 중`
                      : `${nullCheckDetail(supportDetail.project, "프로젝트")}`}
                  </Col>
                </Row>
                <Row>
                  <Col className="px-1" md="12">
                    <br></br>
                    <hr></hr>
                  </Col>
                </Row>
                <Row>
                  <Col className="px-1" md="1"></Col>
                  <Col className="px-1" md="11">
                    <p>
                      <h5>지원 내용 요약</h5>
                    </p>

                  </Col>
                  <Col className="px-1" md="1"></Col>
                  <Col className="px-1" md="10">
                    {/* <pre>
                      <div
                        contentEditable="false"
                        style={{
                          whiteSpace: 'pre-wrap',
                          fontSize: '15px',
                          border: '1px solid #01579B',
                          padding: '1.2em',
                          borderRadius: '5px'
                        }}
                      >
                        {totalSummary ? totalSummary : "내용 없음"}
                      </div>
                    </pre> */}
                    <CkEditor data={totalSummary || ""} disabled={true} onChange={setTotalSummary} />
                  </Col>
                  <Col className="px-1" md="1"></Col>
                </Row>
                <Row>
                  <Col md="1"></Col>
                  {supportDetail.deleteTAndF ? null :
                    <Col md="10" className="pr-1" style={{ textAlign: "right" }}>
                      <Button
                        onClick={() =>
                          navigate(`${API.FOLLOWINGREGIST}?id=${idState}`)}
                        type="button"
                        color="primary"
                        outline
                      >
                        이어쓰기
                      </Button>
                    </Col>}
                  <Col md="1"></Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Form>
    );

  const title = List.map(
    (
      data // 제목 폼
    ) => (
      <FormGroup>
        <div>
          <blockquote>
            <p
              className="blockquote"
              style={{
                padding: "5px",
                borderColor: "white",
                fontWeight: "bold",
              }}
            >
              {data.names}
            </p>
          </blockquote>
        </div>
      </FormGroup>
    )
  );

  const content = (parameter) => {
    // 내용 폼
    return (
      <FormGroup>
        <div>
          <blockquote style={{ textAlign: "center" }}>
            <p
              className="blockquote"
              style={{
                padding: "5px",
                borderColor: "lightgray",
                borderRadius: "0.7rem",
              }}
              id="summaryViewTag"
            >
              {Array.isArray(parameter)
                ? parameter.length != 0
                  ? parameter
                  : "미등록"
                : parameter != null
                  ? parameter
                  : "미등록"}
            </p>
          </blockquote>
        </div>
      </FormGroup>
    );
  };

  const navigate = useNavigate();

  const foldedSupport = (
    <Col
      md="12"
      style={{ borderBottom: "0.5px solid lightgrey", padding: "5px" }}
    >
      {/* 지원 상세 내용을 접고 펼치는 details 요소 */}
      <details open={open}>
        {/* 지원 항목 제목과 상태, 시작일-종료일을 표시 */}
        <summary style={{ marginLeft: "2em" }} className={supportDetail.deleteTAndF ? "deleted-text" : ""}>
          <b style={{ marginLeft: "0.2em" }}>
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              className="bi bi-circle-fill"
              fill="#153099"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginLeft: "0.2em" }}
            >
              <circle cx="8" cy="8" r="8" />
            </svg>
            <label
              style={{
                width: "37%",
                fontSize: "15px",
                color: "#544d82",
                marginLeft: "1em",
              }}
              className={supportDetail.deleteTAndF ? "deleted-text" : ""}
            >
              {" "}
              {/* 지원명 또는 "미등록" */}
              {nullCheck(supportDetail.name)}
            </label>
            <label
              style={{
                width: "7%",
                fontSize: "15px",
                color: "#8a86a3",
                marginLeft: "8em",
                marginRight: "8em",
                textAlign: "center",
              }}
              className={supportDetail.deleteTAndF ? "deleted-text" : ""}
            >
              {/* 작업 상태 표시 */}
              {printStateOptionView(supportDetail.supportState)}
            </label>
            <label style={{ width: "30%", fontSize: "15px", color: "#544d82" }} className={supportDetail.deleteTAndF ? "deleted-text" : ""}>
              📆 <span style={{ marginRight: "0.5em" }}> </span>
              {/* 시작일 ~ 종료일 표시 */}
              {nullCheck(supportDetail.start)} ~ {nullCheck(supportDetail.end)}
            </label>
          </b>
        </summary>
        <hr />
        {/* 지원 상세 내용 표시 */}
        <Form>
          <Row>
            <Col md="12">
              <Card className="card-user">
                <CardHeader>
                  <h5 className="title">
                    {/* 지원명과 예정일 표시 */}
                    {supportDetail.name != null
                      ? supportDetail.name
                      : "지원명 미등록"}
                    (
                    {supportDetail.start != null
                      ? supportDetail.start
                      : "지원(예정일) 미등록"}
                    )
                  </h5>
                  <p className="category" style={{ fontSize: "17px" }}>
                    {/*detailName*/}
                  </p>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col className="pl-1" md="12"></Col>
                  </Row>
                  <Row>
                    <Col className="pl-1" md="4">
                      {content(supportDetail.customer)} {/* 고객사 내용*/}
                    </Col>
                    <Col className="pl-1" md="2">
                      {content(
                        printStateOptionView(supportDetail.supportState)
                      )}{" "}
                      {/* 작업상태 내용*/}
                    </Col>
                    <Col className="pl-1" md="2">
                      {title[3]} {/* 프로젝트 제목 */}
                    </Col>
                    <Col className="pl-1" md="4">
                      {content(supportDetail.project)} {/* 프로젝트  내용 */}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pl-1" md="2">
                      {title[6]} {/* 요청자 제목 */}
                    </Col>
                    <Col className="pl-1" md="4">
                      {content(
                        supportDetail.caller ? supportDetail.caller : "미등록"
                      )}{" "}
                      {/* 요청자 내용 */}
                    </Col>
                    <Col className="pl-1" md="2">
                      {title[8]} {/* 작업담당자 제목 */}
                    </Col>
                    <Col className="pl-1" md="4">
                      {/* {/*content(workersToList(supportDetail.workerList))}{" "} */}
                      {/* 작업담당자 내용  */}
                      {workersToList(supportDetail) ? workersToList(supportDetail) : "미등록"}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pl-1" md="2">
                      {title[7]} {/* 작업 요청일 제목 */}
                    </Col>
                    <Col className="pl-1" md="4">
                      {content(supportDetail.ask)} {/* 작업 요청일 제목 */}
                    </Col>
                    <Col className="pl-1" md="2">
                      {title[9]} {/* 업무구분 제목 */}
                    </Col>
                    <Col className="pl-1" md="4">
                      {content(printRepairOption(supportDetail.division))}{" "}
                      {/* 업무구분 내용 */}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pl-1" md="2">
                      {title[5]} {/* 지원 예정일 제목 */}
                    </Col>
                    <Col className="pl-1" md="4">
                      {content(supportDetail.start)} {/* 지원 예정일 내용 */}
                    </Col>
                    <Col className="pl-1" md="2">
                      {title[10]} {/* 종료일 제목 */}
                    </Col>
                    <Col className="pl-1" md="4">
                      {content(supportDetail.end)} {/* 종료일 내용 */}
                    </Col>
                    <Col className="pl-1" md="3">
                      {/* 첨부파일 내용 */}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pl-1" md="12">
                      {title[11]} {/* 첨부파일 제목 */}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pl-1" md="12">
                      <FormGroup>
                        <div>
                          <FileDownView domain="SUPPORT" domainId={id} />
                        </div>
                      </FormGroup>{" "}
                    </Col>
                  </Row>
                  <hr></hr>
                  <Row>
                    <Col md="12">
                      <h6>{title[4]}</h6> {/* 지원 제목 */}
                      {/* <Input
                        type="textarea"
                        className="detail-input textArea"
                        defaultValue={supportDetail.content}
                      /> */}
                      <CkEditor data={supportDetail.content || ""} disabled={true} />
                    </Col>
                  </Row>
                  <DetailButton domainId={id} domain="SUPPORT" deleteTf={supportDetail.deleteTAndF} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </details>
    </Col>
  );

  // 이어쓰기 상세보기 내용
  const foldedFollowing = followings?.map((following) => {
    return (
      <Col
        md="12"
        style={{ borderBottom: "0.5px solid lightgrey", padding: "5px" }}
      >
        <details open={open}> {/*이어쓰기 리스트*/}
          <summary style={{ marginLeft: "2em" }} className={following.deleteTAndF ? "deleted-text" : ""}>
            <b style={{ marginLeft: "0.2em" }}>
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                className="bi bi-circle-fill"
                fill="#b4c2ed"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginLeft: "0.2em" }}
              >
                <circle cx="8" cy="8" r="8" />
              </svg>
              <label
                style={{
                  width: "37%",
                  fontSize: "15px",
                  color: following.deleteTAndF ? "red" : "#544d82",
                  marginLeft: "1em",
                }}
              >
                {" "}
                {/* 이어쓰기명 또는 "미등록" */}
                {nullCheck(following.name)}
              </label>
              <label
                style={{
                  width: "7%",
                  fontSize: "15px",
                  color: following.deleteTAndF ? "red" : "#8a86a3",
                  marginLeft: "8em",
                  marginRight: "8em",
                  textAlign: "center",
                }}
              >
                {" "}
                {/* 작업 상태 표시 */}
                {nullCheck(printStateOptionView(following.state))}
              </label>
              <label
                style={{ width: "30%", fontSize: "15px", color: following.deleteTAndF ? "red" : "#544d82" }}
              >
                📆 <span style={{ marginRight: "0.5em" }}> </span>
                {/* 시작일 ~ 종료일 표시 */}
                {nullCheck(following.start)} ~ {nullCheck(following.end)}
              </label>
            </b>
          </summary>
          <hr />
          {/*이어쓰기 항목 상세*/}
          <Form>
            <Row>
              <Col md="12">
                <Card className="card-user">
                  <CardHeader>
                    <h5 className={"title"}>
                      {nullCheckDetail(following.name, "이어쓰기명")} (
                      {nullCheckDetail(following.start, "지원예정일")})
                    </h5>
                    <p className="category" style={{ fontSize: "17px" }}>
                      {/*detailName*/}
                    </p>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col className="pl-1" md="12"></Col>
                    </Row>
                    <Row>
                      <Col className="pl-1" md="4">
                        {content(following.customer)} {/* 고객사 내용*/}
                      </Col>
                      <Col className="pl-1" md="2">
                        {content(printStateOptionView(following.state))}{" "}
                        {/* 작업상태 내용*/}
                      </Col>
                      <Col className="pl-1" md="2">
                        {title[3]} {/* 프로젝트 제목 */}
                      </Col>
                      <Col className="pl-1" md="4">
                        {content(following.project)} {/* 프로젝트  내용 */}
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pl-1" md="2">
                        {title[6]} {/* 요청자 제목 */}
                      </Col>
                      <Col className="pl-1" md="4">
                        {content(
                          following.caller ? following.caller : "미등록"
                        )}{" "}
                        {/* 요청자 내용 */}
                      </Col>
                      <Col className="pl-1" md="2">
                        {title[8]} {/* 작업담당자 제목 */}
                      </Col>
                      <Col className="pl-1" md="4">

                        {FollowingWorkersToList(following) ? FollowingWorkersToList(following) : "미등록"}{" "}
                        {/* 작업담당자 내용  */}
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pl-1" md="2">
                        {title[7]} {/* 작업 요청일 제목 */}
                      </Col>
                      <Col className="pl-1" md="4">
                        {content(following.ask)} {/* 작업 요청일 제목 */}
                      </Col>
                      <Col className="pl-1" md="2">
                        {title[9]} {/* 업무구분 제목 */}
                      </Col>
                      <Col className="pl-1" md="4">
                        {content(printRepairOption(following.division))}{" "}
                        {/* 업무구분 내용 */}
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pl-1" md="2">
                        {title[5]} {/* 지원 예정일 제목 */}
                      </Col>
                      <Col className="pl-1" md="4">
                        {content(following.start)} {/* 지원 예정일 내용 */}
                      </Col>
                      <Col className="pl-1" md="2">
                        {title[10]} {/* 종료일 제목 */}
                      </Col>
                      <Col className="pl-1" md="4">
                        {content(following.end)} {/* 종료일 내용 */}
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pl-1" md="12">
                        {title[11]} {/* 첨부파일 제목 */}
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pl-1" md="12">
                        <FormGroup>
                          <div>
                            <FollwingFileDownView
                              supportId={id}
                              domain="FOLLOWING"
                              domainId={following.id}
                            />
                          </div>
                        </FormGroup>{" "}
                      </Col>
                    </Row>
                    <hr></hr>
                    <Row>
                      <Col md="12">
                        <h6>{title[4]}</h6> {/* 지원 제목 */}
                        {/* <Input
                          type="textarea"
                          className="detail-input textArea"
                          defaultValue={following.content}
                        /> */}
                        <CkEditor data={following.content || ""} disabled={true} />
                      </Col>
                    </Row>
                    <FolliwngDetailButton
                      supportId={id}
                      domainId={following.id}
                      domain="FOLLOWING"
                      deleteTf={following.deleteTAndF}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        </details>
      </Col>
    );
  });

  return (
    <div className="content">
      {/* <div className="detail-wrap">  */}
      <div>{summary}</div>
      <Form>
        <Row>
          <Col md="12">
            <Card className="card-user">
              <CardHeader
                style={{
                  background: "#270571",
                  paddingTop: "0.5em",
                  paddingBottom: "0.5em",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "1em 0",
                  }}
                >
                  <div
                    style={{
                      marginTop: "auto",
                      marginBottom: "auto",
                      marginLeft: "1em",
                      fontSize: "1.5em",
                      color: "#fff",
                    }}
                  >
                    상세보기
                  </div>
                  <div style={{ textAlign: "right" }}>
                    {/* 펼치기/접기 버튼 */}
                    <Button
                      class="fold-btn"
                      style={{
                        width: "11em",
                        height: "3em",
                        marginTop: "auto",
                        marginBottom: "auto",
                        marginRight: "2em",
                        border: "1px solid lightgray",
                        borderRadius: "0.5em",
                        backgroundColor: "white",
                        color: "black",
                      }}
                      type="button"
                      onClick={() => {
                        setOpen(!open);
                        setOpenHeader(
                          openHeader === "펼치기📖" ? "접기📘" : "펼치기📖"
                        );
                      }}
                    >
                      {openHeader}
                    </Button>
                  </div>
                </div>
              </CardHeader>
              {/*<CardBody style={{maxHeight:"30em", overflowY:"auto"}}>*/}
              <CardBody>
                <Row>
                  {/* 지원 항목 표시 */}
                  {loading ? <Spinner color="primary" /> : foldedSupport}
                  {/* 이어쓰기 항목 표시 */}
                  {loading ? `불러오는 중` : foldedFollowing}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Form>
      {/* </div> */}
    </div>
  );
}
export default SupportDetail;
