import instanceAxios from "components/reissue/InstanceAxios";
import React from "react";
import { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  Nav,
  NavItem,
} from "reactstrap";
import "assets/css/Noti.css";
import MaterialIcon from "react-google-material-icons";
import ErrAlert from "variables/ErrAlert";

function Notification(props) {
  // 라이선스 계약 알림 상태 설정
  const [licenseNotice, setLicenseNotice] = useState([]);
  const [contractNotice, setcontractNotice] = useState([]);
  const [noticeClass, setNoticeClass] = useState("ALL");
  const [period, setPeriod] = useState("seven");
  const [reload, setReload] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [notiCount, setNotiCount] = useState(0);

  // 모달 토글 함수
  const toggleModal = () => {
    setModalState(!modalState);
    setNotiCount(0);
  };

  var i = 1;
  var j = 1;

  // 알림 정보 가져오는 함수
  const geNotiInfo = async () => {
    try {
      const [response] = await Promise.all([
        instanceAxios.get(`/api/notification?scope=` + period),
      ]);
      setLicenseNotice(response.data.data.licenseListForNotificationDtoList);
      setcontractNotice(response.data.data.contractListForNotificationDtoList);

      // 전체 알림 갯수 설정
      const totalCount =
        response.data.data.licenseListForNotificationDtoList.length +
        response.data.data.contractListForNotificationDtoList.length;
      setNotiCount(totalCount); // 알림 갯수 추후 수정 일단 테스트용 -> 알림 종 모양 눌렀을 때 0으로 세팅
    } catch (e) {
      ErrAlert({ err: "데이터 불러오기 실패" });
    }
  };

  useEffect(() => {
    geNotiInfo();
  }, [period, reload]);

  //== 라이선스 테이블 만들기 ==

  // 라이선스 테이블 헤드 설정
  const makeLicenseViewHead = (
    <thead className="ntThead">
      <tr className="nt-category-title">
        <td colSpan={3}>라이선스</td>
      </tr>
      <tr style={{ fontSize: "1.1em" }}>
        <td>No</td>
        <td>라이선스명</td>
        <td>만료일</td>
      </tr>
    </thead>
  );

  // 라이선스 테이블 바디 생성
  const makeLicenseViewBody = licenseNotice?.map((license) => {
    return (
      <tr>
        <td>{j++}</td>
        <td>{license.license}</td>
        <td style={{ fontSize: "1.1em" }}>{license.expiredDate}</td>
      </tr>
    );
  });

  // 라이선스 테이블 생성
  const licenseTable = (
    <Table className="ntTable">
      {makeLicenseViewHead}
      <tbody style={{ overflowY: "scroll" }}>{makeLicenseViewBody}</tbody>
    </Table>
  );

  //== 계약 테이블 만들기 ==
  // 계약 테이블 헤드 생성
  const makeContractViewHead = (
    <thead className="ntThead">
      <tr className="nt-category-title">
        <td colSpan={4}>📝계약📝</td>
      </tr>
      <tr style={{ fontSize: "1.1em" }}>
        <td>No</td>
        <td>계약명</td>
        <td>종류</td>
        <td>만료일</td>
      </tr>
    </thead>
  );

  // 계약 테이블 바디 생성
  const makeContractViewBody = contractNotice?.map((contract) => {
    if (noticeClass === "CONTRACTALL" || noticeClass === "ALL") {
      return (
        <tr>
          {/* <td>{contract.id}</td> */}
          <td>{i++}</td>
          <td>{contract.contract}</td>
          <td>{contract.contractClass === "SET" ? "일반" : "유지보수"}</td>
          <td style={{ fontSize: "1.1em" }}>{contract.expiredDate}</td>
        </tr>
      );
    } else if (
      noticeClass === "CONTRACT1" &&
      contract.contractClass === "SET"
    ) {
      return (
        <tr>
          {/* <td>{contract.id}</td> */}
          <td>{i++}</td>
          <td>{contract.contract}</td>
          <td>{contract.contractClass === "SET" ? "일반" : "유지보수"}</td>
          <td>{contract.expiredDate}</td>
        </tr>
      );
    } else if (
      noticeClass === "CONTRACT2" &&
      contract.contractClass === "REPAIR"
    ) {
      return (
        <tr>
          {/* <td>{contract.id}</td> */}
          <td>{i++}</td>
          <td>{contract.contract}</td>
          <td>{contract.contractClass === "SET" ? "일반" : "유지보수"}</td>
          <td>{contract.expiredDate}</td>
        </tr>
      );
    }
  });

  //계약 테이블 생성
  const contractTable = (
    <Table className="ntTable">
      {makeContractViewHead}
      <tbody style={{ overflowY: "scroll" }}>{makeContractViewBody}</tbody>
    </Table>
  );

  const showTable = () => {
    if (noticeClass === "ALL") {
      return (
        <div className="flex1">
          <div className="flex1-child">{licenseTable}</div>
          <div className="flex1-child">{contractTable}</div>
        </div>
      );
    }
    if (noticeClass === "LICENSE") {
      return <div className="flex2">{licenseTable}</div>;
    } else {
      return <div className="flex2">{contractTable}</div>;
    }
  };

  // 옵션 바
  const optionBar = (
    <Table className="optionBar">
      <tr>
        <td className="ntTitle">
          알림{" "}
          <span
            style={{
              display: "fixed",
              cursor: "pointer",
              fontSize: "x-large",
              fontWeight: "bolder",
              color: "green",
            }}
            onClick={() => {
              setReload(!reload);
            }}
          >
            ⟳
          </span>
        </td>
      </tr>
      <div style={{ display: "flex", margin: "auto" }}>
        <div style={{ borderRight: "0.1em solid lightgray" }}>
          <tr
            onChange={(event) => {
              setPeriod(event.target.value);
            }}
          >
            <td>
              7일 이내
              <input
                id="periodSeven"
                type="radio"
                name="period"
                value="seven"
                defaultChecked
              ></input>
            </td>
            <td>
              30일 이내
              <input
                id="periodMonth"
                type="radio"
                name="period"
                value="month"
              ></input>
            </td>
          </tr>
        </div>
        <div style={{ borderLeft: "0.1em solid lightgray" }}>
          <tr
            onChange={(event) => {
              setNoticeClass(event.target.value);
            }}
          >
            <td>
              전체 보기
              <input
                type="radio"
                name="noticeClass"
                value="ALL"
                defaultChecked
              ></input>
            </td>
            <td>
              라이선스{" "}
              <input type="radio" name="noticeClass" value="LICENSE"></input>
            </td>
            <td>
              계약(전체){" "}
              <input
                type="radio"
                name="noticeClass"
                value="CONTRACTALL"
              ></input>
            </td>
            <td>
              계약(일반){" "}
              <input type="radio" name="noticeClass" value="CONTRACT1"></input>
            </td>
            <td>
              계약(유지보수){" "}
              <input type="radio" name="noticeClass" value="CONTRACT2"></input>
            </td>
          </tr>
        </div>
      </div>
    </Table>
  );

  return (
    <Nav navbar>
      <NavItem>
        {/* <span onClick={toggleModal} style={{ fontSize: "x-large", cursor: "pointer" }}>📣</span> */}
        {/* <i className="nc-icon nc-bell-55" onClick={toggleModal} /> */}
        <span
          onClick={toggleModal}
          style={{
            cursor: "pointer",
            marginRight: "-0.9em",
            position: "relative",
          }}
          className="nav-link btn-rotate text-primary"
        >
          <MaterialIcon icon="notifications" size={30} />
          {notiCount > 0 && (
            <span className="notification-count">{notiCount}</span>
          )}
        </span>
      </NavItem>
      <Modal isOpen={modalState} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Notification</ModalHeader>
        {optionBar}
        <ModalBody style={{ overflowY: "auto" }}>{showTable()}</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            닫기
          </Button>
        </ModalFooter>
      </Modal>
    </Nav>
  );
}

export default Notification;
