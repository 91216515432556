// 우측 톱니바퀴(배경 색상 변경)

/*!

=========================================================
* Paper Dashboard React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import instanceAxios from "components/reissue/InstanceAxios";
import React, { useEffect, useState } from "react";

import { Button, Table, Input, Form } from "reactstrap";
import MaterialIcon from 'react-google-material-icons';
import ToastAlert from "variables/ToastAlert";
import ErrAlert from "variables/ErrAlert";


/**
 * TODOList - 우측 사이드 고정
 * @returns 
 */
function FixedPlugin() {
  // 할 일 목록을 저장하는 state
  const [todoList, setTodoList] = useState([]);

  // 입력된 실행 날짜와 내용을 저장하는 state
  const [exeData, setExeDate] = useState("");
  const [content, setContent] = useState("");
  const [renderTrigger, setRenderTrigger] = useState(true);

  // 페이지 로딩 시 할 일 목록을 불러오는 useEffect
  useEffect(() => {
    try {
      instanceAxios.get("/api/todo").then((response) => {
        setTodoList(response.data.data);
      });
    } catch (e) {
      console.error(e);
    }
  }, [renderTrigger]);

  // const todoSize = todoList.length + 1; //  이준복 커스텀 - id 넣어줄거라 나중에 삭제 가능

  // 현재 날짜 정보를 가져오는 부분
  const date = new Date();
  const day = ["일", "월", "화", "수", "목", "금", "토"];

  // TodoList 목록의 타이틀 부분
  const todoTitle =
    (
      <li
        className="header-title"
        style={{ fontSize: "large", marginBottom: "0.3em", marginTop: "0.3em" }}
      >
        📑 {date.getFullYear()} - {date.getMonth() + 1} - {date.getDate()} ({" "}
        {day[date.getDay()]} ) 📌
      </li>
    );

  // TodoList 목록의 테이블 헤더 부분
  const tableHead = (
    <thead className="table-head">
      <tr style={{ textAlign: "center", fontWeight: "bold" }}>
        <td style={{ width: "10em", verticalAlign: "middle" }}>번호</td>
        <td style={{ width: "60em", verticalAlign: "middle" }}>내용</td>
        <td style={{ width: "20em", verticalAlign: "middle" }}>진행(예정)일</td>
        <td style={{ width: "6em", verticalAlign: "middle" }}>완료</td>
        <td style={{ width: "6em", verticalAlign: "middle" }}>삭제</td>
        <td style={{ width: "0em" }}></td>
      </tr>
    </thead>
  );

  // TodoList 목록의 테이블 내용 부분
  const tableBodyPart = todoList?.map((todo, index) => (
    <tr style={{ textAlign: "center" }}>
      <td style={{ width: "10em", verticalAlign: "middle" }}>{index + 1}</td>
      <td
        style={{
          width: "60em",
          verticalAlign: "middle",
          whiteSpace: "pre-line",
          textDecoration: todo.complete ? "line-through" : "none",
        }}
      >
        {todo.content}
      </td>
      <td style={{ 
        width: "16em", 
        verticalAlign: "middle",
        textDecoration: todo.complete ? "line-through" : "none" 
        }}>
        {todo.exeDate || "미정"}
      </td>
      <td style={{ width: "6em", verticalAlign: "middle" }}>
        <Button
          style={{
            backgroundColor: !todo.complete ? "gray" : "green",
            width: "4em", // 원하는 너비
            height: "2.5em", // 원하는 높이
            padding: "0", // 패딩 제거
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => {
            completeTodo(`${todo.id}`);
          }}
          // disabled={todo.complete} // 완료된 항목 버튼 비활성화
          className="complete-btn"
        >
          ✔
        </Button>
      </td>
      <td style={{ width: "6em", verticalAlign: "middle" }}>
        <Button
          style={{
            backgroundColor: "#eb6767",
            width: "4em", // 원하는 너비
            height: "2.5em", // 원하는 높이
            padding: "0", // 패딩 제거
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => {
            deleteTodo(`${todo.id}`);
          }}
          // disabled={!todo.complete} // 완료되지 않은 항목 버튼 비활성화
          className="delete-btn"
        >
          <MaterialIcon icon="delete" />
        </Button>
      </td>
    </tr>
  ));

  // TodoList 목록의 테이블 내용 부분을 감싸는 부분
  const tableBody = (
    <tbody className="table-body" style={{ overflowY: "scroll" }}>
      {tableBodyPart}
    </tbody>
  );

  // TodoList 추가 함수
  const addTodo = (event) => {
    event.preventDefault();

    var formData = new FormData();
    formData.append("content", content);
    formData.append("exeDate", exeData);

    try {
      instanceAxios.post("/api/todo", formData).then((response) => {

        document.getElementById("content").value = "";  // input 초기화
        document.getElementById("exeDate").value = "";  // input 초기화

        setRenderTrigger(!renderTrigger);
      });
    } catch (e) {
      alert(e);
      console.error(e);
    }
  };

  // TodoList 완료 함수
  const completeTodo = (id) => {
    try {
      instanceAxios.patch("/api/todo/" + `${id}`).then((response) => {
        ToastAlert({ icon: "success", title: "완료" });
        setRenderTrigger(!renderTrigger);
      });
    } catch (e) {
      const errorMessage = e.response.data.message;
      ErrAlert({ err: errorMessage || "투두리스트를 완료하는 중에 오류가 발생했습니다."  });
    }
  };

  // TodoList 삭제 함수
  const deleteTodo = (id) => {
    try {
      instanceAxios.delete("/api/todo/" + `${id}`).then((response) => {
        ToastAlert({ icon: "success", title: "삭제 완료" });
        setRenderTrigger(!renderTrigger);
      });
    } catch (e) {
      ErrAlert({ err: "투두리스트를 삭제하는 중에 오류가 발생했습니다." });
    }
  };

  // 입력 폼의 날짜 부분
  const inputFormDate = (
    <div>
      <Input
        id="exeDate"
        type="date"
        className="input-form-date"
        onChange={(event) => {
          setExeDate(event.target.value);
        }}
      ></Input>
    </div>
  );

  // 입력 폼의 텍스트 영역 부분
  const inputFormTextArea = (
    <textarea
      id="content"
      className="inputTodo"
      style={{ whiteSpace: "pre-wrap" }}
      onBlur={(event) => {
        setContent(event.target.value);
      }}
    ></textarea>
  );

  // 입력 폼의 버튼 부분
  const inputFormBtn = (
    <button type="submit" className="add-todolist-btn">
      <MaterialIcon icon="add" size="24" />
    </button>
  );

  const inputForm = (
    <Form
      onSubmit={(event) => {
        addTodo(event);
      }}
    >
      <div className="add-todolist-div">
        {inputFormDate}
        {inputFormTextArea}
        {inputFormBtn}
      </div>
    </Form>
  );

  // 토글 버튼 클릭 시 TodoList 메뉴를 보여주거나 숨기는 함수
  const [classes, setClasses] = React.useState("dropdown");
  const handleClick = () => {
    if (classes === "dropdown") {
      setClasses("dropdown show");
    } else {
      setClasses("dropdown");
    }
  };
  return (
    <div className="fixed-plugin">
      <div className={classes}>
        <div onClick={handleClick}>
          {/* <i className="fa fa-cog fa-2x" /> */}
          {/* <span style={{fontSize:"x-large"}}>📝</span> */}
          <span className="text-white nav-link" style={{ cursor: "pointer" }}>
            <MaterialIcon icon="fact_check" size={24} />
          </span>
        </div>
        <ul className="dropdown-menu show">
          {/* 타이틀 자리 */}
          {todoTitle}
          <div className="todolist-table-area">
            <Table className="todolist-table">
              <div>{tableHead}</div>
              <div className="table-background">
                {tableBody}
              </div>
            </Table>
            <hr />
            {inputForm}
          </div>
        </ul>
      </div>
    </div>
  );
}

export default FixedPlugin;
