import Pagination from "react-js-pagination";
import styled from "styled-components";

const SContainer = styled.div`
  margin: 20px 0px;
  ul {
    color: #686868;
    display: flex;
    gap: 10px;
    justify-content: center;
    & > li.active {
      color: black;
      font-weight: 600;
      border-bottom: 2px solid #bb2649;
    }
  }
  li {
    padding: 4px 13px;
    border-radius: 2px;
    &:hover {
      color: #bb2649;
      cursor: pointer;
    }
  }
`;

/**
 * 페이징 처리 컴포넌트
 * @param {object} param0 - 페이징 처리에 필요한 속성들을 담은 객체
 * @param {number} page - 현재 활성화된 페이지 번호
 * @param {number} count - 전체 아이템의 개수
 * @param {number} perPage - 페이지 당 아이템 개수
 * @param {function} handlePageChange - 페이지 변경 이벤트 핸들러
 * @returns Paging 컴포넌트
 */
const Paging = ({ page, count, perPage, handlePageChange }) => {
  return (
    <SContainer>
      <Pagination
        activePage={page}             // 현재 활성화된 페이지 번호
        itemsCountPerPage={perPage}   // 페이지 당 아이템 개수
        totalItemsCount={count}       // 전체 아이템의 개수
        pageRangeDisplayed={5}        // 보여질 페이지 범위의 크기
        prevPageText={"<"}            // 이전 페이지로 이동하는 버튼에 표시될 텍스트
        nextPageText={">"}            // 다음 페이지로 이동하는 버튼에 표시될 텍스트
        onChange={handlePageChange}   // 페이지 변경 시 호출될 함수
      />
    </SContainer>
  );
};

export default Paging;
