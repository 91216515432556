import React, { useEffect, useState } from "react";
import "../../variables/RelationList";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Row,
  Col,
  Spinner,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { useParams } from "react-router-dom";
import { API } from "../../ApiConfig";
import instanceAxios from "components/reissue/InstanceAxios";
import DetailButton from "variables/DetailButton";
import FileDownView from "views/Support/FileDownView";
import Swal from "sweetalert2";
import ErrAlert from "variables/ErrAlert";

const titleName = "라이선스";

/**
 *
 * @returns 라이선스 리스트에서 클릭한 id값에 맞는 단일 게시물 상세보기 페이지를 리턴
 */
function LicenseDetail() {
  // 수정, 정보보기에 사용되는 클릭한 라이선스 id값
  const { id } = useParams();

  /**
   * 라이선스 데이터를 불러와 사용하기 편하도록 변환하는 함수
   */
  async function fetchData() {
    try {
      const response = await instanceAxios.get(`${API.LICENSELIST}/${id}`);
      const data = response.data?.data || [];
      setLicense(data);
    } catch (error) {
      // 에러 처리
      ErrAlert({ err: "데이터 불러오기 실패" });
    } finally {
      // 로딩 상태 업데이트
      setLoading(false);
    }
  }
  const [license, setLicense] = useState([]);
  const [loading, setLoading] = useState(true); // 로딩 상태 추가
  useEffect(() => {
    // 페이지 로딩 시 라이선스 정보 가져오기
    fetchData();
  }, [id]); // ID가 변경될 때마다 useEffect 실행

  const [useWhetherText, setUseWhetherText] = useState("");

  useEffect(() => {
    // license.useWhether가 true면 "Y", false면 "N"으로 설정
    setUseWhetherText(license.useWhether ? "Y" : "N");
  }, [license.useWhether]);

  // 복사 버큰 누르면 라이선스 key 클립보드에 복사해주는 메소드
  const copyKey = () => {
    var copyText = license.licenseKey;
    navigator.clipboard.writeText(copyText);
    const Toast = Swal.mixin({
      toast: true,
      position: "top",
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: "success",
      title: "복사되었습니다.",
    });
  };

  const getLicenseLabel = (licenseDivision) => {
    switch (licenseDivision) {
      case "SUBSCRIBE":
        return "구독형";
      case "PERMANENT":
        return "영구형";
      case "POC":
        return "POC";
      case "TEMPORARY":
        return "임시";
      default:
        return "-";
    }
  };

  return (
    <>
      <div className="content">
        <Form>
          <Row>
            <Col md="12">
              <div className="detail-wrap">
                <Card className="card-user">
                  <CardHeader>
                    <h5 className="title">{titleName} 상세보기</h5>
                    <p className="category" style={{ fontSize: "17px" }}></p>
                  </CardHeader>
                  <CardBody>
                    {loading ? (
                      <Spinner color="primary" />
                    ) : (
                      <>
                        <FormGroup>
                          <Label>라이선스명</Label>
                          <Input
                            className="detail-input"
                            value={license.license || "-"}
                            disabled
                          />
                        </FormGroup>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label>사용여부</Label>
                              <Input
                                className="detail-input"
                                value={useWhetherText || "-"}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>구분</Label>
                              <Input
                                className="detail-input"
                                value={getLicenseLabel(license.licenseDivision)}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <FormGroup>
                          <Label>고객사</Label>
                          <Input
                            className="detail-input"
                            value={license.customer || "-"}
                            disabled
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>프로젝트</Label>
                          <Input
                            className="detail-input"
                            value={license.project || "-"}
                            disabled
                          />
                        </FormGroup>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label>발급일자</Label>
                              <Input
                                className="detail-input"
                                value={license.issueDate || "-"}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>시작일자</Label>
                              <Input
                                className="detail-input"
                                value={license.startDate || "-"}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label>만료일자</Label>
                              <Input
                                className="detail-input"
                                value={license.expiredDate || "-"}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>남은일자</Label>
                              <Input
                                className="detail-input"
                                value={license.remainDate || "-"}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <FormGroup>
                          <Label>IP</Label>
                          <Input
                            className="detail-input"
                            placeholder={license.ipInfo || "-"}
                            disabled
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>항목</Label>
                          {license.itemList ? (
                            license.itemList.map((item, index) => (
                              <div key={index}>
                                <Row>
                                  <Col md="2">
                                    <FormGroup>
                                      <Input
                                        className="detail-input"
                                        value={`${item.majorCategory} `}
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Input
                                        className="detail-input"
                                        value={`${item.middleCategory} `}
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Input
                                        className="detail-input"
                                        value={`${item.smallCategory} `}
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Input
                                        className="detail-input"
                                        value={`${item.itemDetail} `}
                                        disabled
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </div>
                            ))
                          ) : (
                            <div>
                              <FormGroup>
                                <Input
                                  className="detail-input"
                                  value="-"
                                  disabled
                                />
                              </FormGroup>
                            </div>
                          )}
                        </FormGroup>
                        <FormGroup>
                          <Label>라이선스 KEY</Label>
                          <Row style={{ alignItems: "center" }}>
                            <Col>
                              <Input
                                className="detail-input"
                                value={license.licenseKey || "-"}
                                disabled
                              />
                            </Col>
                            <Col md="2">
                              <Button color="primary" onClick={copyKey}>
                                복사
                              </Button>
                            </Col>
                          </Row>
                        </FormGroup>
                        <FormGroup>
                          <Label>첨부파일</Label>
                          <FileDownView domain="LICENSE" domainId={id} />
                        </FormGroup>
                        <FormGroup>
                          <Label>비고</Label>
                          <Input
                            className="detail-input"
                            value={license.content || ""}
                            disabled
                            type="textarea"
                          />
                        </FormGroup>
                        <DetailButton domainId={id} domain="LICENSE" deleteTf={license.deleteTAndF}/>
                      </>
                    )}
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}

export default LicenseDetail;
