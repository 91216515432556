import React, { useEffect } from "react";
import { useState } from "react";
import Paging from "../../variables/Paging";
import "../../assets/css/List.css";

// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  CardFooter,
  Spinner,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { API } from "../../ApiConfig";
import instanceAxios from "components/reissue/InstanceAxios";
import SelectBox from "variables/Fillter/SelectBox";
import Swal from "sweetalert2";
import ErrAlert from "variables/ErrAlert";

/**
 * 고객사 리스트 페이지
 * @returns table형식의 list page를 리턴합니다.
 */
function CustomerList() {
  const [customerData, setCustomerData] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0); // 전체 요소 갯수
  const [loading, setLoading] = useState(true); // 로딩 상태 추가

  const isMobile = () => {
    return window.innerWidth <= 768; // 현재는 768px를 기준으로 모바일로 간주합니다.
  };

  const PER_PAGE_MOBILE = 5; // 모바일에서 한 페이지에 보이는 개수
  const PER_PAGE_DESKTOP = 12; // 데스크톱에서 한 페이지에 보이는 개수

  const PER_PAGE = isMobile() ? PER_PAGE_MOBILE : PER_PAGE_DESKTOP;

  async function fetchData(page = 1) {
    try {
      const response = await instanceAxios.get(
        `${API.CUSTOMERLIST}?page=${page}&${firstSelectValue}=${secondSelectValue}&pageSize=${PER_PAGE}`
      );

      const data = response.data?.data?.data || [];
      setCustomerData(data);
      setCount(response.data.data.totalElements);
    } catch (error) {
      ErrAlert({ err: "데이터 불러오기 실패" });
    } finally {
      // 로딩 상태 업데이트
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  /**
   * 전체{}건 을 클릭시 필터가 적용되지 않은 첫 페이지로 돌아갑니다
   */
  const handleTotalCountClick = () => {
    window.location.reload();
  };

  /**
   * 페이지가 변경될 때마다 페이지 번호를 가져와 useState의 setPage를 이용하여 업데이트 해주는 함수입니다.
   * @param {number} page
   */
  const handlePageChange = async (newPage) => {
    setLoading(true); // 로딩 상태를 true로 설정하여 데이터를 가져오는 중임을 나타냄
    setPage(newPage);
    await fetchData(newPage);
  };

  const navigate = useNavigate();

  // 두 번째 셀렉트로 필터링된 데이터를 저장할 상태
  const [firstSelectValue, setFirstSelectValue] = useState("");
  const [secondSelectValue, setSecondSelectValue] = useState("");

  /**
   * 첫 번째 셀렉트 변경 시 호출되는 함수
   * @param {첫 번째 셀렉트 값} value
   */
  const handleFirstSelectChange = (value) => {
    setFirstSelectValue(value);
  };

  /**
   * 두 번째 셀렉트 변경 시 호출되는 함수
   * @param {두 번째 셀렉트 값} value
   */
  const handleSecondSelectChange = (value) => {
    setSecondSelectValue(value);
  };

  /**
   * 검색버튼을 클릭하면 실행되는 함수
   * 셀렉트 된 값에 맞춰 필터링하여 저장
   */
  const handleSearchButtonClick = () => {
    if (firstSelectValue && secondSelectValue) {
      setPage(1); // 검색 후 첫 페이지로 이동
      setLoading(true); // 로딩 상태를 true로 설정하여 데이터를 가져오는 중임을 나타냄
      fetchData();
    } else {
      Swal.fire({
        icon: "warning",
        title: "오류",
        text: `검색 카테고리를 선택해주세요.`,
      });
    }
  };

  /**
   * 배열을 페이지 번호에 맞춰 반복문을 돌려 담아주는 함수입니다.
   * @returns 게시글을 배열에 담아 리턴합니다.
   */
  function arrayList() {
    var array = [];
    if (!customerData || customerData.length === 0) {
      return (
        <tr key="no-data" style={{ textAlign: "center" }}>
          <td colSpan="6">등록된 글이 없습니다.</td>
        </tr>
      );
    } else {
      customerData.map((item) => {
        const isDeleted = item.deleteTandF;
        array.push(
          <Col lg="3" md="6" sm="6">
            <Card
              id="move"
              onClick={() => {
                navigate(`${API.CUSTOMERDETAIL}/${item.id}`);
              }}
              className={`card-stats ${isDeleted ? "deleted" : ""}`}
            >
              <CardBody>
                <Row>
                  <Col>
                    <div style={{ fontSize: "1.5em" }}>
                      <CardTitle
                        tag="p"
                        className={isDeleted ? "deleted-text" : ""}
                      >
                        {item.customer}
                      </CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">→ Details</div>
              </CardFooter>
            </Card>
          </Col>
        );
      });
    }
    return array;
  }

  return (
    <>
      <div className="content">
        <div>
          <div style={{ display: "flex", marginBottom: "30px" }}>
            <Col md="auto" className="d-flex align-items-center">
              <p
                className="category"
                style={{ fontSize: "17px", display: "flex" }}
              >
                <span
                  style={{ cursor: "pointer" }}
                  onClick={handleTotalCountClick}
                  onMouseEnter={(e) => (e.target.style.color = "#240170")}
                  onMouseLeave={(e) => (e.target.style.color = "#9A9A9A")}
                >
                  전체 {count}건
                </span>
              </p>
            </Col>
            {/* <SelectBox
              onFirstSelectChange={handleFirstSelectChange}
              onSecondSelectChange={handleSecondSelectChange}
              options="customer"
            /> */}
            <Col md="auto" className="d-flex align-items-center">
              {/* <Button
                    outline
                    color="primary"
                    type="button"
                    onClick={handleSearchButtonClick}
                    style={{ marginLeft: "15px" }}
                  >
                    검색
                  </Button> */}
              {/* <i
                id="iconBtn"
                className="nc-icon nc-zoom-split"
                onClick={handleSearchButtonClick}
                color="primary"
              /> */}
            </Col>
          </div>
          {loading ? (
            <Spinner color="primary" />
          ) : (
            <>
              <Row>{arrayList()}</Row>
            </>
          )}
          {!loading && (
            <div style={{ textAlign: "right", marginRight: "10px" }}>
              <Button
                color="primary"
                type="submit"
                onClick={() => navigate(`${API.CUSTOMERREGIST}`)}
              >
                등록
              </Button>
            </div>
          )}
        </div>
        {!loading && (
          <Paging
            count={count}
            page={page}
            perPage={PER_PAGE}
            handlePageChange={handlePageChange}
          />
        )}
      </div>
    </>
  );
}

export default CustomerList;
