/*!

=========================================================
* Paper Dashboard React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import { API } from "ApiConfig";
import { useNavigate } from "react-router-dom";
import FormJson from "components/RegistForm/FormJson";
import React, { useState, useEffect } from "react";
import { fetchCustomers, fetchProjectsByCustomerName } from "ApiUtils";
import Select from "react-select";
import instanceAxios from "components/reissue/InstanceAxios";
import FileUpload from "components/RegistForm/FileUpload";
import Swal from "sweetalert2";
import DeleteButton from "variables/DeleteButton";
import ToastAlert from "variables/ToastAlert";
import ErrAlert from "variables/ErrAlert";

/**
 * 라이선스 등록 페이지
 * @author 김지연
 * @returns 라이선스 등록 폼 리턴
 */
function LicenseRegist() {
  //라이선스 구분 옵션
  const divOptions = [
    { value: "SUBSCRIBE", label: "구독형" },
    { value: "PERMANENT", label: "영구형" },
    { value: "POC", label: "POC" },
    { value: "TEMPORARY", label: "임시" },
  ];

  const [license, setLicense] = useState(""); // 라이선스 명
  const [customer, setCustomer] = useState(""); // 고객사
  const [project, setProject] = useState(""); // 프로젝트
  const [issueDate, setIssueDate] = useState(""); // 발급 일자
  const [startDate, setStartDate] = useState(""); // 발주 일자
  const [licenseDivision, setLicenseDivision] = useState(""); // 라이선스 구분
  const [expiredDate, setExpiredDate] = useState(""); // 만료 일자
  const [ipInfo, setIpInfo] = useState(""); // IP 정보
  const [licenseKey, setLicenseKey] = useState(""); // 라이선스 key
  const [content, setContent] = useState(""); // 비고
  const [file, setFile] = useState([]); // 첨부파일

  const [isCusInitial, setIsCusInitial] = useState(true); // 초기 상태를 추적합니다.
  const [isPrjInitial, setIsPrjInitial] = useState(true); // 초기 상태를 추적합니다.
  const [isDivInitial, setIsDivInitial] = useState(true); // 초기 상태를 추적합니다.
  const [isStartInitial, setIsStartInitial] = useState(true); // 초기 상태를 추적합니다.
  const [isExpInitial, setIsExpInitial] = useState(true); // 초기 상태를 추적합니다.

  // FormJson 컴포넌트에서 입력한 내용을 상태로 관리
  const [dynamicFieldsData, setDynamicFieldsData] = useState([]); //라이선스 정보
  const [startDateError, setStartDateError] = useState(false); // 라이선스 날짜 오류 여부 상태

  //========== api load
  const [customerName, setCustomerName] = useState([]); // 고객사명 리스트
  const [projectName, setProjectName] = useState([]); // 프로젝트명 리스트

  //고객사 불러오기
  useEffect(() => {
    const fetchData = async () => {
      try {
        const customers = await fetchCustomers();
        customers.splice(0, 2);
        setCustomerName(customers);
      } catch (error) {
        ErrAlert({ err: "데이터 불러오기 실패" });
      }
    };

    fetchData();
  }, []);

  //고객사 선택시 맞는 프로젝트 불러오기
  const handleCustomerChange = async (selectedOption) => {
    const newCustomerName = selectedOption.label;
    const newCustomerId = selectedOption.value;
    setCustomer(selectedOption.value);
    setProjectName([]);
    setIsPrjInitial(true);
    try {
      if (newCustomerName) {
        const fetchData = async () => {
          const projects = await fetchProjectsByCustomerName(newCustomerId);

          setProjectName(
            projects.map((project) => ({
              value: project.id,
              label: project.project,
            }))
          );
        };

        fetchData();
      }
    } catch (error) {
      ErrAlert({ err: "데이터 불러오기 실패" });
    }
    setIsCusInitial(false);
    setProject("");
  };

  // FormJson 컴포넌트에서 입력한 내용을 받는 함수
  const handleDynamicFieldsChange = (data) => {
    setDynamicFieldsData(data);
  };

  // 계약 시작일 변경 시 실행되는 함수
  const handleStartDateChange = (event) => {
    const selectedStartDate = event.target.value;
    setStartDate(selectedStartDate);
    setIsStartInitial(false);

    // 종료일이 설정되어 있고 시작일이 종료일보다 이후인 경우 오류 설정
    if (expiredDate && selectedStartDate > expiredDate) {
      setStartDateError(true);
    } else {
      setStartDateError(false);
    }
  };

  // 계약 종료일 변경 시 실행되는 함수
  const handleEndDateChange = (event) => {
    const selectedEndDate = event.target.value;
    setExpiredDate(selectedEndDate);
    setIsExpInitial(false);

    // 시작일이 설정되어 있고 종료일이 시작일보다 이전인 경우 오류 설정
    if (selectedEndDate < startDate) {
      setStartDateError(true);
    } else {
      setStartDateError(false);
    }
  };

  

  // ============== form submit ==============

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (startDateError) {
      Swal.fire({
        icon: "warning",
        title: "주의",
        text: `시작일/만료일을 확인해주세요.`,
        showCancelButton: false,
        confirmButtonText: "확인",
      })
    } else {
      Swal.fire({
        icon: "question",
        title: "등록",
        text: `등록 하시겠습니까?`,
        showCancelButton: true,
        confirmButtonText: "등록",
        cancelButtonText: "취소",
      }).then(async (res) => {
        if (res.isConfirmed) {
          const formData = new FormData();

          formData.append("license", license); //필수값
          formData.append("customer", customer); //필수값
          formData.append("project", project.value);
          formData.append("issueDate", issueDate);
          formData.append("startDate", startDate);
          formData.append("licenseDivision", licenseDivision);
          formData.append("expiredDate", expiredDate);
          formData.append("ipInfo", ipInfo);
          formData.append("licenseKey", licenseKey);
          formData.append("content", content);

          //라이선스 정보 등록
          // const arangeData = dynamicFieldsData.map(field => {
          //     return {
          //       majorCategory: field.majorCategory,
          //       middleCategory: field.middleCategory,
          //       smallCategory: field.smallCategory,
          //       itemDetail: field.itemDetail
          //     };
          // });
          dynamicFieldsData.forEach((info, index) => {
            formData.append(
              `itemList[${index}].majorCategory`,
              info.majorCategory
            );
            formData.append(
              `itemList[${index}].middleCategory`,
              info.middleCategory
            );
            formData.append(
              `itemList[${index}].smallCategory`,
              info.smallCategory
            );
            formData.append(`itemList[${index}].itemDetail`, info.itemDetail);
          });

          //formData.append("itemList", JSON.stringify(arangeData));

          // 파일 등록
          for (var i = 0; i < file.length; i++) {
            formData.append("fileList", file[i]);
          }

          //통신
          try {
            const response = await instanceAxios.post(
              `${API.LICENSELIST}`,
              formData,
              {
                headers: {
                  "Content-Type": "application/form-data;charset=UTF-8",
                },
              }
            );
            // 서버 응답에 따른 동작 수행
            ToastAlert({ icon: "success", title: "등록이 완료되었습니다." });
            navigate(`/${API.LICENSE}`);
          } catch (error) {
            ErrAlert({ err: "필수 항목을 확인해주세요" });
          }
        } else {
          //취소
        }
      });
    }
  };

  // ========== 등록 폼 ===================
  const navigate = useNavigate();
  return (
    <div className="content">
      <div className="regist-wrap">
        <Card className="card-user">
          <CardHeader>
            <CardTitle tag="h5">라이선스 등록</CardTitle>
          </CardHeader>
          <CardBody>
            <Form encType="multipart/form-data" onSubmit={handleSubmit}>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <label>라이선스명</label>
                    <Input
                      required
                      placeholder="필수로 입력해주세요."
                      className={license ? "is-valid" : "is-invalid"}
                      type="text"
                      name="license"
                      onChange={(event) => {
                        setLicense(event.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="pr-1" md="5">
                  <FormGroup>
                    <label>고객사</label>
                    <Select
                      required
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          borderColor: isCusInitial
                            ? "#ff0000"
                            : provided.borderColor,
                          // 선택되었을 때와 선택되지 않았을 때의 테두리색을 지정할 수 있습니다.
                        }),
                      }}
                      placeholder="필수값입니다"
                      options={customerName}
                      onChange={handleCustomerChange}
                    />
                  </FormGroup>
                </Col>
                <Col className="pl-1" md="7">
                  <FormGroup>
                    <label>프로젝트</label>
                    <Select
                      name="cProject"
                      required
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          borderColor: isPrjInitial
                            ? "#ff0000"
                            : provided.borderColor,
                          // 선택되었을 때와 선택되지 않았을 때의 테두리색을 지정할 수 있습니다.
                        }),
                      }}
                      placeholder="필수값입니다"
                      options={projectName}
                      onChange={(selectedOption) => {
                        setProject(selectedOption);
                        setIsPrjInitial(false);
                      }}
                      value={project}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="pr-1" md="3">
                  <FormGroup>
                    <label>발급 일자</label>
                    <Input
                      type="date"
                      name="issueDate"
                      onChange={(selectedOption) => {
                        setIssueDate(selectedOption.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col className="px-1" md="3">
                  <FormGroup>
                    <label>시작 일자</label>
                    <Input
                      required
                      className={startDate ? "is-valid" : "is-invalid"}
                      type="date"
                      name="startDate"
                      onChange={
                        handleStartDateChange
                      }
                    />
                    {!startDate && (
                      <span style={{ color: "red" }}>필수로 입력해주세요.</span> 
                    )}
                  </FormGroup>
                </Col>
                <Col className="px-1" md="3">
                  <FormGroup>
                    <label>만료 일자</label>
                    <Input
                      required
                      className={expiredDate ? "is-valid" : "is-invalid"}
                      type="date"
                      name="expiredDate"
                      onChange={handleEndDateChange}
                    />
                    {!expiredDate && (
                      <span style={{ color: "red" }}>필수로 입력해주세요.</span> 
                    )}
                    {startDateError && (
                      <span style={{ color: "red" }}>
                        만료 일자는 시작 일자 이후여야 합니다.
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col className="pl-1" md="3">
                  <FormGroup>
                    <label>구분</label>
                    <Select
                      name="licenseDivision"
                      required
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          borderColor: isDivInitial
                            ? "#ff0000"
                            : provided.borderColor,
                          // 선택되었을 때와 선택되지 않았을 때의 테두리색을 지정할 수 있습니다.
                        }),
                      }}
                      placeholder="필수값입니다"
                      options={divOptions}
                      onChange={(event) => {
                        setLicenseDivision(event.value);
                        setIsDivInitial(false);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <label>IP</label>
                    <Input
                      type="text"
                      name="ipInfo"
                      onChange={(selectedOption) => {
                        setIpInfo(selectedOption.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <hr />
              <Row>
                <CardHeader>
                  <CardTitle tag="h5">라이선스 정보</CardTitle>
                </CardHeader>
              </Row>
              <Row>
                <Col className="pr-1">
                  {/* 유동적 범위 추가 */}
                  <FormJson onChange={handleDynamicFieldsChange} />
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <label>라이선스 Key</label>
                    <Input
                      type="text"
                      name="licenseKey"
                      onChange={(selectedOption) => {
                        setLicenseKey(selectedOption.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <FileUpload file={file} setFile={setFile} />
              <Row>
                <Col md="12">
                  <FormGroup>
                    <label>비고</label>
                    <Input
                      type="textarea"
                      name="content"
                      onChange={(selectedOption) => {
                        setContent(selectedOption.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <DeleteButton
                  to={`/${API.LICENSE}`}
                  text1="작성을 취소 하시겠습니까?"
                />
              </Row>
            </Form>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default LicenseRegist;
