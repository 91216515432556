// 계약 리스트 페이지
import React from "react";
import { useState, useEffect } from "react";
import Paging from "../../variables/Paging";
import "../../assets/css/Custom.css";
import "../../assets/css/List.css";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Col,
  Button,
  Spinner,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { API } from "../../ApiConfig";
import instanceAxios from "components/reissue/InstanceAxios";
import SelectBox from "variables/Fillter/SelectBox";
import Swal from "sweetalert2";
import ErrAlert from "variables/ErrAlert";
import SortFilter from "variables/Fillter/SortFilter";
import ClassFilter from "variables/Fillter/ClassFilter";
import StateFilter from "variables/Fillter/StateFilter";
/**
 * 어떤 리스트인지 나타내는 제목입니다.
 */
const titleName = "계약";

/**
 * 계약 리스트 페이지
 * @returns table형식의 list page를 리턴합니다.
 */
function ContractList() {
  const [contractData, setListData] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0); // 전체 요소 갯수
  const [loading, setLoading] = useState(true); // 로딩 상태 추가
  const PER_PAGE = 10; // 한 페이지 출력 갯수

  // 현재 페이지의 첫 번째 아이템 번호
  const startingIndex = (page - 1) * PER_PAGE + 1;

  // const [originalData, setOriginalData] = useState([]);

  // 정렬 상태
  const [sortCol, setSortCol] = useState("");
  const [sortState, setSortState] = useState("false");
  const [contractState, setContractState] = useState([]);

  // 계약 구분 필터링
  const [classState, setClassState] = useState([]);

  // 두 번째 셀렉트로 필터링된 데이터를 저장할 상태
  const [firstSelectValue, setFirstSelectValue] = useState("");
  const [secondSelectValue, setSecondSelectValue] = useState("");
  const [searchValues, setSearchValues] = useState({
    firstSelectValue: "",
    secondSelectValue: "",
  });
  const [isSearch, setIsSearch] = useState(false); // 검색 상태 추가

  async function fetchData(page = 1, col = "", sortState = "true", state = "", classState = "") {
    const { firstSelectValue, secondSelectValue } = searchValues;
    try {
      const response = await instanceAxios.get(
        `${API.CONTRACTLIST}?page=${page}&${firstSelectValue}=${secondSelectValue}&order=${col}&asc=${sortState}&state=${state}&contractClass=${classState}&pageSize=10` // 이 부분을 수정
      );
      const data = response.data?.data?.data || [];
      // setOriginalData(data);
      setListData(data);
      setCount(response.data.data.totalElements);
    } catch (error) {
      ErrAlert({ err: "데이터 불러오기 실패" });
      // 에러 처리
    } finally {
      // 로딩 상태 업데이트
      setLoading(false); // 로딩 처리도 다시 해야할듯?
    }
  }

  useEffect(() => {
    fetchData(page, sortCol, sortState, contractState, classState);
  }, [searchValues, page, sortCol, sortState, contractState, classState]);

  useEffect(() => {
    fetchData();
  }, []);

  /**
   * 전체{}건 을 클릭시 필터가 적용되지 않은 첫 페이지로 돌아갑니다
   */
  const handleTotalCountClick = () => {
    window.location.reload();
  };

  /**
   * 페이지가 변경될 때마다 페이지 번호를 가져와 useState의 setPage를 이용하여 업데이트 해주는 함수입니다.
   * @param {number} page
   */
  const handlePageChange = async (newPage) => {
    setLoading(true); // 로딩 상태를 true로 설정하여 데이터를 가져오는 중임을 나타냄
    setPage(newPage);
    await fetchData(newPage, sortCol, sortState, contractState, classState);
  };

  const navigate = useNavigate();

  /**
   * 첫 번째 셀렉트 변경 시 호출되는 함수
   * @param {첫 번째 셀렉트 값} value
   */
  const handleFirstSelectChange = (value) => {
    setFirstSelectValue(value);
  };

  /**
   * 두 번째 셀렉트 변경 시 호출되는 함수
   * @param {두 번째 셀렉트 값} value
   */
  const handleSecondSelectChange = (value) => {
    setSecondSelectValue(value);
  };

  /**
   * 검색버튼을 클릭하면 실행되는 함수
   * 셀렉트 된 값에 맞춰 필터링하여 저장
   */
  const handleSearchButtonClick = () => {
    if (firstSelectValue && secondSelectValue) {
      setPage(1); // 검색 후 첫 페이지로 이동
      setLoading(true); // 로딩 상태를 true로 설정하여 데이터를 가져오는 중임을 나타냄
      setIsSearch(true); // 검색 상태를 true로 설정
      setSearchValues({ firstSelectValue, secondSelectValue });
      // await fetchData(1, sortCol, sortState, contractState, classState); // 직접 fetchData 호출
    } else {
      Swal.fire({
        icon: "warning",
        title: "오류",
        text: `검색 카테고리를 선택해주세요.`,
      });
    }
  };

  // 계약구분 값 변경 ex) WORKING -> 작업 진행중
  const [conDiv, setConDiv] = useState([]);

  useEffect(() => {
    // contractData 존재하고, contractData 배열일 경우에만 작업 상태 값을 설정
    if (Array.isArray(contractData) && contractData.length > 0) {
      const updatedConDiv = contractData.map((item) => {
        if (item.contractClass === "REPAIR") {
          return "유지보수";
        } else if (item.contractClass === "SET") {
          return "일반";
        } else {
          return "-";
        }
      });
      setConDiv(updatedConDiv);
    }
  }, [contractData, page]);

  //===========================================================================
  // ## 계약 상태 옵션
  const stateOptions = [
    { value: "CONTRACTING", label: "계약중" },
    { value: "CONTRACT_COMPLETE", label: "계약 완료" },
    { value: "CONTRACT_EXPIRED", label: "계약 만료" },
    { value: "ETC", label: "기타" },
  ];

  const classOptions = [
    { value: "SET", label: "일반" },
    { value: "REPAIR", label: "유지보수" },
  ];

  //## 계약 상태 필터를 위한 state
  const [filterOpen, setFilterOpen] = useState(false);

  //## 계약 구분 필터를 위한 state
  const [classOpen, setClassOpen] = useState(false);
  //===========================================================================

  /**
   * 정렬 버튼 클릭 시 작동되는 함수
   */
  const handleSort = async (col) => {
    const newSortState = sortState === "true" ? "false" : "true";
    setLoading(true);
    setSortCol(col);
    setSortState(newSortState);
    await fetchData(page, col, newSortState, contractState, classState);
  };

  // ## state ENUM 명을 한글로 변경해서 return
  function conState(state) {
    // var state;
    stateOptions.map((option) => {
      if (option.value === state) {
        state = option.label;
      }
    });
    return state;
  }

  const handleTempFilter = async (tempFilter) => {
    // 부모 컴포넌트에서 tempFilter 값을 사용하는 작업을 수행합니다.
    // temFilter를 보내야함 setContractState
    setLoading(true);
    setContractState(tempFilter);
    await fetchData(page, sortCol, sortState, tempFilter, classState);
  };

  const handleClassFilter = async (classFilter) => {
    // 부모 컴포넌트에서 tempFilter 값을 사용하는 작업을 수행합니다.
    // temFilter를 보내야함 setContractState
    setLoading(true);
    setClassState(classFilter);
    await fetchData(page, sortCol, sortState, contractState, classFilter);
  };

  /**
   * 배열을 페이지 번호에 맞춰 반복문을 돌려 담아주는 함수입니다.
   * 필터가 적용되면 조건에 부합하게 다시 리스트를 뿌려줍니다.
   * @returns 게시글을 배열에 담아 리턴합니다.
   */
  function arrayList() {
    var array = [];
    const isAscending = sortState === "true";

    // 리스트에 데이터가 없을 때
    if (!contractData || contractData.length === 0) {
      if (isSearch) {
        return (
          <tr key="no-search-data" style={{ textAlign: "center" }}>
            <td colSpan="7">검색된 결과가 없습니다.</td>
          </tr>
        );
      } else {
        return (
          <tr key="no-data" style={{ textAlign: "center" }}>
            <td colSpan="7">등록된 글이 없습니다.</td>
          </tr>
        );
      }
    }
    // 초기 리스트에 데이터가 있을 때
    contractData.map((item, index) => {
      const isDeleted = item.deleteTandF;
      const displayIndex = isAscending ? (page - 1) * PER_PAGE + index + 1 : count - ((page - 1) * PER_PAGE + index);
      array.push(
        <tr
          key={item.id}
          id="move"
          onClick={() => {
            if (item.contractClass === "SET") {
              navigate(`${API.CONTRACTDETAIL1}/${item.id}`);
            } else if (item.contractClass === "REPAIR") {
              navigate(`${API.CONTRACTDETAIL2}/${item.id}`);
            }
          }}
          style={{ textAlign: "center" }}
        >
          <div
            className={`contract-list-wrap ${isDeleted ? "deleted-text" : ""
              }`}
          >
            <div className={"contract-list-left"}>
              <td>{displayIndex || "-"}</td>
              <td>{item.customer || "-"}</td>
              <td>{item.contract || "-"}</td>
            </div>
            <div className={"contract-list-right"}>
              <td>{conDiv[index] || "-"}</td>
              <div className={"contract-date-wrap"}>
                <td>{item.start || "-"}</td>
                <div className={"date-period-mark"}>~</div>
                <td>{item.end || "-"}</td>
              </div>
              <td>{conState(item.state) || "-"}</td>
            </div>
          </div>
        </tr>
      );
    });

    return array;
  }

  return (
    <>
      <div className="content">
        <Card>
          <CardHeader>
            <CardTitle tag="h4" className="cardTitle">
              {titleName}
            </CardTitle>
          </CardHeader>
          <CardBody>
            {/* <Table responsive /> */}
            <div style={{ display: "flex" }}>
              <Col md="auto" className="d-flex align-items-center">
                <p
                  className="category"
                  style={{ fontSize: "17px", display: "flex" }}
                >
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={handleTotalCountClick}
                    onMouseEnter={(e) => (e.target.style.color = "#240170")}
                    onMouseLeave={(e) => (e.target.style.color = "#9A9A9A")}
                  >
                    전체 {count}건
                  </span>
                </p>
              </Col>
              <SelectBox
                onFirstSelectChange={handleFirstSelectChange}
                onSecondSelectChange={handleSecondSelectChange}
                options="contract"
                handleSearchButtonClick={handleSearchButtonClick}
              />
              <Col md="auto" className="d-flex align-items-center">
                <i
                  id="iconBtn"
                  className="nc-icon nc-zoom-split"
                  onClick={handleSearchButtonClick}
                  color="primary"
                />
              </Col>
            </div>

            <Table>
              <thead className="text-primary">
                <tr className={"contract-title-wrap"}>
                  <div className={"contract-title-left"}>
                    <SortFilter
                      text="번호"
                      value="number"
                      handleSort={handleSort}
                      isActive={sortCol === "number"}
                      activeCol={sortCol}
                      sortState={sortState}
                    />
                    <th>고객사</th>
                    <th>계약명</th>
                  </div>
                  <div className={"contract-title-right"}>
                    <th>계약구분
                      <ClassFilter
                        classOptions={classOptions}
                        classOpen={classOpen}
                        setClassOpen={setClassOpen}
                        setCount={setCount}
                        handleClassFilter={handleClassFilter}
                      />
                    </th>
                    <SortFilter
                      text="계약시작일"
                      value="start"
                      handleSort={handleSort}
                      isActive={sortCol === "start"}
                      activeCol={sortCol}
                      sortState={sortState}
                    />
                    <SortFilter
                      text="계약종료일"
                      value="end"
                      handleSort={handleSort}
                      isActive={sortCol === "end"}
                      activeCol={sortCol}
                      sortState={sortState}
                    />
                    <th style={{ verticalAlign: "center" }}>
                      계약상태{" "}
                      <StateFilter
                        domain="CONTRACT"
                        stateOptions={stateOptions}
                        // originalData={originalData}
                        // setOriginalData={setOriginalData}
                        filterOpen={filterOpen}
                        setFilterOpen={setFilterOpen}
                        // setListData={setListData}
                        setCount={setCount}
                        handleTempFilter={handleTempFilter}
                      />
                    </th>
                  </div>
                  {/* <th>{showStateCheck}</th> */}
                </tr>
              </thead>
              {loading ? (
                <Spinner color="primary" style={{ marginTop: "15px" }} />
              ) : (
                <>
                  <tbody>{arrayList()}</tbody>
                </>
              )}
            </Table>
            {!loading && (
              <div style={{ textAlign: "right", padding: "0px 15px" }}>
                <Button
                  outline
                  color="primary"
                  type="submit"
                  onClick={() => navigate(`${API.CONTRACTREGIST}`)}
                >
                  등록
                </Button>
              </div>
            )}
          </CardBody>
          {!loading && (
            <Paging
              count={count}
              page={page}
              perPage={PER_PAGE}
              handlePageChange={handlePageChange}
            />
          )}
        </Card>
      </div>
    </>
  );
}

export default ContractList;
