/*!

=========================================================
* Paper Dashboard React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import Select, { components } from "react-select";
import instanceAxios from "components/reissue/InstanceAxios";
import { useLocation, useNavigate } from "react-router-dom";
import { API } from "ApiConfig";
import FileUpload from "components/RegistForm/FileUpload";
import Swal from "sweetalert2";
import ErrAlert from "variables/ErrAlert";
import CkEditor from "variables/CkEditor";

/**
 * 지원 이어쓰기 등록 페이지
 * @author 김지연
 * @returns 지원 이어쓰기 등록 폼 리턴
 */

//지원 등록 폼
function FollowingSupportRegist() {
  //업무 구분 select 옵션
  const [Option, setOption] = useState(); // 업무 구분 select 옵션 상태

  const [file, setFile] = useState([]); // 파일 상태

  const navigate = useNavigate();

  const location = useLocation();
  const id = location.search.split("?id=")[1]; // 쿼리 파라미터에서 id를 추출합니다.

  const [customerIdOfSup, setCustomerIdOfSup] = useState();     // 담당자의 고객사 id
  const [customerNameOfSup, setCustomerNameOfSup] = useState(); // 담당자의 고객사 name
  const [projectIdOfSup, setprojectIdOfSup] = useState();       // 프로젝트 id
  const [projectNameOfSup, setprojectNameOfSup] = useState();   // 프로젝트 name

  const [startTime, setStartTime] = useState(""); // 시작 시간 상태
  const [endTime, setEndTime] = useState(""); // 종료 시간 상태

  const [isStartDateSelected, setIsStartDateSelected] = useState(false); // 시작 날짜 선택 여부 상태

  //=====================Member dropdown select======================

  const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
  }) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    //스타일 설정
    let bg = "transparent";
    if (isFocused) bg = "#eee";
    if (isActive) bg = "#B2D4FF";

    const style = {
      alignItems: "center",
      backgroundColor: bg,
      color: "inherit",
      display: "flex",
    };

    //prop
    const props = {
      ...innerProps,
      onMouseDown,
      onMouseUp,
      onMouseLeave,
      style,
    };

    /**
     * 멤버 다중 선택이 가능한 셀렉트 컴포넌트
     * @returns 담당자 드롭다운 셀렉트 컴포넌트 반환
     */

    return (
      <components.Option
        {...rest}
        isDisabled={isDisabled}
        isFocused={isFocused}
        isSelected={isSelected}
        getStyles={getStyles}
        innerProps={props}
      >
        <input type="checkbox" checked={isSelected} />
        {children}
      </components.Option>
    );
  };

  const allOptions = [];  // 모든 옵션을 담을 배열

  const [selectedWorkers, setSelectedWorkers] = useState([]); // 선택된 작업자 상태
  const [showMemberList, setShowMemeberList] = useState([]); // 멤버 리스트 상태

  // 멤버 변경 핸들러
  const handleMemberChange = async (selectedOptions) => {
    if (selectedOptions && selectedOptions.length > 0) {
      setIsMemberInitial(false);
    } else {
      setIsMemberInitial(true);
    }

    if (Array.isArray(selectedOptions)) {
      setSelectedWorkers(selectedOptions.map((opt) => opt.value));
    }
  };
  //====================================================================
  useEffect(() => {
    try {
      // 작업자 이름 가져오기
      instanceAxios.get(`${API.SUPERVISORNAME}?supervisorClass=NINEONE`).then((response) => {
        // setShowMemeberList(response.data.data);

        response.data.data.map((member) => {
          allOptions.push(
            JSON.parse(
              `{ "value" : "${member.id}", "label" : "${member.supervisor}" }`
            )
          );
        });
        setShowMemeberList(allOptions);
      });

      instanceAxios.get("/api/support/" + id).then((support) => {
        const supportData = support.data.data;

        setCustomerIdOfSup(supportData.customerId);
        setCustomerNameOfSup(supportData.customer);
        setprojectIdOfSup(supportData.projectId);
        setprojectNameOfSup(supportData.project);
      });
    } catch (e) {
      ErrAlert({ err: "데이터 불러오기 실패" });
    }
  }, []);

  // 지원 옵션
  const repairOption = [
    { value: "INQUERY_WATAB", label: "와탭 문의" },
    { value: "INQUERY_CABLE", label: "유선" },
    { value: "VISIT", label: "방문" },
    { value: "MAIL", label: "메일" },
    { value: "VPN", label: "VPN" },
    { value: "RESIDE", label: "상주" },
    { value: "INSTALL", label: "설치"},
    { value: "INSPECTION", label: "검수"},
  ];

  // 작업 상태 옵션
  const stateOption = [
    { value: "WORK_EXPECTED", label: "작업 예정" },
    { value: "WORKING", label: "작업 중" },
    { value: "WORK_COMPLETE", label: "작업 완료" },
  ];

  // 파일 삭제 함수
  const removeFile = (index) => {
    if (!window.confirm(`첨부된 ${index + 1} 번째 파일을 삭제하시겠습니까?}`)) {
      return;
    }
    const filesAfterRemove = [...file];
    filesAfterRemove.splice(index, 1);
    setFile(filesAfterRemove);
  };

  // 파일 목록 출력 함수
  const printFileList = () => {
    return (
      <table>
        {file.map((data, index) => (
          <tr>
            <td>{data.name}</td>
            <td>
              <button
                type="button"
                onClick={() => removeFile(index)}
                style={{ marginLeft: "3em" }}
              >
                X
              </button>
            </td>
          </tr>
        ))}
      </table>
    );
  };

  // const [dataToSubmit, setDataToSubmit] = useState({
  //   supportId: "",
  //   name: "",
  //   customerId: customerIdOfSup,
  //   projectId: projectIdOfSup,
  //   supportState: stateOption[0].value,
  //   division: repairOption[0].value,
  //   caller: "",
  //   workers: "",
  //   ask: "",
  //   start: "",
  //   end: "",
  //   content: "",
  // });

  const [name, setName] = useState("");                 // 지원명
  const [supportState, setSupportState] = useState(""); // 작업 상태
  const [division, setDivision] = useState("");         // 업무 구분
  const [caller, setCaller] = useState("");             // 요청자
  const [ask, setAsk] = useState("");                   // 요청일
  const [start, setStart] = useState("");               // 시작일
  const [end, setEnd] = useState("");                   // 종료일
  const [content, setContent] = useState("");           // 내용

  const [startDateError, setStartDateError] = useState(false); // 시작일 오류 여부 상태

  const [isDivInitial, setIsDivInitial] = useState(true); // 초기 상태를 추적합니다.
  const [isStateInitial, setIsStateInitial] = useState(true); // 초기 상태를 추적합니다.
  const [isMemberInitial, setIsMemberInitial] = useState(true); // 초기 상태를 추적합니다.

  // 종료일이 변경될 때 실행되는 함수
  const handleEndDateChange = (event) => {
    const selectedEndDate = event.target.value;
    setEnd(selectedEndDate);

    // 만약 선택된 종료일이 시작일보다 이전이면 시작일 오류 상태를 설정합니다.
    if (selectedEndDate < start) {
      setStartDateError(true);
    } else {
      setStartDateError(false);
    }
  };

  // 시작일이 변경될 때 실행되는 함수
  const handleStartDateChange = (event) => {
    const selectedStartDate = event.target.value;
    setStart(selectedStartDate);
    setIsStartDateSelected(true);

    // 종료일이 존재하고 선택된 시작일이 종료일보다 이후이면 시작일 오류 상태를 설정합니다.
    if (end && selectedStartDate > end) {
      setStartDateError(true);
    } else {
      setStartDateError(false);
    }
  };

  // 시작 시간이 변경될 때 실행되는 함수
  const handleStartTimeChange = (event) => {
    const selectedStartTime = event.target.value;

    setStartDateError(false);

    // 선택된 시작 시간이 종료 시간보다 이후이고 시작일과 종료일이 같은 경우 시작일 오류 상태를 설정합니다.
    if (selectedStartTime > endTime && start === end) {
      setStartDateError(true);
    }

    setStartTime(selectedStartTime);

    // 시작일과 선택된 시작 시간이 모두 존재하는 경우
    if (start && selectedStartTime) {
      // 종료일을 시작일로 설정합니다.
      setEnd(start);
      // 선택된 시작 시간에 1시간을 추가하여 종료 시간을 설정합니다.
      const selectedTime = new Date(`2000-01-01T${selectedStartTime}`);
      selectedTime.setHours(selectedTime.getHours() + 1); // 선택된 시간에서 1시간 추가
      const formattedEndTime = selectedTime.toTimeString().slice(0, 5); // 시간 형식 변환 (hh:mm)
      setEndTime(formattedEndTime);
    }
  }

  // 종료 시간이 변경될 때 실행되는 함수
  const handleEndTimechange = (event) => {
    const selectEndTime = event.target.value;

    setStartDateError(false);

    // 선택된 종료 시간이 시작 시간보다 이전이고 시작일과 종료일이 같은 경우 시작일 오류 상태를 설정합니다.
    if (startTime > selectEndTime && start === end) {
      setStartDateError(true);
    }

    setEndTime(selectEndTime);
  }

  const registFollowing = async (e) => {
    e.preventDefault();

    if (startDateError) {
      Swal.fire({
        icon: "warning",
        title: "주의",
        text: `지원일/종료일을 확인해주세요.`,
        showCancelButton: false,
        confirmButtonText: "확인",
      })
    } else {
      Swal.fire({
        icon: "question",
        title: "등록",
        text: `등록 하시겠습니까?`,
        showCancelButton: true,
        confirmButtonText: "등록",
        cancelButtonText: "취소",
      }).then(async (res) => {
        if (res.isConfirmed) {
          var tempWorkerList = [];

          selectedWorkers.map((worker) => {
            tempWorkerList.push(worker);
          });
          let selectedStartTime = startTime || "00:00";
          let selectedEndTime = endTime || "00:00";

          var formData = new FormData();
          formData.append("supportId", id);
          formData.append("name", name);
          formData.append("supportState", supportState.value);
          formData.append("division", division.value);
          formData.append("caller", caller);
          formData.append("ask", ask ? ask : "");
          formData.append("start", start + "T" + selectedStartTime);
          formData.append(
            "end",
            end ? end + "T" + selectedEndTime : start + "T" + selectedEndTime
          );
          formData.append("content", content);
          formData.append("workers", tempWorkerList);

          for (var i = 0; i < file.length; i++) {
            formData.append("fileList", file[i]);
          }

          try {
            await instanceAxios
              .post(`${API.SUPPORTLIST}/${id}/following`, formData, {
                headers: {
                  withCredentials: "true",
                  "Content-Type": `multipart/form-data;charset=UTF-8`,
                  Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                },
              })
              .then((response) => {
                const Toast = Swal.mixin({
                  toast: true,
                  position: "top",
                  showConfirmButton: false,
                  timer: 1500,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                    toast.addEventListener("mouseenter", Swal.stopTimer);
                    toast.addEventListener("mouseleave", Swal.resumeTimer);
                  },
                });

                Toast.fire({
                  icon: "success",
                  title: "등록이 완료되었습니다.",
                });
                navigate(`/${API.SUPPORT}`);
              });
          } catch (e) {
            ErrAlert({ err: "필수 항목을 확인해주세요" });
          }
        }
      });
    }
  };

  // const setFormData = (e) => {
  //   const { name, value } = e.target;
  //   setDataToSubmit((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // }

  return (
    <div className="content">
      <div className="regist-wrap">
        <Card className="card-user">
          <CardHeader>
            <CardTitle tag="h5">지원 이어쓰기 등록</CardTitle>
          </CardHeader>
          <CardBody>
            <Form
              onSubmit={registFollowing}
              encType="multipart/form-data"
              id="registFollowingForm"
            >
              <Row>
                <Col md="12">
                  <FormGroup>
                    <label>지원 - 이어쓰기 명</label>
                    <Input
                      type="text"
                      name="name"
                      onChange={(event) => {
                        setName(event.target.value);
                      }}
                      placeholder="직접 입력"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="pr-1" md="4">
                  <FormGroup>
                    <label>고객사</label>
                    <Input
                      id="cSelect"
                      name="customerId"
                      type="select"
                      value={Option}
                      disabled="disabled"
                      required
                    >
                      <option value={customerIdOfSup}>
                        {customerNameOfSup}
                      </option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col className="pl-1" md="8">
                  <FormGroup>
                    <label>프로젝트</label>
                    <Input
                      id="cSelect"
                      name="projectId"
                      type="select"
                      value={Option}
                      disabled="disabled"
                      required
                    >
                      <option value={projectIdOfSup} checked="checked">
                        {projectNameOfSup}
                      </option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="pr-1" md="4">
                  <FormGroup>
                    <label>업무 구분</label>
                    <Select
                      required
                      menuPortalTarget={document.body}
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          borderColor: isDivInitial
                            ? "#ff0000"
                            : provided.borderColor,
                          // 선택되었을 때와 선택되지 않았을 때의 테두리색을 지정할 수 있습니다.
                        }),
                        menuPortal: (base) => ({ ...base, zIndex: 9998 }),
                      }}
                      placeholder="필수값입니다"
                      name="cProject"
                      options={repairOption}
                      onChange={(selectedOption) => {
                        setDivision(selectedOption);
                        setIsDivInitial(false);
                      }}
                      value={division}
                    />
                  </FormGroup>
                </Col>
                <Col className="px-1" md="4">
                  <FormGroup>
                    <label>요청자</label>
                    <Input
                      id="cSelect"
                      name="caller"
                      type="text"
                      onBlur={(event) => {
                        setCaller(event.target.value);
                      }}
                      placeholder="직접 입력"
                    ></Input>
                  </FormGroup>
                </Col>
                <Col className="pl-1" md="4">
                  <FormGroup>
                    <label>작업 상태</label>
                    <Select
                      required
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          borderColor: isStateInitial
                            ? "#ff0000"
                            : provided.borderColor,
                          // 선택되었을 때와 선택되지 않았을 때의 테두리색을 지정할 수 있습니다.
                        }),
                      }}
                      placeholder="필수값입니다"
                      name="cProject"
                      options={stateOption}
                      onChange={(selectedOption) => {
                        setSupportState(selectedOption);
                        setIsStateInitial(false);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="pr-1" md="4">
                  <FormGroup>
                    <label>작업자</label>
                    <div className="MDrop">
                      <Select
                        name="workerList"
                        isMulti
                        closeMenuOnSelect={false}
                        // onChange={(options) => {
                        //   if (Array.isArray(options)) {
                        //     setSelectedWorkers(options.map((opt) => opt.value));
                        //   }
                        // }}
                        onChange={handleMemberChange}
                        options={showMemberList} //임시 리스트
                        components={{
                          Option: InputOption,
                        }}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                    </div>
                  </FormGroup>
                </Col>
                <Col className="px-1" md="4">
                  <FormGroup>
                    <label>작업 요청일</label>
                    <Input
                      type="datetime-local"
                      name="ask"
                      onInput={(event) => {
                        setAsk(event.target.value);
                      }}
                    ></Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="pr-1" md="4">
                  <FormGroup>
                    <label>지원(예정)일</label>
                    <Input
                      required
                      className={start ? "is-valid" : "is-invalid"}
                      type="date"
                      name="start"
                      onChange={handleStartDateChange}
                    />
                    {!start && (
                      <span style={{ color: "red" }}>필수로 입력해주세요.</span>
                    )}
                  </FormGroup>
                </Col>
                <Col className="px-1" md="2">
                  <FormGroup>
                    <label>지원(예정)시간</label>
                    <Input
                      type="time"
                      name="startTime"
                      disabled={!isStartDateSelected}
                      onChange={handleStartTimeChange}
                    />
                  </FormGroup>
                </Col>
                <Col className="px-1" md="4">
                  <FormGroup>
                    <label>종료일</label>
                    <Input
                      type="date"
                      name="end"
                      value={end}
                      onChange={handleEndDateChange}
                    />
                    {startDateError && (
                      <span style={{ color: "red" }}>
                        지원 종료일은 지원 예정일 이후여야 합니다.
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col className="pl-1" md="2">
                  <FormGroup>
                    <label>지원 종료 시간</label>
                    <Input
                      type="time"
                      name="endTime"
                      disabled={!isStartDateSelected}
                      value={endTime}
                      onChange={handleEndTimechange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <label>지원 항목</label>
                    {/* <Input
                      className="textArea"
                      type="textarea"
                      name="content"
                      onChange={(event) => {
                        setContent(event.target.value);
                      }}
                    /> */}
                    <CkEditor onChange={setContent} />
                  </FormGroup>
                </Col>
              </Row>
              <FileUpload file={file} setFile={setFile} />
              <Row>
                <div className="update ml-auto mr-auto">
                  <Button
                    outline
                    color="danger"
                    type="cancel"
                    onClick={() => navigate(`${API.SUPPORT}`)}
                  >
                    취소
                  </Button>
                  <Button
                    outline
                    color="primary"
                    type="submit"
                  >
                    등록
                  </Button>
                </div>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
export default FollowingSupportRegist;
