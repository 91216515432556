/*!

=========================================================
* Paper Dashboard React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from "react";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import { API } from "../ApiConfig";
import { Button } from "reactstrap";
import MaterialIcon from "react-google-material-icons";
import Swal from "sweetalert2";

/**
 * 
 * @returns 에러 페이지
 */
const ErrorPage = () => {

  useEffect(() => {
    // 로컬 스토리지에서 accessToken을 가져옵니다.
    const storedToken = localStorage.getItem("accessToken");
    // accessToken이 없고 현재 페이지가 로그인 페이지가 아닌 경우
    if (!storedToken && window.location.pathname !== `/${API.LOGIN}`) {
      Swal.fire({
        icon: "error",
        title: "로그인이 필요합니다.",
        text: `로그인 후 이용해주세요`,
      });
    }
  }, []);

  // 로그인 페이지로 이동하는 함수
  const handleReturnToLogin = () => {
    window.location.href = `${API.LOGIN}`;
  };

  return (
    <>
      <Card
        className="text-center"
        style={{
          width: "40rem",
          margin: "auto",
          marginTop: "8em",
          opacity: "0.85",
        }}
      >
        <CardHeader style={{ marginTop: "20px" }}>
          <div>
            <div>
              {/* <img src={logo} alt="logo" style={{ width: "18rem" }} /> */}
              <h3 style={{ fontWeight: "bold" }}>404 Not Found</h3>
            </div>
          </div>
          <hr
            style={{
              border: "1px solid #A3BAF8",
              opacity: "0.5",
              marginTop: "20px",
              width: "80%",
            }}
          />
          <CardTitle tag="h3" style={{ marginTop: "30px" }} className="text-warning">
            <MaterialIcon icon="warning" size={70} />
          </CardTitle>
        </CardHeader>
        <CardBody>
          <div className="error-box">
            <h3 style={{ fontWeight: "600" }}>원하시는 페이지를 찾을 수 없습니다.</h3>
            <p>찾으려는 페이지의 주소가 잘못 입력되었거나, <br/>
              로그인을 하지 않아 접근 권한이 없습니다. <br/>
              로그인을 진행하거나, 메인 페이지로 돌아가 주세요.</p>
            <Button
              onClick={handleReturnToLogin}
              className="return-to-login"
              size="lg"
              color="success"
              outline
            >
              로그인/메인 페이지로 돌아가기
            </Button>
            <ul></ul>

          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default ErrorPage;
