// 담당자 상세페이지

import { API } from "../../ApiConfig";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import instanceAxios from "components/reissue/InstanceAxios";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import DetailButton from "variables/DetailButton";
import FileDownView from "views/Support/FileDownView";
import ErrAlert from "variables/ErrAlert";

const titleName = "담당자";

/**
 * 담당자 데이터를 불러와 사용하기 편하도록 변환하는 함수
 */

function SupervisorDetail() {
  const [supervisor, setSupervisor] = useState([]);
  const [content, setContent] = useState("");
  const [detailName, setDetailName] = useState("");
  const [loading, setLoading] = useState(true); // 로딩 상태 추가

  const { id } = useParams();

  async function fetchData() {
    try {
      const response = await instanceAxios.get(`${API.SUPERVISORLIST}/${id}`);
      const data = response.data?.data || [];
      const prop = data.supervisor;

      const content = data.content || "";

      const supervisorClass = data.supervisorClass;
      let detailName = "";
      if (supervisorClass === "CUSTOMER") {
        detailName = "고객사";
      } else if (supervisorClass === "WATAB") {
        detailName = "와탭";
      } else if (supervisorClass === "NINEONE") {
        detailName = "나인원";
      } else {
        detailName = "상세 정보";
      }

      setContent(content);
      setSupervisor(data);
      setDetailName(detailName);
    } catch (error) {
      ErrAlert({ err: "데이터 불러오기 실패" });
      // 에러 처리
    } finally {
      // 로딩 상태 업데이트
      setLoading(false);
    }
  }

  useEffect(() => {
    // 페이지 로딩 시 프로젝트 정보 가져오기
    fetchData();
  }, [id]); // ID가 변경될 때마다 useEffect 실행

  return (
    <>
      <div className="content">
        <Form>
          <Row>
            <Col md="12">
              <div className="detail-wrap">
                <Card className="card-user">
                  <CardHeader>
                    <h5 className="title">{titleName} 상세보기</h5>
                    <p className="category" style={{ fontSize: "17px" }}>
                      {detailName}
                    </p>
                  </CardHeader>
                  <CardBody>
                    {loading ? (
                      <Spinner color="primary" />
                    ) : (
                      <>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label>이름</Label>
                              <Input
                                className="detail-input"
                                value={supervisor.supervisor || "-"}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>연락처</Label>
                              <Input
                                className="detail-input"
                                value={supervisor.phone || "-"}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <FormGroup>
                          <Label>이메일</Label>
                          <Input
                            className="detail-input"
                            value={supervisor.email || "-"}
                            disabled
                          />
                        </FormGroup>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label>고객사</Label>
                              <Input
                                className="detail-input"
                                value={supervisor.customer}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>지점명</Label>
                              <Input
                                className="detail-input"
                                value={
                                  supervisor.location !== "undefined"
                                    ? supervisor.location
                                    : "-"
                                }
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <FormGroup>
                          <Label>비고</Label>
                          <Input
                            className="detail-input"
                            value={content}
                            disabled
                            type="textarea"
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>첨부파일</Label>
                          <FileDownView domain="SUPERVISOR" domainId={id} />
                        </FormGroup>
                        <DetailButton domainId={id} domain="SUPERVISOR" deleteTf={supervisor.deleteTAndF} />
                      </>
                    )}
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}

export default SupervisorDetail;
