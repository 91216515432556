/*!

=========================================================
* Paper Dashboard React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState, useCallback } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import Select, { components } from "react-select";
import instanceAxios from "components/reissue/InstanceAxios";
import { useNavigate, useParams } from "react-router-dom";
import { API } from "../../ApiConfig";
import OldFileUpload from "components/RegistForm/OldFileUpload";
import FileUpload from "components/RegistForm/FileUpload";
import ErrAlert from "variables/ErrAlert";
import Swal from "sweetalert2";
import ToastAlert from "variables/ToastAlert";
import { fetchMembers } from "ApiUtils";
import CkEditor from "variables/CkEditor";

/**
 * 지원 등록 페이지
 * @author 김지연, 이준복
 * @returns 지원 등록 폼 리턴
 */

function FollowingSupportModify() {
  //업무 구분 select 옵션

  const [followingtDetail, setfollowingtDetail] = useState([]);
  const [idState, setIdState] = useState([]);

  const [name, setName] = useState("");             // 지원명
  const [caller, setCaller] = useState("");         // 요청자
  const [ask, setAsk] = useState("");               // 요청일
  const [start, setStart] = useState("");           // 시작일
  const [end, setEnd] = useState("");               // 종료일
  const [content, setContent] = useState("");       // 내용
  const [file, setFile] = useState([]);             // 파일
  const [customerId, setCustomerId] = useState([]); // 고객사 id
  const [projectId, setProjectId] = useState([]);   // 프로젝트 id

  const [startTime, setStartTime] = useState("");   // 시작 시간
  const [endTime, setEndTime] = useState("");       // 종료 시간

  const [startDateError, setStartDateError] = useState(false); // 계약 시작일 오류 여부 상태

  const [isDivInitial, setIsDivInitial] = useState(false); // 초기 상태를 추적합니다.
  const [isStateInitial, setIsStateInitial] = useState(false); // 초기 상태를 추적합니다.
  const [isMemberInitial, setIsMemberInitial] = useState(false); // 초기 상태를 추적합니다.
  const [oldFileList, setOldFileList] = useState([]); // be 전달해야할 list<String>
  const [domainFileList, setDomainFileList] = useState([]);

  const [memberName, setMemberName] = useState([]); // 작업자 이름

  // 지원 옵션
  const repairOption = [
    { value: "INQUERY_WATAB", label: "와탭 문의" },
    { value: "INQUERY_CABLE", label: "유선" },
    { value: "VISIT", label: "방문" },
    { value: "MAIL", label: "메일" },
    { value: "VPN", label: "VPN" },
    { value: "RESIDE", label: "상주" },
    { value: "INSTALL", label: "설치"},
    { value: "INSPECTION", label: "검수"},
  ];

  // 작업 상태 옵션
  const stateOption = [
    { value: "WORK_EXPECTED", label: "작업 예정" },
    { value: "WORKING", label: "작업 중" },
    { value: "WORK_COMPLETE", label: "작업 완료" },
  ];

  const [division, setDivision] = useState("");
  const [supportState, setSupportState] = useState("");

  //=====================Member dropdown select======================

  const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
  }) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    //스타일 설정
    let bg = "transparent";
    if (isFocused) bg = "#eee";
    if (isActive) bg = "#B2D4FF";

    const style = {
      alignItems: "center",
      backgroundColor: bg,
      color: "inherit",
      display: "flex",
    };

    //prop
    const props = {
      ...innerProps,
      onMouseDown,
      onMouseUp,
      onMouseLeave,
      style,
    };

    return (
      <components.Option
        {...rest}
        isDisabled={isDisabled}
        isFocused={isFocused}
        isSelected={isSelected}
        getStyles={getStyles}
        innerProps={props}
      >
        <input type="checkbox" checked={isSelected} />
        {children}
      </components.Option>
    );
  };

  /**
   * 다중 선택이 가능한 셀렉트 컴포넌트
   * @returns 담당자 드롭다운 셀렉트 컴포넌트 반환
   */

  //임시 리스트
  const allOptions = [];

  const [selectedWorkers, setSelectedWorkers] = useState([]);  // 선택된 작업자들

  // const handleMemberChange = async (selectedOptions) => {
  //   if (selectedOptions && selectedOptions.length > 0) {
  //     setIsMemberInitial(false);
  //   } else {
  //     setIsMemberInitial(true);
  //   }

  //   if (Array.isArray(selectedOptions)) {
  //     setSelectedWorkers(selectedOptions.map((opt) => opt.value));
  //   }
  // };
  //====================================================================

  const { supportId } = useParams();
  const { domainId } = useParams();

  //======api load============================================================
  const [customer, setCustomer] = useState([]);
  const [project, setProject] = useState([]);
  //=== close of api load =====================================================

  var following = {};

  // 수정시 기존 데이터 불러오기 위한 요청
  const getFollowingDetail = useCallback(async () => {
    try {
      //기존 데이터 불러오기
      await instanceAxios
        .get(`${API.SUPPORTLIST}/${supportId}/following/${domainId}`)
        .then((response) => {
          following = response.data.data;
          setfollowingtDetail(following);

          setIdState(following.id);
          setName(following.name);

          // setSupportState(following.state);
          // setDivision(following.division);

          stateOption?.map((option) => {
            if (option.value === following.state) {
              setSupportState(option);
            }
          });

          repairOption?.map((option) => {
            if (option.value === following.division) {
              setDivision(option);
            }
          });

          setCaller(following.caller);
          setAsk(following.ask || "");

          const sDateTimeString = following.start;
          const [sDate, sTime] = sDateTimeString.split(" "); // 문자열을 공백을 기준으로 나누어서 배열로 만듭니다.
          setStart(sDate);
          setStartTime(sTime);

          const eDateTimeString = following.end; // 예시로 받아온 데이터에서 "start" 키에 해당하는 값을 가져옵니다.
          const [eDate, eTime] = eDateTimeString.split(" "); // 문자열을 공백을 기준으로 나누어서 배열로 만듭니다.
          setEnd(eDate);
          setEndTime(eTime);

          setContent(following.content);
          setCustomerId(following.customerId);
          setProjectId(following.projectId);
          setSelectedWorkers(following.memberWorkersNo);

          setDomainFileList(following.fileResponseDtoList); // ###############################################################
        });

      const [customerResponse, projectResponse, workerResponse] =
        await Promise.all([
          instanceAxios.get(`${API.CUSTOMERLIST}`),
          instanceAxios.get(`${API.PROJECTLIST}`),
          fetchMembers()
        ]);

      var tempJson;

      const customerData = customerResponse.data.data.data;
      const projectData = projectResponse.data.data.data;
      setMemberName(workerResponse);

      // 멤버 초기값 세팅
      if (workerResponse.length > 0) {
        const initialMembers = [];
        following.workers.forEach((workerName) => {
          const initialMember = workerResponse.find(
            (member) => member.label === workerName
          );
          if (initialMember) {
            initialMembers.push(initialMember);
          }
        });
        setSelectedWorkers(initialMembers);
      }

      // 기존 고객사 불러와서 뷰로 만들어주는 작업
      customerData?.map((customer) => {
        if (customer.id === following.customerId) {
          tempJson = {
            label: customer.customer,
            value: customer.id,
          };
          setCustomer(tempJson);
        }
      });

      // 기존 프로젝트 불러와서 뷰로 만들어주는 작업
      projectData?.map((project) => {
        if (project.id === following.projectId) {
          tempJson = {
            label: project.project,
            value: project.id,
          };
          setProject(tempJson);
        }
      });
    } catch (e) {
      ErrAlert({ err: "데이터 불러오기 실패" });
    }
  });

  useEffect(() => {
    getFollowingDetail();
  }, []);

  useEffect(() => {
    try {
      instanceAxios
        .get("/api/member/name", {
          headers: {
            withCredentials: "true",
            "Content-Type": `application/json;charset=UTF-8`,
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        })
        .then((response) => {
          response.data.data.map((member) => {
            allOptions.push(
              JSON.parse(
                `{ "value" : "${member.id}", "label" : "${member.name}"}`
              )
            );
          });
        });
    } catch (e) {
      ErrAlert({ err: "데이터 불러오기 실패" });
    }
  }, [allOptions]);

  const navigate = useNavigate();

  // 종료일이 변경될 때 실행되는 함수
  const handleEndDateChange = (event) => {
    const selectedEndDate = event.target.value;
    setEnd(selectedEndDate);

    // 만약 선택된 종료일이 시작일보다 이전이면 시작일 오류 상태를 설정합니다.
    if (selectedEndDate < start) {
      setStartDateError(true);
    } else {
      setStartDateError(false);
    }
  };

  // 시작일이 변경될 때 실행되는 함수
  const handleStartDateChange = (event) => {
    const selectedStartDate = event.target.value;
    setStart(selectedStartDate);

    // 종료일이 존재하고 선택된 시작일이 종료일보다 이후이면 시작일 오류 상태를 설정합니다.
    if (end && selectedStartDate > end) {
      setStartDateError(true);
    } else {
      setStartDateError(false);
    }
  };

  // 시작 시간이 변경될 때 실행되는 함수
  const handleStartTimeChange = (event) => {
    const selectedStartTime = event.target.value;

    setStartDateError(false);

    // 선택된 시작 시간이 종료 시간보다 이후이고 시작일과 종료일이 같은 경우 시작일 오류 상태를 설정합니다.
    if (selectedStartTime > endTime && start === end) {
      setStartDateError(true);
    }

    setStartTime(selectedStartTime);
  }

  // 종료 시간이 변경될 때 실행되는 함수
  const handleEndTimechange = (event) => {
    const selectEndTime = event.target.value;

    setStartDateError(false);

    // 선택된 종료 시간이 시작 시간보다 이전이고 시작일과 종료일이 같은 경우 시작일 오류 상태를 설정합니다.
    if (startTime > selectEndTime && start === end) {
      setStartDateError(true);
    }

    setEndTime(selectEndTime);
  }

  const modifyFollowing = async (e) => {
    e.preventDefault();

    if (startDateError) {
      Swal.fire({
        icon: "warning",
        title: "주의",
        text: `지원일/종료일을 확인해주세요.`,
        showCancelButton: false,
        confirmButtonText: "확인",
      })
    } else {
      Swal.fire({
        icon: "question",
        title: "수정",
        text: `수정 하시겠습니까?`,
        showCancelButton: true,
        confirmButtonText: "저장",
        cancelButtonText: "취소",
      }).then(async (res) => {
        if (res.isConfirmed) {
          var formData = new FormData();

          let selectedStartTime = startTime || "00:00";
          let selectedEndTime = endTime || "00:00";

          formData.append("name", name);
          formData.append("supportState", supportState.value);
          formData.append("division", division.value);
          formData.append("caller", caller);
          formData.append("ask", ask ? ask : "");
          formData.append("start", start + "T" + selectedStartTime);
          formData.append(
            "end",
            end ? end + "T" + selectedEndTime : start + "T" + selectedEndTime
          );
          formData.append("content", content);
          formData.append("oldFileList", oldFileList);

          for (var i = 0; i < file.length; i++) {
            formData.append("newFileList", file[i]);
          }

          var workersToSubmit = [];
          selectedWorkers.map((worker) => {
            workersToSubmit.push(worker.value);
          });
          formData.append("workers", workersToSubmit);

          try {
            await instanceAxios
              .put(`/api/support/${supportId}/following/${domainId}`, formData, {
                headers: {
                  withCredentials: "true",
                  "Content-Type": `multipart/form-data;charset=UTF-8`,
                  Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                },
              })
              .then((response) => {
                ToastAlert({ icon: "success", title: "수정이 완료되었습니다." });
                navigate(`${API.SUPPORTDETAIL}/${supportId}`);
              });
          } catch (e) {
            ErrAlert({ err: "필수 항목을 확인해주세요" });
          }
        }
      });


    }
  };


  return (
    <div className="content">
      <Card className="card-user">
        <CardHeader>
          <CardTitle tag="h5">지원 수정</CardTitle>
        </CardHeader>
        <CardBody>
          <Form
            onSubmit={modifyFollowing}
            encType="multipart/form-data"
            id="modifySupportForm"
          >
            <Row>
              <Col md="12">
                <FormGroup>
                  <label>지원명</label>
                  <Input
                    type="text"
                    name="name"
                    onBlur={(event) => {
                      setName(event.target.value);
                    }}
                    defaultValue={followingtDetail?.name}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="pr-1" md="4">
                <FormGroup>
                  <label>고객사</label>
                  <Input
                    type="text"
                    name="customerId"
                    value={followingtDetail?.customer}
                    readOnly
                  ></Input>
                </FormGroup>
              </Col>
              <Col className="px-1" md="5">
                <FormGroup>
                  <label>프로젝트</label>
                  <Input
                    type="text"
                    name="projectId"
                    value={followingtDetail?.project}
                    readOnly
                  ></Input>
                </FormGroup>
              </Col>
              <Col className="pl-1" md="3">
                <FormGroup>
                  <label>작업 상태</label>
                  <Select
                    required
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        borderColor: isStateInitial
                          ? "#ff0000"
                          : provided.borderColor,
                        // 선택되었을 때와 선택되지 않았을 때의 테두리색을 지정할 수 있습니다.
                      }),
                    }}
                    placeholder="필수값입니다"
                    name="cProject"
                    options={stateOption}
                    onChange={(selectedOption) => {
                      setSupportState(selectedOption);
                      setIsStateInitial(false);
                    }}
                    value={supportState}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="pr-1" md="3">
                <FormGroup>
                  <label>업무 구분</label>
                  <Select
                    required
                    menuPortalTarget={document.body}
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        borderColor: isDivInitial
                          ? "#ff0000"
                          : provided.borderColor,
                        // 선택되었을 때와 선택되지 않았을 때의 테두리색을 지정할 수 있습니다.
                      }),
                      menuPortal: (base) => ({ ...base, zIndex: 9998 }),
                    }}
                    placeholder="필수값입니다"
                    name="cProject"
                    options={repairOption}
                    onChange={(selectedOption) => {
                      setDivision(selectedOption);
                      setIsDivInitial(false);
                    }}
                    value={division}
                  />
                </FormGroup>
              </Col>
              <Col className="pl-1" md="3">
                <FormGroup>
                  <label>요청자</label>
                  <Input
                    type="text"
                    name="caller"
                    placeholder="직접 입력"
                    onChange={(event) => {
                      setCaller(event.target.value);
                    }}
                    defaultValue={followingtDetail?.caller}
                  ></Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="pr-1" md="4">
                <FormGroup>
                  <label>작업자</label>
                  <div className="MDrop">
                    <Select
                      name="workers"
                      //value={allOptions.find(option => option.value)}
                      isMulti
                      closeMenuOnSelect={false}
                      onChange={(options) => {
                        if (Array.isArray(options)) {
                          setSelectedWorkers(options.map((opt) => opt));
                        }
                      }}
                      // onChange={handleMemberChange}
                      options={memberName} //임시 리스트
                      components={{
                        Option: InputOption,
                      }}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      value={selectedWorkers}
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col className="px-1" md="4">
                <FormGroup>
                  <label>작업 요청일</label>
                  <Input
                    type="datetime-local"
                    name="ask"
                    onChange={(event) => {
                      setAsk(event.target.value);
                    }}
                    defaultValue={followingtDetail.ask}
                  ></Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="pr-1" md="4">
                <FormGroup>
                  <label>지원(예정)일</label>
                  <Input
                    required
                    className={start ? "is-valid" : "is-invalid"}
                    type="date"
                    name="start"
                    value={start}
                    onChange={handleStartDateChange}
                  />
                  {!start && (
                    <span style={{ color: "red" }}>필수로 입력해주세요.</span>
                  )}
                </FormGroup>
              </Col>
              <Col className="px-1" md="2">
                <FormGroup>
                  <label>지원(예정)시간</label>
                  <Input
                    type="time"
                    name="startTime"
                    value={startTime}
                    onChange={handleStartTimeChange}
                  />
                </FormGroup>
              </Col>
              <Col className="px-1" md="4">
                <FormGroup>
                  <label>종료일</label>
                  <Input
                    type="date"
                    name="end"
                    value={end}
                    onChange={handleEndDateChange}
                  />
                  {startDateError && (
                    <span style={{ color: "red" }}>
                      지원 종료일은 지원 예정일 이후여야 합니다.
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col className="pl-1" md="2">
                <FormGroup>
                  <label>지원 종료 시간</label>
                  <Input
                    type="time"
                    name="endTime"
                    value={endTime}
                    onChange={handleEndTimechange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="pr-1" md="12">
                <FormGroup>
                  <label>지원 항목</label>
                  {/* <Input
                    className="textArea"
                    type="textarea"
                    name="content"
                    onChange={(event) => {
                      setContent(event.target.value);
                    }}
                    defaultValue={followingtDetail.content}
                  /> */}
                  <CkEditor onChange={setContent} data={followingtDetail.content} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label>기존 첨부 파일</label>
                  <Col me="12">
                    <OldFileUpload //##################################################################################################
                      domainFileList={domainFileList}
                      setDomainFileList={setDomainFileList}
                      setOldFileList={setOldFileList}
                    />
                  </Col>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label>신규 첨부 파일</label>
                  <FileUpload file={file} setFile={setFile} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <div className="update ml-auto mr-auto">
                <Button
                  outline
                  color="danger"
                  type="cancel"
                  onClick={() => navigate(`${API.SUPPORTDETAIL}/${supportId}`)}
                >
                  취소
                </Button>
                <Button
                  outline
                  color="primary"
                  type="submit"
                >
                  수정
                </Button>
              </div>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
}
export default FollowingSupportModify;
