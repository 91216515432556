import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Spinner,
  Label,
} from "reactstrap";

import instanceAxios from "components/reissue/InstanceAxios";
import { useNavigate, useParams } from "react-router-dom";
import { API } from "ApiConfig";
import FileUpload from "components/RegistForm/FileUpload";
import OldFileUpload from "components/RegistForm/OldFileUpload";
import DeleteButton from "variables/DeleteButton";
import Swal from "sweetalert2";
import ToastAlert from "variables/ToastAlert";
import ErrAlert from "variables/ErrAlert";
import CkEditor from "variables/CkEditor";

/**
 * 공지사항 수정 페이지
 * @author 김지연
 * @returns 공지사항 수정 폼 리턴
 */

function NoticeModify() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true); // 로딩 상태 추가

  const [title, setTitle] = useState(""); // 공지사항 제목
  const [content, setContent] = useState(""); // 공지사항 본문
  const [hide, setHide] = useState(""); // 공지사함 숨김 여부

  const [file, setFile] = useState([]);
  const [Data, setData] = useState([]);

  //== 수정 시 파일 업로드 위한 state 추가 ==
  const [oldFileList, setOldFileList] = useState([]);
  const [domainFileList, setDomainFileList] = useState([]);
  const memRole = localStorage.getItem("memRole");

  useEffect(() => {
    if (memRole === "USER") {
      ErrAlert({ err: "접근할 수 없는 권한입니다." });
      navigate(`/${API.NOTICE}`);
    }
    const fetchData = async () => {
      // const data = await fetchProjectData();
      try {
        const mainData = await instanceAxios.get(`${API.NOTICELIST}/${id}`);
        const data = mainData.data?.data;

        setHide(data.hide);
        setTitle(data.title);
        setContent(data.content);
        setDomainFileList(mainData.data.data.fileResponseDtoList);

        setData(data);
      } catch (error) {
        ErrAlert({ err: "데이터 불러오기 실패" });
      } finally {
        // 로딩 상태 업데이트
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  // const handleCheck = () => {
  //     setHide(is)
  // }

  // form submit시 FormData 내용
  const handleSubmit = async (e) => {
    e.preventDefault();
    Swal.fire({
      icon: "question",
      title: "수정",
      text: `수정 하시겠습니까?`,
      showCancelButton: true,
      confirmButtonText: "저장",
      cancelButtonText: "취소",
    }).then(async (res) => {
      if (res.isConfirmed) {
        const formData = new FormData();
        formData.append("title", title);
        formData.append("content", content);
        formData.append("hide", hide);
        formData.append("oldFileList", oldFileList);
        //파일 및 멤버 처리
        for (var i = 0; i < file.length; i++) {
          formData.append("newFileList", file[i]);
        }

        try {
          const response = await instanceAxios.put(
            `${API.NOTICELIST}/${id}`,
            formData,
            {
              headers: {
                "Content-Type": `multipart/form-data;charset=UTF-8`,
              },
            }
          );
          ToastAlert({ icon: "success", title: "수정이 완료되었습니다." });
          navigate(`${API.NOTICEDETAIL}/${id}`);
        } catch (error) {
          ErrAlert({ err: "필수 항목을 확인해주세요" });
        }
      } else {
        //취소
      }
    });
  };

  const navigate = useNavigate();
  return (
    <div className="content">
      <div className="detail-wrap">
        <Card className="card-user">
          <CardHeader>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <CardTitle tag="h5">공지사항 수정</CardTitle>
              <FormGroup>
                <Input
                  type="checkbox"
                  checked={hide}
                  onChange={(event) => {
                    setHide(!hide);
                  }}
                />
                <Label>숨김 여부</Label>
              </FormGroup>
            </div>
          </CardHeader>
          <CardBody>
            {loading ? (
              <Spinner color="primary" />
            ) : (
              <>
                <Form encType="multipart/form-data" onSubmit={handleSubmit}>
                  <FormGroup>
                    <Label>제목</Label>
                    <Input
                      type="text"
                      name="title"
                      onChange={(event) => {
                        setTitle(event.target.value);
                      }}
                      defaultValue={Data.title}
                    />
                  </FormGroup>
                  <Row style={{ paddingLeft: "0.2em" }}>
                    <Col md="6">
                      <FormGroup>
                        <label>기존 첨부 파일</label>
                        <OldFileUpload
                          domainFileList={domainFileList}
                          setDomainFileList={setDomainFileList}
                          setOldFileList={setOldFileList}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label>신규 첨부 파일</label>
                        <FileUpload file={file} setFile={setFile} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>본문</label>
                        {/* <Input
                          type="textarea"
                          className="textArea"
                          name="content"
                          onChange={(event) => {
                            setContent(event.target.value);
                          }}
                          defaultValue={Data.content}
                        /> */}
                        <CkEditor onChange={setContent} data={Data.content} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <DeleteButton
                      to={`${API.NOTICEDETAIL}/${id}`}
                      text1="수정을 취소하시겠습니까?"
                    />
                  </Row>
                </Form>
              </>
            )}
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
export default NoticeModify;
