/*!

=========================================================
* Paper Dashboard React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
import Radio from "./ConComponents/Radio";
import RadioGroup from "./ConComponents/RadioGroup";
import ContractCommon from "./ConComponents/ContractCommon";
import ContractMaintain from "./ConComponents/ContractMaintain";

// reactstrap components
import { Row, Col } from "reactstrap";

/**
 * 계약 등록 페이지
 * @author 김지연
 * @returns 일반/유지보수 계약 등록 폼 리턴
 * 라디오 버튼을 통해 일반/유지보수 계약 등록 폼 변경 가능
 * 기본값 : 일반(설치) 계약
 */

function ContractRegist() {
  const [content, setContent] = useState("0");

  const handleClickButton = (event) => {
    setContent(event.target.value);
  };

  return (
    <>
      <div className="content">
        <div className="regist-wrap">
          <RadioGroup>
            <Radio
              name="regist"
              value="0"
              defaultChecked
              onChange={handleClickButton}
            >
              일반(설치)
            </Radio>
            <Radio name="regist" value="1" onChange={handleClickButton}>
              유지보수
            </Radio>
          </RadioGroup>
          <Row>
            <Col md="12">
              <div>
                {content === "0" ? <ContractCommon /> : <ContractMaintain />}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default ContractRegist;
