import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Spinner,
} from "reactstrap";

import { API } from "ApiConfig";
import { useNavigate, useParams } from "react-router-dom";
import FormJson from "components/RegistForm/FormJson";
import React, { useState, useEffect } from "react";
import { fetchCustomers, fetchProjectsByCustomerName } from "ApiUtils";
import Select from "react-select";
import instanceAxios from "components/reissue/InstanceAxios";
import FileUpload from "components/RegistForm/FileUpload";
import OldFileUpload from "components/RegistForm/OldFileUpload";
import DeleteButton from "variables/DeleteButton";
import Swal from "sweetalert2";
import ToastAlert from "variables/ToastAlert";
import ErrAlert from "variables/ErrAlert";

/**
 * 라이선스 수정 페이지
 * @author 김지연, 김예함(기능, css)
 * @returns 라이선스 수정 폼 리턴
 */
function LicenseModify() {
  const { id } = useParams(); // id 파라미터
  const [loading, setLoading] = useState(true); // 로딩 상태 추가

  //라이선스 구분 옵션
  const divOptions = [
    { value: "SUBSCRIBE", label: "구독형" },
    { value: "PERMANENT", label: "영구형" },
    { value: "POC", label: "POC" },
    { value: "TEMPORARY", label: "임시" },
  ];

  const [license, setLicense] = useState(""); // 라이선스 코드
  const [customer, setCustomer] = useState(""); // 고객사
  const [project, setProject] = useState(""); // 프로젝트
  const [issueDate, setIssueDate] = useState(""); // 발급 일자
  const [startDate, setStartDate] = useState(""); // 발주 일자
  const [licenseDivision, setLicenseDivision] = useState(""); // 라이선스 구분
  const [expiredDate, setExpiredDate] = useState(""); // 만료 일자
  const [ipInfo, setIpInfo] = useState(""); // IP 정보
  const [licenseKey, setLicenseKey] = useState(""); // 라이선스 key
  const [content, setContent] = useState(""); // 비고
  const [file, setFile] = useState([]); // 첨부파일

  const [isCusInitial, setIsCusInitial] = useState(false); // 초기 상태를 추적합니다.
  const [isPrjInitial, setIsPrjInitial] = useState(false); // 초기 상태를 추적합니다.
  const [isDivInitial, setIsDivInitial] = useState(false);

  // FormJson 컴포넌트에서 입력한 내용을 상태로 관리
  const [dynamicFieldsData, setDynamicFieldsData] = useState([]); //라이선스 정보
  const [startDateError, setStartDateError] = useState(false); // 계약 시작일 오류 여부 상태

  //========== api load
  const [customerName, setCustomerName] = useState([]); // 고객사명 리스트
  const [projectName, setProjectName] = useState([]); // 프로젝트명 리스트
  const [Data, setData] = useState([]);

  // == 파일 업로드 수정 ==
  const [oldFileList, setOldFileList] = useState([]);
  const [domainFileList, setDomainFileList] = useState([]);

  //라이선스 정보 조회 및 초기화
  useEffect(() => {
    /**
     * id값에 맞는 라이선스정보 조회
     */
    const mainData = async () => {
      try {
        const response = await instanceAxios.get(`${API.LICENSELIST}/${id}`);
        const data = response.data?.data || [];
        setData(data);

        // 구분 초기값
        const initialDiv = divOptions.find(
          (option) => option.value === data?.licenseDivision
        );
        setLicenseDivision(initialDiv);
        // 아무것도 수정 안할 시 데이터를 등록
        setLicense(data.license);
        setIssueDate(data.issueDate);
        setStartDate(data.startDate);
        setExpiredDate(data.expiredDate);
        setIpInfo(data.ipInfo);
        setLicenseKey(data.licenseKey);
        setContent(data.content);

        setDomainFileList(data.fileResponseDtoList);

        setDynamicFieldsData(data.itemList);
        return data; // 데이터 반환
      } catch (error) {
        // 에러 처리
        ErrAlert({ err: "데이터 불러오기 실패" });
      }
    };

    //고객사 정보 및 프로젝트 정보 불러오기
    const fetchData = async () => {
      const data = await mainData();
      try {
        const customers = await fetchCustomers();
        customers.splice(0, 2);
        setCustomerName(customers);

        // 고객사 초기값
        const initialCustomer = customers.find(
          (option) => option.label === data?.customer
        );
        setCustomer(initialCustomer);

        // 고객사 초기값 설정 후 프로젝트 초기설정
        if (initialCustomer) {
          const projects = await fetchProjectsByCustomerName(
            initialCustomer.value
          );
          setProjectName(
            projects.map((project) => ({
              value: project.id,
              label: project.project,
            }))
          );

          // 프로젝트 초기값
          const initialProject = projects.find(
            (option) => option.label === data?.projects
          );

          setProject({
            value: initialProject.id,
            label: initialProject.project,
          });
        }
      } catch (error) {
        ErrAlert({ err: "데이터 불러오기 실패" });
      } finally {
        // 로딩 상태 업데이트
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  //고객사 선택시 맞는 프로젝트 불러오기
  const handleCustomerChange = async (selectedOption) => {
    const newCustomerId = selectedOption.value;
    setCustomer(selectedOption);
    setProjectName([]);
    setIsPrjInitial(true);
    try {
      if (newCustomerId) {
        const fetchData = async () => {
          const projects = await fetchProjectsByCustomerName(newCustomerId);

          setProjectName(
            projects.map((project) => ({
              value: project.id,
              label: project.project,
            }))
          );
        };

        fetchData();
      }
    } catch (error) {
      ErrAlert({ err: "데이터 불러오기 실패" });
    }
    setIsCusInitial(false);
    setProject("");
  };

  // 동적 필드 데이터 변경 시 상태 업데이트
  const handleDynamicFieldsChange = (data) => {
    setDynamicFieldsData(data);
  };

  // 삭제된 필드의 인덱스를 받아와 해당 필드를 제거하는 함수
  const handleRemoveField = (indexToRemove) => {
    setDynamicFieldsData((prevData) => {
      // 인덱스를 기준으로 필드를 제거한 새로운 배열 생성
      const newData = prevData.filter((_, index) => index !== indexToRemove);
      return newData;
    });
  };

  // 계약 시작일 변경 시 실행되는 함수
  const handleStartDateChange = (event) => {
    const selectedStartDate = event.target.value;
    setStartDate(selectedStartDate);

    // 종료일이 설정되어 있고 시작일이 종료일보다 이후인 경우 오류 설정
    if (expiredDate && selectedStartDate > expiredDate) {
      setStartDateError(true);
    } else {
      setStartDateError(false);
    }
  };

  // 계약 종료일 변경 시 실행되는 함수
  const handleEndDateChange = (event) => {
    const selectedEndDate = event.target.value;
    setExpiredDate(selectedEndDate);

    // 시작일이 설정되어 있고 종료일이 시작일보다 이전인 경우 오류 설정
    if (selectedEndDate < startDate) {
      setStartDateError(true);
    } else {
      setStartDateError(false);
    }
  };

  // ============== form submit ==============

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (startDateError) {
      Swal.fire({
        icon: "warning",
        title: "주의",
        text: `시작일/만료일을 확인해주세요.`,
        showCancelButton: false,
        confirmButtonText: "확인",
      })
    } else {
      Swal.fire({
        icon: "question",
        title: "수정",
        text: `수정 하시겠습니까?`,
        showCancelButton: true,
        confirmButtonText: "저장",
        cancelButtonText: "취소",
      }).then(async (res) => {
        if (res.isConfirmed) {
          const formData = new FormData();

          formData.append("license", license); //필수값
          formData.append("customer", customer.value); //필수값
          formData.append("project", project.value);
          formData.append("issueDate", issueDate || "");
          formData.append("startDate", startDate);
          formData.append("licenseDivision", licenseDivision.value);
          formData.append("expiredDate", expiredDate);
          formData.append("ipInfo", ipInfo);
          formData.append("licenseKey", licenseKey);
          formData.append("content", content);
          formData.append("oldFileList", oldFileList);
          formData.append("useWhether", getUseWhetherValue());

          dynamicFieldsData?.forEach((info, index) => {
            formData.append(
              `itemList[${index}].majorCategory`,
              info.majorCategory
            );
            formData.append(
              `itemList[${index}].middleCategory`,
              info.middleCategory
            );
            formData.append(
              `itemList[${index}].smallCategory`,
              info.smallCategory
            );
            formData.append(`itemList[${index}].itemDetail`, info.itemDetail);
          });

          // 파일 등록
          for (var i = 0; i < file.length; i++) {
            formData.append("newFileList", file[i]);
          }

          //통신
          try {
            const response = await instanceAxios.put(
              `${API.LICENSELIST}/${id}`,
              formData,
              {
                headers: {
                  "Content-Type": "application/form-data;charset=UTF-8",
                },
              }
            );
            ToastAlert({ icon: "success", title: "수정이 완료되었습니다." });
            navigate(`${API.LICENSEDETAIL}/${id}`);
          } catch (error) {
            ErrAlert({ err: "필수 항목을 확인해주세요" });
          }
        } else {
          //취소
        }
      });
    }
  };

  const [useWhetherText, setUseWhetherText] = useState("");

  useEffect(() => {
    // license.useWhether가 true면 "Y", false면 "N"으로 설정
    setUseWhetherText(Data.useWhether ? "Y" : "N");
  }, [Data.useWhether]);

  const toggleUseWhether = () => {
    // 사용여부 텍스트를 토글하여 업데이트
    setUseWhetherText((prevText) => (prevText === "Y" ? "N" : "Y"));
  };

  const getUseWhetherValue = () => {
    // 사용여부 텍스트에 따라 boolean 값을 반환
    return useWhetherText === "Y";
  };

  {
    /*useEffect(()=>{},[file,oldFileList]);*/
  }

  // ========== 수정 폼 ===================
  const navigate = useNavigate();

  return (
    <div className="content">
      <div className="detail-wrap">
        <Card className="card-user">
          <CardHeader>
            <CardTitle tag="h5">라이선스 수정</CardTitle>
          </CardHeader>
          <CardBody>
            {loading ? (
              <Spinner color="primary" />
            ) : (
              <>
                <Form encType="multipart/form-data" onSubmit={handleSubmit}>
                  <Row>
                    <Col md="9">
                      <FormGroup>
                        <label>라이선스명</label>
                        <Input
                          required
                          placeholder="필수로 입력해주세요."
                          className="required_placeholder"
                          type="text"
                          name="license"
                          onChange={(event) => {
                            setLicense(event.target.value);
                          }}
                          defaultValue={Data.license}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <div style={{ display: "flex" }}>
                        <FormGroup>
                          <label>사용여부</label>
                          <Input
                            type="text"
                            name="useState"
                            value={useWhetherText}
                            onChange={(event) => {
                              setLicense(event.target.value);
                            }}
                            defaultValue={useWhetherText}
                            readOnly
                          />
                        </FormGroup>
                        {/* <MaterialIcon icon="published_with_changes" size="24" onClick={toggleUseWhether}/> */}
                        <Button
                          color="primary"
                          size="sm"
                          type="button"
                          onClick={toggleUseWhether}
                        >
                          변경
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <label>고객사</label>
                        <Select
                          required
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              borderColor: isCusInitial
                                ? "#ff0000"
                                : provided.borderColor,
                              // 선택되었을 때와 선택되지 않았을 때의 테두리색을 지정할 수 있습니다.
                            }),
                          }}
                          placeholder="필수값입니다"
                          options={customerName}
                          onChange={handleCustomerChange}
                          value={customer}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label>프로젝트</label>
                        <Select
                          name="cProject"
                          required
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              borderColor: isPrjInitial
                                ? "#ff0000"
                                : provided.borderColor,
                              // 선택되었을 때와 선택되지 않았을 때의 테두리색을 지정할 수 있습니다.
                            }),
                          }}
                          placeholder="필수값입니다"
                          options={projectName}
                          onChange={(selectedOption) => {
                            setProject(selectedOption);
                            setIsPrjInitial(false);
                          }}
                          value={project}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <label>발급 일자</label>
                        <Input
                          type="date"
                          name="issueDate"
                          onChange={(selectedOption) => {
                            setIssueDate(selectedOption.target.value);
                          }}
                          defaultValue={issueDate}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label>시작 일자</label>
                        <Input
                          required
                          className={startDate ? "is-valid" : "is-invalid"}
                          type="date"
                          name="startDate"
                          onChange={handleStartDateChange}
                          defaultValue={startDate}
                        />
                        {!startDate && (
                          <span style={{ color: "red" }}>필수로 입력해주세요.</span>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <label>만료 일자</label>
                        <Input
                          required
                          className={expiredDate ? "is-valid" : "is-invalid"}
                          type="date"
                          name="expiredDate"
                          onChange={handleEndDateChange}
                          defaultValue={expiredDate}
                        />
                        {!expiredDate && (
                          <span style={{ color: "red" }}>필수로 입력해주세요.</span>
                        )}
                        {startDateError && (
                          <span style={{ color: "red" }}>
                            만료 일자는 시작 일자 이후여야 합니다.
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label>구분</label>
                        <Select
                          name="licenseDivision"
                          required
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              borderColor: isDivInitial
                                ? "#ff0000"
                                : provided.borderColor,
                              // 선택되었을 때와 선택되지 않았을 때의 테두리색을 지정할 수 있습니다.
                            }),
                          }}
                          placeholder="필수값입니다"
                          options={divOptions}
                          onChange={(event) => {
                            setLicenseDivision(event);
                            setIsDivInitial(false);
                          }}
                          value={licenseDivision}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>IP</label>
                        <Input
                          type="text"
                          name="ipInfo"
                          onChange={(selectedOption) => {
                            setIpInfo(selectedOption.target.value);
                          }}
                          defaultValue={Data.ipInfo}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <CardHeader>
                      <CardTitle tag="h5">라이선스 정보</CardTitle>
                    </CardHeader>
                  </Row>
                  <Row>
                    <Col className="pr-1">
                      {/* 유동적 범위 추가 */}
                      <FormGroup>
                        <FormJson
                          onChange={handleDynamicFieldsChange}
                          data={dynamicFieldsData}
                          onRemoveField={handleRemoveField}
                          isEditMode={true}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>라이선스 Key</label>
                        <Input
                          type="text"
                          name="licenseKey"
                          onChange={(selectedOption) => {
                            setLicenseKey(selectedOption.target.value);
                          }}
                          defaultValue={Data.licenseKey}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{ paddingLeft: "0.2em" }}>
                    <Col md="6">
                      <FormGroup>
                        <label>기존 첨부 파일</label>
                        <OldFileUpload
                          domainFileList={domainFileList}
                          setDomainFileList={setDomainFileList}
                          setOldFileList={setOldFileList}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label>신규 첨부 파일</label>
                        <FileUpload file={file} setFile={setFile} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>비고</label>
                        <Input
                          type="textarea"
                          name="content"
                          onChange={(selectedOption) => {
                            setContent(selectedOption.target.value);
                          }}
                          defaultValue={Data.content}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <DeleteButton
                      to={`${API.LICENSEDETAIL}/${id}`}
                      text1="수정을 취소하시겠습니까?"
                    />
                  </Row>
                </Form>
              </>
            )}
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default LicenseModify;
