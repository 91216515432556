import React, { useEffect, useState } from "react";
import "../../variables/RelationList";
import {
  Card,
  CardHeader,
  CardBody,
  Form,
  Row,
  Col,
  Spinner,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { useParams } from "react-router-dom";
import { API } from "../../ApiConfig";
import instanceAxios from "components/reissue/InstanceAxios";
import DetailButton from "variables/DetailButton";
import FileDownView from "views/Support/FileDownView";
import ErrAlert from "variables/ErrAlert";
import CkEditor from "variables/CkEditor";

const titleName = "유지보수";

/**
 *
 * @returns 유지보수 리스트에서 클릭한 id값에 맞는 단일 게시물 상세보기 페이지를 리턴
 */
function MaintainDetail() {
  // 수정, 정보보기에 사용되는 클릭한 프로젝트의 id값
  const { id } = useParams();

  /**
   * 유지보수 데이터를 불러와 사용하기 편하도록 변환하는 함수
   */
  async function fetchData() {
    try {
      const response = await instanceAxios.get(`${API.MAINTAINLIST}/${id}`);
      const data = response.data?.data || [];
      setMaintain(data);
    } catch (error) {
      // 에러 처리
      ErrAlert({ err: "데이터 불러오기 실패" });
    } finally {
      // 로딩 상태 업데이트
      setLoading(false);
    }
  }
  const [maintain, setMaintain] = useState([]);
  const [loading, setLoading] = useState(true); // 로딩 상태 추가
  useEffect(() => {
    // 페이지 로딩 시 프로젝트 정보 가져오기
    fetchData();
  }, [id]); // ID가 변경될 때마다 useEffect 실행

  // 작업상태 값 변경 ex) WORKING -> 작업 진행중
  const [workStates, setWorkStates] = useState([]);
  useEffect(() => {
    // maintainData가 존재하고, maintainData가 배열일 경우에만 작업 상태 값을 설정
    const updatedWorkStates = () => {
      if (maintain.state === "WORKING") {
        return "작업 진행중";
      } else if (maintain.state === "WORK_EXPECTED") {
        return "작업 예정";
      } else if (maintain.state === "WORK_COMPLETE") {
        return "작업 완료";
      } else {
        return "-";
      }
    };
    setWorkStates(updatedWorkStates);
  }, [maintain]);

  return (
    <>
      <div className="content">
        <Form>
          <Row>
            <Col md="12">
              <div className="detail-wrap">
                <Card className="card-user">
                  <CardHeader>
                    <h5 className="title">{titleName} 상세보기</h5>
                    <p className="category" style={{ fontSize: "17px" }}></p>
                  </CardHeader>
                  <CardBody>
                    {loading ? (
                      <Spinner color="primary" />
                    ) : (
                      <>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label>고객사</Label>
                              <Input
                                className="detail-input"
                                value={maintain.customer || "-"}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label>작업 상태</Label>
                              <Input
                                className="detail-input"
                                value={workStates || "-"}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <FormGroup>
                          <Label>계약명(유지보수)</Label>
                          <Input
                            className="detail-input"
                            value={maintain.contract || "-"}
                            disabled
                          />
                        </FormGroup>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label>지원 형태</Label>
                              <Input
                                className="detail-input"
                                value={maintain.division || "-"}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>계약 기간</Label>
                              <Input
                                className="detail-input"
                                value={maintain.contractPeriod || "-"}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label>고객사 담당자</Label>
                              <Input
                                className="detail-input"
                                value={maintain.supervisor || "-"}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>작업자</Label>
                              <Input
                                className="detail-input"
                                value={
                                  maintain.workers &&
                                    maintain.workers.length > 0
                                    ? maintain.workers.join(", ")
                                    : "-"
                                }
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label>지원(예정)일</Label>
                              <Input
                                className="detail-input"
                                value={maintain.start || "-"}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>지원 종료일</Label>
                              <Input
                                className="detail-input"
                                value={maintain.end || "-"}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <FormGroup>
                          <Label>첨부파일</Label>
                          <FileDownView domain="REPAIR" domainId={id} />
                        </FormGroup>
                        <FormGroup>
                          <Label>유지보수 항목</Label>
                          {/* <Input
                            type="textarea"
                            disabled
                            className="detail-input textArea"
                            defaultValue={maintain.content || ""}
                          /> */}
                          <CkEditor data={maintain.content || ""} disabled={true} />
                        </FormGroup>
                        <FormGroup>
                          <Label>참조 사항</Label>
                          <Input
                            className="detail-input"
                            value={maintain.ref || ""}
                            disabled
                            type="textarea"
                          />
                        </FormGroup>
                        <DetailButton domainId={id} domain="MAINTAIN" deleteTf={maintain.deleteTAndF} />
                      </>
                    )}
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}

export default MaintainDetail;
