/*!

=========================================================
* Paper Dashboard React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Nav } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

import logo from "../../assets/img/logo.png";
import logoName from "../../assets/img/logo-name.png";
import { API } from "../../ApiConfig";
import "../../assets/css/Custom.css";

var ps;

function Sidebar(props) {
  const location = useLocation(); // 현재 경로 정보
  const sidebar = React.useRef(); // 사이드바 참조
  // 활성 경로 여부를 확인합니다.
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  React.useEffect(() => {
    // Windows 플랫폼인 경우에만 스크롤바를 생성합니다.
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return function cleanup() {
      // 컴포넌트 정리 시에 스크롤바를 제거합니다.
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  });

  return (
    <div
      className="sidebar"
      data-color={props.bgColor}
      data-active-color={props.activeColor}
    >
      <div className="logo">
        <a href={API.MAIN} className="simple-text logo-mini">
          <div className="logo-img">
            <img src={logo} alt="logo" style={{ marginTop: "10px" }} />
          </div>
        </a>
        <a href={API.MAIN} className="simple-text logo-normal">
          <img src={logoName} alt="logo-name" style={{ height: "40px" }} />
        </a>
      </div>

      <div className="sidebar-wrapper" ref={sidebar}>
        {/* 사이드바 메뉴 항목들을 반복하여 출력합니다. */}
        <Nav>
          {props.routes.map((prop, key) => {
            // 특정 경로들을 제외하고 메뉴를 생성합니다.
            if (
              prop.path !== `${API.PROJECTDETAIL}/:id` &&
              prop.path !== `${API.CUSTOMERDETAIL}/:id` &&
              prop.path !== `${API.SUPERVISORDETAIL}/:id` &&
              prop.path !== `${API.SUPPORTDETAIL}/:id` &&
              prop.path !== `${API.MAINTAINDETAIL}/:id` &&
              prop.path !== `${API.LICENSEDETAIL}/:id` &&
              prop.path !== `${API.CONTRACTDETAIL1}/:id` &&
              prop.path !== `${API.CONTRACTDETAIL2}/:id` &&
              prop.path !== `${API.LICENSEREGIST}` &&
              prop.path !== `${API.CUSTOMERREGIST}` &&
              prop.path !== `${API.MAINTAINREGIST}` &&
              prop.path !== `${API.PROJECTREGIST}` &&
              prop.path !== `${API.SUPPORTREGIST}` &&
              prop.path !== `${API.CONTRACTREGIST}` &&
              prop.path !== `${API.SUPERVISORREGIST}` &&
              prop.path !== `${API.CONTRACTMODIFY1}/:id` &&
              prop.path !== `${API.CONTRACTMODIFY2}/:id` &&
              prop.path !== `${API.CUSTOMERMODIFY}/:id` &&
              prop.path !== `${API.LICENSEMODIFY}/:id` &&
              prop.path !== `${API.MAINTAINMODIFY}/:id` &&
              prop.path !== `${API.PROJECTMODIFY}/:id` &&
              prop.path !== `${API.SUPERVISORMODIFY}/:id` &&
              prop.path !== `${API.SUPPORTMODIFY}/:id` &&
              prop.path !== `${API.LOGIN}` &&
              prop.path !== `${API.FOLLOWINGREGIST}` &&
              prop.path !== `${API.FOLLOWINGMODIFY}/:id` &&
              prop.path !== `${API.FOLLOWINGMODIFY}/:supportId/:domainId` &&
              prop.path !== `${API.MYPAGE}` &&
              prop.path !== `${API.MEMBERDETAIL}/:id` &&
              prop.path !== `${API.MEMBERMODIFY}/:id` &&
              prop.path !== `${API.NOTICEREGIST}` &&
              prop.path !== `${API.NOTICEDETAIL}/:id` &&
              prop.path !== `${API.NOTICEMODIFY}/:id` &&
              prop.path !== `${API.ERRORPAGE}` &&
              prop.path !== `*`
            ) {
              return (
                <li
                  className={
                    activeRoute(prop.path) + (prop.pro ? " active-pro" : "")
                  }
                  key={key}
                >
                  <NavLink to={prop.layout + prop.path} className="nav-NavLink">
                    <i className={prop.icon} />
                    <p>{prop.name}</p>
                  </NavLink>
                </li>
              );
            }
            return null;
          })}
        </Nav>
      </div>
    </div>
  );
}

export default Sidebar;
