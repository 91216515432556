/*!

=========================================================
* Paper Dashboard React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
} from "reactstrap";

import Select, { components } from "react-select";
import instanceAxios from "components/reissue/InstanceAxios";
import { useNavigate } from "react-router-dom";
import { API } from "../../ApiConfig";
import FileUpload from "components/RegistForm/FileUpload";
import Swal from "sweetalert2";
import DeleteButton from "variables/DeleteButton";
import { fetchCustomers } from "ApiUtils";
import { fetchProjectsByCustomerName } from "ApiUtils";
import ToastAlert from "variables/ToastAlert";
import ErrAlert from "variables/ErrAlert";
import CkEditor from "variables/CkEditor";

/**
 * 지원 등록 페이지
 * @author 김지연, 이준복
 * @returns 지원 등록 폼 리턴
 */

function SupportRegist() {
  //업무 구분 select 옵션
  const [Option, setOption] = useState();

  const [name, setName] = useState("");         // 지원명
  const [caller, setCaller] = useState("");     // 요청자
  const [ask, setAsk] = useState("");           // 요청일
  const [start, setStart] = useState("");       // 시작일
  const [end, setEnd] = useState("");           // 종료일
  const [content, setContent] = useState("");   // 지원 내용
  const [file, setFile] = useState([]);         // 파일
  const [customer, setCustomer] = useState([]); // 고객사
  const [project, setProject] = useState([]);   // 프로젝트

  const [showMemberList, setShowMemeberList] = useState([]); // 멤버리스트 출력용

  const [isCusInitial, setIsCusInitial] = useState(true); // 초기 상태를 추적합니다.
  const [isPrjInitial, setIsPrjInitial] = useState(true); // 초기 상태를 추적합니다.
  const [isDivInitial, setIsDivInitial] = useState(true); // 초기 상태를 추적합니다.
  const [isStateInitial, setIsStateInitial] = useState(true); // 초기 상태를 추적합니다.
  const [isMemberInitial, setIsMemberInitial] = useState(true); // 초기 상태를 추적합니다.

  const [startDateError, setStartDateError] = useState(false); // 시작일 오류 여부 상태

  const [startTime, setStartTime] = useState("");   // 시작 시간
  const [endTime, setEndTime] = useState("");       // 종료 시간

  const [isStartDateSelected, setIsStartDateSelected] = useState(false); // start Date select 여부
  //=====================Member dropdown select======================

  const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
  }) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    //스타일 설정
    let bg = "transparent";
    if (isFocused) bg = "#eee";
    if (isActive) bg = "#B2D4FF";

    const style = {
      alignItems: "center",
      backgroundColor: bg,
      color: "inherit",
      display: "flex",
    };

    //prop
    const props = {
      ...innerProps,
      onMouseDown,
      onMouseUp,
      onMouseLeave,
      style,
    };

    return (
      <components.Option
        {...rest}
        isDisabled={isDisabled}
        isFocused={isFocused}
        isSelected={isSelected}
        getStyles={getStyles}
        innerProps={props}
      >
        <input type="checkbox" checked={isSelected} />
        {children}
      </components.Option>
    );
  };

  /**
   * 다중 선택이 가능한 셀렉트 컴포넌트
   * @returns 담당자 드롭다운 셀렉트 컴포넌트 반환
   */

  //임시 리스트
  const allOptions = [];

  const [selectedWorkers, setSelectedWorkers] = useState([]);

  const handleMemberChange = async (selectedOptions) => {
    if (selectedOptions && selectedOptions.length > 0) {
      setIsMemberInitial(false);
    } else {
      setIsMemberInitial(true);
    }

    if (Array.isArray(selectedOptions)) {
      setSelectedWorkers(selectedOptions.map((opt) => opt.value));
    }
  };

  // // 병렬 처리 중
  // const fetchData =  async () => {
  //     const [memberResponse, customers, supervisorRespons, projectResponse] =
  //     await Promise.all([
  //       instanceAxios.get(`${API.MEMBERNAME}`),
  //       [fetchCustomers()],
  //       instanceAxios.get(`${API.PROJECTLIST}`),
  //       instanceAxios.get(`${API.PROJECTLIST}`),
  //     ]);

  //     memberResponse.data.data.amp((member) => {
  //       allOptions.push(
  //         JSON.parse(
  //           `{ "value" : "${member.id}", "label" : "${member.name}" }`
  //         )
  //       );
  //     })
  //     setShowMemeberList(allOptions);

  //     setCustomerName(customers);
  // }

  //====================================================================
  // 맨 처음에 멤버 정보 미리 불러오는 useEffect
  // 맨 처음에 고객사 정보 미리 불러오는 useEffect
  // 맨 처음에 프로젝트 정보 미리 불러오는 useEffect
  useEffect(() => {
    try {
      /**
       * 최초 랜더링 시 작업 담당자에 필요한 멤버 정보 미리 불러오기
       * @returns
       */
      instanceAxios.get(`${API.SUPERVISORNAME}?supervisorClass=NINEONE`).then((response) => {
        response.data.data.map((member) => {
          allOptions.push(
            JSON.parse(
              `{ "value" : "${member.id}", "label" : "${member.supervisor}" }`
            )
          );
        });
        setShowMemeberList(allOptions);
      });

      /**
       * 최초 랜더링 시 필요한 고객사 정보 미리 불러오기
       * @returns
       */
      const fetchData = async () => {
        const [customers] = await Promise.all([fetchCustomers()]);
        // 고객사 리스트 중 처음 2개는 제외
        customers.splice(0, 2);
        setCustomerName(customers);
      };
      fetchData();

      /**
       * 최초 랜더링 시 필요한 프로젝트 정보 미리 불러오기
       * @returns
       */
      instanceAxios.get(`${API.PROJECTLIST}`).then((response) => {
        const projectData = response.data.data.data.map((project) => ({
          value: project.id,
          label: project.project,
        }));
        setProjectName(projectData);
      });
    } catch (e) {
      ErrAlert({ err: "데이터 불러오기 실패" });
    }
  }, []);

  // 지원 옵션
  const repairOption = [
    { value: "INQUERY_WATAB", label: "와탭 문의" },
    { value: "INQUERY_CABLE", label: "유선" },
    { value: "VISIT", label: "방문" },
    { value: "MAIL", label: "메일" },
    { value: "VPN", label: "VPN" },
    { value: "RESIDE", label: "상주" },
    { value: "INSTALL", label: "설치"},
    { value: "INSPECTION", label: "검수"},
  ];

  // 작업 상태 옵션
  const stateOption = [
    { value: "WORK_EXPECTED", label: "작업 예정" },
    { value: "WORKING", label: "작업 중" },
    { value: "WORK_COMPLETE", label: "작업 완료" },
  ];

  const [division, setDivision] = useState(repairOption[0].value);
  const [supportState, setSupportState] = useState(stateOption[0].value);

  const navigate = useNavigate();

  //======api load============================================================
  const [customerName, setCustomerName] = useState([]); // 고객사명 리스트
  const [projectName, setProjectName] = useState([]); // 프로젝트명 리스트

  const handleCustomerChange = async (selectedOption) => {
    const newCustomerId = selectedOption.value;
    setCustomer(selectedOption.value);
    setProjectName([]);
    setIsPrjInitial(true);

    try {
      if (newCustomerId) {
        const fetchData = async () => {
          const [projects] = await Promise.all([
            fetchProjectsByCustomerName(newCustomerId),
          ]);

          setProjectName(
            projects.map((project) => ({
              value: project.id,
              label: project.project,
            }))
          );
        };
        setIsCusInitial(false);
        fetchData();
      }
    } catch (error) {
      ErrAlert({ err: "데이터 불러오기 실패" });
    }

    setProject("");
  };

  // 종료일이 변경될 때 실행되는 함수
  const handleEndDateChange = (event) => {
    const selectedEndDate = event.target.value;
    setEnd(selectedEndDate);

    // 만약 선택된 종료일이 시작일보다 이전이면 시작일 오류 상태를 설정합니다.
    if (selectedEndDate < start) {
      setStartDateError(true);
    } else {
      setStartDateError(false);
    }
  };

  // 시작일이 변경될 때 실행되는 함수
  const handleStartDateChange = (event) => {
    const selectedStartDate = event.target.value;
    setStart(selectedStartDate);
    setIsStartDateSelected(true);

    // 종료일이 존재하고 선택된 시작일이 종료일보다 이후이면 시작일 오류 상태를 설정합니다.
    if (end && selectedStartDate > end) {
      setStartDateError(true);
    } else {
      setStartDateError(false);
    }
  };

  // 시작 시간이 변경될 때 실행되는 함수
  const handleStartTimeChange = (event) => {
    const selectedStartTime = event.target.value;

    setStartDateError(false);

    // 선택된 시작 시간이 종료 시간보다 이후이고 시작일과 종료일이 같은 경우 시작일 오류 상태를 설정합니다.
    if (selectedStartTime > endTime && start === end) {
      setStartDateError(true);
    }

    setStartTime(selectedStartTime);

    // 시작일과 선택된 시작 시간이 모두 존재하는 경우
    if (start && selectedStartTime) {
      // 종료일을 시작일로 설정합니다.
      setEnd(start);
      // 선택된 시작 시간에 1시간을 추가하여 종료 시간을 설정합니다.
      const selectedTime = new Date(`2000-01-01T${selectedStartTime}`);
      selectedTime.setHours(selectedTime.getHours() + 1); // 선택된 시간에서 1시간 추가
      const formattedEndTime = selectedTime.toTimeString().slice(0, 5); // 시간 형식 변환 (hh:mm)
      setEndTime(formattedEndTime);
    }
  }

  // 종료 시간이 변경될 때 실행되는 함수
  const handleEndTimechange = (event) => {
    const selectEndTime = event.target.value;

    setStartDateError(false);

    // 선택된 종료 시간이 시작 시간보다 이전이고 시작일과 종료일이 같은 경우 시작일 오류 상태를 설정합니다.
    if (startTime > selectEndTime && start === end) {
      setStartDateError(true);
    }

    setEndTime(selectEndTime);
  }

  // 시작일에 일수를 더하는 함수
  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result.toISOString().slice(0, 10);
  };

  //=== close of api load =====================================================

  const registSupport = async (e) => {

    e.preventDefault();

    if (startDateError) {
      Swal.fire({
        icon: "warning",
        title: "주의",
        text: `지원일/종료일을 확인해주세요.`,
        showCancelButton: false,
        confirmButtonText: "확인",
      })
    } else {
      Swal.fire({
        icon: "question",
        title: "등록",
        text: `등록 하시겠습니까?`,
        showCancelButton: true,
        confirmButtonText: "등록",
        cancelButtonText: "취소",
      }).then(async (res) => {
        if (res.isConfirmed) {

          
          var formData = new FormData();

          let selectedStartTime = startTime || "00:00";
          let selectedEndTime = endTime || "00:00";

          console.log(start + "T" + selectedStartTime);
          console.log(end + "T" + selectedEndTime);

          formData.append("name", name);
          formData.append("supportState", supportState.value);
          formData.append("division", division.value);
          formData.append("caller", caller);
          formData.append("ask", ask ? ask : "");
          formData.append("start", start + "T" + selectedStartTime);
          formData.append(
            "end",
            end ? end + "T" + selectedEndTime : start + "T" + selectedEndTime
          );
          formData.append("content", content);
          formData.append("customer", customer);
          formData.append("project", project.value);

          for (var i = 0; i < file.length; i++) {
            formData.append("fileList", file[i]); // dto 변수명 변경에 의한 formData key file -> fileList로 변경
          }

          formData.append("workers", selectedWorkers);

          try {
            await instanceAxios
              .post("/api/support", formData, {
                headers: {
                  "Content-Type": `multipart/form-data;charset=UTF-8`,
                },
              })
              .then((response) => {
                ToastAlert({ icon: "success", title: "등록이 완료되었습니다." });
                navigate(`/${API.SUPPORT}`);
              });
          } catch (e) {
            ErrAlert({ err: "필수 항목을 확인해주세요" });
          }
        } else {
          //취소
        }
      });
    }
  };
  return (
    <div className="content">
      <div className="regist-wrap">
        <Card className="card-user">
          <CardHeader>
            <CardTitle tag="h5">지원 등록</CardTitle>
          </CardHeader>
          <CardBody>
            <Form
              onSubmit={registSupport}
              encType="multipart/form-data"
              id="registSupportForm"
            >
              <Row>
                <Col md="12">
                  <FormGroup>
                    <label>지원명</label>
                    <Input
                      type="text"
                      name="name"
                      onChange={(event) => {
                        setName(event.target.value);
                      }}
                      placeholder="직접 입력"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="pr-1" md="4">
                  <FormGroup>
                    <label>고객사</label>
                    <Select
                      required
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          borderColor: isCusInitial
                            ? "#ff0000"
                            : provided.borderColor,
                          // 선택되었을 때와 선택되지 않았을 때의 테두리색을 지정할 수 있습니다.
                        }),
                      }}
                      placeholder="필수값입니다"
                      options={customerName}
                      onChange={handleCustomerChange}
                    />
                  </FormGroup>
                </Col>
                <Col className="pl-1" md="8">
                  <FormGroup>
                    <label>프로젝트</label>
                    <Select
                      required
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          borderColor: isPrjInitial
                            ? "#ff0000"
                            : provided.borderColor,
                          // 선택되었을 때와 선택되지 않았을 때의 테두리색을 지정할 수 있습니다.
                        }),
                      }}
                      placeholder="필수값입니다"
                      name="cProject"
                      options={projectName}
                      onChange={(selectedOption) => {
                        setProject(selectedOption);
                        setIsPrjInitial(false);
                      }}
                      value={project}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="pr-1" md="4">
                  <FormGroup>
                    <label>업무 구분</label>
                    <Select
                      required
                      menuPortalTarget={document.body}
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          borderColor: isDivInitial
                            ? "#ff0000"
                            : provided.borderColor,
                          // 선택되었을 때와 선택되지 않았을 때의 테두리색을 지정할 수 있습니다.
                        }),
                        menuPortal: (base) => ({ ...base, zIndex: 9998 }),
                      }}
                      placeholder="필수값입니다"
                      name="cProject"
                      options={repairOption}
                      onChange={(selectedOption) => {
                        setDivision(selectedOption);
                        setIsDivInitial(false);
                      }}
                      value={division}
                    />
                  </FormGroup>
                </Col>
                <Col className="px-1" md="4">
                  <FormGroup>
                    <label>요청자</label>
                    <Input
                      type="text"
                      name="caller"
                      id="caller"
                      placeholder="직접 입력"
                      onBlur={(event) => {
                        setCaller(event.target.value);
                      }}
                    ></Input>
                  </FormGroup>
                </Col>
                <Col className="pl-1" md="4">
                  <FormGroup>
                    <label>작업 상태</label>
                    <Select
                      required
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          borderColor: isStateInitial
                            ? "#ff0000"
                            : provided.borderColor,
                          // 선택되었을 때와 선택되지 않았을 때의 테두리색을 지정할 수 있습니다.
                        }),
                      }}
                      placeholder="필수값입니다"
                      name="cProject"
                      options={stateOption}
                      onChange={(selectedOption) => {
                        setSupportState(selectedOption);
                        setIsStateInitial(false);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="pr-1" md="4">
                  <FormGroup>
                    <label>작업자</label>
                    <div className="MDrop">
                      <Select
                        name="workerList"
                        isMulti
                        closeMenuOnSelect={false}
                        // onChange={(options) => {
                        //   if (Array.isArray(options)) {
                        //     setSelectedWorkers(options.map((opt) => opt.value));

                        //   }
                        // }}
                        onChange={handleMemberChange}
                        options={showMemberList}
                        components={{
                          Option: InputOption,
                        }}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                    </div>
                  </FormGroup>
                </Col>
                <Col className="px-1" md="4">
                  <FormGroup>
                    <label>작업 요청일</label>
                    <Input
                      type="datetime-local"
                      name="ask"
                      onInput={(event) => {
                        setAsk(event.target.value);
                      }}
                    ></Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="pr-1" md="4">
                  <FormGroup>
                    <label>지원(예정)일</label>
                    <Input
                      required
                      className={start ? "is-valid" : "is-invalid"}
                      type="date"
                      name="start"
                      onChange={handleStartDateChange}
                    />
                    {!start && (
                      <span style={{ color: "red" }}>필수로 입력해주세요.</span>
                    )}
                  </FormGroup>
                </Col>
                <Col className="px-1" md="2">
                  <FormGroup>
                    <label>지원(예정)시간</label>
                    <Input
                      type="time"
                      name="startTime"
                      disabled={!isStartDateSelected}
                      onChange={handleStartTimeChange}
                    />
                  </FormGroup>
                </Col>
                <Col className="px-1" md="4">
                  <FormGroup>
                    <label>종료일</label>
                    <Input
                      type="date"
                      name="end"
                      value={end}
                      onChange={handleEndDateChange}
                    />
                    {startDateError && (
                      <span style={{ color: "red" }}>
                        지원 종료일은 지원 예정일 이후여야 합니다.
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col className="pl-1" md="2">
                  <FormGroup>
                    <label>지원 종료 시간</label>
                    <Input
                      type="time"
                      name="endTime"
                      disabled={!isStartDateSelected}
                      value={endTime}
                      onChange={handleEndTimechange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <label>지원 항목</label>
                    {/* <Input
                      className="textArea"
                      type="textarea"
                      name="content"
                      onChange={(event) => {
                        setContent(event.target.value);
                      }}
                    /> */}
                    <CkEditor onChange={setContent}/>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <Label>첨부파일</Label>
                    <FileUpload file={file} setFile={setFile} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <DeleteButton
                  to={`/${API.SUPPORT}`}
                  text1="작성을 취소 하시겠습니까?"
                />
              </Row>
            </Form>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
export default SupportRegist;
