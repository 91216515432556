// table list 페이지
// 길이가 길어지면 아래로 내려가는게 나은지 스크롤이 있는게 나은지

import React, { useEffect } from "react";
import { useState } from "react";
import Paging from "../../variables/Paging";
import "../../assets/css/List.css";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Col,
  Button,
  Spinner,
} from "reactstrap";

import { useNavigate } from "react-router-dom";
import { API } from "../../ApiConfig";
import instanceAxios from "components/reissue/InstanceAxios";
import SelectBox from "variables/Fillter/SelectBox";
import Swal from "sweetalert2";
import ErrAlert from "variables/ErrAlert";
import SortFilter from "variables/Fillter/SortFilter";
import StateFilter from "variables/Fillter/StateFilter";

/**
 * 어떤 리스트인지 나타내는 제목입니다.
 */
const titleName = "유지보수";

/**
 * 유지보수 리스트 페이지
 * @returns table형식의 list page를 리턴합니다.
 */
function MaintainList() {
  const [maintainData, setListData] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0); // 전체 요소 갯수
  const [loading, setLoading] = useState(true); // 로딩 상태 추가
  const DEFAULT_PER_PAGE = 10; // 한 페이지 출력 갯수
  const [PER_PAGE, setPerPage] = useState(DEFAULT_PER_PAGE);

  // ## 계약 상태 옵션
  const stateOptions = [
    { value: "WORKING", label: "작업중" },
    { value: "WORK_EXPECTED", label: "작업 예정" },
    { value: "WORK_COMPLETE", label: "작업 완료" },
  ];

  // 두 번째 셀렉트로 필터링된 데이터를 저장할 상태
  const [firstSelectValue, setFirstSelectValue] = useState("");
  const [secondSelectValue, setSecondSelectValue] = useState("");
  const [searchValues, setSearchValues] = useState({
    firstSelectValue: "",
    secondSelectValue: "",
  });
  const [isSearch, setIsSearch] = useState(false); // 검색 상태 추가

  //## 계약 상태 필터를 위한 state
  const [filterOpen, setFilterOpen] = useState(false);

  const [contractState, setContractState] = useState([]);

  const handleTempFilter = async (tempFilter) => {
    // 부모 컴포넌트에서 tempFilter 값을 사용하는 작업을 수행합니다.
    // temFilter를 보내야함 setContractState
    setLoading(true);
    setContractState(tempFilter);
    await fetchData(page, sortCol, sortState, tempFilter);
  };

  // 정렬 상태
  const [sortCol, setSortCol] = useState("");
  const [sortState, setSortState] = useState("false");

  async function fetchData(
    page = 1,
    col = "",
    sortState = "false",
    state = ""
  ) {
    try {
      const { firstSelectValue, secondSelectValue } = searchValues;
      const response = await instanceAxios.get(
        `${API.REPAIRLIST}?page=${page}&${firstSelectValue}=${secondSelectValue}&order=${col}&asc=${sortState}&state=${state}&pageSize=10`
      );

      const data = response.data?.data?.data || [];
      setListData(data);
      setCount(response.data.data.totalElements);
    } catch (error) {
      // 에러 처리
      ErrAlert({ err: "데이터 불러오기 실패" });
    } finally {
      // 로딩 상태 업데이트
      setLoading(false);
    }
  }

  const [reloadData, setReloadData] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData(page, sortCol, sortState, contractState);
  }, [searchValues, page, sortCol, sortState, contractState]);

  /**
   * 전체{}건 을 클릭시 필터가 적용되지 않은 첫 페이지로 돌아갑니다
   */
  const handleTotalCountClick = () => {
    // 필터 상태 초기화
    setSearchClicked(false);
    setFirstSelectValue("");
    setSecondSelectValue("");
    setFilteredMaintains([]);

    // 페이지를 1로 설정하여 초기 리스트로 이동
    setPage(1);

    // 페이지 리로드
    setReloadData(!reloadData);
  };

  /**
   * 페이지가 변경될 때마다 페이지 번호를 가져와 useState의 setPage를 이용하여 업데이트 해주는 함수입니다.
   * @param {number} page
   */
  const handlePageChange = async (newPage) => {
    setLoading(true);
    setPage(newPage);
    await fetchData(newPage, sortCol, sortState, contractState);
  };

  const handleSort = async (col) => {
    const newSortState = sortState === "true" ? "false" : "true";
    setLoading(true);
    setSortCol(col);
    setSortState(newSortState);
    await fetchData(page, col, newSortState, contractState);
  };

  const navigate = useNavigate();

  // 두 번째 셀렉트로 필터링된 데이터를 저장할 상태
  const [filteredMaintains, setFilteredMaintains] = useState([]);
  const [searchClicked, setSearchClicked] = useState(false); // 검색 버튼 클릭 여부를 저장

  /**
   * 첫 번째 셀렉트 변경 시 호출되는 함수
   * @param {첫 번째 셀렉트 값} value
   */
  const handleFirstSelectChange = (value) => {
    setFirstSelectValue(value);
  };

  /**
   * 두 번째 셀렉트 변경 시 호출되는 함수
   * @param {두 번째 셀렉트 값} value
   */
  const handleSecondSelectChange = (value) => {
    setSecondSelectValue(value);
  };

  /**
   * 검색버튼을 클릭하면 실행되는 함수
   * 셀렉트 된 값에 맞춰 필터링하여 저장
   */
  const handleSearchButtonClick = () => {
    if (firstSelectValue && secondSelectValue) {
      setPage(1); // 검색 후 첫 페이지로 이동
      setLoading(true); // 로딩 상태를 true로 설정하여 데이터를 가져오는 중임을 나타냄
      setIsSearch(true); // 검색 상태를 true로 설정
      setSearchValues({ firstSelectValue, secondSelectValue });
      // fetchData();
    } else {
      Swal.fire({
        icon: "warning",
        title: "오류",
        text: `검색 카테고리를 선택해주세요.`,
      });
    }
  };

  // 작업상태 값 변경 ex) WORKING -> 작업 진행중
  const [workStates, setWorkStates] = useState([]);
  const startingIndex = (page - 1) * PER_PAGE + 1; // 현재 페이지의 첫 번째 아이템 번호

  useEffect(() => {
    // maintainData가 존재하고, maintainData가 배열일 경우에만 작업 상태 값을 설정
    if (Array.isArray(maintainData) && maintainData.length > 0) {
      const updatedWorkStates = maintainData.map((item) => {
        if (item.repairState === "WORKING") {
          return "작업 진행중";
        } else if (item.repairState === "WORK_EXPECTED") {
          return "작업 예정";
        } else if (item.repairState === "WORK_COMPLETE") {
          return "작업 완료";
        } else {
          return "-";
        }
      });
      setWorkStates(updatedWorkStates);
    }
  }, [maintainData, page]);
  /**
   * 배열을 페이지 번호에 맞춰 반복문을 돌려 담아주는 함수입니다.
   * @returns 게시글을 배열에 담아 리턴합니다.
   */
  function arrayList() {
    var array = [];
    const pageRender = (page - 1) * PER_PAGE;
    const isAscending = sortState === "true";
    // 초기 리스트에 데이터가 없을 때
    if (!maintainData || maintainData.length === 0) {
      if (isSearch) {
        return (
          <tr key="no-search-data" style={{ textAlign: "center" }}>
            <td colSpan="7">검색된 결과가 없습니다.</td>
          </tr>
        );
      } else {
        return (
          <tr key="no-data" style={{ textAlign: "center" }}>
            <td colSpan="7">등록된 글이 없습니다.</td>
          </tr>
        );
      }
    }

    // 초기 리스트에 데이터가 있을 때
    maintainData.map((item, index) => {
      const isDeleted = item.deleteTandF;
      const displayIndex = isAscending ? (page - 1) * PER_PAGE + index + 1 : count - ((page - 1) * PER_PAGE + index);
      array.push(
        <tr
          key={item.id}
          id="move"
          onClick={() => navigate(`${API.MAINTAINDETAIL}/${item.id}`)}
          style={{ textAlign: "center" }}
        >
          <div
            className={`maintain-list-wrap ${isDeleted ? "deleted-text" : ""
              }`}
          >
            <div className={"maintain-list-left"}>
              <td>{displayIndex || "-"}</td>
              <td>{item.customer || "-"}</td>
              <td>{item.contract || "-"}</td>
            </div>
            <div className={"maintain-list-right"}>
              <div className={"maintain-date-wrap"}>
                <td>{item.start ? item.start.split(" ")[0] : "-"}</td>
                <div className={"date-period-mark"}>~</div>
                <td>{item.end ? item.end.split(" ")[0] : "-"}</td>
              </div>
              <div className="maintain-progress-wrap">
                <td>{workStates[index] || "-"}</td>
              </div>
            </div>
          </div>
        </tr>
      );
    });
    return array;
  }
  
  return (
    <>
      <div className="content">
        <Card>
          <CardHeader>
            <CardTitle className="cardTitle" tag="h4">
              {titleName}
            </CardTitle>
          </CardHeader>
          <CardBody>
            {/* <Table responsive /> */}
            <div style={{ display: "flex" }}>
              <Col md="auto" className="d-flex align-items-center">
                <p
                  className="category"
                  style={{ fontSize: "17px", display: "flex" }}
                >
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={handleTotalCountClick}
                    onMouseEnter={(e) => (e.target.style.color = "#240170")}
                    onMouseLeave={(e) => (e.target.style.color = "#9A9A9A")}
                  >
                    전체 {count}건
                  </span>
                </p>
              </Col>
              <SelectBox
                onFirstSelectChange={handleFirstSelectChange}
                onSecondSelectChange={handleSecondSelectChange}
                options="maintain"
                handleSearchButtonClick={handleSearchButtonClick}
              />
              <Col md="auto" className="d-flex align-items-center">
                <i
                  id="iconBtn"
                  className="nc-icon nc-zoom-split"
                  onClick={handleSearchButtonClick}
                  color="primary"
                />
              </Col>
            </div>
            <Table>
              <thead className={"text-primary"}>
                <tr className={"maintain-title-wrap"}>
                  <div className={"maintain-title-left"}>
                    <SortFilter
                      text="번호"
                      value="number"
                      handleSort={handleSort}
                      isActive={sortCol === "number"}
                      activeCol={sortCol}
                      sortState={sortState}
                    />
                    <th>고객사</th>
                    <th>계약명</th>
                  </div>
                  <div className={"maintain-title-right"}>
                    <SortFilter
                      text="시작일"
                      value="start"
                      handleSort={handleSort}
                      isActive={sortCol === "start"}
                      activeCol={sortCol}
                      sortState={sortState}
                    />
                    <SortFilter
                      text="종료일"
                      value="end"
                      handleSort={handleSort}
                      isActive={sortCol === "end"}
                      activeCol={sortCol}
                      sortState={sortState}
                    />
                    <th>
                      작업상태{" "}
                      <StateFilter
                        stateOptions={stateOptions}
                        filterOpen={filterOpen}
                        setFilterOpen={setFilterOpen}
                        setCount={setCount}
                        handleTempFilter={handleTempFilter}
                      />
                    </th>
                  </div>
                </tr>
              </thead>
              {loading ? (
                <Spinner color="primary" />
              ) : (
                <>
                  <tbody>{arrayList()}</tbody>
                </>
              )}
            </Table>
            {!loading && (
              <div style={{ textAlign: "right", padding: "0px 15px" }}>
                <Button
                  outline
                  color="primary"
                  type="submit"
                  onClick={() => navigate(`${API.MAINTAINREGIST}`)}
                >
                  등록
                </Button>
              </div>
            )}
          </CardBody>
          {!loading && (
            <Paging
              count={count}
              page={page}
              perPage={PER_PAGE}
              handlePageChange={handlePageChange}
            />
          )}
        </Card>
      </div>
    </>
  );
}

export default MaintainList;
