import { API } from "ApiConfig";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import Swal from "sweetalert2";

/**
 * axios에 특별한 설정을 하기 위해 axios.create 메소드를 이용해서 만든 인스턴스
 */
const instanceAxios = axios.create({
  headers: {
    withCredentials: "true",
    "Content-Type": "application/json;charset=UTF-8",
    Accept: "application/json",
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  },
});

/**
 * 토큰이 필요한 페이지에서 헤더에 토큰값을 담아주기 위한 컴포넌트
 */
export const addTokenToHeader = () => {
  const accessToken = localStorage.getItem("accessToken");

  if (accessToken) {
    // 토큰 추가
    instanceAxios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${accessToken}`;
  } else {
    // 토큰이 없을 경우에 대한 처리 (예: 로그아웃 등)
    delete instanceAxios.defaults.headers.common["Authorization"];
  }
};

/**
 * 요청 인터셉터 추가하기(요청을 보내거나 받을 때 라이브러리가 호출)
 */
instanceAxios.interceptors.request.use(
  (config) => {
    // 요청이 전달되기 전에 작업 수행
    // 토큰 가져오기
    const localAccessToken = localStorage.getItem("accessToken");
    const localRefreshToken = localStorage.getItem("refreshToken");

    // 토큰이 존재하지 않는 경우 로그인 페이지로 이동
    if (!localAccessToken || !localRefreshToken) {
      // alert("로그인이 필요합니다.");
      window.location.href = `${API.ERRORPAGE}`;
      return config;
    }

    //현재시간(초 단위)
    const now = Math.floor(Date.now() / 1000);
    //토큰 디코딩
    const accessDecoded = jwtDecode(localAccessToken);
    const refreshDecoded = jwtDecode(localRefreshToken);

    // 3. 엑세스토큰의 유효기간이 끝나기 전에 갱신처리
    // 60* 5 => 5분
    if (localAccessToken) {
      config.headers["Authorization"] = `Bearer ${localAccessToken}`;
    }
    if (accessDecoded.exp - now < 60 * 5 || localAccessToken === "undefined") {
      // if(리프레쉬토큰 만료되기 1분 전이라면)
      if (refreshDecoded.exp - now < 60 * 1) {
        // 로그아웃
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("memRole");
        window.location.href = `${API.LOGIN}`;
      } else {
        // 리프레쉬토큰 만료기간이 남았다면 엑세스토큰 갱신 요청
        const localRefreshToken = localStorage.getItem("refreshToken");
        const token = async () => {
          await axios
            .post(
              "/api/auth/accessToken",
              {},
              {
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  Authorization: localRefreshToken,
                  email: refreshDecoded.sub,
                },
              }
            )
            .then((res) => {
              // 새로운 토큰 저장
              const newAccessToken = res.headers.get("access-token");
              localStorage.setItem("accessToken", newAccessToken);
              if (newAccessToken) {
                config.headers["Authorization"] = `Bearer ${newAccessToken}`;
              }
            })
            .catch((err) => {
              Swal.fire({
                icon: "warning",
                title: "오류" + err,
                text: "오류가 발생했습니다.",
              });
              console.log(err);
            });
        };
        token();
      }
    }

    return config;
  },
  (error) => {
    // 여기에 에러 페이지 추가
    window.location.href = `${API.ERRORPAGE}`;
    // 요청 오류가 있는 작업 수행
    return Promise.reject(error);
  }
);
//

// 전역 에러 핸들러 추가
// axios.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     if (error.response && error.response.status === 401) {
//       await Swal.fire({
//         icon: "warning",
//         title: "로그인 필요",
//         text: "로그인이 필요합니다.",
//       });
//       // 로그인 페이지로 리다이렉트
//       window.location.href = `${API.LOGIN}`; // 로그인 페이지의 URL로 리다이렉트할 수 있습니다.
//     }
//     return Promise.reject(error);
//   }
// );

export default instanceAxios;
