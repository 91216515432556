/*!

=========================================================
* Paper Dashboard React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import { useNavigate } from "react-router-dom";
import { API } from "ApiConfig";
import Select, { components } from "react-select";
import {
  fetchCustomers,
  fetchMembers,
  fetchSupervisorByCustomerName,
  fetchContractsByCustomerName,
  fetchContractById,
} from "ApiUtils";
import instanceAxios from "components/reissue/InstanceAxios";
import FileUpload from "components/RegistForm/FileUpload";
import Swal from "sweetalert2";
import DeleteButton from "variables/DeleteButton";
import ToastAlert from "variables/ToastAlert";
import ErrAlert from "variables/ErrAlert";
import CkEditor from "variables/CkEditor";

/**
 * 유지보수 등록 페이지
 * @author 김지연
 * @returns 유지보수 등록 폼 리턴
 */

function MaintainRegist() {
  //=====================Member dropdown select======================
  const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
  }) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    //스타일 설정
    let bg = "transparent";
    if (isFocused) bg = "#eee";
    if (isActive) bg = "#B2D4FF";

    const style = {
      alignItems: "center",
      backgroundColor: bg,
      color: "inherit",
      display: "flex",
    };

    //prop
    const props = {
      ...innerProps,
      onMouseDown,
      onMouseUp,
      onMouseLeave,
      style,
    };

    return (
      <components.Option
        {...rest}
        isDisabled={isDisabled}
        isFocused={isFocused}
        isSelected={isSelected}
        getStyles={getStyles}
        innerProps={props}
      >
        <input type="checkbox" checked={isSelected} />
        {children}
      </components.Option>
    );
  };

  // ======= 작업 상태 옵션
  const stateOptions = [
    { value: "WORK_EXPECTED", label: "작업 예정" },
    { value: "WORKING", label: "작업 진행중" },
    { value: "WORK_COMPLETE", label: "작업 완료" },
  ];

  const [customer, setCustomer] = useState(""); // 고객사
  const [repairContract, setRepairContract] = useState(""); // 계약-유지보수
  const [state, setState] = useState(""); // 작업 상태
  const [division, setDivision] = useState(""); // 지원 형태
  const [supervisor, setSupervisor] = useState(""); // 고객사 담당자
  const [workerList, setWorkerList] = useState(""); // 작업 담당자
  const [start, setStart] = useState(""); // 지원 예정일
  const [end, setEnd] = useState(""); // 지원 종료일
  const [content, setContent] = useState(""); // 유지보수 항목
  const [ref, setRef] = useState(""); // 참조사항
  const [file, setFile] = useState([]); // 첨부 파일

  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  const [isStartDateSelected, setIsStartDateSelected] = useState(false);

  const [isCusInitial, setIsCusInitial] = useState(true); // 초기 상태를 추적합니다.
  const [isConInitial, setIsConInitial] = useState(true); // 초기 상태를 추적합니다.
  const [isSupInitial, setIsSupInitial] = useState(true);

  const [contractTerm, setContractTerm] = useState(""); // 계약기간 .. 등록x

  const [startDateError, setStartDateError] = useState(false); // 계약 시작일 오류 여부 상태

  //========= api load
  const [customerName, setCustomerName] = useState([]); // 고객사명 리스트
  const [repairContracts, setRepairContracts] = useState([]); // 계약-유지보수 리스트
  const [supervisorList, setSupervisorList] = useState([]); // 고객사 담당자 리스트
  const [memberOptions, setMemberOptions] = useState([]); // 멤버 리스트

  useEffect(() => {
    const fetchData = async () => {
      try {
        // 고객사명과 멤버 리스트를 동시에 불러옵니다.
        const [customers, members] = await Promise.all([
          fetchCustomers(),
          fetchMembers(),
        ]);
        // 고객사명 리스트에서 처음 두 개의 요소를 제거합니다.
        customers.splice(0, 2);
        setCustomerName(customers);
        setMemberOptions(members);
      } catch (error) {
        ErrAlert({ err: "데이터 불러오기 실패" });
      }
    };

    fetchData();
  }, []);

  //고객사 선택시 리스트 변경 - 계약유지보수, 고객사 담당자
  const handleCustomerChange = async (selectedOption) => {
    // 선택된 고객사명과 ID를 변수에 저장합니다.
    const newCustomerName = selectedOption.label;
    const newCustomerId = selectedOption.value;

    // 고객사 상태를 업데이트하고, 계약유지보수와 담당자 리스트를 초기화합니다.
    setCustomer(selectedOption.value);
    setRepairContracts([]);
    setSupervisorList([]);
    setIsConInitial(true);
    setIsSupInitial(true);

    try {
      if (newCustomerName) {
        // 선택된 고객사에 대한 담당자를 불러오는 비동기 함수 실행
        const fetchData = async () => {
          const [supervisors] = await Promise.all([
            fetchSupervisorByCustomerName(newCustomerName),
          ]);

          // 불러온 담당자 리스트를 셋팅합니다.
          setSupervisorList(
            supervisors.map((supervisor) => ({
              value: supervisor.id,
              label: supervisor.supervisor,
            }))
          );
        };

        setIsCusInitial(false);
        fetchData();
      }

      if (newCustomerId) {
        // 선택된 고객사 ID로 해당 고객사의 계약유지보수 리스트를 불러오는 함수 실행
        const fetchContract = async () => {
          const contracts = await fetchContractsByCustomerName(newCustomerId);

          setRepairContracts(
            contracts.map((contract) => ({
              value: contract.id,
              label: contract.contract,
            }))
          );
        };

        fetchContract();
      }
    } catch (error) {
      ErrAlert({ err: "데이터 불러오기 실패" });
    }

    setRepairContract("");
    setSupervisor("");
    setDivision("");
    setContractTerm("");
  };

  // 계약 유지보수 선택시 리스트 변경 - 지원 형태, 계약 기간
  const handleContractChange = async (selectedOption) => {
    const newContractId = selectedOption.value;

    try {
      if (newContractId) {
        // 선택된 계약 ID로 해당 계약의 데이터를 불러오는 함수 실행
        const contractData = await fetchContractById(newContractId);
        setDivision(contractData.data.division); // division 값을 설정합니다.

        const startDate = contractData.data.start;
        const endDate = contractData.data.end;

        const contractTerm = (startDate && endDate) ? `${startDate} ~ ${endDate}` : '-';
        setContractTerm(contractTerm);
      }
    } catch (error) {
      ErrAlert({ err: "데이터 불러오기 실패" });
    }
  };


  // 종료일이 변경될 때 실행되는 함수
  const handleEndDateChange = (event) => {
    // 선택된 종료일을 변수에 저장합니다.
    const selectedEndDate = event.target.value;
    // 종료일 상태를 업데이트합니다.
    setEnd(selectedEndDate);

    // 만약 선택된 종료일이 시작일보다 이전이면 시작일 오류 상태를 설정합니다.
    if (selectedEndDate < start) {
      setStartDateError(true);
    } else {
      // 그렇지 않으면 오류 상태를 해제합니다.
      setStartDateError(false);
    }
  };

  // 시작일이 변경될 때 실행되는 함수
  const handleStartDateChange = (event) => {
    // 선택된 시작일을 변수에 저장합니다.
    const selectedStartDate = event.target.value;
    // 시작일 상태를 업데이트합니다.
    setStart(selectedStartDate);
    // 시작일 선택 여부를 true로 설정합니다.
    setIsStartDateSelected(true);

    // 종료일이 존재하고 선택된 시작일이 종료일보다 이후이면 시작일 오류 상태를 설정합니다.
    if (end && selectedStartDate > end) {
      setStartDateError(true);
    } else {
      // 그렇지 않으면 오류 상태를 해제합니다.
      setStartDateError(false);
    }
  };

  // 시작 시간이 변경될 때 실행되는 함수
  const handleStartTimeChange = (event) => {
    const selectedStartTime = event.target.value;

    setStartDateError(false);

    // 선택된 시작 시간이 종료 시간보다 이후이고 시작일과 종료일이 같은 경우 시작일 오류 상태를 설정합니다.
    if (selectedStartTime > endTime && start === end) {
      setStartDateError(true);
    }

    setStartTime(selectedStartTime);

    // 시작일과 선택된 시작 시간이 모두 존재하는 경우
    if (start && selectedStartTime) {
      // 종료일을 시작일로 설정합니다.
      setEnd(start);
      // 선택된 시작 시간에 1시간을 추가하여 종료 시간을 설정합니다.
      const selectedTime = new Date(`2000-01-01T${selectedStartTime}`);
      selectedTime.setHours(selectedTime.getHours() + 1); // 선택된 시간에서 1시간 추가
      const formattedEndTime = selectedTime.toTimeString().slice(0, 5); // 시간 형식 변환 (hh:mm)
      setEndTime(formattedEndTime);
    }
  }

  // 종료 시간이 변경될 때 실행되는 함수
  const handleEndTimechange = (event) => {
    // 선택된 종료 시간을 변수에 저장합니다.
    const selectEndTime = event.target.value;

    // 시작일 오류 상태를 해제합니다.
    setStartDateError(false);

    // 선택된 종료 시간이 시작 시간보다 이전이고 시작일과 종료일이 같은 경우 시작일 오류 상태를 설정합니다.
    if (startTime > selectEndTime && start === end) {
      setStartDateError(true);
    }

    // 선택된 종료 시간을 설정합니다.
    setEndTime(selectEndTime);
  };

  // 시작일에 일수를 더하는 함수
  const addDays = (date, days) => {
    // 입력된 날짜와 일수를 기반으로 새로운 날짜를 계산하여 반환합니다.
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result.toISOString().slice(0, 10);
  };

  // ========== form 등록 ==============

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (startDateError) {
      Swal.fire({
        icon: "warning",
        title: "주의",
        text: `시작일/종료일을 확인해주세요.`,
        showCancelButton: false,
        confirmButtonText: "확인",
      })
    } else {
      Swal.fire({
        icon: "question",
        title: "등록",
        text: `등록 하시겠습니까?`,
        showCancelButton: true,
        confirmButtonText: "등록",
        cancelButtonText: "취소",
      }).then(async (res) => {
        if (res.isConfirmed) {
          const formData = new FormData();

          let selectedStartTime = startTime || "00:00";
          let selectedEndTime = endTime || "00:00";

          formData.append("customer", customer);
          formData.append("repairContract", repairContract.value);
          formData.append("state", state || "WORK_EXPECTED");
          formData.append("division", division);
          formData.append("supervisor", supervisor.value);
          formData.append("workers", workerList);
          formData.append("start", start + "T" + selectedStartTime);
          formData.append(
            "end",
            end ? end + "T" + selectedEndTime : start + "T" + selectedEndTime
          );
          formData.append("content", content);
          formData.append("ref", ref);

          for (var i = 0; i < file.length; i++) {
            formData.append("fileList", file[i]);
          }

          try {
            const response = await instanceAxios.post(
              API.MAINTAINLIST,
              formData,
              {
                headers: {
                  "Content-Type": "application/form-data;charset=UTF-8",
                },
              }
            );
            ToastAlert({ icon: "success", title: "등록이 완료되었습니다." });
            navigate(`/${API.MAINTAIN}`);
          } catch (error) {
            ErrAlert({ err: "필수 항목을 확인해주세요" });
          }
        } else {
          //취소
        }
      });
    }


  };

  //===================== 등록폼 ========
  const navigate = useNavigate();
  return (
    <div className="content">
      <div className="regist-wrap">
        <Card className="card-user">
          <CardHeader>
            <CardTitle tag="h5">유지보수 등록</CardTitle>
          </CardHeader>
          <CardBody>
            <Form encType="multipart/form-data" onSubmit={handleSubmit}>
              <Row>
                <Col className="pr-1" md="4">
                  <FormGroup>
                    <label>고객사</label>
                    <Select
                      required
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          borderColor: isCusInitial
                            ? "#ff0000"
                            : provided.borderColor,
                          // 선택되었을 때와 선택되지 않았을 때의 테두리색을 지정할 수 있습니다.
                        }),
                      }}
                      placeholder="필수값입니다"
                      options={customerName}
                      onChange={handleCustomerChange}
                    />
                  </FormGroup>
                </Col>
                <Col className="px-1" md="5">
                  <FormGroup>
                    <label>계약-유지보수</label>
                    <Select
                      required
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          borderColor: isConInitial
                            ? "#ff0000"
                            : provided.borderColor,
                          // 선택되었을 때와 선택되지 않았을 때의 테두리색을 지정할 수 있습니다.
                        }),
                      }}
                      placeholder="필수값입니다"
                      options={repairContracts}
                      onChange={(selectedOption) => {
                        handleContractChange(selectedOption);
                        setRepairContract(selectedOption);
                        setIsConInitial(false);
                      }}
                      value={repairContract}
                    />
                  </FormGroup>
                </Col>
                <Col className="pl-1" md="3">
                  <FormGroup>
                    <label>작업 상태</label>
                    <Select
                      name="state"
                      options={stateOptions}
                      onChange={(event) => {
                        setState(event.value);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="pr-1" md="3">
                  <FormGroup>
                    <label>지원 형태</label>
                    <Input
                      name="select"
                      type="text"
                      value={division}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col className="pl-1" md="3">
                  <FormGroup>
                    <label>고객사 담당자</label>
                    <Select
                      name="cSupervisor"
                      required
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          borderColor: isSupInitial
                            ? "#ff0000"
                            : provided.borderColor,
                          // 선택되었을 때와 선택되지 않았을 때의 테두리색을 지정할 수 있습니다.
                        }),
                      }}
                      placeholder="필수값입니다"
                      options={supervisorList}
                      onChange={(selectedOption) => {
                        setSupervisor(selectedOption);
                        setIsSupInitial(false);
                      }}
                      value={supervisor}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="pr-1" md="3">
                  <FormGroup>
                    <label>작업자</label>
                    <div className="MDrop">
                      <Select
                        name="members"
                        isMulti
                        closeMenuOnSelect={false}
                        onChange={(options) => {
                          if (Array.isArray(options)) {
                            setWorkerList(options.map((opt) => opt.value));
                          }
                        }}
                        options={memberOptions}
                        components={{
                          Option: InputOption,
                        }}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                    </div>
                  </FormGroup>
                </Col>
                <Col className="px-1" md="4">
                  <FormGroup>
                    <label>계약 기간</label>
                    <Input type="text" value={contractTerm} readOnly />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="pr-1" md="4">
                  <FormGroup>
                    <label>지원(예정)일</label>
                    <Input
                      required
                      className={start ? "is-valid" : "is-invalid"}
                      type="date"
                      name="start"
                      onChange={handleStartDateChange}
                    />
                    {!start && (
                      <span style={{ color: "red" }}>필수로 입력해주세요.</span>
                    )}
                  </FormGroup>
                </Col>
                <Col className="px-1" md="2">
                  <FormGroup>
                    <label>지원(예정)시간</label>
                    <Input
                      type="time"
                      name="startTime"
                      disabled={!isStartDateSelected}
                      onChange={handleStartTimeChange}
                    />
                  </FormGroup>
                </Col>
                <Col className="px-1" md="4">
                  <FormGroup>
                    <label>지원 종료일</label>
                    <Input
                      type="date"
                      name="end"
                      value={end}
                      onChange={handleEndDateChange}
                    />
                    {startDateError && (
                      <span style={{ color: "red" }}>
                        지원 종료일은 지원 예정일 이후여야 합니다.
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col className="pl-1" md="2">
                  <FormGroup>
                    <label>지원 종료 시간</label>
                    <Input
                      type="time"
                      name="endTime"
                      disabled={!isStartDateSelected}
                      value={endTime}
                      onChange={handleEndTimechange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <label>유지보수 항목</label>
                    {/* <Input
                      className="textArea"
                      type="textarea"
                      name="content"
                      onChange={(event) => {
                        setContent(event.target.value);
                      }}
                    /> */}
                    <CkEditor onChange = {setContent} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <label>참조 사항</label>
                    <Input
                      type="textarea"
                      name="ref"
                      onChange={(event) => {
                        setRef(event.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <FileUpload file={file} setFile={setFile} />
              <Row>
                <DeleteButton
                  to={`/${API.MAINTAIN}`}
                  text1="작성을 취소 하시겠습니까?"
                />
              </Row>
            </Form>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
export default MaintainRegist;
