import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Spinner,
  Label,
} from "reactstrap";

import instanceAxios from "components/reissue/InstanceAxios";
import { useNavigate, useParams } from "react-router-dom";
import { API } from "ApiConfig";
import { fetchCustomers, fetchSupervisors, fetchMembers } from "ApiUtils";
import FileUpload from "components/RegistForm/FileUpload";
import OldFileUpload from "components/RegistForm/OldFileUpload";
import DeleteButton from "variables/DeleteButton";
import Swal from "sweetalert2";
import ToastAlert from "variables/ToastAlert";
import ErrAlert from "variables/ErrAlert";
import CkEditor from "variables/CkEditor";

/**
 * 프로젝트 수정 페이지
 * @author 김지연
 * @returns 프로젝트 수정 폼 리턴
 */

function ProjectModify() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true); // 로딩 상태 추가

  const [name, setName] = useState(""); // 프로젝트 명
  const [customer, setCustomer] = useState(""); // 고객사 -
  const [supervisorNo, setSupervisorNo] = useState(""); // 나인원 담당자
  const [supervisorWt, setSupervisorWt] = useState(""); // 와탭 담당자
  const [start, setStart] = useState(""); // 시작일
  const [end, setEnd] = useState(""); // 종료일
  const [selectedMembers, setSelectedMembers] = useState([]); // 멤버
  const [content, setContent] = useState(""); // 비고
  const [file, setFile] = useState([]);
  const [Data, setData] = useState([]);
  const [startDateError, setStartDateError] = useState(false); // 계약 시작일 오류 여부 상태

  const [isCusInitial, setIsCusInitial] = useState(false); // 초기 상태를 추적합니다.
  const [isWtInitial, setIsWtInitial] = useState(false); // 초기 상태를 추적합니다.
  const [isNoInitial, setIsNoInitial] = useState(false); // 초기 상태를 추적합니다.
  //=============== 리스트 가져오기=========================================
  const [customerName, setCustomerName] = useState([]); // 고객사 저장소
  const [NoList, setNoList] = useState([]); //나인원 담당자 저장소
  const [WtList, setWtList] = useState([]); //와탭 담당자 저장소
  const [memberOptions, setMemberOptions] = useState([]); // 멤버 저장소
  //== 수정 시 파일 업로드 위한 state 추가 ==
  const [oldFileList, setOldFileList] = useState([]);
  const [domainFileList, setDomainFileList] = useState([]);

  useEffect(() => {
    /**
     * id값에 맞는 고객사정보 조회
     */
    // const fetchProjectData = async () => {
    //   try {
    //     const response = await instanceAxios.get(`${API.PROJECTLIST}/${id}`);
    //     const data = response.data?.data || [];
    //     setData(data);
    //     setName(data.project);
    //     return data; // 데이터 반환
    //   } catch (error) {
    //     console.error("Error fetching data:", error);
    //     // 에러 처리
    //   }
    // };

    const fetchData = async () => {
      // const data = await fetchProjectData();
      try {
        const [customers, supervisors, members, mainData] = await Promise.all([
          fetchCustomers(),
          fetchSupervisors(),
          fetchMembers(),
          instanceAxios.get(`${API.PROJECTLIST}/${id}`),
        ]);

        const data = mainData.data?.data || [];
        setData(data);
        setName(data.project);

        // 고객사 리스트중 처음 두개를 제외하고 반환
        customers.splice(0, 2);

        setCustomerName(customers);
        setNoList(supervisors.supervisorsNo);
        setWtList(supervisors.supervisorsWt);
        setMemberOptions(members);
        setStart(data.start || "");
        setEnd(data.end || "");

        setDomainFileList(mainData.data.data.fileResponseDtoList);

        // 멤버 초기값 세팅
        if (members.length > 0) {
          const initialMembers = [];
          data.workers.forEach((memberLabel) => {
            const initialMember = members.find(
              (member) => member.label === memberLabel
            );
            if (initialMember) {
              initialMembers.push(initialMember);
            }
          });
          setSelectedMembers(initialMembers);
        }

        // 담당자 초기값 세팅
        const initialSupervisorNo = supervisors.supervisorsNo.find(
          (option) => option.label === data?.supervisorNo
        );
        const initialSupervisorWt = supervisors.supervisorsWt.find(
          (option) => option.label === data?.supervisorWt
        );
        setSupervisorNo(initialSupervisorNo);
        setSupervisorWt(initialSupervisorWt);

        // 고객사 초기값 세팅
        const initialCustomer = customers.find(
          (option) => option.label === data?.customer
        );
        setCustomer(initialCustomer);
      } catch (error) {
        ErrAlert({ err: "데이터 불러오기 실패" });
      } finally {
        // 로딩 상태 업데이트
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  // useEffect(()=>{}, [file,oldFileList]);

  // 종료일이 변경될 때 실행되는 함수
  const handleEndDateChange = (event) => {
    const selectedEndDate = event.target.value;
    setEnd(selectedEndDate);

    // 만약 선택된 종료일이 시작일보다 이전이면 시작일 오류 상태를 설정합니다.
    if (selectedEndDate < start) {
      setStartDateError(true);
    } else {
      setStartDateError(false);
    }
  };

  // 시작일이 변경될 때 실행되는 함수
  const handleStartDateChange = (event) => {
    const selectedStartDate = event.target.value;
    setStart(selectedStartDate);

    // 종료일이 존재하고 선택된 시작일이 종료일보다 이후이면 시작일 오류 상태를 설정합니다.
    if (end && selectedStartDate > end) {
      setStartDateError(true);
    } else {
      setStartDateError(false);
    }
  };

  //=====================Member dropdown select======================
  const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
  }) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    //스타일 설정
    let bg = "transparent";
    if (isFocused) bg = "#eee";
    if (isActive) bg = "#B2D4FF";

    const style = {
      alignItems: "center",
      backgroundColor: bg,
      color: "inherit",
      display: "flex",
    };

    //prop
    const props = {
      ...innerProps,
      onMouseDown,
      onMouseUp,
      onMouseLeave,
      style,
    };

    return (
      <components.Option
        {...rest}
        isDisabled={isDisabled}
        isFocused={isFocused}
        isSelected={isSelected}
        getStyles={getStyles}
        innerProps={props}
      >
        <input type="checkbox" checked={isSelected} />
        {children}
      </components.Option>
    );
  };

  // form submit시 FormData 내용
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (startDateError) {
      Swal.fire({
        icon: "warning",
        title: "주의",
        text: `시작일/종료일을 확인해주세요.`,
        showCancelButton: false,
        confirmButtonText: "확인",
      })
    } else {
      Swal.fire({
        icon: "question",
        title: "수정",
        text: `수정 하시겠습니까?`,
        showCancelButton: true,
        confirmButtonText: "저장",
        cancelButtonText: "취소",
      }).then(async (res) => {
        if (res.isConfirmed) {
          const formData = new FormData();
          formData.append("name", name);
          formData.append("customer", customer.value);
          formData.append("supervisorNo", supervisorNo.value || 1);
          formData.append("supervisorWt", supervisorWt.value || 2);
          formData.append("start", start);
          formData.append("end", end);
          formData.append("content", content);
          formData.append("oldFileList", oldFileList);
          //파일 및 멤버 처리
          for (var i = 0; i < file.length; i++) {
            formData.append("newFileList", file[i]);
          }

          // 멤버 데이터 처리
          const selectedMemberValues = selectedMembers.map(
            (member) => member.value
          );
          formData.append("workers", selectedMemberValues);

          try {
            const response = await instanceAxios.put(
              `${API.PROJECTLIST}/${id}`,
              formData,
              {
                headers: {
                  "Content-Type": `multipart/form-data;charset=UTF-8`,
                },
              }
            );
            ToastAlert({ icon: "success", title: "수정이 완료되었습니다." });
            navigate(`${API.PROJECTDETAIL}/${id}`);
          } catch (error) {
            ErrAlert({ err: "필수 항목을 확인해주세요" });
          }
        } else {
          //취소
        }
      });
    }
  };
  const navigate = useNavigate();
  return (
    <div className="content">
      <div className="detail-wrap">
        <Card className="card-user">
          <CardHeader>
            <CardTitle tag="h5">프로젝트 수정</CardTitle>
          </CardHeader>
          <CardBody>
            {loading ? (
              <Spinner color="primary" />
            ) : (
              <>
                <Form encType="multipart/form-data" onSubmit={handleSubmit}>
                  <FormGroup>
                    <Label>프로젝트</Label>
                    <Input
                      type="text"
                      name="name"
                      onChange={(event) => {
                        setName(event.target.value);
                      }}
                      defaultValue={Data.project}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>고객사</Label>
                    <Select
                      required
                      name="customerId"
                      options={customerName}
                      onChange={(event) => {
                        setCustomer(event); // 여기 잘 넘어가나 확인
                      }}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        control: (provided, state) => ({
                          ...provided,
                          borderColor: isCusInitial
                            ? "#ff0000"
                            : provided.borderColor,
                          // 선택되었을 때와 선택되지 않았을 때의 테두리색을 지정할 수 있습니다.
                        }),
                      }}
                      value={customer}
                    />
                  </FormGroup>
                  <Row>
                    <Col>
                      <FormGroup>
                        <label>영업 담당자(나인원)</label>
                        <Select
                          name="supervisorNo"
                          options={NoList}
                          onChange={(event) => {
                            setSupervisorNo(event);
                            setIsNoInitial(false);
                          }}
                          value={supervisorNo}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label>영업 담당자(와 탭)</label>
                        <Select
                          name="supervisorWt"
                          options={WtList}
                          onChange={(event) => {
                            setSupervisorWt(event);
                            setIsWtInitial(false);
                          }}
                          value={supervisorWt}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <label>작업자</label>
                    <div className="MDrop">
                      <Select
                        name="members"
                        isMulti
                        closeMenuOnSelect={false}
                        onChange={(options) => {
                          if (Array.isArray(options)) {
                            setSelectedMembers(options.map((opt) => opt));
                          }
                        }}
                        options={memberOptions}
                        components={{
                          Option: InputOption,
                        }}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        value={selectedMembers}
                      />
                    </div>
                  </FormGroup>
                  <Row>
                    <Col>
                      <FormGroup>
                        <label>프로젝트 시작일</label>
                        <Input
                          type="date"
                          name="start"
                          value={start}
                          onChange={handleStartDateChange}
                          defaultValue={Data.start}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label>프로젝트 종료일</label>
                        <Input
                          type="date"
                          name="end"
                          value={end}
                          onChange={handleEndDateChange}
                          defaultValue={Data.end}
                        />
                        {startDateError && (
                          <span style={{ color: "red" }}>
                            계약 종료일은 계약 시작일 이후여야 합니다.
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{ paddingLeft: "0.2em" }}>
                    <Col md="6">
                      <FormGroup>
                        <label>기존 첨부 파일</label>
                        <OldFileUpload
                          domainFileList={domainFileList}
                          setDomainFileList={setDomainFileList}
                          setOldFileList={setOldFileList}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label>신규 첨부 파일</label>
                        <FileUpload file={file} setFile={setFile} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>설명</label>
                        {/* <Input
                          className="textArea"
                          type="textarea"
                          name="content"
                          onChange={(event) => {
                            setContent(event.target.value);
                          }}
                          defaultValue={Data.content}
                        /> */}
                        <CkEditor onChange={setContent} data={Data.content} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <DeleteButton
                      to={`${API.PROJECTDETAIL}/${id}`}
                      text1="수정을 취소하시겠습니까?"
                    />
                  </Row>
                </Form>
              </>
            )}
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
export default ProjectModify;
