// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --fc-border-color: rgb(204, 204, 204);

  --fc-button-text-color: #e9efff;
  --fc-button-bg-color: #270571;
}

/* 일요일 날짜: 빨간색 */
.fc-day-sun a {
  color: red;
}

/* 토요일 날짜: 파란색 */
.fc-day-sat a {
  color: blue;
}

.fc-day-mon a {
  color: black;
}

.fc-day-tue a {
  color: black;
}

.fc-day-wed a {
  color: black;
}

.fc-day-thu a {
  color: black;
}

.fc-day-fri a {
  color: black;
}

.event-item:hover {
  transform: scale(1.03); /* 호버 시 3% 확대 */
  transition: transform 0.3s ease; /* 호버 시 부드럽게 전환 */
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/Calendar.css"],"names":[],"mappings":"AAAA;EACE,qCAAqC;;EAErC,+BAA+B;EAC/B,6BAA6B;AAC/B;;AAEA,gBAAgB;AAChB;EACE,UAAU;AACZ;;AAEA,gBAAgB;AAChB;EACE,WAAW;AACb;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,sBAAsB,EAAE,eAAe;EACvC,+BAA+B,EAAE,iBAAiB;AACpD","sourcesContent":[":root {\n  --fc-border-color: rgb(204, 204, 204);\n\n  --fc-button-text-color: #e9efff;\n  --fc-button-bg-color: #270571;\n}\n\n/* 일요일 날짜: 빨간색 */\n.fc-day-sun a {\n  color: red;\n}\n\n/* 토요일 날짜: 파란색 */\n.fc-day-sat a {\n  color: blue;\n}\n\n.fc-day-mon a {\n  color: black;\n}\n\n.fc-day-tue a {\n  color: black;\n}\n\n.fc-day-wed a {\n  color: black;\n}\n\n.fc-day-thu a {\n  color: black;\n}\n\n.fc-day-fri a {\n  color: black;\n}\n\n.event-item:hover {\n  transform: scale(1.03); /* 호버 시 3% 확대 */\n  transition: transform 0.3s ease; /* 호버 시 부드럽게 전환 */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
