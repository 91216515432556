import React, { useState } from "react";
import "../assets/css/CheckButton.css";
import LicenseRD from "views/Customer/RelationDetail/LicenseRD";
import ProjectRD from "views/Customer/RelationDetail/ProjectRD";
import MaintainRD from "views/Customer/RelationDetail/MaintainRD";
import ContractRD from "views/Customer/RelationDetail/ContractRD";

// 관련 리스트의 이름 배열
const RelationListName = ["프로젝트", "유지보수", "계약", "라이선스"];

/**
 * CustomerDetail 페이지의 정보보기란에서 체크된 항목에 따라 리스트를 렌더링하는 컴포넌트
 * @param {object} prop - 부모 컴포넌트에서 전달되는 속성들
 * @returns RelationList 컴포넌트
 */
function RelationList(prop) {
  // 선택된 항목 상태를 관리하는 useState 훅
  const [selectedTitles, setSelectedTitles] = useState([]);

  // 체크박스 변경 이벤트 핸들러
  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    // 이미 선택된 항목인지 확인
    const isSelected = selectedTitles.includes(value);

    // 이미 선택된 항목이면 제거, 아니면 추가
    setSelectedTitles((prevTitles) =>
      isSelected
        ? prevTitles.filter((title) => title !== value)
        : [...prevTitles, value]
    );
  };

  // 체크박스 렌더링 함수
  const RLCheck = RelationListName.map((data) => (
    <label key={data} htmlFor={data}>
      <input
        id={data}
        type="checkbox"
        name="checkRL"
        value={data}
        onChange={handleCheckboxChange}
        checked={selectedTitles.includes(data)}
      />
      <span
        style={{
          marginLeft: "4px",
          fontSize: "13px",
          marginRight: "10px",
        }}
      >
        {data}
      </span>
    </label>
  ));

  // 선택된 항목에 따라 관련 리스트 컴포넌트를 렌더링하는 함수
  const RLSelect = (selectedTitles) => {
    return selectedTitles.map((title) => {
      switch (title) {
        case "프로젝트":
          return <ProjectRD customer={prop.customer.customer} data={prop} />;
        case "유지보수":
          return <MaintainRD customer={prop.customer.customer} data={prop} />;
        case "계약":
          return <ContractRD customer={prop.customer.customer} data={prop} />;
        case "라이선스":
          return <LicenseRD customer={prop.customer.customer} data={prop} />;
        default:
          return null;
      }
    });
  };

  return (
    <>
      <div className="relation-list">
        <h6>
          정보보기 / {selectedTitles.join(", ") || "선택된 항목이 없습니다."}
        </h6>
        <div>{RLCheck}</div>
      </div>
      {RLSelect(selectedTitles)}
    </>
  );
}

export default RelationList;
