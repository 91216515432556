// table list 페이지
// 길이가 길어지면 아래로 내려가는게 나은지 스크롤이 있는게 나은지

import React from "react";
import { useState, useEffect } from "react";
import Paging from "../../variables/Paging";
import "../../assets/css/List.css";
import "../../assets/css/Custom.css";
import instanceAxios from "components/reissue/InstanceAxios";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Col,
  Button,
  Spinner,
} from "reactstrap";

import { useNavigate } from "react-router-dom";
import { API } from "../../ApiConfig";
import SelectBox from "variables/Fillter/SelectBox";
import Swal from "sweetalert2";
import ErrAlert from "variables/ErrAlert";
import SortFilter from "variables/Fillter/SortFilter";
/**
 * 어떤 리스트인지 나타내는 제목입니다.
 */
const titleName = "담당자";

/**
 * 담당자 리스트 페이지
 * @returns table형식의 list page를 리턴합니다.
 */
function SupervisorList() {
  const [supervisorData, setSupervisorData] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0); // 전체 요소 갯수
  const [loading, setLoading] = useState(true); // 로딩 상태 추가
  const PER_PAGE = 10; // 한 페이지 출력 갯수
  const startingIndex = (page - 1) * PER_PAGE + 1; // 현재 페이지 첫번째 아이템 번호

  // 정렬 상태
  const [sortCol, setSortCol] = useState("");
  const [sortState, setSortState] = useState("false");

  // 두 번째 셀렉트로 필터링된 데이터를 저장할 상태
  const [firstSelectValue, setFirstSelectValue] = useState("");
  const [secondSelectValue, setSecondSelectValue] = useState("");
  const [searchValues, setSearchValues] = useState({
    firstSelectValue: "",
    secondSelectValue: "",
  });
  const [isSearch, setIsSearch] = useState(false); // 검색 상태 추가

  async function fetchData(page = 1, col = "", sortState = "true") {
    try {
      const { firstSelectValue, secondSelectValue } = searchValues;
      const response = await instanceAxios.get(
        `${API.SUPERVISORLIST}?page=${page}&${firstSelectValue}=${secondSelectValue}&order=${col}&asc=${sortState}&pageSize=10`
      );
      const supervisorData = response.data?.data?.data || [];
      setSupervisorData(supervisorData);
      setCount(response.data.data.totalElements);
    } catch (error) {
      // 에러 처리
      ErrAlert({ err: "데이터 불러오기 실패" });
    } finally {
      // 로딩 상태 업데이트
      setLoading(false);
    }
  }

  //페이지가 load될때 api에서 data를 가져와 리스트로 리턴
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData(page, sortCol, sortState);
  }, [searchValues, page, sortCol, sortState]);

  /**
   * 전체{}건 을 클릭시 필터가 적용되지 않은 첫 페이지로 돌아갑니다
   */
  const handleTotalCountClick = () => {
    window.location.reload();
  };

  /**
   * 페이지가 변경될 때마다 페이지 번호를 가져와 useState의 setPage를 이용하여 업데이트 해주는 함수입니다.
   * @param {number} page
   */
  const handlePageChange = async (newPage) => {
    setLoading(true); // 로딩 상태를 true로 설정하여 데이터를 가져오는 중임을 나타냄
    setPage(newPage);
    await fetchData(newPage, sortCol, sortState);
  };

  const navigate = useNavigate();

  /**
   * 첫 번째 셀렉트 변경 시 호출되는 함수
   * @param {첫 번째 셀렉트 값} value
   */
  const handleFirstSelectChange = (value) => {
    setFirstSelectValue(value);
  };

  /**
   * 두 번째 셀렉트 변경 시 호출되는 함수
   * @param {두 번째 셀렉트 값} value
   */
  const handleSecondSelectChange = (value) => {
    setSecondSelectValue(value);
  };

  /**
   * 검색버튼을 클릭하면 실행되는 함수
   * 셀렉트 된 값에 맞춰 필터링하여 저장
   */
  const handleSearchButtonClick = () => {
    if (firstSelectValue && secondSelectValue) {
      setPage(1); // 검색 후 첫 페이지로 이동
      setLoading(true); // 로딩 상태를 true로 설정하여 데이터를 가져오는 중임을 나타냄
      setIsSearch(true); // 검색 상태를 true로 설정
      setSearchValues({ firstSelectValue, secondSelectValue });
      // fetchData();
    } else {
      Swal.fire({
        icon: "warning",
        title: "오류",
        text: `검색 카테고리를 선택해주세요.`,
      });
    }
  };

  /**
   * 정렬 버튼 클릭 시 작동되는 함수
   */
  const handleSort = async (col) => {
    const newSortState = sortState === "true" ? "false" : "true";
    setLoading(true);
    setSortCol(col);
    setSortState(newSortState);
    await fetchData(page, col, newSortState);
  };

  /**
   * 배열을 페이지 번호에 맞춰 반복문을 돌려 담아주는 함수입니다.
   * @returns 게시글을 배열에 담아 리턴합니다.
   */

  function arrayList() {
    var array = [];
    const isAscending = sortState === "true";

    // 초기 리스트에 데이터가 없을 때
    if (!supervisorData || supervisorData.length === 0) {
      if (isSearch) {
        return (
          <tr key="no-search-data" style={{ textAlign: "center" }}>
            <td colSpan="7">검색된 결과가 없습니다.</td>
          </tr>
        );
      } else {
        return (
          <tr key="no-data" style={{ textAlign: "center" }}>
            <td colSpan="7">등록된 글이 없습니다.</td>
          </tr>
        );
      }
    }
    // 초기 리스트에 데이터가 있을 때
    supervisorData.map((item, index) => {
      const isDeleted = item.deleteTandF; // 삭제 여부 확인
      const displayIndex = isAscending ? (page - 1) * PER_PAGE + index + 1 : count - ((page - 1) * PER_PAGE + index);
      array.push(
        <tr
          key={item.id}
          id="move"
          onClick={() => {
            navigate(`${API.SUPERVISORDETAIL}/${item.id}`);
          }}
          style={{ textAlign: "center" }}
        >
          <div
            className={`five-list-wrap ${isDeleted ? "deleted-text" : "-"}`}
          >
            <div className={"five-list-left"}>
              <td>{displayIndex || "-"}</td>
              <td>{item.supervisor || "-"}</td>
              <td>{item.phone || "-"}</td>
            </div>
            <div className={"five-list-right"}>
              <td>{item.customer || "-"}</td>
              <td>{item.location !== "undefined" ? item.location : "-"}</td>
            </div>
          </div>
        </tr>
      );
    });

    return array;
  }

  return (
    <>
      <div className="content">
        <Card>
          <CardHeader>
            <CardTitle className="cardTitle" tag="h4">
              {titleName}
            </CardTitle>
          </CardHeader>
          <CardBody>
            {/* <Table responsive /> */}
            <div style={{ display: "flex" }}>
              <Col md="auto" className="d-flex align-items-center">
                <p
                  className="category"
                  style={{ fontSize: "17px", display: "flex" }}
                >
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={handleTotalCountClick}
                    onMouseEnter={(e) => (e.target.style.color = "#240170")}
                    onMouseLeave={(e) => (e.target.style.color = "#9A9A9A")}
                  >
                    전체 {count}건
                  </span>
                </p>
              </Col>
              <SelectBox
                onFirstSelectChange={handleFirstSelectChange}
                onSecondSelectChange={handleSecondSelectChange}
                options="supervisor"
                handleSearchButtonClick={handleSearchButtonClick}
              />
              <Col md="auto" className="d-flex align-items-center">
                <i
                  id="iconBtn"
                  className="nc-icon nc-zoom-split"
                  onClick={handleSearchButtonClick}
                  color="primary"
                />
              </Col>
            </div>

            <Table>
              <thead className="text-primary">
                <tr className={"five-title-wrap"}>
                  <div className={"five-title-left"}>
                    <SortFilter
                      text="번호"
                      value="number"
                      handleSort={handleSort}
                      isActive={sortCol === "number"}
                      activeCol={sortCol}
                      sortState={sortState}
                    />
                    <th>담당자</th>
                    <th>연락처</th>
                  </div>
                  <div className={"five-title-right"}>
                    <th>고객사</th>
                    <th>주소</th>
                  </div>
                </tr>
              </thead>
              {loading ? (
                <Spinner color="primary" style={{ marginTop: "15px" }} />
              ) : (
                <>
                  <tbody>{arrayList()}</tbody>
                </>
              )}
            </Table>
            {!loading && (
              <div style={{ textAlign: "right", padding: "0px 15px" }}>
                <Button
                  outline
                  color="primary"
                  type="submit"
                  onClick={() => navigate(`${API.SUPERVISORREGIST}`)}
                >
                  등록
                </Button>
              </div>
            )}
          </CardBody>
          {!loading && (
            <Paging
              count={count}
              page={page}
              perPage={PER_PAGE}
              handlePageChange={handlePageChange}
            />
          )}
        </Card>
      </div>
    </>
  );
}

export default SupervisorList;
