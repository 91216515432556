import { API } from "../../ApiConfig";
import React from "react";
import { useNavigate } from "react-router-dom";
import { NavItem } from "reactstrap";
import Swal from "sweetalert2";
import MaterialIcon from "react-google-material-icons";

const Logout = () => {
  const navigate = useNavigate();
  // 로그아웃 이벤트
  const tokenRemove = () => {
    Swal.fire({
      icon: "warning",
      title: "로그아웃 하시겠습니까?",
      showCancelButton: true,
      confirmButtonText: "예",
      cancelButtonText: "아니요",
    }).then((res) => {
      if (res.isConfirmed) {
        // 토큰 비우기
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("memRole")
        // 삭제 요청 처리 후 이동
        navigate(`/${API.LOGIN}`);
      } else {
        // 취소
      }
    });
  };
  //
  return (
    <NavItem>
      <div
        className="nav-link btn-rotate"
        style={{ marginRight: "-5px", cursor: "pointer" }}
      >
        {/* <span onClick={tokenRemove} style={{fontSize:"x-large"}}>
        ↪️
        </span> */}
        {/* <a className="text-danger" onClick={tokenRemove}>
        <i onClick={tokenRemove} className="nc-icon nc-button-power"/>
        Logout
      </a> */}
        <span onClick={tokenRemove} className="text-danger">
          <MaterialIcon icon="logout" size={30} />
        </span>
      </div>
    </NavItem>
  );
};

export default Logout;
