function ClassFilter(props) {

  const classOptions = props.classOptions;

  // 클래스 필터 오픈 상태와 오픈 상태를 변경하는 함수
  const classOpen = props.classOpen;
  const setClassOpen = props.setClassOpen;

  /**
   * 상태 체크박스 체크 여부 배열
   */
  function calculateChk() {
    // 체크박스 true, false 배열
    var tempCheck = [];
    // 각 클래스 옵션의 체크 여부 확인
    for (var i = 0; i < classOptions.length; i++) {
      tempCheck.push(
        document.getElementById("classCondition").childNodes[i].childNodes[0]
          .checked
      );
    }

    var tempFilter = [];
    // 체크된 항목 필터링
    tempCheck.map((check, index) => {
      if (check === true) {
        tempFilter.push(classOptions[index].value);
      }
    });
    props.handleClassFilter(tempFilter);
  }

  // 필터 옵션 뷰 생성 함수
  const filterView = () => {
    return classOptions.map((option) => {
      return (
        <li key={option.value}>
          <input
            type="checkbox"
            name="chk"
            id={option.value}
            value={option.value}
            onChange={(event) => {
              calculateChk();
            }}
            defaultChecked
          />{" "}
          <label
            style={{
              fontSize: "1em",
              color: "black",
              width: "4em",
              margin: "auto",
            }}
          >
            {option.label}
          </label>
        </li>
      );
    });
  };

  // 선택 영역 열고 닫는 함수
  const openCloseSelect = (state) => {
    document.getElementById("Class").style.visibility = `${state}`;
    //setOpenClose("")
  };
  // ▼ 버튼 클릭 시 계약상태 체크박스 영역 열고 닫기
  function openCloseBtn() {
    if (classOpen === false) {
      openCloseSelect("visible");
      setClassOpen(true);
    } else {
      openCloseSelect("hidden");
      setClassOpen(false);
    }
  }

  return (
    <>
      {/* 옵션 열고 닫는 버튼 */}
      <span
        style={{ position: "absolute", width: "auto" }}
        type="button"
        onClick={() => {
          openCloseBtn();
        }}
      >
        ⚙️
      </span>
      {/* 필터링된 클래스 옵션 */}
      <div
        id="Class"
        className="filter-div"
        style={{
          position: "absolute",
          // right:"0",
          width: "auto",
          borderRadius: "0.5em",
          border: "1px solid lightgray",
          visibility: "hidden",
          fontSize: "12px",
          backgroundColor: "whitesmoke",
          zIndex: "1",
        }}
      >
        <ul id="classCondition" className="filter-wrap">
          {filterView()}
        </ul>
      </div>
    </>
  );
}
export default ClassFilter;
