// table list 페이지
import React, { useEffect } from "react";
import { useState } from "react";
import Paging from "../../variables/Paging";
import "../../assets/css/List.css";
import "../../assets/css/Custom.css";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Col,
  Button,
  Spinner,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { API } from "../../ApiConfig";
import instanceAxios from "components/reissue/InstanceAxios";
import SelectBox from "variables/Fillter/SelectBox";
import Swal from "sweetalert2";
import ErrAlert from "variables/ErrAlert";
import SortFilter from "variables/Fillter/SortFilter";

/**
 * 어떤 리스트인지 나타내는 제목입니다.
 */
const titleName = "라이선스";

/**
 * 라이선스 리스트 페이지
 * @returns table형식의 list page를 리턴합니다.
 */
function LicenseList() {
  const [licenseData, setLicenseData] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0); // 전체 요소 갯수
  const [loading, setLoading] = useState(true); // 로딩 상태 추가
  const PER_PAGE = 10; // 한 페이지 출력 갯수
  const startingIndex = (page - 1) * PER_PAGE + 1; // 현재 페이지 첫번째 아이템 번호
  // 정렬 상태
  const [sortCol, setSortCol] = useState("");
  const [sortState, setSortState] = useState("false");

  // 두 번째 셀렉트로 필터링된 데이터를 저장할 상태
  const [firstSelectValue, setFirstSelectValue] = useState("");
  const [secondSelectValue, setSecondSelectValue] = useState("");
  const [searchValues, setSearchValues] = useState({
    firstSelectValue: "",
    secondSelectValue: "",
  });
  const [isSearch, setIsSearch] = useState(false); // 검색 상태 추가

  async function fetchData(page = 1, col = "", sortState = "true") {
    try {
      const { firstSelectValue, secondSelectValue } = searchValues;
      const response = await instanceAxios.get(
        `${API.LICENSELIST}?page=${page}&${firstSelectValue}=${secondSelectValue}&order=${col}&asc=${sortState}&pageSize=10`
      );
      const data = response.data?.data?.data || [];
      setLicenseData(data);
      setCount(response.data.data.totalElements);
    } catch (error) {
      // 에러 처리
      ErrAlert({ err: "데이터 불러오기 실패" });
    } finally {
      // 로딩 상태 업데이트
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData(page, sortCol, sortState);
  }, [searchValues, page, sortCol, sortState]);

  useEffect(() => {
    fetchData();
  }, []);

  /**
   * 전체{}건 을 클릭시 필터가 적용되지 않은 첫 페이지로 돌아갑니다
   */
  const handleTotalCountClick = () => {
    window.location.reload();
  };

  /**
   * 페이지가 변경될 때마다 페이지 번호를 가져와 useState의 setPage를 이용하여 업데이트 해주는 함수입니다.
   * @param {number} page
   */
  const handlePageChange = async (newPage) => {
    setLoading(true); // 로딩 상태를 true로 설정하여 데이터를 가져오는 중임을 나타냄
    setPage(newPage);
    await fetchData(newPage, sortCol, sortState);
  };

  const navigate = useNavigate();

  /**
   * 첫 번째 셀렉트 변경 시 호출되는 함수
   * @param {첫 번째 셀렉트 값} value
   */
  const handleFirstSelectChange = (value) => {
    setFirstSelectValue(value);
  };

  /**
   * 두 번째 셀렉트 변경 시 호출되는 함수
   * @param {두 번째 셀렉트 값} value
   */
  const handleSecondSelectChange = (value) => {
    setSecondSelectValue(value);
  };

  /**
   * 검색버튼을 클릭하면 실행되는 함수
   * 셀렉트 된 값에 맞춰 필터링하여 저장
   */
  const handleSearchButtonClick = () => {
    if (firstSelectValue && secondSelectValue) {
      setPage(1); // 검색 후 첫 페이지로 이동
      setLoading(true); // 로딩 상태를 true로 설정하여 데이터를 가져오는 중임을 나타냄
      setIsSearch(true); // 검색 상태를 true로 설정
      setSearchValues({ firstSelectValue, secondSelectValue });
      // fetchData();
    } else {
      Swal.fire({
        icon: "warning",
        title: "오류",
        text: `검색 카테고리를 선택해주세요.`,
      });
    }
  };

  /**
   * 정렬 버튼 클릭 시 작동되는 함수
   */
  const handleSort = async (col) => {
    const newSortState = sortState === "true" ? "false" : "true";
    setLoading(true);
    setSortCol(col);
    setSortState(newSortState);
    await fetchData(page, col, newSortState);
  };

  /**
   * 배열을 페이지 번호에 맞춰 반복문을 돌려 담아주는 함수입니다.
   * @returns 게시글을 배열에 담아 리턴합니다.
   */
  function arrayList() {
    var array = [];
    const isAscending = sortState === "true";

    // 리스트에 데이터가 없을 때
    if (!licenseData || licenseData.length === 0) {
      if (isSearch) {
        return (
          <tr key="no-search-data" style={{ textAlign: "center" }}>
            <td colSpan="7">검색된 결과가 없습니다.</td>
          </tr>
        );
      } else {
        return (
          <tr key="no-data" style={{ textAlign: "center" }}>
            <td colSpan="7">등록된 글이 없습니다.</td>
          </tr>
        );
      }
    }
    
    // 리스트에 데이터가 있을 때
    licenseData.map((item, index) => {
      const isDeleted = item.deleteTandF;
      const displayIndex = isAscending ? (page - 1) * PER_PAGE + index + 1 : count - ((page - 1) * PER_PAGE + index);
      array.push(
        <tr
          key={item.id}
          id="move"
          onClick={() => navigate(`${API.LICENSEDETAIL}/${item.id}`)}
          style={{ textAlign: "center" }}
        >
          <div
            className={`license-list-wrap ${isDeleted ? "deleted-text" : ""}`}
          >
            <div className={"license-list-left"}>
              <td>{displayIndex || "-"}</td>
              <td>{item.customer || "-"}</td>
              <td>{item.license || "-"}</td>
              {item.itemList && item.itemList.length > 0 ? (
                <td>
                  {item.itemList.every(subItem =>
                    subItem.majorCategory === "" &&
                    subItem.middleCategory === "" &&
                    subItem.smallCategory === "" &&
                    subItem.itemDetail === ""
                  ) ? (
                    <span>-</span>
                  ) : (
                    item.itemList.map((subItem, index) => (
                      <span key={index}>
                        {"["}
                        {subItem.majorCategory}, {subItem.middleCategory}, {subItem.smallCategory}, {subItem.itemDetail}
                        {"]"}
                        {index !== item.itemList.length - 1 ? " / " : ""}
                      </span>
                    ))
                  )}
                </td>
              ) : (
                <td>-</td>
              )}
            </div>
            <div className={"license-list-right"}>
              <td>{item.issueDate || "-"}</td>
              <div className={"license-date-wrap"}>
                <td>{item.startDate || "-"}</td>
                <div className={"date-period-mark"}>~</div>
                <td>{item.expiredDate || "-"}</td>
              </div>
            </div>
          </div>
        </tr>
      );
    });


    return array;
  }
  return (
    <>
      <div className="content">
        <Card>
          <CardHeader>
            <CardTitle tag="h4" className="cardTitle">
              {titleName}
            </CardTitle>
          </CardHeader>
          <CardBody>
            {/* <Table responsive /> */}
            <div style={{ display: "flex" }}>
              <Col md="auto" className="d-flex align-items-center">
                <p
                  className="category"
                  style={{ fontSize: "17px", display: "flex" }}
                >
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={handleTotalCountClick}
                    onMouseEnter={(e) => (e.target.style.color = "#240170")}
                    onMouseLeave={(e) => (e.target.style.color = "#9A9A9A")}
                  >
                    전체 {count}건
                  </span>
                </p>
              </Col>
              <SelectBox
                onFirstSelectChange={handleFirstSelectChange}
                onSecondSelectChange={handleSecondSelectChange}
                options="license"
                handleSearchButtonClick={handleSearchButtonClick}
              />
              <Col md="auto" className="d-flex align-items-center">
                <i
                  id="iconBtn"
                  className="nc-icon nc-zoom-split"
                  onClick={handleSearchButtonClick}
                  color="primary"
                />
              </Col>
            </div>

            <Table>
              <thead className="text-primary">
                <tr className={"license-title-wrap"}>
                  <div className={"license-title-left"}>
                    <SortFilter
                      text="번호"
                      value="number"
                      handleSort={handleSort}
                      isActive={sortCol === "number"}
                      activeCol={sortCol}
                      sortState={sortState}
                    />
                    <th>고객사</th>
                    <th>라이선스명</th>
                    {/* <th>프로젝트</th> */}
                    <th>항목 정보</th>
                  </div>
                  <div className={"license-title-right"}>
                    <SortFilter
                      text="발급일자"
                      value="issueDate"
                      handleSort={handleSort}
                      isActive={sortCol === "issueDate"}
                      activeCol={sortCol}
                      sortState={sortState}
                    />
                    <SortFilter
                      text="시작일자"
                      value="startDate"
                      handleSort={handleSort}
                      isActive={sortCol === "startDate"}
                      activeCol={sortCol}
                      sortState={sortState}
                    />
                    <SortFilter
                      text="만료일자"
                      value="expiredDate"
                      handleSort={handleSort}
                      isActive={sortCol === "expiredDate"}
                      activeCol={sortCol}
                      sortState={sortState}
                    />
                  </div>
                </tr>
              </thead>
              {loading ? (
                <Spinner color="primary" style={{ marginTop: "15px" }} />
              ) : (
                <>
                  <tbody>{arrayList()}</tbody>
                </>
              )}
            </Table>
            {!loading && (
              <div style={{ textAlign: "right", padding: "0px 15px" }}>
                <Button
                  outline
                  color="primary"
                  type="submit"
                  onClick={() => navigate(`${API.LICENSEREGIST}`)}
                >
                  등록
                </Button>
              </div>
            )}
          </CardBody>
          {!loading && (
            <Paging
              count={count}
              page={page}
              perPage={PER_PAGE}
              handlePageChange={handlePageChange}
            />
          )}
        </Card>
      </div>
    </>
  );
}

export default LicenseList;
