import FullCalendar from "@fullcalendar/react";
import React, { useState, useEffect } from "react";
import dayGridPlugin from "@fullcalendar/daygrid";
import googleCalendarPlugin from "@fullcalendar/google-calendar";
import "../../assets/css/Calendar.css";
import instanceAxios from "components/reissue/InstanceAxios";
import { API } from "ApiConfig";
import { useNavigate } from "react-router-dom";
import { Spinner } from "reactstrap";
import ErrAlert from "variables/ErrAlert";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";

// 캘린더 컴포넌트 정의
function Calendar() {
  // apiKey 환경변수 설정
  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // 지원, 유지보수, 라이선스 이벤트 데이터 상태 설정
  const [supData, setSupData] = useState([]);
  const [mtData, setMtData] = useState([]);
  const [lcData, setLcData] = useState([]);
  const [folData, setFolData] = useState([]);

  const fetchEvents = async () => {
    try {
      // 병렬 호출을 위해 Promise.all 사용
      const [supportResponse, maintainResponse, licenseResponse, followingResponse] =
        await Promise.all([
          instanceAxios.get(`${API.SUPPORTCALENDAR}`),
          instanceAxios.get(`${API.REPAIRCALENDAR}`),
          instanceAxios.get(`${API.LICENSECALENDAR}`),
          instanceAxios.get(`${API.FOLLOWINGCALENDAR}`),
        ]);

      const supportData = supportResponse.data?.data || [];
      const maintainData = maintainResponse.data?.data || [];
      const licenseData = licenseResponse.data?.data || [];
      const followingData = followingResponse.data?.data || [];

      // 지원 이벤트 데이터 변환
      const newSupData = supportData
        .map((res) => {
          if (res.start) {
            const date = res.start && res.start.split(" ")[0];
            const workersString = res.workers.join(", ");
            let title;
            if (workersString.length > 0) {
              title = `[${workersString}](${res.customer})${res.support}`;
            } else {
              title = `(${res.customer})${res.support}`;
            }
            return {
              id: res.id,
              title: title,
              date: date,
              description: "support",
              backgroundColor: "#D551F6",
              borderColor: "#D551F6",
            };
          } else {
            return null;
          }
        })
        .filter((item) => item !== null);

      // followingData를 newSupData에 추가
      const newFollowingData = followingData.map((res) => {
        const date = res.start && res.start.split(" ")[0];
        const workersString = res.workers.join(", ");
        let title;
        if (workersString.length > 0) {
          title = `[${workersString}](${res.customer})${res.following}`;
        } else {
          title = `(${res.customer})${res.following}`;
        }
        return {
          id: res.id,
          title: title,
          date: date,
          description: "following",
          backgroundColor: "#D551F6", // 색상은 필요에 따라 변경 가능
          borderColor: "#D551F6", // 색상은 필요에 따라 변경 가능
          supportId: res.supportId,
        };
      });


      // 유지보수 이벤트 데이터 변환
      const newMtData = maintainData.map((res) => {
        // const date = res.start && res.start.split(" ")[0];
        const workersString = res.workers.join(", ");
        let title;
        if (workersString.length > 0) {
          title = `[${workersString}](${res.customer})`;
        } else {
          title = `(${res.customer})`;
        }
        return {
          id: res.id,
          title: title,
          date: res.start,
          allDay: true,
          description: "maintain",
          backgroundColor: "#9487FF",
          borderColor: "#9487FF",
        };
      });

      // 라이선스 이벤트 데이터 변환
      const newLcData = licenseData.map((res) => {
        return {
          id: res.id,
          title: "[라이센스 만료]" + `(${res.division})[${res.license}]`,
          date: res.expiredDate,
          description: "license",
          backgroundColor: "gray",
          borderColor: "gray",
        };
      });

      // 데이터 설정
      setSupData(newSupData);
      setMtData(newMtData);
      setLcData(newLcData);
      setFolData(newFollowingData);
      // 성공 시 setLoading(false);
      setLoading(false);
    } catch (error) {
      ErrAlert({ err: "데이터 불러오기 실패" });
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEvents();

    // 화면 크기 변경 이벤트 리스너 등록
    window.addEventListener("resize", handleResize);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // 빈 배열을 의존성으로 전달하여 한 번만 실행되도록 함

  // 화면 크기가 변경될 때 호출되는 함수
  const handleResize = () => {
    // 화면 크기가 변경되면 다시 캘린더를 렌더링함
    fetchEvents();
  };
  const navigate = useNavigate();

  if (!apiKey) {
    return <div>Error: Google API key가 없습니다.</div>;
  }

  // 이벤트에 마우스 진입 시 발생하는 함수
  const handleEventMouseEnter = (info) => {
    if (
      info.event._def.extendedProps.description === "공휴일" ||
      info.event._def.extendedProps.description.includes("기념일")
    ) {
      return (info.el.style.cursor = "auto");
    }
    info.el.style.cursor = "pointer"; // 마우스 진입 시 커서 모양 변경
    info.el.classList.add("event-item"); // event-item 클래스 추가
  };

  // 이벤트에서 마우스가 떠날 때 발생하는 함수
  const handleEventMouseLeave = (info) => {
    if (
      info.event._def.extendedProps.description === "공휴일" ||
      info.event._def.extendedProps.description.includes("기념일")
    ) {
      return;
    }
    info.el.classList.remove("event-item"); // event-item 클래스 제거
  };

  return (
    <div id="calendar" className="cal-container">
      {/* <iframe
        src="https://calendar.google.com/calendar/embed?src=c_0d9e96bf1079b44dc98d0628cd37a5a38ef9eb19dcd0d511fb773492bc434268%40group.calendar.google.com&ctz=Asia%2FSeoul"
        style={{
          border: "0",
          width: "800px",
          height: "600px",
          frameborder: "0",
          scrolling: "no",
        }}
      ></iframe> */}
      {error && <div>Error loading events: {error}</div>}
      {loading ? (
        <Spinner color="primary" />
      ) : (
        <FullCalendar
          plugins={[
            dayGridPlugin,
            googleCalendarPlugin,
            timeGridPlugin,
            listPlugin,
          ]}
          locale="ko"
          expandRows={true} // 화면에 맞게 높이 재설정
          googleCalendarApiKey={apiKey}
          eventSources={[
            {
              googleCalendarId:
                "ko.south_korea#holiday@group.v.calendar.google.com",
              color: "#FF8484",
            },
            // {
            //   googleCalendarId:
            //     "c_0d9e96bf1079b44dc98d0628cd37a5a38ef9eb19dcd0d511fb773492bc434268@group.calendar.google.com",
            //   color: "#FF8484",
            // },
          ]}
          events={[...supData, ...mtData, ...lcData, ...folData]}
          // 공휴일 클릭 시 구글 캘린더로 넘어가는 것을 막아줌
          eventClick={(info) => {
            info.jsEvent.stopPropagation();
            info.jsEvent.preventDefault();
            // 클릭한 일정id
            var eventId = info.event._def.publicId;
            // 클릭한 일정 구분
            var eventDiv = info.event._def.extendedProps.description;
            // 클릭한 이벤트의 supportId 가져오기
            const supportId = info.event.extendedProps.supportId;
            // id값을 받아 해당 detail페이지로 이동
            if (eventDiv === "support") {
              navigate(`${API.SUPPORTDETAIL}/${eventId}`);
            } else if (eventDiv === "maintain") {
              navigate(`${API.MAINTAINDETAIL}/${eventId}`);
            } else if (eventDiv === "license") {
              navigate(`${API.LICENSEDETAIL}/${eventId}`);
            } else if (eventDiv === "following") {
              navigate(`${API.SUPPORTDETAIL}/${supportId}`);
            }
          }}
          eventMouseEnter={handleEventMouseEnter}
          eventMouseLeave={handleEventMouseLeave}
          eventDisplay={"block"}
          eventTextColor={"#FFF"}
          height={"auto"}
          eventTimeFormat={{
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          }}
          // slotMinTime={"08:00:00"}
          // slotMaxTime={"21:00:00"}
          // views={{
          //   listWeek: {
          //     eventClassNames: "list-week-event", // ListWeek 뷰에서 이벤트를 구분할 클래스 지정
          //   },
          // }}
          headerToolbar={{
            left: "prev next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
          }}
          slotLabelFormat={{
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          }}
          dayHeaderFormat={{ weekday: 'short' }} // 요일을 짧게 표시 (월, 화, 수, ...)
          dayCellContent={(arg) => arg.date.getDate()} // 날짜를 숫자로만 표시
          dayMaxEvents={4}
        />
      )}
    </div>
  );
}

export default Calendar;
