
import instanceAxios from "components/reissue/InstanceAxios";
import { API } from "./ApiConfig";
import Swal from "sweetalert2";

// 담당자 목록 가져오기
export const fetchSupervisors = async () => {
    try {
        const NoResponse = await instanceAxios.get(`${API.SUPERVISORNAME}?supervisorClass=NINEONE`);    //나인원 담당자
        const WtResponse = await instanceAxios.get(`${API.SUPERVISORNAME}?supervisorClass=WATAB`);      //와탭 담당자
        const NoData = NoResponse.data.data;
        const WtData = WtResponse.data.data;

        const filteredSupervisorNo = NoData
            .map((supervisor) => ({
                value: supervisor.id,
                label: supervisor.supervisor,
            }));

        const filteredSupervisorWt = WtData
            .map((supervisor) => ({
                value: supervisor.id,
                label: supervisor.supervisor,
            }));

        return { supervisorsNo: filteredSupervisorNo, supervisorsWt: filteredSupervisorWt };
    } catch (error) {
        Swal.fire({
            icon: "warning",
            title: "오류",
            text: `오류가 발생했습니다.`,
        });
        throw error;
    }
};

//고객사 목록 가져오기
export const fetchCustomers = async () => {
    try {
        const response = await instanceAxios.get(`${API.CUSTOMERLIST}/name`);
        const customerData = response.data.data.map((customer) => ({
            value: customer.id,
            label: customer.customer,
        }));

        return customerData;
    } catch (error) {
        Swal.fire({
            icon: "warning",
            title: "오류",
            text: `오류가 발생했습니다.`,
        });
        throw error;
    }
};

// export const fetchCustomerAddressesById = async (customerId) => {
//     try {
//         const response = await instanceAxios.get(`${API.CUSTOMERLIST}/${customerId}`);

//         // const { customerAddressInfoList } = response.data.data;
//         // const addresses = JSON.parse(customerAddressInfoList).map(
//         //     (address) => ({
//         //         value: address.location,
//         //         label: address.location,
//         //     })
//         // );

//         const { data } = response.data;
//         const addresses = data.customerAddress.map((address) => ({
//             value: address.location,
//             label: address.location,
//         }));

//         return addresses;
//     } catch (error) {
//         console.error("Error fetching customer addresses:", error);
//         throw new Error("Failed to fetch customer addresses");
//     }
// };

// 선택한 고객사 id별 지점명 목록 가져오기
export const fetchCustomerAddressesById = async (customerId) => {
    try {
        // 선택한 고객사 id에 해당하는 상세 정보 가져오기
        const response = await instanceAxios.get(`${API.CUSTOMERLIST}/${customerId}`);
        const customerData = response.data?.data;

        if (customerData.customerAddress && customerData.customerAddress.length > 0) {
            const addresses = customerData.customerAddress.reduce((acc, address) => {
                // 값과 라벨이 모두 빈 문자열이 아닌 경우에만 주소를 추가합니다.
                if ((address.location !== "" && address.location !== null && address.location !== undefined) ||
                    (address.address !== "" && address.address !== null && address.address !== undefined)) {
                    acc.push({
                        value: address.location,
                        label: address.location,
                    });
                }
                return acc;
            }, []);
            return addresses;   // 필터링된 주소 목록 반환
        } else {
                                // customerAddress가 비어 있거나 존재하지 않는 경우, 빈 배열을 반환합니다.
            return [];          // 주소 목록이 비어 있는 경우 빈 배열 반환
        }
    } catch (error) {
        Swal.fire({
            icon: "warning",
            title: "오류",
            text: `오류가 발생했습니다.`,
        });
        throw new Error("Failed to fetch customer addresses");
    }
};

// Member(나인원 담당자) 목록 가져오기
export const fetchMembers = async () => {
    try {
        const response = await instanceAxios.get(`${API.SUPERVISORNAME}?supervisorClass=NINEONE`);

        const supervisors = response.data.data;
        // const NineoneSup = supervisors.filter(
        //     (supervisor) => supervisor.supervisorClass === "NINEONE"
        // ).map((member) => ({
        //     value: member.id,
        //     label: member.supervisor,
        // }));

        const NineoneSup = supervisors.map(
            (member) => ({
                value: member.id,
                label: member.supervisor,
            }));

        return NineoneSup;

        // const memberData = response.data.data.map((member) => ({
        //     value: member.id,
        //     label: member.name,
        // }));

        // return memberData;
    } catch (error) {
        Swal.fire({
            icon: "warning",
            title: "오류",
            text: `오류가 발생했습니다.`,
        });
        throw error;
    }
};

// 선택한 고객사 Id별 프로젝트 가져오기
export const fetchProjectsByCustomerName = async (customerId) => {
    try {
        const response = await instanceAxios.get(`${API.PROJECTLIST}/list-by-customer?customerId=${customerId}`);

        const projects = response.data.data;

        return projects;
    } catch (error) {
        Swal.fire({
            icon: "warning",
            title: "오류",
            text: `오류가 발생했습니다.`,
        });
        throw new Error("Failed to fetch projects by customer name");
    }
};

// 선택한 고객사별 담당자 가져오기
export const fetchSupervisorByCustomerName = async (customerName) => {
    try {
        const response = await instanceAxios.get(`${API.SUPERVISORNAME}?supervisorClass=CUSTOMER`);

        const supervisors = response.data.data;
        const supervisorsByCustomerName = supervisors.filter(
            (supervisor) => supervisor.customer === customerName
        );

        return supervisorsByCustomerName;
    } catch (error) {
        Swal.fire({
            icon: "warning",
            title: "오류",
            text: `오류가 발생했습니다.`,
        });
        throw new Error("Failed to fetch supervisors by customer name");
    }
}

// 선택한 고객사 Id별 계약 가져오기
export const fetchContractsByCustomerName = async (customerId) => {
    try {
        const response = await instanceAxios.get(`${API.CONTRACTLIST}/list-by-customer?customerId=${customerId}`);
        const contracts = response.data?.data;

        // customerName과 contractClass가 REPAIR인 데이터만 필터링합니다.
        const filteredContracts = contracts.filter(contract =>
            contract.contractClass === "REPAIR"
        );

        return filteredContracts;
    } catch (error) {
        Swal.fire({
            icon: "warning",
            title: "오류",
            text: `오류가 발생했습니다.`,
        });
        throw new Error("Failed to fetch contracts by customer name");
    }
};

// 계약 id로 정보 가져오기
export const fetchContractById = async (contractId) => {
    try {
        const response = await instanceAxios.get(`${API.CONTRACTLIST}/${contractId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};
