// 유지보수계약 상세페이지
import React, { useEffect, useState } from "react";
import "../../variables/RelationList";
import {
  Card,
  CardHeader,
  CardBody,
  Form,
  Row,
  Col,
  Spinner,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { useParams } from "react-router-dom";
import { API } from "../../ApiConfig";
import instanceAxios from "components/reissue/InstanceAxios";
import ContractDetailButton from "variables/ContractDetailButton";
import FileDownView from "views/Support/FileDownView";
import ErrAlert from "variables/ErrAlert";

const titleName = "유지보수 계약";

/**
 *
 * @returns 유지보수계약 리스트에서 클릭한 id값에 맞는 단일 게시물 상세보기 페이지를 리턴
 */
function ContractDetail2() {
  const [contract, setContract] = useState([]);
  const [loading, setLoading] = useState(true); // 로딩 상태 추가

  // 수정, 정보보기에 사용되는 클릭한 id값
  const { id } = useParams();

  /**
   * 유지보수계약 데이터를 불러와 사용하기 편하도록 변환하는 함수
   */
  async function fetchData() {
    try {
      const response = await instanceAxios.get(`${API.CONTRACTLIST}/${id}`);
      const data = response.data?.data || [];
      setContract(data);
    } catch (error) {
      ErrAlert({ err: "데이터 불러오기 실패" });
      // 에러 처리
    } finally {
      // 로딩 상태 업데이트
      setLoading(false);
    }
  }

  useEffect(() => {
    // 페이지 로딩 시 정보 가져오기
    fetchData();
  }, [id]); // ID가 변경될 때마다 useEffect 실행

  // 작업상태 값 변경 ex) WORKING -> 작업 진행중
  const [conStates, setConStates] = useState([]);

  useEffect(() => {
    // maintainData가 존재하고, maintainData가 배열일 경우에만 작업 상태 값을 설정
    const updatedConStates = () => {
      if (contract.state === "CONTRACT_COMPLETE") {
        return "계약 완료";
      } else if (contract.state === "CONTRACTING") {
        return "계약중";
      } else if (contract.state === "ETC") {
        return "기타";
      } else if (contract.state === "CONTRACT_EXPIRED") {
        return "계약 만료";
      } else {
        return "-";
      }
    };
    setConStates(updatedConStates);
  }, [contract]);

  return (
    <>
      <div className="content">
        <Form>
          <Row>
            <Col md="12">
              <div className="detail-wrap">
                <Card className="card-user">
                  <CardHeader>
                    <h5 className="title">{titleName} 상세보기</h5>
                    <p className="category" style={{ fontSize: "17px" }}></p>
                  </CardHeader>
                  <CardBody>
                    {loading ? (
                      <Spinner color="primary" />
                    ) : (
                      <>
                        <Row>
                          <Col md="8">
                            <FormGroup>
                              <Label>계약명</Label>
                              <Input
                                className="detail-input"
                                value={contract.contract || "-"}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>지원 형태</Label>
                              <Input
                                className="detail-input"
                                value={contract.division || "-"}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>계약상태</Label>
                              <Input
                                className="detail-input"
                                value={conStates || "-"}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <FormGroup>
                          <Label>고객사</Label>
                          <Input
                            className="detail-input"
                            value={contract.customer || "-"}
                            disabled
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>프로젝트</Label>
                          <Input
                            className="detail-input"
                            value={contract.project || "-"}
                            disabled
                          />
                        </FormGroup>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label>계약 시작</Label>
                              <Input
                                className="detail-input"
                                value={contract.start || "-"}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>계약 종료</Label>
                              <Input
                                className="detail-input"
                                value={contract.end || "-"}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>최초 유지보수 예정일</Label>
                              <Input
                                className="detail-input"
                                value={contract.firstDate || "-"}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label>고객사 담당자</Label>
                              <Input
                                className="detail-input"
                                value={contract.supervisorCus || "-"}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>영업 담당자(나인원)</Label>
                              <Input
                                className="detail-input"
                                value={contract.supervisorNo || "-"}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>영업 담당자(와탭)</Label>
                              <Input
                                className="detail-input"
                                value={contract.supervisorWt || "-"}
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <FormGroup>
                          <Label>첨부파일</Label>
                          <FileDownView domain="CONTRACT" domainId={id} />
                        </FormGroup>
                        <FormGroup>
                          <Label>비고</Label>
                          <Input
                            className="detail-input"
                            value={contract.content || ""}
                            disabled
                            type="textarea"
                          />
                        </FormGroup>
                        <ContractDetailButton
                          domainId={id}
                          domain="CONTRACT"
                          suffix="2"
                          deleteTf={contract.deleteTAndF}
                        />
                      </>
                    )}
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}

export default ContractDetail2;
