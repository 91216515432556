import { useEffect } from "react";
import { Button, FormGroup } from "reactstrap";

function OldFileUpload(props) {

  // 부모 컴포넌트로부터 전달받은 domainFileList와 setDomainFileList, setOldFileList 함수를 props로 받습니다.
  const domainFileList = props.domainFileList; // 도메인 파일 목록
  const setDomainFileList = props.setDomainFileList; // 도메인 파일 목록 설정 함수
  const setOldFileList = props.setOldFileList; // 기존 파일 목록 설정 함수

  // 기존 파일 목록을 설정하는 함수입니다.
  const defaultOldFileList = () => {
    var tempOldFileList = [];
    // domainFileList가 존재할 때만 동작합니다.
    domainFileList?.map((oldFile, index) => {
      tempOldFileList.push(oldFile.path.toString()); // 파일 경로를 문자열로 변환하여 tempOldFileList에 추가합니다.
    });
    // 설정된 기존 파일 목록을 setOldFileList 함수를 통해 업데이트합니다.
    setOldFileList(tempOldFileList);
  }

  // 컴포넌트가 렌더링될 때마다 기존 파일 목록을 초기화합니다.
  useEffect(() => {
    defaultOldFileList();
  }, [domainFileList]);

  //const fileInputRef = useRef(null);

  // 파일 개별 삭제
  const removeOldFile = (index) => {                // 기존에 첨부되어있었던 파일 x 누르면 기존 파일에서 삭제
    const exFilesAfterRemove = [...domainFileList]; // 기존 파일 목록을 복제합니다.
    exFilesAfterRemove.splice(index, 1);            // 선택한 파일을 기존 파일 목록에서 제거합니다.
    setDomainFileList(exFilesAfterRemove);          // 제거된 파일 목록으로 도메인 파일 목록을 업데이트합니다.

    var tempOldFileList = [];
    exFilesAfterRemove?.map((exFile, index) => {
      tempOldFileList.push(exFile.path);
    })
    setOldFileList(tempOldFileList);
  }

  // 파일 전체 삭제 함수입니다.
  const removeAllOldFiles = () => {
    // 사용자에게 확인을 받고 모든 파일을 삭제합니다.
    if (!window.confirm("모든 파일을 삭제하시겠습니까?")) {
      return; // 사용자가 취소하면 함수 실행을 종료합니다.
    }
    // 도메인 파일 목록과 기존 파일 목록을 모두 빈 배열로 설정하여 초기화합니다.
    setDomainFileList([]);
    setOldFileList([]);
  }

  // 파일 리스트 출력
  const printOldFileList = () => {

    return (
      <div>
        <Button
          onClick={removeAllOldFiles}
          style={{ marginLeft: "0em" }}
          color="danger"
        >
          전체 삭제
        </Button>
        <table>
          {domainFileList?.map((data, index) => (
            <tr key={index}>
              <td>{data.name}( {(data.size / (1000 * 1000))}mb )</td>
              <td>
                <Button
                  type="button"
                  onClick={() => removeOldFile(index)}
                  style={{ marginLeft: "1em" }}
                  size="sm"
                  color="danger"
                >
                  X
                </Button>
              </td>
            </tr>
          ))}
        </table>
      </div>
    );
  };


  return (
    <div className="content">
      {/* 파일 선택 버튼 */}
      <FormGroup>
        {printOldFileList()}
      </FormGroup>
    </div>
  );
}

export default OldFileUpload;
