import React, { useEffect, useState } from "react";
import "../../variables/RelationList";
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Spinner,
  Label,
  Input,
} from "reactstrap";
import RelationList from "../../variables/RelationList";
import { useParams } from "react-router-dom";
import { API } from "../../ApiConfig";
import instanceAxios from "components/reissue/InstanceAxios";
import DetailButton from "variables/DetailButton";
import FileDownView from "views/Support/FileDownView";
import ErrAlert from "variables/ErrAlert";

const titleName = "고객사";
/**
 *
 * @returns 고객사 리스트에서 클릭한 id값에 맞는 단일 게시물 상세보기 페이지를 리턴
 */
function CustomerDetail() {
  // 수정, 정보보기에 사용되는 클릭한 고객사의 id값
  const { id } = useParams();

  /**
   * 고객사 데이터를 불러와 사용하기 편하도록 변환하는 함수
   */
  async function fetchData() {
    try {
      const response = await instanceAxios.get(`${API.CUSTOMERLIST}/${id}`);
      const data = response.data?.data || [];
      const note = data.note || "";

      // setRelationCustomer(prop);
      setNote(note);
      setCustomer(data);
    } catch (error) {
      ErrAlert({ err: "데이터 불러오기 실패" });
      // 에러 처리
    } finally {
      // 로딩 상태 업데이트
      setLoading(false);
    }
  }
  // const [relationCustomer, setRelationCustomer] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [note, setNote] = useState("");
  const [loading, setLoading] = useState(true); // 로딩 상태 추가
  useEffect(() => {
    // 페이지 로딩 시 프로젝트 정보 가져오기
    fetchData();
  }, [id]); // ID가 변경될 때마다 useEffect 실행

  return (
    <>
      <div className="content">
        <Form>
          <div className="detail-wrap">
            <Card className="card-user">
              <CardHeader>
                <h5 className="title">{titleName} 상세보기</h5>
                <p className="category" style={{ fontSize: "17px" }}></p>
              </CardHeader>
              <CardBody>
                {loading ? (
                  <Spinner color="primary" />
                ) : (
                  <>
                    <FormGroup>
                      <Label>고객사</Label>
                      <Input
                        className="detail-input"
                        value={customer.customer}
                        disabled
                      />
                    </FormGroup>
                    <Label>지점 / 주소</Label>
                    {customer.customerAddress ? (
                      customer.customerAddress.map((address, index) => (
                        <div key={index}>
                          <FormGroup>
                            <Input
                              className="detail-input"
                              value={`${address.location} / ${address.address}`}
                              disabled
                            />
                          </FormGroup>
                        </div>
                      ))
                    ) : (
                      <div>
                        <FormGroup>
                          <Input
                            className="detail-input"
                            value="- / -"
                            disabled
                          />
                        </FormGroup>
                      </div>
                    )}
                    <FormGroup>
                      <Label>영업 담당자(나인원)</Label>
                      <Input
                        className="detail-input"
                        value={customer.supervisorNo}
                        disabled
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>영업 담당자(와탭)</Label>
                      <Input
                        className="detail-input"
                        value={customer.supervisorWt}
                        disabled
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>첨부파일</Label>
                      <FileDownView domain="CUSTOMER" domainId={id} />
                    </FormGroup>
                    <FormGroup>
                      <Label>비고</Label>
                      <Input
                        className="detail-input"
                        value={note}
                        disabled
                        type="textarea"
                      />
                    </FormGroup>
                    <DetailButton
                      domainId={id}
                      domain="CUSTOMER"
                      deleteTf={customer.deleteTAndF}
                    />
                    <hr></hr>
                    <div>
                      {/* RelationList(관련 정보보기)컴포넌트에 현재 상세보기 페이지의 고객사를 props로 전달 */}{" "}
                      <RelationList customer={customer} />
                    </div>
                  </>
                )}
              </CardBody>
            </Card>
          </div>
        </Form>
      </div>
    </>
  );
}

export default CustomerDetail;
