export const API = {
  LOGIN: "loginpage",
  MAIN: "/mainboard",
  CONTRACT: "contract",
  CONTRACTREGIST: "/contract/contractregist",
  CONTRACTDETAIL1: "/contract/contractdetail1", // 일반 계약
  CONTRACTDETAIL2: "/contract/contractdetail2", // 유지보수 계약
  CONTRACTMODIFY1: "/contract/contractmodify1",
  CONTRACTMODIFY2: "/contract/contractmodify2",
  PROJECT: "project",
  PROJECTREGIST: "/project/projectregist",
  PROJECTDETAIL: "/project/projectdetail",
  PROJECTMODIFY: "/project/projectmodify",
  MAINTAIN: "maintain",
  MAINTAINREGIST: "/maintain/maintainregist",
  MAINTAINDETAIL: "/maintain/maintaindetail",
  MAINTAINMODIFY: "/maintain/maintainmodify",
  SUPPORT: "support",
  SUPPORTREGIST: "/support/supportregist",
  SUPPORTDETAIL: "/support/supportdetail",
  SUPPORTMODIFY: "/support/supportmodify",
  LICENSE: "license",
  LICENSEREGIST: "/license/licenseregist",
  LICENSEDETAIL: "/license/licensedetail",
  LICENSEMODIFY: "/license/licensemodify",
  SUPERVISOR: "supervisor",
  SUPERVISORREGIST: "/supervisor/supervisorregist",
  SUPERVISORDETAIL: "/supervisor/supervisordetail",
  SUPERVISORMODIFY: "/supervisor/supervisormodify",
  CUSTOMER: "customer",
  CUSTOMERREGIST: "/customer/customerregist",
  CUSTOMERDETAIL: "/customer/customerdetail",
  CUSTOMERMODIFY: "/customer/customermodify",
  FOLLOWING: "support",
  FOLLOWINGREGIST: "/support/followingsupportregist",
  FOLLOWINGMODIFY: "/support/followingsupportmodify",
  MYPAGE: "mypage",
  MEMBER: "member",
  MEMBERDETAIL: "/member/memberdetail",
  MEMBERMODIFY: "/member/membermodify",
  NOTICE: "notice",
  NOTICEREGIST: "/notice/noticeregist",
  NOTICEDETAIL: "/notice/noticedetail",
  NOTICEMODIFY: "/notice/noticemodify",
  ERRORPAGE: "errorpage",
  // 수정 추가해야 됨.

  // 서버
  SUPPORTCALENDAR: "/api/support/calendar",
  REPAIRLIST: "/api/repair",
  PROJECTLIST: "/api/project",
  LICENSELIST: "/api/license",
  CUSTOMERLIST: "/api/customer",
  SUPPORTLIST: "/api/support",
  CONTRACTLIST: "/api/contract",
  SUPERVISORLIST: "/api/supervisor",
  MAINTAINLIST: "/api/repair",
  SUPERVISORNAME: "/api/supervisor/name",
  MEMBERNAME: "/api/member/name",
  MEMBERLIST: "/api/admin/member",
  FOLLOWINGLIST: "/api/following",
  REPAIRCALENDAR: "/api/repair/calendar",
  LICENSECALENDAR: "/api/license/calendar",
  NOTICELIST: "/api/notice",
  MYPAGESERVER: "/api/member/mail",
  FOLLOWINGCALENDAR: "/api/support/following/calendar",
};
