// import React, { useEffect, useState } from "react";
// import "../../variables/RelationList";
// import { Card, CardHeader, CardBody, Form, Button, Spinner } from "reactstrap";
// import { LocalizationProvider, StaticTimePicker } from "@mui/x-date-pickers";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import dayjs from "dayjs";
// import ErrAlert from "variables/ErrAlert";
// import instanceAxios from "components/reissue/InstanceAxios";
// import { API } from "ApiConfig";
// import Swal from "sweetalert2";
// import { useNavigate } from "react-router-dom";
// /**
//  * 내정보 페이지 입니다. 메일 수신 여부와 수신 시간을 설정 할 수 있습니다.
//  * @returns 메일 시간 설정과 수신여부 폼
//  */
// function MyPage() {
//   const [time, setTime] = useState(); // 초기값에 설정 해 놓은 시간
//   const [isChecked, setIsChecked] = useState(); // 초기값은 체크되지 않은 상태
//   const [loading, setLoading] = useState(true); // 로딩 상태 추가
//   const navigate = useNavigate();
//   async function fetchData() {
//     try {
//       const response = await instanceAxios.get(`${API.MYPAGESERVER}`);
//       const data = response.data?.data || [];

//       const hour = parseInt(data.mailTime.split(":")[0]); // 시간 부분 추출 및 정수형 변환
//       const minute = parseInt(data.mailTime.split(":")[1]); // 분 부분 추출 및 정수형 변환
//       const initialTime = dayjs().hour(hour).minute(minute);
//       const initialChecked = data.mailTF;
//       setTime(initialTime);
//       setIsChecked(initialChecked);
//     } catch (error) {
//       // 에러 처리
//       ErrAlert({ err: "데이터 불러오기 실패" });
//     } finally {
//       // 로딩 상태 업데이트
//       setLoading(false);
//     }
//   }

//   useEffect(() => {
//     fetchData();
//   }, []);

//   /**
//    * 체크박스 변경 이벤트
//    * @param {*} event
//    */
//   const handleCheckboxChange = (event) => {
//     setIsChecked(event.target.checked);
//   };

//   /**
//    * 저장 버튼 클릭 함수
//    */
//   const handleTimeSubmit = async () => {
//     Swal.fire({
//       icon: "question",
//       title: "저장",
//       text: `저장 하시겠습니까?`,
//       showCancelButton: true,
//       confirmButtonText: "저장",
//       cancelButtonText: "취소",
//     }).then(async () => {
//       try {
//         const hour = time.$H;
//         const minute = time.$m;

//         const formattedTime = `${hour.toString().padStart(2, "0")}:${minute
//           .toString()
//           .padStart(2, "0")}`;
//         const requestBody = JSON.stringify({
//           mailTF: isChecked,
//           mailTime: formattedTime,
//         });
//         const request = await instanceAxios.patch(
//           `${API.MYPAGESERVER}`,
//           requestBody
//         );
//         navigate(`${API.MAIN}`);
//       } catch (error) {
//         ErrAlert({ err: "데이터 전송 실패" });
//         console.error("데이터 전송 실패:", error.message);
//       }
//     });
//   };
//   return (
//     <>
//       <div className="content">
//         <Form>
//           <div className="mypage-wrap">
//             <Card className="card-user mycard">
//               <CardHeader>
//                 <h5 className="title">내정보</h5>
//                 <span
//                   style={{
//                     fontSize: "1.3em",
//                     marginLeft: "15px",
//                   }}
//                 >
//                   메일 설정
//                 </span>
//               </CardHeader>
//               <CardBody>
//                 {loading ? (
//                   <Spinner color="primary" style={{ marginTop: "15px" }} />
//                 ) : (
//                   <>
//                     <LocalizationProvider dateAdapter={AdapterDayjs}>
//                       <StaticTimePicker
//                         sx={{
//                           "& .css-4f0ona-MuiClock-clock": {
//                             backgroundColor: "#BBCEFF",
//                           },
//                           "& .css-umzx0k-MuiClock-pin": {
//                             backgroundColor: "#270571",
//                           },
//                           "& .css-d0vs79-MuiClockPointer-root": {
//                             backgroundColor: "#270571",
//                           },
//                           "& .css-12t0dn4-MuiClockPointer-thumb": {
//                             backgroundColor: "#270571",
//                             border: "16px solid #7377CD;",
//                           },
//                           "& .css-f53ilk-MuiClockPointer-thumb": {
//                             backgroundColor: "#270571",
//                             border: "16px solid #270571;",
//                           },
//                           "& .css-1e6y48t-MuiButtonBase-root-MuiButton-root": {
//                             display: "none !important;",
//                           },
//                           "& .css-1hbyad5-MuiTypography-root": {
//                             position: "relative",
//                             visibility: "hidden",
//                           },
//                           "& .css-1hbyad5-MuiTypography-root::after": {
//                             position: "absolute",
//                             content: '"메일 발송 시간"',
//                             visibility: "visible",
//                             top: 0,
//                             left: 0,
//                             fontWeight: "bold",
//                           },
//                           "& .css-2x8kvt-MuiPickersArrowSwitcher-root-MuiTimeClock-arrowSwitcher":
//                             {
//                               right: "-15px",
//                             },
//                         }}
//                         orientation="landscape"
//                         value={time}
//                         onChange={(newValue) => setTime(newValue)}
//                       />
//                       <span
//                         style={{
//                           fontSize: "1.2em",
//                           marginLeft: "15px",
//                         }}
//                       >
//                         메일 수신 여부
//                       </span>
//                       <div
//                         style={{
//                           marginTop: "15px",
//                           marginLeft: "15px",
//                         }}
//                       >
//                         <input
//                           type="checkbox"
//                           name="checkRL"
//                           checked={isChecked}
//                           onChange={handleCheckboxChange}
//                         />
//                         <span
//                           style={{
//                             marginLeft: "4px",
//                             fontSize: "13px",
//                             marginRight: "10px",
//                           }}
//                         >
//                           메일을 받겠습니다.
//                         </span>
//                       </div>
//                       <div style={{ textAlign: "right", marginRight: "20px" }}>
//                         <Button
//                           outline
//                           color="primary"
//                           type="button"
//                           onClick={handleTimeSubmit}
//                         >
//                           저장
//                         </Button>
//                       </div>
//                     </LocalizationProvider>
//                   </>
//                 )}
//               </CardBody>
//             </Card>
//           </div>
//         </Form>
//       </div>
//     </>
//   );
// }

// export default MyPage;
