/*!

=========================================================
* Paper Dashboard React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import instanceAxios from "components/reissue/InstanceAxios";
import { useNavigate } from "react-router-dom";
import { API } from "ApiConfig";
import FileUpload from "components/RegistForm/FileUpload";
import Swal from "sweetalert2";
import DeleteButton from "variables/DeleteButton";
import ToastAlert from "variables/ToastAlert";
import ErrAlert from "variables/ErrAlert";
import CkEditor from "variables/CkEditor";

/**
 * 공지사항 등록 페이지
 * @author 김지연
 * @returns 공지사항 등록 폼 리턴
 */

function NoticeRegist() {
  const navigate = useNavigate();
  const memRole = localStorage.getItem("memRole");

  // =============== form submit시 내보낼 형식 ==============
  const [title, setTitle] = useState(""); // 공지사항 제목
  const [content, setContent] = useState(""); // 공지사함 본문
  const [file, setFile] = useState([]);

  useEffect(() => {
    if (memRole === "USER") {
      ErrAlert({ err: "접근할 수 없는 권한입니다." });
      navigate(`/${API.NOTICE}`);
    }
  }, []);

  // form submit시 FormData 내용
  const handleSubmit = async (e) => {
    e.preventDefault();
    Swal.fire({
      icon: "question",
      title: "등록",
      text: `등록 하시겠습니까?`,
      showCancelButton: true,
      confirmButtonText: "등록",
      cancelButtonText: "취소",
    }).then(async (res) => {
      if (res.isConfirmed) {
        const formData = new FormData();
        formData.append("title", title);
        formData.append("content", content);

        //파일 및 멤버 처리
        for (var i = 0; i < file.length; i++) {
          formData.append("fileList", file[i]);
        }

        try {
          const response = await instanceAxios.post(
            `${API.NOTICELIST}`,
            formData,
            {
              headers: {
                "Content-Type": `multipart/form-data;charset=UTF-8`,
              },
            }
          );
          ToastAlert({ icon: "success", title: "등록이 완료되었습니다." });
          navigate(`/${API.NOTICE}`);
        } catch (error) {
          ErrAlert({ err: "필수 항목을 확인해주세요" });
        }
      } else {
        //취소
      }
    });
  };

  //============= 등록 폼
  return (
    <div className="content">
      <div className="regist-wrap">
        <Card className="card-user">
          <CardHeader>
            <CardTitle tag="h5">공지사항 등록</CardTitle>
          </CardHeader>
          <CardBody>
            <Form encType="multipart/form-data" onSubmit={handleSubmit}>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <label>제목</label>
                    <Input
                      type="text"
                      name="title"
                      onChange={(event) => {
                        setTitle(event.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <FileUpload file={file} setFile={setFile} />
              <Row>
                <Col md="12">
                  <FormGroup>
                    <label>본문</label>
                    {/* <Input
                      className="textArea"
                      type="textarea"
                      name="content"
                      onChange={(event) => {
                        setContent(event.target.value);
                      }}
                    /> */}
                    <CkEditor onChange={setContent}/>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <DeleteButton
                  to={`/${API.NOTICE}`}
                  text1="작성을 취소 하시겠습니까?"
                />
              </Row>
            </Form>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
export default NoticeRegist;
