// 고객사 연관정보 리스트 - 라이선스
import React, { useEffect } from "react";
import { useState } from "react";
import Paging from "../../../variables/Paging";
import "../../../assets/css/List.css";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Col,
  Spinner,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { API } from "../../../ApiConfig";

/**
 * 어떤 리스트인지 나타내는 제목입니다.
 */
const titleName = "라이선스";

/**
 *
 * @returns table형식의 list page를 리턴합니다.
 */
function LicenseRD(prop) {
  const [licenseData, setLicenseData] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(3000); // 전체 요소 갯수
  const [loading, setLoading] = useState(true); // 로딩 상태 추가
  const PER_PAGE = 5; // 한 페이지 출력 갯수

  function fetchData() {
    try {
      const data = prop.data.customer.licenseListResponseDtoList;

      setCount(data.length);
      setLicenseData(data);
    } catch (error) {
      console.log(error);
      // 에러 처리
    } finally {
      // 로딩 상태 업데이트
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  /**
   * 페이지가 변경될 때마다 페이지 번호를 가져와 useState의 setPage를 이용하여 업데이트 해주는 함수입니다.
   * @param {number} page
   */
  const handlePageChange = (page) => {
    setPage(page);
    // const pageData = await getDatabyPage(page);
    // setitems([]]);
    // 데이터가 바껴야 함.
  };

  const navigate = useNavigate();

  /**
   * 배열을 페이지 번호에 맞춰 반복문을 돌려 담아주는 함수입니다.
   * @returns 게시글을 배열에 담아 리턴합니다.
   */
  function arrayList() {
    var array = [];
    const pageRender = (page - 1) * PER_PAGE;

    // 초기 리스트에 데이터가 없을 때
    if (!licenseData || licenseData.length === 0) {
      return (
        <tr key="no-data" style={{ textAlign: "center" }}>
          <td colSpan="5">등록된 글이 없습니다.</td>
        </tr>
      );
    }
    const startingIndex = (page - 1) * PER_PAGE + 1; // 현재 페이지의 첫 번째 아이템 번호
    // 초기 리스트에 데이터가 있을 때
    licenseData
    .slice(pageRender, pageRender + PER_PAGE)
    .map((item, index) =>
      array.push(
        <tr
          key={item.id}
          id="move"
          onClick={() => navigate(`${API.LICENSEDETAIL}/${item.id}`)}
          style={{ textAlign: "center" }}
          className={item.deleteTandF ? "deleted-text" : ""}
        >
          <td>{startingIndex + index || "-"}</td>
          <td>{item.license || "-"}</td>
          {item.itemList && item.itemList.length > 0 ? (
            <td>
              {item.itemList.every(subItem =>
                subItem.majorCategory === "" &&
                subItem.middleCategory === "" &&
                subItem.smallCategory === "" &&
                subItem.itemDetail === ""
              ) ? (
                <span>-</span>
              ) : (
                item.itemList.map((subItem, index) => (
                  <span key={index}>
                    {"["}
                    {subItem.majorCategory}, {subItem.middleCategory}, {subItem.smallCategory}, {subItem.itemDetail}
                    {"]"}
                    {index !== item.itemList.length - 1 ? " / " : ""}
                  </span>
                ))
              )}
            </td>
          ) : (
            <td>-</td>
          )}
          <td>{item.expiredDate || "-"}</td>
        </tr>
      )
    );

    return array;
  }
  return (
    <>
      <div className="content">
        <Card>
          <CardHeader>
            <CardTitle className="cardTitle" tag="h4">
              {titleName}
            </CardTitle>
          </CardHeader>
          <CardBody>
            {loading ? (
              <Spinner color="primary" />
            ) : (
              <>
                <div style={{ display: "flex" }}>
                  <Col md="auto" className="d-flex align-items-center">
                    <p
                      className="category"
                      style={{ fontSize: "17px", display: "flex" }}
                    >
                      <span>전체 {count}건</span>
                    </p>
                  </Col>
                </div>
                <Table>
                  <thead className="text-primary">
                    <tr style={{ textAlign: "center" }}>
                      <th>번호</th>
                      {/* <th>고객사</th> */}
                      <th>라이선스</th>
                      <th>항목</th>
                      {/* <th>발급일자</th> */}
                      <th>만료일자</th>
                    </tr>
                  </thead>
                  <tbody>{arrayList()}</tbody>
                </Table>
              </>
            )}
          </CardBody>
          {!loading && (
            <Paging
              count={count}
              page={page}
              perPage={PER_PAGE}
              handlePageChange={handlePageChange}
            />
          )}
        </Card>
      </div>
    </>
  );
}

export default LicenseRD;
