// 셀렉트 박스 항목, value는 api/~에 맞게 적어주세요
const getSelectOptions = (type) => {
  switch (type) {
    case "project":
      return [
        { value: "customer", label: "고객사" },
        { value: "project", label: "프로젝트" },
      ];
    case "license":
      return [
        { value: "customer", label: "고객사" },
        { value: "license", label: "라이선스명" },
      ];
    case "maintain":
      return [
        { value: "customer", label: "고객사" },
        { value: "contract", label: "계약명" },
      ];
    case "supervisor":
      return [
        { value: "customer", label: "고객사" },
        { value: "supervisor", label: "담당자" },
      ];
    case "customer":
      return [{ value: "customer", label: "고객사" }];
    case "contract":
      return [
        { value: "customer", label: "고객사" },
        { value: "contract", label: "계약명" },
        // { value: "contractClass", label: "구분" },
      ];
    case "support":
      return [
        { value: "customer", label: "고객사" },
        { value: "support", label: "지원명" },
      ]; 
    case "member":
      return[
        { value: "state", label: "상태"},
        { value: "name", label: "이름"},
      ];
    default:
      return [];
  }
};

export { getSelectOptions };
