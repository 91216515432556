import React, { useEffect } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


const CkEditor = ({ onChange, data, disabled = false }) => {
    // 고유한 ID 생성
    const readOnlyLockId = 'readOnlyLockId_' + Math.random().toString(36).substr(2, 9);

    useEffect(() => {
        // 전역 오류 핸들러 설정
        const handleError = (message, source, lineno, colno, error) => {
            if (message.includes('ResizeObserver loop limit exceeded')) {
                alert('ResizeObserver loop limit exceeded 에러가 발생했습니다.');
                return true; // 에러를 콘솔에 표시하지 않음
            }
        };

        window.addEventListener('error', handleError);

        return () => {
            window.removeEventListener('error', handleError);
        };
    }, []);


    return (
        <div className="App">
            <CKEditor
                editor={ClassicEditor}
                data={data}
                config={{
                    placeholder: '내용을 입력하세요.',
                    toolbar: {
                        items: ['undo', 'redo',
                            '|', 'heading',
                            '|', 'bold', 'italic',
                            '|', 'link', 'insertTable', 'blockQuote',
                            '|', 'bulletedList', 'numberedList'
                        ],
                        shouldNotGroupWhenFull: true
                    }
                }}
                onReady={editor => {
                    const toolbarElement = editor.ui.view.toolbar.element;
                    if (disabled) {
                        editor.enableReadOnlyMode(readOnlyLockId);
                        toolbarElement.style.display = 'none';
                    } else {
                        toolbarElement.style.display = 'flex';
                    }
                }}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    onChange(data);
                }}
                disabled={disabled}
            />
        </div>
    );
}

export default CkEditor;