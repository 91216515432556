import { API } from "ApiConfig";
import instanceAxios from "components/reissue/InstanceAxios";
import React, { useCallback, useEffect, useState } from "react";
import { Button } from "reactstrap";
import ErrAlert from "variables/ErrAlert";

function FileDownView(props) {
  const domain = props.domain;
  const domainPost = `${props.domain}LIST`;
  const domainId = props.domainId;

  // 파일 다운로드 URL과 파일 이름을 상태로 관리합니다.
  const [fileDownUrl, setFileDownUrl] = useState([]);   // 파일 다운로드 URL
  const [fileName, setFileName] = useState([]);         // 파일 이름

  const getSupportDetail = useCallback(async () => {
    try {
      await instanceAxios
        .get(`${API[domainPost]}/${domainId}`)
        .then((response) => {
          var tempFileName = [];    // 임시 파일 이름 배열
          var tempFileDownUrl = []; // 임시 파일 다운로드 URL 배열

          // 파일 이름 배열
          response.data.data.fileResponseDtoList.map((file) => {
            tempFileName.push(file.name);   // 파일 이름을 배열에 추가합니다.
          });
          setFileName(tempFileName);        // 파일 이름 상태를 업데이트합니다.

          // 각 파일의 다운 url 배열
          tempFileName.map((filename) => {
            var downUrl = `/api/file?domain=${domain}&domainId=${domainId}&filename=${filename}`;
            tempFileDownUrl.push(downUrl);  // 임시 파일 다운로드 URL 배열에 추가합니다.
          });
          setFileDownUrl(tempFileDownUrl);  // 파일 관련 오류 발생 시 알림을 표시합니다.
        });
    } catch (e) {
      ErrAlert({ err: "File Error" });
    }
  });

  useEffect(() => {
    getSupportDetail();
  }, []);

  // 각 파일마다 다운로드 요청 따로 보내야한다.
  const fileDownRequest = async (requestUrl, fileName) => {
    try {
      await instanceAxios
        .get(requestUrl, { responseType: "blob" })  // 파일 다운로드 요청을 보냅니다.
        .then((response) => {
          const blob = response.data;                         // 응답 데이터를 Blob으로 가져옵니다.
          const link = document.createElement("a");           // a 태그 엘리먼트를 생성합니다.
          const blobUrl = window.URL.createObjectURL(blob);   // Blob URL을 생성합니다.

          link.href = blobUrl;      // 링크의 href 속성을 설정합니다.
          link.download = fileName; // 다운로드 속성을 설정합니다.

          document.body.appendChild(link); // body에 링크를 추가합니다.
          link.click();                    // 링크를 클릭합니다.
          document.body.removeChild(link); // body에서 링크를 제거합니다.
        });
    } catch (e) {
      ErrAlert({ err: "File Error" });
    }
  };

  // 첨부 파일 뷰 -> 나중에 컴포넌트 분리할 예정
  const getFileDownView = () => {
    return fileDownUrl.map((url, index) => (
      <p>
        <li>
          {fileName[index]}

          <Button
            type="button"
            size="sm"
            style={{ marginLeft: "1em" }}
            onClick={(e) => {
              fileDownRequest(url, fileName[index]);
            }}
          >
            {" "}
            다운로드
          </Button>
        </li>
      </p>
    ));
  };

  return getFileDownView();
}

export default FileDownView;
