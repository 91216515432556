// 컴포넌트들 불러와서 라우터에 묶어주는곳, 사이드바임

import Dashboard from "views/Main/Dashboard.js";
import ProjectList from "views/Project/ProjectList";
import MaintainList from "views/Maintain/MaintainList";
import SupportList from "views/Support/SupportList";
import LicenseList from "views/License/LicenseList";
import SupervisorList from "views/Supervisor/SupervisorList";
import CustomerList from "views/Customer/CustomerList";
import ContractList from "views/Contract/ContractList";
import CustomerDetail from "views/Customer/CustomerDetail";
import SupportDetail from "views/Support/SupportDetail";
import ProjectDetail from "views/Project/ProjectDetail";
import MaintainDetail from "views/Maintain/MaintainDetail";
import LicenseDetail from "views/License/LicenseDetail";
import ContractDetail1 from "views/Contract/ContractDetail1";
import ContractDetail2 from "views/Contract/ContractDetail2";
import SupervisorDetail from "views/Supervisor/SupervisorDetail";
import CustomerRegist from "views/Customer/CustomerRegist";
import LicenseRegist from "views/License/LicenseRegist";
import MaintainRegist from "views/Maintain/MaintainRegist";
import ProjectRegist from "views/Project/ProjectRegist";
import SupportRegist from "views/Support/SupportRegist";
import ContractRegist from "views/Contract/ContractRegist";
import SupervisorRegist from "views/Supervisor/SupervisorRegist";
import LoginPage from "views/Login/LoginPage";
import ContractCommonModify from "views/Contract/ContractCommonModify";
import ContractMaintainModify from "views/Contract/ContractMaintainModify";
import CustomerModify from "views/Customer/CustomerModify";
import LicenseModify from "views/License/LicenseModify";
import MaintainModify from "views/Maintain/MaintainModify";
import ProjectModify from "views/Project/ProjectModify";
import SupervisorModify from "views/Supervisor/SupervisorModify";
import SupportModify from "views/Support/SupportModify";
import FollowingSupportRegist from "views/Support/FollowingSupportRegist";
import FollowingSupportModify from "views/Support/FollowingSupportModify";
import { API } from "ApiConfig";
import MemberList from "views/Admin/MemberList";
import MyPage from "views/MyPage/MyPage";
import MemberDetail from "views/Admin/MemberDetail";
import NoticeList from "./views/Notice/NoticeList";
import NoticeDetail from "./views/Notice/NoticeDetail";
import NoticeRegist from "./views/Notice/NoticeRegist";
import NoticeModify from "./views/Notice/NoticeModify";
import MemberModify from "views/Admin/MemberModify";
import ErrorPage from "views/ErrorPage";
import { Navigate } from "react-router-dom";

const memRole = localStorage.getItem("memRole");

var routes = [
  {
    path: `mainboard`,
    name: "메인보드",
    icon: "nc-icon nc-calendar-60",
    component: <Dashboard />,
    layout: "/",
  },
  {
    path: `${API.CONTRACT}`,
    name: "계약",
    icon: "nc-icon nc-paper",
    component: <ContractList />,
    layout: "/",
  },
  {
    path: `${API.PROJECT}`,
    name: "프로젝트",
    icon: "nc-icon nc-bulb-63",
    component: <ProjectList />,
    layout: "/",
  },
  {
    path: `${API.MAINTAIN}`,
    name: "유지보수",
    icon: "nc-icon nc-settings",
    component: <MaintainList />,
    layout: "/",
  },
  {
    path: `${API.LICENSE}`,
    name: "라이선스",
    icon: "nc-icon nc-credit-card",
    component: <LicenseList />,
    layout: "/",
  },
  {
    path: `${API.SUPPORT}`,
    name: "지원",
    icon: "nc-icon nc-briefcase-24",
    component: <SupportList />,
    layout: "/",
  },
  {
    path: `${API.CUSTOMER}`,
    name: "고객사",
    icon: "nc-icon nc-alert-circle-i",
    component: <CustomerList />,
    layout: "/",
  },
  {
    path: `${API.SUPERVISOR}`,
    name: "담당자",
    icon: "nc-icon nc-badge",
    component: <SupervisorList />,
    layout: "/",
  },
  {
    path: `${API.CUSTOMERDETAIL}/:id`,
    name: "고객사상세",
    icon: "nc-icon nc-caps-small",
    component: <CustomerDetail />,
    layout: "/",
  },
  {
    path: `${API.SUPERVISORDETAIL}/:id`,
    name: "담당자상세",
    icon: "nc-icon nc-caps-small",
    component: <SupervisorDetail />,
    layout: "/",
  },
  {
    path: `${API.SUPPORTDETAIL}/:id`,
    name: "지원상세",
    icon: "nc-icon nc-caps-small",
    component: <SupportDetail />,
    layout: "/",
  },
  {
    path: `${API.PROJECTDETAIL}/:id`,
    name: "프로젝트상세",
    icon: "nc-icon nc-caps-small",
    component: <ProjectDetail />,
    layout: "/",
  },
  {
    path: `${API.MAINTAINDETAIL}/:id`,
    name: "유지보수상세",
    icon: "nc-icon nc-caps-small",
    component: <MaintainDetail />,
    layout: "/",
  },
  {
    path: `${API.LICENSEDETAIL}/:id`,
    name: "라이선스상세",
    icon: "nc-icon nc-caps-small",
    component: <LicenseDetail />,
    layout: "/",
  },
  {
    path: `${API.CONTRACTDETAIL1}/:id`,
    name: "일반계약상세",
    icon: "nc-icon nc-caps-small",
    component: <ContractDetail1 />,
    layout: "/",
  },
  {
    path: `${API.CONTRACTDETAIL2}/:id`,
    name: "유지보수계약상세",
    icon: "nc-icon nc-caps-small",
    component: <ContractDetail2 />,
    layout: "/",
  },
  // {
  //   // 성수
  //   path: "/SSSS",
  //   name: "성수 담당자 등록페이지",
  //   icon: "nc-icon nc-single-02",
  //   component: <ManagerRegistSS />,
  //   layout: "/admin",
  // },
  {
    path: `${API.LICENSEREGIST}`,
    name: "라이선스등록",
    icon: "nc-icon nc-caps-small",
    component: <LicenseRegist />,
    layout: "/",
  },
  {
    path: `${API.CUSTOMERREGIST}`,
    name: "고객사등록",
    icon: "nc-icon nc-caps-small",
    component: <CustomerRegist />,
    layout: "/",
  },
  {
    path: `${API.MAINTAINREGIST}`,
    name: "유지보수등록",
    icon: "nc-icon nc-caps-small",
    component: <MaintainRegist />,
    layout: "/",
  },
  {
    path: `${API.PROJECTREGIST}`,
    name: "프로젝트등록",
    icon: "nc-icon nc-caps-small",
    component: <ProjectRegist />,
    layout: "/",
  },
  {
    path: `${API.SUPPORTREGIST}`,
    name: "지원등록",
    icon: "nc-icon nc-caps-small",
    component: <SupportRegist />,
    layout: "/",
  },
  {
    path: `${API.CONTRACTREGIST}`,
    name: "계약등록",
    icon: "nc-icon nc-caps-small",
    component: <ContractRegist />,
    layout: "/",
  },
  {
    path: `${API.SUPERVISORREGIST}`,
    name: "담당자등록",
    icon: "nc-icon nc-caps-small",
    component: <SupervisorRegist />,
    layout: "/",
  },
  {
    path: `${API.LOGIN}`,
    name: "LoginPage",
    icon: "nc-icon nc-caps-small",
    component: <LoginPage />,
    layout: "/",
  },
  {
    path: `${API.CONTRACTMODIFY1}/:id`,
    name: "일반계약수정",
    icon: "nc-icon nc-caps-small",
    component: <ContractCommonModify />,
    layout: "/",
  },
  {
    path: `${API.CONTRACTMODIFY2}/:id`,
    name: "유지보수계약수정",
    icon: "nc-icon nc-caps-small",
    component: <ContractMaintainModify />,
    layout: "/",
  },
  {
    path: `${API.CUSTOMERMODIFY}/:id`,
    name: "고객사수정",
    icon: "nc-icon nc-caps-small",
    component: <CustomerModify />,
    layout: "/",
  },
  {
    path: `${API.LICENSEMODIFY}/:id`,
    name: "라이선스수정",
    icon: "nc-icon nc-caps-small",
    component: <LicenseModify />,
    layout: "/",
  },
  {
    path: `${API.MAINTAINMODIFY}/:id`,
    name: "유지보수수정",
    icon: "nc-icon nc-caps-small",
    component: <MaintainModify />,
    layout: "/",
  },
  {
    path: `${API.PROJECTMODIFY}/:id`,
    name: "프로젝트수정",
    icon: "nc-icon nc-caps-small",
    component: <ProjectModify />,
    layout: "/",
  },
  {
    path: `${API.SUPERVISORMODIFY}/:id`,
    name: "담당자수정",
    icon: "nc-icon nc-caps-small",
    component: <SupervisorModify />,
    layout: "/",
  },
  {
    path: `${API.SUPPORTMODIFY}/:id`,
    name: "지원수정",
    icon: "nc-icon nc-caps-small",
    component: <SupportModify />,
    layout: "/",
  },
  {
    path: `${API.FOLLOWINGREGIST}`,
    name: "이어쓰기등록",
    icon: "nc-icon nc-caps-small",
    component: <FollowingSupportRegist />,
    layout: "/",
  },
  {
    path: `${API.FOLLOWINGMODIFY}/:supportId/:domainId`,
    name: "이어쓰기수정",
    icon: "nc-icon nc-caps-small",
    component: <FollowingSupportModify />,
    layout: "/",
  },
  {
    path: `${API.MYPAGE}`,
    name: "내정보",
    icon: "nc-icon nc-caps-small",
    component: <MyPage />,
    layout: "/",
  },
  {
    path: `${API.NOTICE}`,
    name: "공지사항",
    icon: "nc-icon nc-bulb-63",
    component: <NoticeList />,
    layout: "/",
  },
  {
    path: `${API.NOTICEDETAIL}/:id`,
    name: "공지사항상세",
    icon: "nc-icon nc-caps-small",
    component: <NoticeDetail />,
    layout: "/",
  },
  {
    path: `${API.NOTICEREGIST}`,
    name: "공지사항등록",
    icon: "nc-icon nc-caps-small",
    component: <NoticeRegist />,
    layout: "/",
  },
  {
    path: `${API.NOTICEMODIFY}/:id`,
    name: "공지사항수정",
    icon: "nc-icon nc-caps-small",
    component: <NoticeModify />,
    layout: "/",
  },
  {
    path: `${API.MYPAGE}`,
    name: "내정보",
    icon: "nc-icon nc-caps-small",
    component: <MyPage />,
    layout: "/",
  },
  {
    path: `${API.ERRORPAGE}`,
    name: "에러페이지",
    icon: "nc-icon nc-caps-small",
    component: <ErrorPage />,
    layout: "/",
  },
  {
    path: `*`,
    name: "에러페이지",
    icon: "nc-icon nc-caps-small",
    component: <Navigate to="/errorpage" />,
    layout: "/",
  },
];


// 멤버 권한이 "ADMIN"(관리자) 일때만 아래의 route 추가
if (memRole === "ADMIN") {
  routes.push({
    path: `${API.MEMBER}`,
    name: "관리자",
    icon: "nc-icon nc-settings-gear-65",
    component: <MemberList />,
    layout: "/",
  });
  routes.push({
    path: `${API.MEMBERDETAIL}/:id`,
    name: "멤버 상세",
    icon: "nc-icon nc-caps-small",
    component: <MemberDetail />,
    layout: "/",
  });
  routes.push({
    path: `${API.MEMBERMODIFY}/:id`,
    name: "멤버 수정",
    icon: "nc-icon nc-caps-small",
    component: <MemberModify />,
    layout: "/",
  });
  routes.push({
    path: `${API.MEMBERMODIFY}/:id`,
    name: "멤버 수정",
    icon: "nc-icon nc-caps-small",
    component: <MemberModify />,
    layout: "/",
  });
}

export default routes;
