import React, { useEffect } from "react";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import logo from "../../assets/img/logo+.png";
import { API } from "../../ApiConfig";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const navigate = useNavigate();

  // useEffect를 사용하여 컴포넌트가 렌더링될 때 실행되도록 설정
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      //  addTokenToHeader(); // 헤더에 토큰 추가
      navigate(`${API.MAIN}`);

      // 강제로 새로고침
      window.location.reload();
    }
  }, []);

  const handleSocialLogin = () => {
    window.location.assign(
      `https://nosum.nineonesoft.com/oauth2/authorization/google`
      // `http://localhost:8080/oauth2/authorization/google`
    );
  };

  return (
    <>
      <div className="login-wrapper">
        <Card
          className="text-center"
          style={{
            width: "20rem",
            margin: "auto",
            marginTop: "8em",
            opacity: "0.85",
          }}
        >
          <CardHeader style={{ marginTop: "20px" }}>
            <div>
              <div>
                <img src={logo} alt="logo" style={{ width: "15rem" }} />
              </div>
            </div>
            <hr
              style={{
                border: "1px solid #A3BAF8",
                opacity: "0.5",
                marginTop: "20px",
                width: "80%",
              }}
            />
            <CardTitle tag="h3" style={{ marginTop: "30px" }}>
              Welcome!
            </CardTitle>
          </CardHeader>
          <CardBody>
            <div className="login-box">
              <a
                onClick={() => handleSocialLogin()}
                className="social-button"
                id="google-connect"
              >
                <span>Connect with Google</span>
              </a>
              <ul class="bg-bubbles">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default LoginPage;
