import { useRef } from "react";
import { Button, FormGroup } from "reactstrap";

function FileUpload({ file, setFile }) {
  const fileInputRef = useRef(null);

  // 파일 개별 삭제
  const removeFile = (index) => {
    if (!window.confirm(`첨부된 ${index + 1} 번째 파일을 삭제하시겠습니까?}`)) {
      return;
    }
    const filesAfterRemove = [...file];
    filesAfterRemove.splice(index, 1);
    setFile(filesAfterRemove);
  };

  // 파일 전체 삭제
  const removeAllFiles = () => {
    if (!window.confirm("모든 파일을 삭제하시겠습니까?")) {
      return;
    }
    setFile([]);
  };

  // 파일 리스트 출력
  const printFileList = () => {
    return (
      <table>
        {file.map((data, index) => (
          <tr key={index}>
            <td>
              {data.name}
            </td>
            <td>
              <Button
                onClick={() => removeFile(index)}
                style={{ marginLeft: "2em" }}
                size="sm"
                color="danger"
              >
                X
              </Button>
            </td>
          </tr>
        ))}
      </table>
    );
  };

  // 파일 선택 버튼 클릭 시 input 클릭 이벤트 핸들러
  const handleFileSelect = () => {
    fileInputRef.current.value = ''; // 파일 input의 value를 비워줍니다.
    fileInputRef.current.click();
  };

  // 파일 input onChange .. 실질적으로 파일 리스트에 추가
  const handleFileInputChange = (event) => {
    var tempFileList = [];
    for (var i = 0; i < event.target.files.length; i++) {
      tempFileList.push(event.target.files[i]);
    }
    setFile(prevFiles => [...prevFiles, ...tempFileList]);
  };

  return (
    <div className="content">
      {/* 파일 선택 버튼 */}
      <FormGroup>
        <Button
          onClick={handleFileSelect}>
          파일 선택
        </Button>
        <Button
          onClick={removeAllFiles}
          style={{ marginLeft: "1em" }}
          color="danger"
        >
          전체 삭제
        </Button>
        {/* 파일 선택 input */}
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileInputChange}
          multiple
        />
      </FormGroup>

      {/* 선택한 파일 목록 */}
      <FormGroup>
        {printFileList()}
      </FormGroup>
    </div>
  );
}

export default FileUpload;
